import React from 'react';
import { Button } from 'baseui/button';
import { Block } from 'baseui/block';
import { Check } from 'baseui/icon';
import { FilterUnreadMessagesProps } from '../../relay-messenger-list/RelayMessenger.list';
import { FilterMethods } from '../../relay-messenger-conversations/RelayMessengerConversations';

interface FilterLastModifiedItemProps {
  filterUnreadMessages: FilterUnreadMessagesProps;
  close: () => void;
  dataTestId?: string;
}

export const FilterUnreadAction = ({
  filterUnreadMessages,
  close,
  dataTestId,
}: FilterLastModifiedItemProps) => {
  const {
    filterOptionUnreadMessagesClicked,
    setFilterOptionUnreadMessagesClicked,
  } = filterUnreadMessages;

  return (
    <Block
      overrides={{
        Block: {
          style: {
            width: '190px',
          },
        },
      }}
    >
      <Button
        onClick={() => {
          if (filterOptionUnreadMessagesClicked === FilterMethods.Unread) {
            setFilterOptionUnreadMessagesClicked(null);
          } else {
            setFilterOptionUnreadMessagesClicked(FilterMethods.Unread);
            close();
          }
        }}
        value={FilterMethods.Unread}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              display: 'flex',
              color: filterOptionUnreadMessagesClicked ? '#3693BF' : 'black',
              backgroundColor: filterOptionUnreadMessagesClicked
                ? '#E1EFF5'
                : 'transparent',
              fontWeight: filterOptionUnreadMessagesClicked
                ? 'bolder'
                : 'inherit',
              textAlign: 'left',
              fontSize: '12px',
              outline: 'none !important',
              justifyContent: 'left',
              ':hover': {
                backgroundColor: 'none',
              },
              ':active': {
                backgroundColor: 'none',
              },
            },
            props: {
              'data-testid': 'filter-unread-btn',
            },
          },
        }}
        startEnhancer={() =>
          filterOptionUnreadMessagesClicked ? (
            <Check size={'30px'} color="#3693BF" />
          ) : (
            ''
          )
        }
      >
        {FilterMethods.Unread}
      </Button>
    </Block>
  );
};
