import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TitleService } from '@app/core/services/title.service';
import { Router } from '@angular/router';
import {
  AutoResponses,
  TwoWaySetupService,
} from '@app/two-way/services/two-way-setup.service';
import { ProductGroup } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { FeatureService } from '@app/core/services/feature.service';
import { merge } from 'rxjs';

export enum SelectedAutoResponse {
  DefaultAutoResponse = 'default-auto-response',
  AfterHoursAutoResponse = 'after-hours-auto-response',
  OutOfOfficeAutoResponse = 'out-of-office-auto-response',
}

@Component({
  selector: 'app-setup-auto-response',
  templateUrl: './setup-auto-response.component.html',
  styleUrls: ['./setup-auto-response.component.scss'],
  standalone: false,
})
export class SetupAutoResponseComponent implements OnInit, OnDestroy {
  @ViewChild('autoResponseErrorMessage', { static: true })
  autoResponseMessage: MessageDialogComponent;
  twoWayConfig: TwoWayConfig;
  autoResponseForm: UntypedFormGroup;
  defaultProductGroup: ProductGroup;
  currentClient: Client;
  isRMAutoResponseEnabled: boolean;
  selectedAutoResponse: SelectedAutoResponse;
  autoResponseMessageFeed: string;
  autoResponses: AutoResponses;
  isErrorAutoResponse: boolean;
  isFeedEnabled: boolean;
  clientId: string;

  constructor(
    private titleService: TitleService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private twoWaySetupService: TwoWaySetupService,
    private newFeed: NewFeedTransitionService,
    private sessionService: SessionService,
    public featureService: FeatureService,
  ) {}

  ngOnInit() {
    this.isRMAutoResponseEnabled = this.featureService.checkFlag(
      FeatureFlags.rm_auto_response,
    );
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.clientId = this.currentClient.id;
    this.isFeedEnabled = this.newFeed.enabled;
    this.titleService.activate(
      this.currentClient.feed_enabled
        ? 'Relay Messenger Setup'
        : 'Two Way Messaging Setup',
      'Auto Responses',
    );

    // Display the auto response msgs from twoWayConfig if RMAutoResponseEnabled flag is not enabled or feed is not enabled
    if (!this.isRMAutoResponseEnabled || !this.isFeedEnabled) {
      this.twoWaySetupService.getTwoWayConfig().subscribe((twoWayConfig) => {
        this.twoWayConfig = twoWayConfig;
        this.initAutoResponseForm();

        if (!twoWayConfig.after_hours_blackout_window) {
          this.twoWaySetupService.getClient().subscribe((client) => {
            this.twoWayConfig.after_hours_blackout_window =
              client.blackout_window;
          });
        }
      });
    }

    this.twoWaySetupService
      .getDefaultProductGroup()
      .subscribe((defaultProductGroup) => {
        this.defaultProductGroup = defaultProductGroup;
      });

    if (this.isRMAutoResponseEnabled && this.isFeedEnabled) {
      this.twoWaySetupService
        .getAutoResponses(this.currentClient.id)
        .subscribe({
          next: (autoResponses) => {
            this.autoResponses = autoResponses;
            this.autoResponseMessageFeed =
              this.autoResponses.businessHours.message;
            this.initAutoResponseForm();
          },
          error: () => {
            this.isErrorAutoResponse = true;
          },
        });
      this.selectedAutoResponse = SelectedAutoResponse.DefaultAutoResponse;
    }
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  get defaultAutoResponseCtl(): AbstractControl {
    return this.autoResponseForm.get('default_auto_response');
  }

  get afterHoursAutoResponseCtl(): AbstractControl {
    return this.autoResponseForm.get('after_hours_auto_response');
  }

  get outOfOfficeAutoResponseCtl(): AbstractControl {
    return this.autoResponseForm.get('out_of_office_auto_response');
  }

  setSelectedAutoResponse(inputName: SelectedAutoResponse) {
    this.selectedAutoResponse = inputName;
    // Set the auto response message feed based on the selected auto response
    if (inputName === SelectedAutoResponse.OutOfOfficeAutoResponse) {
      this.autoResponseMessageFeed = this.autoResponses.outOfOffice.message;
    } else if (inputName === SelectedAutoResponse.AfterHoursAutoResponse) {
      this.autoResponseMessageFeed = this.autoResponses.afterHours.message;
    } else {
      this.autoResponseMessageFeed = this.autoResponses.businessHours.message;
    }
  }

  isLoading(): boolean {
    return this.autoResponseForm === undefined;
  }

  saveAndContinue() {
    if (!this.autoResponseForm.valid) {
      return;
    }
    if (this.isRMAutoResponseEnabled && this.isFeedEnabled) {
      const autoresponsesBody = {
        autoResponses: {
          ...this.autoResponses
        }
      }
      this.twoWaySetupService.saveAutoResponses(this.currentClient.id, autoresponsesBody).subscribe({
        next: () => {
          this.router.navigateByUrl(`/two-way/setup/business-hours`);
        },
        error: () => {
          this.autoResponseMessage.showMessage(
            `We are sorry! There was an error saving the changes. Please try again`
          );
        },
      });
    } else {
      this.updateConfigWithFormData();
      this.twoWaySetupService.saveTwoWayConfig(this.twoWayConfig).subscribe(
        (response) => {
          this.router.navigateByUrl(
            `/two-way/setup/${
              this.newFeed.enabled ? 'notification' : 'sms'
            }-ping`,
          );
        },
        (error) => {
          this.autoResponseMessage.showMessage(
            `We're sorry, there was an error saving your information.`,
          );
        },
      );
    }
  }

  private updateConfigWithFormData() {
    const formData = this.autoResponseForm.getRawValue();
    this.twoWayConfig.default_auto_response = formData['default_auto_response'];
    this.twoWayConfig.after_hours_auto_response =
      formData['after_hours_auto_response'];
  }

  private initAutoResponseForm() {
    this.autoResponseForm = this.formBuilder.group(
      this.isRMAutoResponseEnabled && this.isFeedEnabled
        ? {
            default_auto_response: [
              this.autoResponses.businessHours.message,
              Validators.required,
            ],
            after_hours_auto_response: [
              this.autoResponses.afterHours.message,
              Validators.required,
            ],
            out_of_office_auto_response: [
              this.autoResponses.outOfOffice.message,
              Validators.required,
            ],
          }
        : {
            default_auto_response: [
              this.twoWayConfig.default_auto_response,
              Validators.required,
            ],
            after_hours_auto_response: [
              this.twoWayConfig.after_hours_auto_response,
              Validators.required,
            ],
          },
    );

    if (this.isRMAutoResponseEnabled && this.isFeedEnabled) {
      merge(
        this.defaultAutoResponseCtl.valueChanges,
        this.afterHoursAutoResponseCtl.valueChanges,
        this.outOfOfficeAutoResponseCtl.valueChanges,
      ).subscribe(() => {
        this.autoResponses = {
          businessHours: {
            message: this.defaultAutoResponseCtl.value,
          },
          afterHours: {
            message: this.afterHoursAutoResponseCtl.value,
          },
          outOfOffice: {
            message: this.outOfOfficeAutoResponseCtl.value,
          },
        };
      });
      // listen for changes in the auto response message feed
      this.defaultAutoResponseCtl.valueChanges.subscribe(() => {
        this.autoResponseMessageFeed = this.defaultAutoResponseCtl.value;
      });
      this.afterHoursAutoResponseCtl.valueChanges.subscribe(() => {
        this.autoResponseMessageFeed = this.afterHoursAutoResponseCtl.value;
      });
      this.outOfOfficeAutoResponseCtl.valueChanges.subscribe(() => {
        this.autoResponseMessageFeed = this.outOfOfficeAutoResponseCtl.value;
      });
    }
  }
}
