import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VerificationField } from '@app/core/models/validation-verification';
import * as _ from 'lodash';

@Component({
    selector: 'rn-validation-fields-list',
    templateUrl: './validation-fields-list.component.html',
    styleUrls: ['./validation-fields-list.component.scss'],
    standalone: false
})
export class ValidationFieldsListComponent implements OnInit {
  @Input() allFields: VerificationField[]; // allFields is source of truth
  @Input() selectedFields: VerificationField[] = [];
  @Output() listChanged: EventEmitter<any> = new EventEmitter();

  availableFields: VerificationField[];
  heldField:       VerificationField;

  ngOnInit() {
    this.availableFields = _.cloneDeep(this.allFields); 

    if (!_.isEmpty(this.selectedFields)) { 
      this.availableFields = _.differenceWith(this.availableFields, this.selectedFields, (arrVal, othVal) => {
        if (arrVal.field === othVal.field) {
          return othVal;
        }
      });
    }
    
    if (this.availableFields[0]) {
      this.holdField(this.availableFields[0].field);
    }
  }

  holdField(input: string): void {
    this.heldField = _.find(this.availableFields, field => {
      return field.field === input;
    });
  }

  isSelected(input: VerificationField): any {
    return input.field === this.heldField.field ? true : null;
  }

  removeField(input: VerificationField): void {
    _.pull(this.selectedFields, input);

    this.availableFields = _.cloneDeep(this.allFields);

    this.availableFields = _.differenceWith(this.availableFields, this.selectedFields, function(arrVal, othVal) {
      if (arrVal.field === othVal.field) {
        return othVal;
      }
    });

    if (this.availableFields.length === 1) {
      this.holdField(this.availableFields[0].field);
    }

    this.broadcastListChange();
  }

  selectField(input: VerificationField): void {
    this.availableFields = _.differenceWith(this.availableFields, [input], function(arrVal, othVal) {
      if (arrVal.field === othVal.field) {
        return othVal;
      }
    });

    this.selectedFields = _.unionWith(this.selectedFields, [input], function(arrVal, othVal) {
      if (arrVal.field === othVal.field) {
        return othVal;
      }
    });

    if (this.availableFields[0]) {
      this.holdField(this.availableFields[0].field);
    }

    this.broadcastListChange();
  }

  private broadcastListChange() {
    this.listChanged.emit(this.selectedFields);
  }
}
