import * as React from 'react';
import { HintDot, ColorT } from 'baseui/badge';
import { AgentStatusT } from '../relay-messenger-axios-service/relay-messenger-axios-service';

type BadgeProps = {
  children: React.ReactNode;
  color: ColorT;
  position?: 'left' | 'right';
  status?: AgentStatusT;
};

export const Badge = ({
  children,
  color,
  position = 'right',
  status,
}: BadgeProps) => {
  return (
    <HintDot
      color={color}
      overrides={{
        Badge: {
          style: ({ $theme }) =>
            status === 'inactive'
              ? {
                  backgroundColor: $theme.colors.accent50,
                  border: `2px #909496 solid`,
                }
              : color === 'negative'
              ? {
                  width: `9px`,
                  height: `9px`,
                  border: `1px #e11900 solid`,
                }
              : {
                  width: `9px`,
                  height: `9px`,
                  border: `1px #048848 solid`,
                },
        },
        Positioner: {
          style: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            [position]: '-17px',
          },
        },
      }}
    >
      {children}
    </HintDot>
  );
};
