import { Component, Input, OnInit } from '@angular/core';
import { Client } from '../../core/models/client';
import { MediaAsset } from '../../core/models/media';

@Component({
    selector: 'app-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss'],
    standalone: false
})
export class ImageInputComponent implements OnInit {
  @Input() imgModel: any;
  @Input() client:   Client;
  @Input() label:    string;
  @Input() tipText:  string;
  @Input() type:     string;

  showAddAssetDialog: boolean = false;
  imgDimensions = null;

  banner = {
    height: '144px',
    width:  '400px'
  };

  icon = {
    height: '138px',
    width:  '138px'
  };

  constructor() { }

  ngOnInit() {
    this.setDimensions();
  }

  onAddPortalIcon(mediaAsset: MediaAsset) {
      this.imgModel.branding.icon_s3_url = mediaAsset.s3_url;
      this.onClosePortalBannerDialog();
  }
  
  onClosePortalIconDialog() {
    this.showAddAssetDialog = false;
  }
  
  onAddPortalBanner(mediaAsset: MediaAsset) {
      this.imgModel.branding.banner_s3_url = mediaAsset.s3_url;
      this.imgModel.branding.banner_alt_text = mediaAsset.alternate_text;
      this.imgModel.branding.banner_asset_id = mediaAsset.asset_id;
      this.onClosePortalBannerDialog();
  }
  
  onClosePortalBannerDialog() {
    this.showAddAssetDialog = false;
  }

  setDimensions(): void {
    if (this.type === 'banner') {
      this.imgDimensions = this.banner;
    } else if (this.type === 'icon') {
      this.imgDimensions = this.icon;
    }
  }

  get imgID(): string {
    let id = 'portal-' + this.type + '-image';
    return id;
  }

  get imgSrc(): string {
    let src = null;

    if (this.type === 'banner') {
      src = this.imgModel.branding.banner_s3_url;
    } else if (this.type === 'icon') {
      src = this.imgModel.branding.icon_s3_url;
    } 
    
    return src;
  }
}
