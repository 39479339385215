import { Background } from '@app/core/utils/background';
import { JourneyService } from '@app/core/services/journey.service';
import { Journey, JourneyContent } from '@app/core/models/journey';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-journey-metadata-page',
    templateUrl: './journey-metadata-page.component.html',
    styleUrls: ['./journey-metadata-page.component.scss'],
    standalone: false
})
export class JourneyMetadataPageComponent implements OnInit, OnDestroy {

  showDialog = false;
  message: string;
  showSmsByDefault: boolean;

  constructor(
    private location: Location,
    private router: Router,
    private titleService: TitleService,
    private sessionService: SessionService,
    private journeyService: JourneyService) { }

  ngOnInit() {
    this.titleService.activate('Experience Builder');
    this.showSmsByDefault = this.sessionService.getCurrentUsersClient().show_sms_by_default;
    Background.gridsOn();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
    Background.gridsOff();
  }

  onNew(journeyContent: JourneyContent) {
    journeyContent.client_id = this.sessionService.currentUser.client.id;
    const journey = new Journey({showSmsByDefault: this.showSmsByDefault, meta_data: journeyContent});

    this.journeyService.createJourney(journey).subscribe(
     newJourneyId => {
        this.router.navigateByUrl(`/cx-builder/experience-builder/${newJourneyId}`);
      },
      () => {
        this.message = `Oops...couldn't create new experience.`;
        this.showDialog = true;
      });
  }

  onBack() {
    this.location.back();
  }
}
