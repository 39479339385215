import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { useForm } from 'react-hook-form';
import { Textarea } from 'baseui/textarea';
import {
  ConfigurationFooter,
  InputTitle,
} from '@app/api-mgmt/components/push-api/styles/ConfigurationInputStyles';
import { SfmcConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { PushAPIService } from '@app/api-mgmt/components/push-api/push-api-service';
import { useClientInfo } from '@app/api-mgmt/components/push-api/context/ClientInfoContext';
import {
  validateJwtSigningSecret,
  validateName,
  validateRestBaseUri,
  validateAccountId,
  validateSalesforceClientId,
  validateSalesforceClientSecret,
  validateAuthHash,
  validateAuthBaseUri,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { Redirect } from '@app/api-mgmt/components/push-api/components/configurations-display/Redirect';
import { InputBox } from '@app/api-mgmt/components/push-api/components/configurations-display/InputBox';
import { Button } from 'baseui/button';
import {
  ButtonCancelOverride,
  ButtonOverride,
  ButtonRegenerateOverride,
  CheckboxCheckedOverride,
  CheckboxUncheckedOverride,
  TextAreaOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import {
  ButtonIconLeft,
  ButtonIconRight,
} from '@app/api-mgmt/components/push-api/styles/AddConfigurationButtonStyles';
import { StatefulTooltip } from 'baseui/tooltip';
import { Checkbox } from 'baseui/checkbox';
import { set } from 'date-fns';

export interface SfmcFormData {
  name: string;
  uid: string;
  active: boolean;
  jwt_signing_secret?: string;
  sf_client_id: string;
  sf_client_secret: string;
  rest_base_uri: string;
  auth_base_uri: string;
  account_id: string;
  auth_hash?: string;
}

export const SfmcEditor = ({
  onSaveClicked,
  onCancelClicked,
  integration,
}: {
  onSaveClicked?: (newIntegration: SfmcConfiguration) => void;
  onCancelClicked?: () => void;
  integration?: SfmcConfiguration;
}) => {
  const [css] = useStyletron();
  const [{ clientId }] = useClientInfo();

  const defaultFormData = {
    uid: integration?.uid ?? '',
    name: integration?.name ?? '',
    active: integration?.active ?? true,
    jwt_signing_secret: integration?.jwt_signing_secret ?? '',
    sf_client_id: integration?.sf_client_id ?? '',
    sf_client_secret: integration?.sf_client_secret ?? '',
    rest_base_uri: integration?.rest_base_uri ?? '',
    auth_base_uri: integration?.auth_base_uri ?? '',
    account_id: integration?.account_id ?? '',
    auth_hash: integration?.auth_hash ?? undefined,
    newapi: integration?.newapi !== undefined ? integration.newapi : true,
  };

  const maskedJwtSigningSecret =
    integration?.jwt_signing_secret_masked ??
    (integration?.jwt_signing_secret !== undefined ? '••••' : '');

  const maskedSfClientSecret =
    integration?.sf_client_secret_masked ??
    (integration?.sf_client_secret !== undefined ? '••••' : '');

  const [isSfClientSecretUpdated, setIsSfClientSecretUpdated] = useState(false);
  const [isJwtSigningSecretUpdated, setIsJwtSigningSecretUpdated] =
    useState(false);

  const [name, setName] = useState(defaultFormData.name);
  const [jwtSigningSecret, setJwtSigningSecret] = useState(
    defaultFormData.jwt_signing_secret,
  );
  const [displayedJwtSigningSecret, setDisplayedJwtSigningSecret] = useState(
    maskedJwtSigningSecret,
  );
  const [sfClientId, setSfClientId] = useState(defaultFormData.sf_client_id);
  const [sfClientSecret, setSfClientSecret] = useState(
    defaultFormData.sf_client_secret,
  );
  const [displayedSfClientSecret, setDisplayedSfClientSecret] =
    useState(maskedSfClientSecret);
  const [restBaseUri, setRestBaseUri] = useState(defaultFormData.rest_base_uri);
  const [authBaseUri, setAuthBaseUri] = useState(defaultFormData.auth_base_uri);
  const [accountId, setAccountId] = useState(defaultFormData.account_id);
  const [authHash, setAuthHash] = useState(defaultFormData.auth_hash);

  // some values are dependant on the form input values. Those are initalized here
  // and linked to the form input data.
  const [isValid, setIsValid] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRegenerated, setIsRegenerated] = useState(undefined);

  useEffect(() => {
    setIsValid(
      validateName(name, integration?.name, integrationNames) === '' &&
        validateJwtSigningSecret(jwtSigningSecret) === '' &&
        validateSalesforceClientId(sfClientId) === '' &&
        validateSalesforceClientSecret(sfClientSecret) === '' &&
        validateRestBaseUri(restBaseUri) === '' &&
        validateAuthBaseUri(authBaseUri) === '' &&
        validateAuthHash(authHash) === '',
    );
  }, [
    name,
    jwtSigningSecret,
    sfClientId,
    sfClientSecret,
    restBaseUri,
    authBaseUri,
    authHash,
  ]);

  // some values need to be pulled from the backend, so they're initialized here
  const [sfmcEventList, setSfmcEventList] = React.useState<string[]>([]);
  const [integrationNames, setIntegrationNames] = useState([]);

  const [newapi, setNewapi] = useState(defaultFormData.newapi);

  const generateAuthHash = async () => {
    setIsGenerating(true);
    const newAuthHash = await PushAPIService.generateSfmcAuthHash(clientId);
    setIsGenerating(false);

    if (newAuthHash) {
      setAuthHash(newAuthHash);
      setIsRegenerated(true);
    } else if (!newAuthHash) {
      setIsRegenerated(false);
    }
  };

  const getInitialAuthHashValue = async () => {
    if (!integration.uid) {
      const newAuthHash = await PushAPIService.generateSfmcAuthHash(clientId);

      if (newAuthHash) {
        setAuthHash(newAuthHash);
      }
    }
  };

  useEffect(() => {
    PushAPIService.getEventSubscriptionList('sfmc').then((fetchedEvents) => {
      setSfmcEventList(fetchedEvents);
    });

    PushAPIService.getIntegrationNames(clientId).then((integrationNames) => {
      setIntegrationNames(integrationNames);
    });

    getInitialAuthHashValue();
  }, []);

  const { reset } = useForm<SfmcFormData>({
    defaultValues: defaultFormData,
  });

  /**
   * Clears the form data
   */
  const cancel = () => {
    // reset data that was entered when modal closes
    reset(defaultFormData);
    // callback to close the modal
    onCancelClicked();
  };

  const checkIfActive = () => {
    if (integration && integration.active === true) {
      return true;
    }
  };

  /**
   * Handles when data is submitted to the form (e.g. click Save or press
   * Enter).
   */
  const onSubmit = ({ activate }: { activate: boolean }) => {
    if (isValid) {
      const submission = {
        uid: integration?.uid ?? '',
        name: name,
        type: 'sfmc',
        active: activate,
        jwt_signing_secret: jwtSigningSecret,
        sf_client_id: sfClientId,
        sf_client_secret: sfClientSecret,
        rest_base_uri: restBaseUri,
        auth_base_uri: authBaseUri,
        account_id: accountId,
        auth_hash: authHash,
        newapi,
      };
      onSaveClicked(submission);
    }
  };

  const isExistingIntegration = integration?.uid && integration?.uid !== '';

  return (
    <div
      className={css({
        margin: 'auto',
      })}
    >
      <Redirect
        link="https://docs.relaynetwork.com/docs/salesforce-marketing-cloud"
        value="View Relay Integration Documentation"
      />
      <InputBox
        title="Name"
        value={name}
        placeholder="Integration Name"
        validate={(value) =>
          validateName(value, integration.name, integrationNames)
        }
        onChange={setName}
        preValidate={isExistingIntegration}
      />
      <InputBox
        title="Client ID"
        value={sfClientId}
        placeholder="Client_ID"
        validate={(value) => validateSalesforceClientId(value)}
        onChange={setSfClientId}
        tooltipText="Salesforce Client ID"
        preValidate={isExistingIntegration}
      />
      <InputBox
        title="Client Secret"
        value={displayedSfClientSecret}
        placeholder="sdhuwehu28373hwdhw828919"
        validate={(value) => {
          if (value === maskedSfClientSecret && maskedSfClientSecret !== '') {
            return '';
          } else {
            return validateSalesforceClientSecret(value);
          }
        }}
        onChange={(value) => {
          if (
            !isSfClientSecretUpdated &&
            maskedSfClientSecret !== '' &&
            value !== maskedSfClientSecret
          ) {
            setSfClientSecret('');
            setDisplayedSfClientSecret('');
            setIsSfClientSecretUpdated(true);
          } else if (value !== maskedSfClientSecret) {
            setSfClientSecret(value);
            setDisplayedSfClientSecret(value);
            setIsSfClientSecretUpdated(true);
          }
        }}
        hideText={true}
        preValidate={isExistingIntegration}
      />
      <InputBox
        title="REST Base URI"
        value={restBaseUri}
        placeholder="https://www.example.com"
        validate={(value) => validateRestBaseUri(value)}
        onChange={setRestBaseUri}
        preValidate={isExistingIntegration}
      />
      <InputBox
        title="Authentication Base URI"
        value={authBaseUri}
        placeholder="https://www.example.com"
        validate={(value) => validateAuthBaseUri(value)}
        onChange={setAuthBaseUri}
        preValidate={isExistingIntegration}
      />
      <InputBox
        title="JWT Signing Secret"
        value={displayedJwtSigningSecret}
        placeholder="JWT Signing Secret"
        validate={(value) => {
          if (
            value === maskedJwtSigningSecret &&
            maskedJwtSigningSecret !== ''
          ) {
            return '';
          } else {
            return validateJwtSigningSecret(value);
          }
        }}
        onChange={(value) => {
          if (
            !isJwtSigningSecretUpdated &&
            maskedJwtSigningSecret !== '' &&
            value !== maskedJwtSigningSecret
          ) {
            setJwtSigningSecret('');
            setDisplayedJwtSigningSecret('');
            setIsJwtSigningSecretUpdated(true);
          } else if (value !== maskedJwtSigningSecret) {
            setJwtSigningSecret(value);
            setDisplayedJwtSigningSecret(value);
            setIsJwtSigningSecretUpdated(true);
          }
        }}
        hideText={true}
        preValidate={isExistingIntegration}
      />
      <InputBox
        title="Account ID"
        value={accountId}
        placeholder="Account_ID"
        validate={(value) => validateAccountId(value)}
        onChange={setAccountId}
        preValidate={isExistingIntegration}
      />
      <InputTitle>
        <label>Events</label>
      </InputTitle>
      <Textarea
        overrides={{
          InputContainer: {
            style: {
              ...TextAreaOverride.style,
              height: '360px',
            },
          },
        }}
        value={sfmcEventList.join('\n')}
        disabled={true}
      />
      <>
        <InputTitle>
          <label>Auth Hash</label>
        </InputTitle>
        <div
          style={{
            marginTop: '5px',
            marginLeft: '10px',
            display: 'flex',
            fontSize: '16px',
          }}
        >
          {authHash && (
            <>
              <div style={{ width: '80%' }}>
                <Textarea value={authHash} />
              </div>
              <div style={{ marginLeft: '5px' }}>
                <StatefulTooltip
                  accessibilityType={'tooltip'}
                  content={'Copy'}
                  placement="top"
                >
                  <i
                    className="fa-light fa-copy"
                    onClick={() => navigator.clipboard.writeText(authHash)}
                    style={{
                      marginLeft: '5px',
                      marginTop: '10px',
                      cursor: 'pointer',
                      fontSize: '20px',
                    }}
                  ></i>
                </StatefulTooltip>
              </div>
              <div style={{ marginLeft: '10px' }}>
                <div>
                  <Button
                    onClick={() => generateAuthHash()}
                    overrides={{
                      BaseButton: ButtonRegenerateOverride,
                    }}
                  >
                    <ButtonIconLeft>
                      {isGenerating && (
                        <div className="btn-refresh--loading">
                          <i className="fa-solid fa-arrows-rotate"></i>
                        </div>
                      )}
                      {!isGenerating && (
                        <i className="fa-solid fa-arrows-rotate"></i>
                      )}
                    </ButtonIconLeft>
                    <span>{isGenerating ? 'REGENERATING' : 'REGENERATE'}</span>
                  </Button>
                </div>
                <div>
                  <div style={{ fontSize: '14px' }}>
                    {isRegenerated === true && (
                      <i style={{ color: '#b2cf52' }}>Auth Hash updated.</i>
                    )}
                    {isRegenerated === false && (
                      <i style={{ color: '#a80808' }}>
                        Auth Hash not updated. Please try again.
                      </i>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {!authHash && (
            <a>
              There was an error generating an Auth Hash. Please contact support
              for assistance.
            </a>
          )}
        </div>
      </>
      <ConfigurationFooter>
        <div style={{ float: 'left' }}>
          <Button
            type="reset"
            kind="tertiary"
            onClick={cancel}
            overrides={{ Root: ButtonCancelOverride }}
          >
            Cancel
          </Button>
        </div>
        <div style={{ float: 'right', opacity: isValid ? '100%' : '50%' }}>
          <Button
            onClick={() => onSubmit({ activate: false })}
            kind="tertiary"
            overrides={{ Root: ButtonCancelOverride }}
          >
            {checkIfActive() ? 'Save & Deactivate' : 'Save & Exit'}
          </Button>
          <Button
            onClick={() => onSubmit({ activate: true })}
            overrides={{
              BaseButton: ButtonOverride,
            }}
          >
            <span>{checkIfActive() ? 'SAVE' : 'ACTIVATE'}</span>
            <ButtonIconRight>
              <i className="fa-solid fa-check"></i>
            </ButtonIconRight>
          </Button>
        </div>
      </ConfigurationFooter>
    </div>
  );
};
