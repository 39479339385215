import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { LoggerService } from '@app/core/services/logger.service';
import { TwoWayUnassignedChannel } from '@app/two-way/twilio-conversation.types';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ControlTag } from '@app/core/services/control-tags.service';
import * as _ from 'lodash';
import { SortableComponent } from '@app/core/sortable-component';
import { Direction, Ordering } from '@app/core/utils/ordering';
import { sortBy } from '@app/core/utils/sorting-utils';
import { TwoWayConversationService } from '@app/core/services/two-way-conversation.service';
import { TwoWayConversationClientEvents } from '../twilio-conversation.types';

@Component({
    selector: 'unassigned',
    templateUrl: './unassigned.component.html',
    styleUrls: ['./unassigned.component.scss'],
    standalone: false
})
export class UnassignedComponent
  extends SortableComponent
  implements OnInit, OnDestroy
{
  @Input() tagFilter: BehaviorSubject<any>;
  @Input() launchFilter: BehaviorSubject<any>;
  @Input() tags: ControlTag[];
  @Output() selectedChannelEmitter = new EventEmitter<string>();

  error: string;
  loading = true;

  unassignedChats: TwoWayUnassignedChannel[] = [];
  filters: { tags: any[]; launched_by: any[] } = {
    tags: undefined,
    launched_by: undefined,
  };
  headings: string[] = ['name', 'tag', 'created', 'last updated', null];

  private readonly timeout = 10000;
  private interval;

  constructor(
    private twoWayConversationService: TwoWayConversationService,
    private sessionService: SessionService,
  ) {
    super();
  }

  ngOnInit() {
    this.getUnassigned();
    this.pollForUnassignedChats();
    this.subscribeChatEvents();
    this.tagFilter.subscribe((res) => {
      this.filters.tags = _.map(res, 'tag_id');
      this.getUnassigned();
    });
    this.launchFilter.subscribe((res) => {
      this.filters.launched_by = _.map(res, 'lb_name');
      this.getUnassigned();
    });
  }

  ngOnDestroy() {
    this.unassignedChats = [];
    clearInterval(this.interval);
  }

  private subscribeChatEvents(): void {
    this.twoWayConversationService.clientEmitter.subscribe((event) =>
      this.conversationEventRouter(event),
    );
  }

  // required for abstract class SortableComponent
  fetchData() {
    this.applySort();
  }

  applySort(): void {
    // apply default sort if undefined
    if (this.ordering === undefined) {
      this.ordering = new Ordering('created', Direction.Desc);
    }

    if (this.ordering.orderBy === 'name') {
      this.unassignedChats = sortBy(
        this.unassignedChats,
        this.ordering.direction,
        (chat) => {
          return `${chat.attrs?.customer?.first_name} ${chat.attrs?.customer?.last_name}`;
        },
      );
    } else if (this.ordering.orderBy === 'tag') {
      this.unassignedChats = sortBy(
        this.unassignedChats,
        this.ordering.direction,
        (chat) => this.getTagNameById(chat.attrs.tags),
      );
    } else if (this.ordering.orderBy === 'created') {
      this.unassignedChats = sortBy(
        this.unassignedChats,
        this.ordering.direction,
        'created_at',
      );
    } else if (this.ordering.orderBy === 'last updated') {
      this.unassignedChats = sortBy(
        this.unassignedChats,
        this.ordering.direction,
        'updated_at',
      );
    }
  }

  join(channel_sid: string): void {
    // todo - drop off list
    this.twoWayConversationService
      .joinChannel(channel_sid)
      .pipe(
        switchMap(() =>
          this.twoWayConversationService.selectChannel(channel_sid),
        ),
      )
      .subscribe(
        () => {
          _.remove(
            this.unassignedChats,
            (chat) => chat.channel_sid === channel_sid,
          );
          this.selectedChannelEmitter.emit(channel_sid);
        },
        (err) => LoggerService.log('UnassignedComponent', err),
      );
  }

  getTagNameById(id: string): string {
    const tag = this.tags.find((t) => t.tag_id === id);
    return tag ? tag['tag_name'] : 'Missing Tag Data';
  }

  retryUnassigned(): void {
    this.getUnassigned();
  }

  private getUnassigned(): void {
    const clientId = this.sessionService.getCurrentUsersClient().id;
    this.twoWayConversationService
      .getAllUnassignedChats(clientId, this.filters)
      .subscribe(
        (res) => {
          this.loading = false;
          this.error = undefined;
          this.unassignedChats = res;
          this.applySort();
        },
        (err) => this.handleError(err),
      );
  }

  private pollForUnassignedChats(): void {
    this.interval = setInterval(() => {
      if (!this.error) {
        this.getUnassigned();
      }
    }, this.timeout);
  }

  private handleError(err: string): void {
    this.error =
      'There was an error fetching unassigned conversations.  Please refresh to try again.';
    this.loading = false;
    clearInterval(this.interval);
    LoggerService.log('MessagingComponent', err);
  }

  private conversationEventRouter(event: any): void {
    switch (event.event_type) {
      case TwoWayConversationClientEvents.conversationJoined:
        this.channelHandler();
        return;
      case TwoWayConversationClientEvents.conversationLeft:
        this.channelHandler();
        return;
    }
  }

  private channelHandler(): void {
    this.getUnassigned();
  }
}
