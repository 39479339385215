import { Journey } from '@app/core/models/journey';
import { ToolbarService } from '@app/cx-builder/services/toolbar.service';
import { WorkflowClass } from '@app/core/models/message';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-trigger-inspector',
    templateUrl: './trigger-inspector.component.html',
    styleUrls: ['./trigger-inspector.component.scss'],
    standalone: false
})
export class TriggerInspectorComponent implements OnInit, OnChanges {

    @Input() journeyId: string;
    @Input() journey: Journey;
    @Input() trigger: WorkflowClass;
    @Output() onCancel = new EventEmitter<void>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() blur: EventEmitter<void> = new EventEmitter<void>();

    triggerForm: UntypedFormGroup;
    triggerLabelArray: UntypedFormArray;

    constructor(
        public toolbarService: ToolbarService,
        private formBuilder: UntypedFormBuilder
    ) { }

    refreshFormControls() {
        this.triggerLabelArray = this.formBuilder.array(this.trigger.labels.map(l => this.formBuilder.control(l)));
        this.triggerForm = this.formBuilder.group({
            triggerNickname: this.formBuilder.control(this.trigger.nickname),
            clientTriggerId: this.formBuilder.control(this.trigger.client_trigger_id),
            triggerNote: this.formBuilder.control(this.trigger.notes),
            triggerLabels: this.triggerLabelArray
        });
    }

    ngOnInit() {
        this.refreshFormControls();
    }

    ngOnChanges() {
        this.refreshFormControls();
    }

    cancel() {
        this.close.emit();
    }

    keydownEnter(e) {
        e.preventDefault();
        if (!this.isSaveDisabled()) {
            this.save();
        }
    }

    save() {
        const nickname = this.triggerForm.get('triggerNickname').value;
        if (!nickname || !nickname.trim()) {
            this.trigger.nickname = null;
        } else {
            this.trigger.nickname = nickname;
        }
        const triggerId = this.triggerForm.get('clientTriggerId').value;
        if (!triggerId || !triggerId.trim()) {
            this.trigger.client_trigger_id = null;
        } else {
            this.trigger.client_trigger_id = triggerId;
        }
        this.trigger.notes = this.triggerForm.get('triggerNote').value;
        this.trigger.labels = this.triggerForm.get('triggerLabels').value;
        this.triggerForm.markAsPristine();
        this.blur.emit();
    }

    isSaveDisabled() {
        return this.triggerForm.pristine && this.triggerLabelArray.pristine;
    }

    labelsLengthCheck(l) {
        if (l >= 20) {
            return true
        } else {
            return false
        }
    }
}
