import React, { useState } from 'react';
import { styled, Theme } from 'baseui';
import { Button } from 'baseui/button';
import {
  ButtonCancelOverride,
  ButtonOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { ButtonIconRight } from '../styled-containers/styledContainers';
import { on } from 'events';

const StepContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
});

const Step = styled<'div', { $stepsCount: number }, Theme & object>(
  'div',
  ({ $stepsCount }: { $stepsCount: number }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: `calc(${100 / $stepsCount}% - 2px)`,
  }),
);

const Rectangle = styled<'div', { $isActive: boolean }, Theme & object>(
  'div',
  ({ $isActive }: { $isActive: boolean }) => ({
    width: '100%',
    height: '14px',
    backgroundColor: $isActive ? '#B2CF52' : '#CECECE',
    aspectRatio: '14',
  }),
);

const StepText = styled('div', {
  textAlign: 'center',
  marginTop: '2px',
});

interface Step {
  label: string;
  component: React.ReactNode;
  stepComplete: boolean;
}

interface StepperProps {
  steps: Step[];
  onCompletePrimary: () => void;
  completePrimaryText: string;
  onCompleteSecondary?: () => void;
  completeSecondaryText?: string;
}

export const RnStepper: React.FC<StepperProps> = ({
  steps,
  onCompletePrimary,
  completePrimaryText,
  onCompleteSecondary,
  completeSecondaryText,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const currentComponent = steps[currentStep]?.component;

  const handleNext = () => {
    if (currentStep < steps.length - 1 && steps[currentStep].stepComplete) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <StepContainer>
        {steps.map(({ label }, index) => (
          <Step key={index} $stepsCount={steps.length}>
            <Rectangle $isActive={index === currentStep} />
            <StepText>{label.toUpperCase()}</StepText>
          </Step>
        ))}
      </StepContainer>
      {currentComponent}
      <div style={{ paddingTop: '10px', paddingBottom: '50px' }}>
        {currentStep !== 0 && (
          <div style={{ float: 'left' }}>
            <Button
              type="reset"
              kind="tertiary"
              onClick={handlePrevious}
              overrides={{ Root: ButtonCancelOverride }}
            >
              {`< Previous: ${steps[currentStep - 1]?.label}`}
            </Button>
          </div>
        )}
        {currentStep !== steps.length - 1 && (
          <div style={{ float: 'right' }}>
            <Button
              onClick={handleNext}
              overrides={{
                BaseButton: {
                  style: {
                    ...ButtonOverride.style,
                    height: '36px',
                    width: '148px',
                    marginTop: '5px',
                    opacity: steps[currentStep]?.stepComplete ? '100%' : '50%',
                  },
                },
              }}
            >
              <span>NEXT: {steps[currentStep + 1]?.label.toUpperCase()}</span>
            </Button>
          </div>
        )}
        {currentStep === steps.length - 1 && (
          <div
            style={{
              float: 'right',
              opacity: steps[currentStep]?.stepComplete ? '100%' : '50%',
            }}
          >
            {onCompleteSecondary && (
              <Button
                onClick={onCompleteSecondary}
                kind="tertiary"
                overrides={{ Root: ButtonCancelOverride }}
              >
                {completeSecondaryText}
              </Button>
            )}
            <Button
              onClick={onCompletePrimary}
              overrides={{
                BaseButton: ButtonOverride,
              }}
            >
              <span>{completePrimaryText}</span>
              <ButtonIconRight>
                <i className="fa-solid fa-check"></i>
              </ButtonIconRight>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
