import React, { useState } from 'react';
import { useStyletron } from 'styletron-react';
import { InputBox } from '@app/api-mgmt/components/push-api/components/configurations-display/InputBox';
import { Redirect } from '@app/api-mgmt/components/push-api/components/configurations-display/Redirect';
import {
  validateName,
  validateUri,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { NoAuthConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { HTTPMethodSelectBox } from '@app/api-mgmt/components/push-api/components/configurations-display/HTTPMethodSelectBox';
import { Checkbox } from 'baseui/checkbox';
import { StatefulTooltip } from 'baseui/tooltip';
import {
  CheckboxCheckedOverride,
  CheckboxUncheckedOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';

export const NoAuthSettings = ({
  authSettings,
  updateAuthSettings,
  integrationNames,
  integration,
}: {
  authSettings: any;
  updateAuthSettings: (value: any) => void;
  integrationNames: string[];
  integration?: NoAuthConfiguration;
}) => {
  const [css] = useStyletron();

  const [useBatching, setUseBatching] = useState(
    integration?.batching ?? false,
  );

  const isExistingIntegration = integration?.uid && integration?.uid !== '';

  return (
    <div
      className={css({
        marginTop: '10px',
      })}
    >
      <>
        <Redirect
          link="https://docs.relaynetwork.com/docs/push-event-webhook"
          value="View Relay Integration Documentation"
        />
        <InputBox
          title="Name"
          value={authSettings.name}
          placeholder="Integration Name"
          validate={(value) => {
            return validateName(value, integration.name, integrationNames);
          }}
          onChange={(value) => updateAuthSettings({ name: value })}
          preValidate={isExistingIntegration}
        />
        <InputBox
          placeholder="https://www.example.com"
          title="URI/Endpoint"
          value={authSettings.uri}
          validate={(value) => {
            return validateUri(value);
          }}
          onChange={(value) => updateAuthSettings({ uri: value })}
          preValidate={isExistingIntegration}
        />
        <HTTPMethodSelectBox
          placeholder="Select HTTP Method"
          title="HTTP Method"
          value={authSettings.method}
          onChange={(value) => updateAuthSettings({ method: value })}
        />
        <div style={{ margin: '10px 10px 0px 0px' }}>
          <Checkbox
            key={'useBatching'}
            checked={useBatching}
            overrides={
              useBatching
                ? { Checkmark: CheckboxCheckedOverride }
                : { Checkmark: CheckboxUncheckedOverride }
            }
            onChange={() => {
              setUseBatching(!useBatching);
              updateAuthSettings({ batching: !useBatching });
            }}
          >
            <div>
              <span
                style={{
                  color: '#5b5a59',
                }}
              >
                Batching
              </span>
              <StatefulTooltip
                accessibilityType={'tooltip'}
                content={`Enable batching to reduce the number of network calls. Only enable after consulting with the client.`}
                showArrow
                placement="top"
              >
                <i
                  className="fa fa-question-circle"
                  style={{
                    fontSize: '16px',
                    marginLeft: '10px',
                    color: '#908E8F',
                  }}
                ></i>
              </StatefulTooltip>
            </div>
          </Checkbox>
        </div>
      </>
    </div>
  );
};
