import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-customer-message',
    templateUrl: './customer-message.component.html',
    styleUrls: ['./customer-message.component.scss'],
    standalone: false
})
export class CustomerMessageComponent {
  @Input() msg: any; // todo - type this out
}
