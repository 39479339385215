<div
  class="intro-line-1"
  i18n="Introductory for creating a new client - step 1, line 1"
>
  Let's start with the basics
</div>
<div
  class="intro-line-2"
  i18n="Introductory for creating a new client - step 1, line 2"
>
  A client has no name. A client needs a name.
</div>

<ng-container *ngIf="client">
  <client-header
    [headerLabel]="'Set client ID'"
    [headerGroup]="'clientID'"
    (openHeader)="toggleHeader($event)"
    (closeHeader)="toggleHeader($event)"
  ></client-header>
  <div
    class="collapse-container"
    *ngIf="headerGroups.clientID"
    style="padding-bottom: 5px"
  >
    <div class="read-only-container">
      <label class="client-id-label">Client ID</label>
      <p class="read-only-id">{{ client.id }}</p>
    </div>
    <span class="tip-text">
      <svg width="15" [attr.height]="60" style="float: left">
        <polyline
          [attr.points]="'8,0 8,24, 2,30 8,36 8,80'"
          style="fill: none; stroke: #5b5a59; stroke-width: 1"
        />
      </svg>
      <span
        >Your company ID is your main identifier to our system, and
        <strong>once set will not be able to be changed</strong>.</span
      >
    </span>
  </div>

  <client-header
    [headerLabel]="'Company information'"
    [headerGroup]="'companyInformation'"
    (openHeader)="toggleHeader($event)"
    (closeHeader)="toggleHeader($event)"
  ></client-header>

  <div class="collapse-container" *ngIf="headerGroups.companyInformation">
    <ng-container *ngIf="clientInfoValidGroup">
      <rn-text-input
        [inputLabel]="'Company name'"
        [inputControl]="clientInfoValidGroup.controls['company_name']"
        [placeholderText]="'Company name'"
      ></rn-text-input>

      <rn-text-input
        [inputLabel]="'First name'"
        [inputControl]="primaryContactGroup.controls['first_name']"
        [placeholderText]="'First name'"
      ></rn-text-input>

      <rn-text-input
        [inputLabel]="'Last name'"
        [inputControl]="primaryContactGroup.controls['last_name']"
        [placeholderText]="'Last name'"
      ></rn-text-input>

      <rn-text-input
        [inputLabel]="'Email address'"
        [inputControl]="primaryContactGroup.controls['email']"
        [placeholderText]="'Email address'"
        (blurred)="trimEmail($event)"
      ></rn-text-input>

      <rn-text-input
        [inputLabel]="'Mobile number'"
        [inputControl]="primaryContactGroup.controls['mobile_number']"
        [placeholderText]="'Mobile number'"
        (keyedUp)="formatPhoneNumber()"
      ></rn-text-input>

      <ng-container *ngIf="showIndustrySelect()">
        <div class="select-container">
          <label for="industry-select">Select Industry</label>
          <select
            id="industry-select"
            class="form-control"
            name="industry-select"
            [formControl]="industryCtl"
            (ngModelChange)="setCompanyTypeOptions($event)"
          >
            <option [value]="''" [selected]="industryCtl.value === ''">
              Select an Industry
            </option>
            <option
              *ngFor="let industry of industries"
              [value]="industry.id"
              [selected]="industryCtl.value === industry.id"
            >
              {{ industry.title['en-us'] }}
            </option>
          </select>
        </div>

        <div class="select-container">
          <label for="company-type-select">Select Company Type</label>
          <select
            id="company-type-select"
            class="form-control"
            name="company-type-select"
            [formControl]="companyTypeCtl"
          >
            <option
              [value]="''"
              [selected]="
                companyTypeCtl.value === '' || industryCtl.value === ''
              "
            >
              Select a Company Type
            </option>
            <option
              *ngFor="let companyType of relevantCompanyTypes"
              [value]="companyType.id"
              [selected]="companyTypeCtl.value === companyType.id"
            >
              {{ companyType.title['en-us'] }}
            </option>
          </select>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="featureService.checkFlag(featureFlags.contact_card)">
    <client-header
      [headerLabel]="'Contact Card'"
      [headerGroup]="'contactCard'"
      (openHeader)="toggleHeader($event)"
      (closeHeader)="toggleHeader($event)"
    ></client-header>
    <div class="collapse-container" *ngIf="headerGroups.contactCard">
      <label>Upload a Contact Card</label>
      <p class="helper-text">
        Provide a contact card that can be added as an Action Element in message
        builder. <br />
        Contact card files can be exported from email applications or a contact
        app on a smart phone.
      </p>
      <rn-upload-drop-area
        (fileCaptured)="handleContactCardDrop($event)"
        [acceptedFileTypes]="'.vcf, .vcard'"
        style="width: 300px"
      >
        <upload-instruction>
          <ng-container *ngIf="clientContactCardFile">
            <p id="uploaded-vcard-info">
              <strong>Uploaded</strong>: {{ clientContactCardFile }}
            </p>
          </ng-container>
          <ng-container *ngIf="!clientContactCardFile">
            <div id="upload-vcard-instruction">
              Drag file here <strong>(.vcf & .vcard files only)</strong>
            </div>
            <span>or</span>
          </ng-container>
        </upload-instruction>
        <span select-btn-text>{{
          clientContactCardFile ? 'Upload new vcard' : 'SELECT FILE'
        }}</span>
      </rn-upload-drop-area>
    </div>
  </ng-container>

  <div class="button-container">
    <button
      class="btn btn-primary"
      id="next-btn"
      (click)="saveAndContinue()"
      [class.inactive]="!clientInfoValidGroup.valid"
      [disabled]="!clientInfoValidGroup.valid"
    >
      <span
        i18n="Label for Next button on new client configuration"
        id="save-and-next-button"
      >
        Save And Next
      </span>
      <span class="icon icon-right">
        <i class="fa fa-arrow-right"></i>
      </span>
    </button>
  </div>
</ng-container>

<message-dialog></message-dialog>
