import moment from 'moment';
import * as _ from 'lodash';

export enum JobStatus {
  Processing = 'processing',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Pending = 'pending',
  Idle = 'idle',
  Scheduled = 'scheduled',
}

export enum JobFiletype {
  Messaging = 'messaging',
  ExperienceMessaging = 'journey_messaging',
  Onboarding = 'onboarding',
  CCA = 'cca',
  MobileAnalysis = 'mobile_analysis',
  PhoneManagement = 'phone_management',
  ConsentUpdate = 'consent_update',
  Deactivation = 'deactivation',
  MessagingV2 = 'messaging_v2',
  OnboardingV2 = 'onboarding_v2',
  CCAV2 = 'cca_v2',
  DeactivationV2 = 'deactivation_v2',
  Report = 'report'
}

export type DownloadUrlFiletypes = 'original_file' | 'accept_file' | 'reject_file';

export class Job {
  id: string;
  status: JobStatus;
  filename: string;
  filetype: JobFiletype;
  launched_by: string;
  job_failure_reason?: string;
  job_created_at?: Date;
  job_started_at?: Date;
  last_modified_at: Date;
  scheduled_at: Date;
  job_source: string;
  user_email: string;
  user_id: string;
  journey_name: string;

  current_validation_line: number;
  validation_completed_at: string;
  sms_attempted?: number;
  sms_not_attempted?: number;
  records_rejected: number;
  records_accepted: number;
  total_records: number;

  download_urls: { accept_file?: string, reject_file?: string, original_file?: string };

  constructor(rawJob: Object) {
    
    this.id = rawJob['id'];
    this.status = rawJob['status'].toLowerCase();
    this.filename = rawJob['filename'] || '';
    this.filetype = rawJob['filetype'] || '';
    this.launched_by = rawJob['launched_by'] || '';
    this.job_failure_reason = rawJob['job_failure_reason'];
    this.job_created_at = rawJob['job_created_at'] ? new Date(rawJob['job_created_at']) : undefined;
    this.job_started_at = rawJob['job_started_at'] ? new Date(rawJob['job_started_at']) : undefined;
    this.last_modified_at = rawJob['last_modified_at'] ? new Date(rawJob['last_modified_at']) : undefined;
    this.scheduled_at = rawJob['scheduled_at'] ? new Date(rawJob['scheduled_at']) : undefined;
    this.job_source = rawJob['job_source'];
    this.user_email = rawJob['user_email'];
    this.user_id = rawJob['user_id'];
    this.journey_name = rawJob['journey_name'];
  

    this.setDownloadUrls(rawJob);
    this.setStats(rawJob);
  }

  // duplicates angular datePipe's 'short' format
  // Can't use angular pipes in models. :\
  displayableDate(date: Date) {
    return moment(date).format('M/D/YY, h:mm A');
  }

  isCancellable() {
    return this.status === 'idle' || this.status === 'scheduled' || this.status === 'pending' || this.status === 'processing';
  }

  isSchedulable() {
    return this.status === 'idle' || this.status === 'scheduled';
  }

  isCompleted() {
    return this.status === 'completed';
  }

  search(searchTerm) {
    return _.reduce(this.searchableFields(), (result, searchableField) => { 
      return result ? result : new RegExp(searchTerm, 'i').test(searchableField);
    }, false);
  }

  showSmsStats() {
    return this.filetype === 'messaging' || this.filetype === 'journey_messaging' || this.filetype === 'onboarding';
  }

  private searchableFields() {
    return [ 
      this.filetype,
      this.id,
      this.filename,
      this.status,
      this.displayableDate(this.job_started_at),
      this.displayableDate(this.last_modified_at)
    ];
  }

  private setStats(rawJob) {
    this.current_validation_line = parseInt(rawJob['current_validation_line'], 10) || 0;

    const passedValidation = parseInt(rawJob['passed_validation'], 10) || 0;
    const failedValidation = parseInt(rawJob['failed_validation'], 10) || 0;
    
    const passedProcessing = parseInt(rawJob['passed_processing'], 10) || 0;
    const failedProcessing = parseInt(rawJob['failed_processing'], 10) || 0;
    
    this.records_rejected = failedProcessing + failedValidation;
    this.records_accepted = passedProcessing;
    this.total_records = passedValidation + failedValidation;

    if (this.showSmsStats()) {
      this.sms_attempted = parseInt(rawJob['sms_attempted'], 10) || 0;
      this.sms_not_attempted = parseInt(rawJob['sms_not_attempted'], 10) || 0;
    }
  }

  private setDownloadUrls(rawJob) {
    if (!rawJob['download_urls']) {
      return;
    }

    this.download_urls = {};

    if (rawJob['download_urls']['accept_file']) {
      this.download_urls['accept_file'] = rawJob['download_urls']['accept_file'];
    }
  
    if (rawJob['download_urls']['reject_file']) {
      this.download_urls['reject_file'] = rawJob['download_urls']['reject_file'];
    }

    if (rawJob['download_urls']['original_file']) {
      this.download_urls['original_file'] = rawJob['download_urls']['original_file'];
    }
  }
}
