import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'rn-list-item-expandable',
    templateUrl: './list-item-expandable.component.html',
    styleUrls: ['./list-item-expandable.component.scss'],
    standalone: false
})
export class ListItemExpandableComponent {
  @Output() toggled: EventEmitter<'expanded' | 'collapsed'> = new EventEmitter();
  showDetail: boolean = false;

  toggleDetails() {
    this.showDetail = !this.showDetail;
    this.toggled.emit(this.showDetail ? 'expanded' : 'collapsed');
  }
}
