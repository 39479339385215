import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import React from 'react';
import { PushAPIReactContainer } from './push-api-react-container/PushAPIReactContainer';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { createRoot, Root } from 'react-dom/client';

@Component({
    selector: 'rn-push-api',
    templateUrl: './push-api.component.html',
    styleUrls: ['./push-api.component.scss'],
    standalone: false
})
export class PushApiComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  messageDialog: MessageDialogComponent;
  currentClient: Client;
  client: Client;
  clientId: string;
  reactWrapperId: string;

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
  ) {
    this.currentClient = this.sessionService.getCurrentUsersClient();
  }

  root: Root = null;
  ngOnInit(): void {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.clientService.getClient(this.clientId).subscribe(
      (client) => {
        this.client = client;
      },
      () => {
        this.client = new Client();
      }
    );
    this.reactWrapperId = 'push-api-react-wrapper-id';
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
    this.root.unmount();
  }

  ngOnChanges() {
    this.render();
  }

  protected render() {
    if (this.root) {
      this.root.render(
        React.createElement(PushAPIReactContainer, {
          clientId: this.clientId,
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    const node = document.getElementById(this.reactWrapperId);
    this.root = createRoot(node);
    this.render();
  }
}
