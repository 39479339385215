<div class="rn-page">
  <div class="rn-page__section">
    <div class="rn-page--busy" *ngIf="isLoading()">
      <app-spinner></app-spinner>
    </div>
  <div class="help-text-container" *ngIf="this.currentHoursType && this.currentHoursType !== 'BUSINESS_HOURS' && !this.isErrorClientStatus && this.currentHoursExpires">
    <p>
      The {{ this.currentHoursType === 'AFTER_HOURS' ? 'After Hours' : 'Out of Office'}} auto response is enabled until {{ this.currentHoursExpires }}. Your business hours and other auto responses will resume then.
    </p>
  </div>
  <div class="help-text-container" *ngIf="this.currentHoursType && this.currentHoursType !== 'BUSINESS_HOURS' && !this.isErrorClientStatus && !this.currentHoursExpires">
    <p>
      The {{ this.currentHoursType === 'AFTER_HOURS' ? 'After Hours' : 'Out of Office'}} auto response is enabled indefinitely. Update your business hours to reflect availability in Relay Messenger.
    </p>
  </div>
    <div *ngIf="isErrorBusinessHours">
      <p class="business-hours-error-msg">
        Oops, there was an error loading this page. Please try refreshing.
      </p>
    </div>
  </div>

  <ng-container *ngIf="businessHoursGroup">
    <form [formGroup]="businessHoursGroup" class="business-hours-form">
      <h3>Business Hours</h3>
      <p>
        Please set the hours your team will be available in Relay Messenger. All
        times are shown in Eastern time.
      </p>

      <div class="business-hours-container" [class.disabled]="isOutOfOfficeEnabled">
        <div *ngFor="let day of days" [formGroupName]="day">
          <div class="day-row">
            <app-radio-btn
              [btnLabel]="day"
              [btnWidth]="'211px'"
              [btnName]="day + '-enabled'"
              [btnModel]="businessHoursGroup.get(day + '.enabled')?.value"
              [btnDisplayTrueFirst]="true"
              (btnModelChange)="toggleDay(day)"
              style="text-transform: capitalize"
            ></app-radio-btn>

            <!-- Start Time Picker -->
            <mat-form-field appearance="outline" class="start-time">
              <input
                matInput
                [matTimepicker]="startPicker"
                formControlName="startTime"
              />
              <mat-timepicker-toggle matSuffix [for]="startPicker">
                <mat-icon matTimepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-timepicker-toggle>
              <mat-timepicker #startPicker></mat-timepicker>
              <mat-error
                *ngIf="
                  businessHoursGroup
                    .get(day + '.startTime')
                    ?.hasError('required')
                "
              >
                Required
              </mat-error>
              <mat-error
                *ngIf="
                  businessHoursGroup
                    .get(day + '.startTime')
                    ?.hasError('invalidDate')
                "
              >
                Invalid time format
              </mat-error>
            </mat-form-field>

            <span>TO</span>

            <!-- End Time Picker -->
            <mat-form-field appearance="outline">
              <input
                matInput
                [matTimepicker]="endPicker"
                formControlName="endTime"
              />
              <mat-timepicker-toggle matIconSuffix [for]="endPicker">
                <mat-icon matTimepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-timepicker-toggle>
              <mat-timepicker #endPicker></mat-timepicker>
              <mat-error
                *ngIf="
                  businessHoursGroup.get(day + '.endTime')?.hasError('required')
                "
              >
                Required
              </mat-error>
              <mat-error
                *ngIf="
                  businessHoursGroup
                    .get(day + '.endTime')
                    ?.hasError('invalidDate')
                "
              >
                Invalid time format
              </mat-error>
            </mat-form-field>
            <mat-error
              class="err-msg-start-time"
              *ngIf="businessHoursGroup.get(day)?.hasError('startAfterEnd')"
            >
              Start time must be before end time
            </mat-error>
          </div>
        </div>
      </div>
      <div class="enable-out-of-office" formGroupName="outOfOffice">
        <app-radio-btn
          [btnLabel]="'Enable Out of Office Auto Response'"
          [btnWidth]="'370px'"
          [btnName]="'enable-out-of-office'"
          [btnDisplayTrueFirst]="true"
          [btnModel]="isOutOfOfficeEnabled"
          (btnModelChange)="toggleOutOfOffice()"
        ></app-radio-btn>
        <div>
          <p style="margin-bottom: 0;">
            Customers will receive the Out of Office response when this is enabled.
          </p>
          <p class="override-business-hours-disclaimer">This will override business hours and any other auto responses until disabled.</p>
        </div>
        <div style="margin-left: 31px;" *ngIf="isOutOfOfficeEnabled">
          <p class="end-holiday-text">End Holiday Auto Response:</p>
          <mat-form-field 
          appearance="outline"
          >
            <input matInput [matDatepicker]="picker" 
            formControlName="expirationDate"
             placeholder="Select Date"
            [min]="minDate"
            [max]="maxDate" />
            <mat-datepicker-toggle [for]="picker" matPrefix></mat-datepicker-toggle>
            <mat-datepicker #picker />
          </mat-form-field>
  
          <mat-form-field appearance="outline">
                <input
                  matInput
                  [matTimepicker]="outOfOfficePicker"
                  formControlName="expirationTime"
                />
                <mat-timepicker-toggle matIconSuffix [for]="outOfOfficePicker">
                  <mat-icon matTimepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-timepicker-toggle>
                <mat-timepicker #outOfOfficePicker></mat-timepicker>
          </mat-form-field>
          <div style="margin-top: -10px;">
            <p>Your hours of operation and other auto responses will resume at this date and time.</p>
            <span
            class="error-msg"
            *ngIf="isInErrorState()"
          >
        <i class="fa fa-exclamation-circle error-icon-position" aria-hidden="true"></i>
        Please select a date for your Out of Office auto response to end.
          </span>
          <span
            class="error-msg"
          *ngIf="isTimeSelectedInThePast()"
          >
              The time selected is in the past. Please select a future time.
          </span>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <div class="button-container">
    <button
      class="btn btn-primary"
      id="next-btn"
      (click)="saveAndContinue()"
      [disabled]="!businessHours || businessHoursGroup.invalid || isInErrorState() || isTimeSelectedInThePast()"
    >
      <span
        i18n="Label for Next button on new client configuration"
        id="save-and-next-button"
      >
        Save and Next
      </span>
      <span class="icon icon-right">
        <i class="fa fa-arrow-right"></i>
      </span>
    </button>
  </div>
</div>
