import { Component, OnInit, Input } from '@angular/core';
import { ProductGroup } from '@app/core/models/client';

@Component({
    selector: 'two-way-preview',
    templateUrl: './two-way-preview.component.html',
    styleUrls: ['./two-way-preview.component.scss'],
    standalone: false
})
export class TwoWayPreviewComponent implements OnInit {
    @Input() messageText: string;
    @Input() productGroup: ProductGroup;

    constructor() {}

    ngOnInit() {
    }
}
