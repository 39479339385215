import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Job, DownloadUrlFiletypes } from '@app/jobs-list/models/job';
import * as _ from 'lodash';

@Component({
    selector: 'rn-jobs-list-detail',
    templateUrl: './jobs-list-detail.component.html',
    styleUrls: ['./jobs-list-detail.component.scss'],
    standalone: false
})
export class JobsListDetailComponent {
  @Input() job: Job;
  @Input() showCancel: boolean;
  @Input() showSchedule: boolean;
  @Output() cancelClicked: EventEmitter<String> = new EventEmitter();
  @Output() scheduleClicked: EventEmitter<String> = new EventEmitter();

  noUrlsAvailable(): boolean {
    return this.job.download_urls && !(this.hasUrlFor('original_file') || this.hasUrlFor('accept_file') || this.hasUrlFor('reject_file'));
  }

  openFile(type: DownloadUrlFiletypes): void {
    if (this.job.download_urls[type]) {
      window.open(this.job.download_urls[type]);
    }
  }

  hasUrlFor(type: DownloadUrlFiletypes): boolean {
    return this.job.download_urls !== undefined && this.job.download_urls[type] !== undefined;
  }

  cancel(): void {
    this.cancelClicked.emit(this.job.id);
  }

  schedule(): void {
    this.scheduleClicked.emit(this.job.id);
  }

  showProgress(): boolean {
    return this.job.filetype !== 'report';
  }

  showStats(): boolean {
    return this.job.filetype !== 'report';
  }
}
