<div class="rn-page">
  <div class="help-text-container">
    <p *ngIf="!currentClient.feed_enabled">
      Every customer that starts a Two Way Conversation will be greeted with an
      Auto Response. It is a generic message welcoming your customer to the
      conversation, and prompting them to ask a question.
    </p>
    <p *ngIf="currentClient.feed_enabled">
      Every customer that starts a conversation will be greeted with an auto
      response. It is a generic message welcoming your customer to the
      conversation, and prompting them to ask a question.
    </p>
  </div>
  <rn-setup-banner-autoresponse
  [clientId]="clientId"
  ></rn-setup-banner-autoresponse>
  <div class="rn-page__section">
    <div
      class="rn-page--busy"
      *ngIf="
        isLoading() || (isRMAutoResponseEnabled && isErrorAutoResponse);
        else main_content
      "
    >
      <div *ngIf="isRMAutoResponseEnabled && isErrorAutoResponse">
        <p class="auto-responses-error-msg">
          Oops, there was an error loading this page. Please try refreshing
        </p>
      </div>
      <app-spinner
        *ngIf="
          !isRMAutoResponseEnabled ||
          (isRMAutoResponseEnabled && !isErrorAutoResponse)
        "
        [styleRules]="{ transform: 'translateY(250px)' }"
      ></app-spinner>
    </div>

    <ng-template #main_content>
      <form
        *ngIf="autoResponseForm && (!isRMAutoResponseEnabled || !isFeedEnabled)"
        class="form-group"
        [formGroup]="autoResponseForm"
      >
        <h3>Auto Responses</h3>
        <div>
          <div class="input-container input-group-with-preview clearfix">
            <generic-phone>
              <app-spinner
                *ngIf="!defaultProductGroup"
                [styleRules]="{ transform: 'translateY(100px)' }"
              ></app-spinner>
              <wire-header
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
              ></wire-header>
              <two-way-preview
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
                [messageText]="defaultAutoResponseCtl.value"
              ></two-way-preview>
            </generic-phone>

            <div class="input-group-with-preview__fields fields-group">
              <label for="default-auto-response">Auto Response Message</label>
              <p>
                All of your customers will receive this auto response when they
                start a conversation during working hours.
              </p>
              <div
                [ngClass]="{
                  'has-error':
                    defaultAutoResponseCtl.invalid &&
                    defaultAutoResponseCtl.touched
                }"
              >
                <span
                  class="error-msg"
                  *ngIf="
                    !defaultAutoResponseCtl.valid &&
                    defaultAutoResponseCtl.touched
                  "
                  tooltip="This is a Required Field"
                  placement="right"
                >
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>
                <textarea
                  class="form-control"
                  name="default-auto-response"
                  maxlength="8000"
                  formControlName="default_auto_response"
                ></textarea>
              </div>
            </div>
          </div>

          <h3>After Hours Auto Response</h3>
          <div class="input-container input-group-with-preview clearfix">
            <generic-phone>
              <app-spinner
                *ngIf="!defaultProductGroup"
                [styleRules]="{ transform: 'translateY(100px)' }"
              ></app-spinner>
              <wire-header
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
              ></wire-header>
              <two-way-preview
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
                [messageText]="afterHoursAutoResponseCtl.value"
              ></two-way-preview>
            </generic-phone>

            <div class="input-group-with-preview__fields fields-group">
              <label for="after-hours-auto-response"
                >After Hours Auto Response Message</label
              >
              <p>
                All of your customers will receive this auto response when they
                start a conversation during the After Hours window. Don't forget
                to review and adjust your After Hours window below.
              </p>
              <div
                [ngClass]="{
                  'has-error':
                    afterHoursAutoResponseCtl.invalid &&
                    afterHoursAutoResponseCtl.touched
                }"
              >
                <span
                  class="error-msg"
                  *ngIf="
                    !afterHoursAutoResponseCtl.valid &&
                    afterHoursAutoResponseCtl.touched
                  "
                  tooltip="This is a Required Field"
                  placement="right"
                >
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>
                <textarea
                  class="form-control"
                  name="after-hours-auto-response"
                  maxlength="8000"
                  formControlName="after_hours_auto_response"
                ></textarea>
              </div>

              <app-blackout-select
                *ngIf="twoWayConfig.after_hours_blackout_window"
                [(blackoutWindow)]="twoWayConfig.after_hours_blackout_window"
                [labelPrefix]="'After Hours'"
                [thingBeingSent]="'the After Hours Response'"
                [invert]="true"
              ></app-blackout-select>
            </div>
          </div>
        </div>
      </form>

      <!-- New auto responses form -->
      <form
        *ngIf="
          isRMAutoResponseEnabled &&
          isFeedEnabled &&
          !isErrorAutoResponse &&
          autoResponseForm
        "
        class="form-group"
        [formGroup]="autoResponseForm"
      >
        <h3>Auto Responses</h3>
        <div>
          <div class="auto-responses-container">
            <div class="phone-container">
              <phone
                [screen]="'two-way'"
                [autoResponseMessage]="autoResponseMessageFeed"
                [productGroup]="defaultProductGroup"
              ></phone>
            </div>
            <div class="auto-responses-input-group">
              <div
                class="auto-responses-wrapper"
                (click)="setSelectedAutoResponse('default-auto-response')"
              >
                <div
                  class="vertical"
                  [ngClass]="{
                    active: selectedAutoResponse === 'default-auto-response'
                  }"
                ></div>
                <div
                  class="auto-responses-input auto-responses-fields-group"
                  [ngClass]="{
                    active: selectedAutoResponse === 'default-auto-response'
                  }"
                >
                  <label for="default-auto-response"
                    >Welcome auto response</label
                  >
                  <p>
                    Customers will receive this auto response when they start a
                    conversation during the defined hours of operation.
                  </p>
                  <div
                    [ngClass]="{
                      'has-error':
                        defaultAutoResponseCtl.invalid &&
                        defaultAutoResponseCtl.touched
                    }"
                  >
                    <span
                      class="error-msg"
                      *ngIf="
                        !defaultAutoResponseCtl.valid &&
                        defaultAutoResponseCtl.touched
                      "
                      tooltip="This is a Required Field"
                      placement="right"
                    >
                      <i
                        class="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <textarea
                      class="form-control"
                      name="default-auto-response"
                      maxlength="8000"
                      formControlName="default_auto_response"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div
                class="auto-responses-wrapper"
                (click)="setSelectedAutoResponse('after-hours-auto-response')"
              >
                <div
                  class="vertical"
                  [ngClass]="{
                    active: selectedAutoResponse === 'after-hours-auto-response'
                  }"
                ></div>
                <div
                  class="auto-responses-input auto-responses-fields-group"
                  [ngClass]="{
                    active: selectedAutoResponse === 'after-hours-auto-response'
                  }"
                >
                  <label for="after-hours-auto-response"
                    >After Hours auto response</label
                  >
                  <p>
                    Customers will receive this auto response when they send a
                    message outside of the defined hours of operation.
                  </p>
                  <div
                    [ngClass]="{
                      'has-error':
                        afterHoursAutoResponseCtl.invalid &&
                        afterHoursAutoResponseCtl.touched
                    }"
                  >
                    <span
                      class="error-msg"
                      *ngIf="
                        !afterHoursAutoResponseCtl.valid &&
                        afterHoursAutoResponseCtl.touched
                      "
                      tooltip="This is a Required Field"
                      placement="right"
                    >
                      <i
                        class="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <textarea
                      class="form-control"
                      name="after-hours-auto-response"
                      maxlength="8000"
                      formControlName="after_hours_auto_response"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div
                class="auto-responses-wrapper"
                (click)="setSelectedAutoResponse('out-of-office-auto-response')"
              >
                <div
                  class="vertical"
                  [ngClass]="{
                    active:
                      selectedAutoResponse === 'out-of-office-auto-response'
                  }"
                ></div>
                <div
                  class="auto-responses-input auto-responses-fields-group"
                  [ngClass]="{
                    active:
                      selectedAutoResponse === 'out-of-office-auto-response'
                  }"
                >
                  <label for="out-of-office-auto-response"
                    >Out of Office auto response</label
                  >
                  <p>
                    Customers will receive this auto response when the Out of
                    Office response is enabled.
                    <span class="bold"
                      >This will override operating hours and any after hours
                      messaging until disabled.</span
                    >
                  </p>
                  <div
                    [ngClass]="{
                      'has-error':
                        outOfOfficeAutoResponseCtl.invalid &&
                        outOfOfficeAutoResponseCtl.touched
                    }"
                  >
                    <span
                      class="error-msg"
                      *ngIf="
                        !outOfOfficeAutoResponseCtl.valid &&
                        outOfOfficeAutoResponseCtl.touched
                      "
                      tooltip="This is a Required Field"
                      placement="right"
                    >
                      <i
                        class="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <textarea
                      class="form-control"
                      name="out-of-office-auto-response"
                      maxlength="8000"
                      formControlName="out_of_office_auto_response"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </div>

  <div class="button-container">
    <button
      class="btn btn-primary"
      id="next-btn"
      (click)="saveAndContinue()"
      [disabled]="
        (!isLoading() && !this.autoResponseForm.valid) ||
        (isRMAutoResponseEnabled && isErrorAutoResponse)
      "
    >
      <span
        i18n="Label for Next button on new client configuration"
        id="save-and-next-button"
      >
        Save and Next
      </span>
      <span class="icon icon-right">
        <i class="fa fa-arrow-right"></i>
      </span>
    </button>
  </div>
</div>

<message-dialog #autoResponseErrorMessage [showCancel]="false"></message-dialog>
