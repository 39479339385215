import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { Router } from '@angular/router';
import { Consent, Customer, ConsentParts } from '@app/core/models/customer';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { CustomerService } from '@app/core/services/customer.service';
import { FeatureService } from '@app/core/services/feature.service';
import {
  Permissions,
  PermissionService,
} from '@app/core/services/permission.service';
import { FormatUtils } from '@app/core/utils/format-utils';
import { take } from 'rxjs/operators';
import { ConsentEnum } from '@app/core/models/consent-type';
import { CustomerLookupLaunchService } from '@app/core/services/customer-lookup-launch.service';
import { Client } from '@app/core/models/client';

@Component({
    selector: 'rn-customer-row',
    templateUrl: './customer-row.component.html',
    styleUrls: ['./customer-row.component.scss'],
    standalone: false
})
export class CustomerRowComponent implements OnInit {
  @ViewChild('stopConsentDialog', { static: true }) stopConsentDialog: MessageDialogComponent;
  @ViewChild('serverErrorDialog', { static: true }) serverErrorDialog: MessageDialogComponent;
  @Input() customer: Customer;
  @Input() client: Client;
  @Input() id: string;
  @Input() openRowId: string;
  @Output() consentChanged: EventEmitter<any> = new EventEmitter();
  @Output() phoneRemoved: EventEmitter<any> = new EventEmitter();
  @Output() phoneAdded: EventEmitter<any> = new EventEmitter();
  @Output() rowOpened: EventEmitter<any> = new EventEmitter();
  displayDetails: Boolean = false;
  isLoadingDetails: Boolean = false;
  canViewPhoneMgmtEdit: Boolean = false;
  permissions = Permissions;
  consents: Consent[] = [];
  hideSendExperienceButton: Boolean = false;

  constructor(
    private featureService: FeatureService,
    private customerService: CustomerService,
    private router: Router,
    public permissionService: PermissionService,
    private customerLookupLaunchService: CustomerLookupLaunchService
  ) {}

  ngOnInit(): void {
    this.canViewPhoneMgmtEdit = this.featureService.checkFlag(
      FeatureFlags.phone_management_edit_ui
    );
    this.getConsents();
    this.updateDisplay();
  }

  ngOnChanges(): void {
    this.updateDisplay();
  }

  /**
   * This function will retrieve consents for the customer being loaded and determine whether the Send Experience button
   * should be accessible based on whether or not any of the customer's notification channels has a consent type of Stop.
   */
  getConsents() {
    this.isLoadingDetails = true;
    this.consents = [];
    this.customerService
      .getConsentByCCID(this.customer.ccid)
      .pipe(take(1))
      .subscribe({
        next: (consents) => {
          this.consents = consents;
          this.checkForStopConsents(this.consents);
        },
        complete: () => {
          this.isLoadingDetails = false;
        }
      });
  }

  checkForStopConsents(consents: Consent[]) {
    this.hideSendExperienceButton = false;
    this.hideSendExperienceButton = consents.every(
      (consent) => consent.consent_type === ConsentEnum.STOP
    );
  }

  customerCanReceiveMessage() {
    return (
      !this.customer.deactivated &&
      this.customerHasPhoneNumber() &&
      !this.hideSendExperienceButton
    );
  }

  customerHasPhoneNumber() {
    return (
      this.customer.notification_channels &&
      this.customer.notification_channels.length > 0
    );
  }

  formatPhoneNumber(unformattedNumber: string) {
    return FormatUtils.formatPhoneNumber(unformattedNumber);
  }

  onCustomerChannelDelete(
    customerId: string,
    ccid: string,
    channelAddr: string
  ) {
    this.isLoadingDetails = true;
    const formattedNumber = this.formatPhoneNumber(channelAddr);
    this.customerService
      .removePhoneNumber(ccid, channelAddr)
      .pipe(take(1))
      .subscribe(
        () => {
          this.phoneRemoved.emit(formattedNumber);
        },
        () => {
          this.serverErrorDialog.showMessage(
            `There was an error deleting the channel ${formattedNumber} for CCID ${ccid}`
          );
        },
        () => {
          this.getConsents();
        }
      );
  }

  onCustomerConsentChange(
    customerId: string,
    ccid: string,
    updatedConsent: Consent
  ) {
    const formattedNumber = this.formatPhoneNumber(updatedConsent.channel_addr);
    this.isLoadingDetails = true;
    this.hideSendExperienceButton = false;
    this.customerService
      .updateConsent(updatedConsent)
      .pipe(take(1))
      .subscribe(
        (x) => {
          this.consents = this.consents.map((consent) => {
            if (consent.channel_addr === x.channel_addr) {
              consent.consent_type = x.consent_type;
            }
            return consent;
          });
          this.consentChanged.emit(formattedNumber);
        },
        () => {
          this.serverErrorDialog.showMessage(
            `There was an error updating the consent for ${formattedNumber} to ${updatedConsent.consent_type}`
          );
        },
        () => {
          this.isLoadingDetails = false;
        }
      );
  }

  onCustomerChannelAdd(
    customerId: string,
    ccid: string,
    consentParts: ConsentParts
  ) {
    this.isLoadingDetails = true;
    const formattedNumber = this.formatPhoneNumber(consentParts.channel_addr);
    this.customerService
      .addPhoneNumber(
        ccid,
        consentParts.channel_addr,
        consentParts.consent_type as ConsentEnum
      )
      .pipe(take(1))
      .subscribe(
        () => {
          const newConsent: Consent = {
            client_id: this.customer.client_id,
            channel_addr: consentParts.channel_addr,
            channel_type: 'sms',
            consent_type: consentParts.consent_type,
            created_at: null,
            source_channel: 'api',
          };
          this.consents = [...this.consents, newConsent];
          this.phoneAdded.emit(formattedNumber);
        },
        (error) => {
          this.serverErrorDialog.showMessage(
            `There was an error adding the channel ${formattedNumber} for CCID ${ccid}: ${error.toString()}`
          );
        },
        () => {
          this.getConsents();
        }
      );
  }

  goToCustomerDetails(ccid) {
    this.router.navigateByUrl(`/customer-lookup/${encodeURIComponent(ccid)}`);
  }

  launchJourney(customer: Customer) {
    this.customerLookupLaunchService.customer = customer;

    if (
      this.permissionService.checkPermissions(
        this.permissions.ui_journey_launcher
      )
    ) {
      this.router.navigateByUrl(`/launcher/launchforuser/${customer.ccid}`);
    } else if (
      this.permissionService.checkPermissions(this.permissions.ui_quick_launch)
    ) {
      const id = this.client.validation.look_up_by_secondary_account_id
        ? customer.secondary_account_id
        : customer.ccid;
      let url = `/onboarding?ccid=${id}`;
      if (customer.telephone) {
        url = `${url}&telephone=${customer.telephone}`;
      }
      this.router.navigateByUrl(url);
    }
  }

  onRowClick() {
    // prevent displaying customer's details if deactivated
    if (this.customer.deactivated) {
      return;
    }
    if (this.permissionService.checkPermissions(this.permissions.customer)) {
      if (this.displayDetails) {
        this.displayDetails = false;
      } else {
        this.displayDetails = true;
        this.rowOpened.emit(this.id);
      }
    }
  }

  updateDisplay() {
    if (this.openRowId === this.id) {
      this.displayDetails = true;
    } else {
      this.displayDetails = false;
    }
  }

  confirmStopConsent(customer: Customer, consent: Consent, e) {
    e.stopPropagation();
    this.stopConsentDialog.showMessage(
      `Are you sure you want to stop consent for ${customer.first_name} ${customer.last_name}?`,
      consent
    );
  }

  /**
   * To be removed once all this functionality is enclosed in the new consent list component.
   * @param consent
   */
  stopConsent(consent: Consent) {
    if (consent) {
      consent.consent_type = 'stop';
      consent.source_channel = 'csr';
      this.customerService.updateConsent(consent).pipe(take(1)).subscribe();
    }
  }
  get canEditConsent(): boolean {
    return this.permissionService.checkPermissions(
      this.permissions.consent_edit
    );
  }
}
