import { InputParamUtil } from '@app/core/models/input-param-util';
import { Journey } from '@app/core/models/journey';
import { TitleService } from '@app/core/services/title.service';
import { ToolbarService } from '@app/cx-builder/services/toolbar.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-journey-inspector',
    templateUrl: './journey-inspector.component.html',
    styleUrls: ['./journey-inspector.component.scss'],
    standalone: false
})
export class JourneyInspectorComponent implements OnInit {
  @Input() journeyId: string;
  @Input() journey: Journey;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  inputParamsArray: string[] = [];
  journeyForm: UntypedFormGroup;

  constructor(
    private titleService: TitleService,
    public toolbarService: ToolbarService,
    private formBuilder: UntypedFormBuilder
  ) {}

  refreshFormControls() {
    this.journeyForm = this.formBuilder.group({
      journeyNotes: this.formBuilder.control(this.journey.latestContent.notes),
    });
  }

  ngOnInit() {
    this.inputParamsArray = InputParamUtil.findCompleteJourneyParams(
      this.journey.latestContent
    );
    this.refreshFormControls();
  }

  ngOnChanges() {
    this.refreshFormControls();
  }

  editJourney() {
    this.titleService.emitOnShowEditChange(true);
  }

  cancel() {
    this.close.emit();
  }

  keydownEnter(e) {
    return this.isSaveDisabled() ? e.preventDefault() : this.save()
  }

  save() {
    this.journey.latestContent.notes = this.journeyForm.get('journeyNotes').value;
  }

  isSaveDisabled() {
    return this.journeyForm.pristine;
  }

  onSubmit(): void {
    this.journeyForm.markAsPristine();
  }
}
