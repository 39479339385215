import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import * as _ from 'lodash';
import { SessionService } from '@app/security/session.service';

@Component({
    templateUrl: './media-library-container.component.html',
    styleUrls: ['./media-library-container.component.scss'],
    standalone: false
})
export class MediaLibraryContainer implements OnInit {
  routeSub$: Subscription;
  statusTabs: object[] = [{ id: 'media-library', text: 'Media Library' }];

  tabSelected = 'media-library';

  constructor(
    private activatedRoute: ActivatedRoute,
    public sessionService: SessionService,
    private router: Router,
    private featureService: FeatureService,
  ) {}

  ngOnInit() {
    const isFeedEnabled = _.get(
      this.sessionService.getCurrentUsersClient(),
      'feed_enabled',
      false,
    );
    if (isFeedEnabled) {
      this.statusTabs.push({
        id: 'branding-library',
        text: 'Branding Library',
      });
    }
    this.goToMedia();
  }
  
  isClickable() {
    return true;
  }
  tabClicked(tabId) {
    if (tabId === 'media-library') {
      this.goToMedia();
    } else {
      this.goToBranding();
    }
  }

  goToMedia(): void {
    const routeInfo = this.activatedRoute.routeConfig.children[0];
    if (routeInfo.path === 'media-library') {
      this.router.navigateByUrl('/media-branding-library/media-library');
    }
  }

  goToBranding(): void {
    const routeInfo = this.activatedRoute.routeConfig.children[1];
    if (routeInfo.path === 'branding-library') {
      this.router.navigateByUrl('/media-branding-library/branding-library');
    }
  }
}
