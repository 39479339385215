import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// Model and Service Imports
import { Client } from '../../../../../core/models/client';
import * as _ from 'lodash';

@Component({
    selector: 'app-agent-express-consent',
    templateUrl: './agent-express-consent.component.html',
    styleUrls: ['./agent-express-consent.component.scss'],
    standalone: false
})
export class AgentExpressConsentComponent implements OnInit {
  @Input() client: Client;

  consentFormGroup: UntypedFormGroup;

  constructor(
    public formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.consentFormGroup = this.formBuilder.group({
      'written_consent_language': [this.client.onboarding.csr.written_consent_language, Validators.required],
      'express_consent_language': [this.client.onboarding.csr.express_consent_language, Validators.required]
    });

    this.consentFormGroup.valueChanges.subscribe(() => {
      _.assign(this.client.onboarding.csr, this.consentFormGroup.getRawValue())
    });
  }

  getTooltipData(errors?: Object): string {
    if (errors['required']) {
      return `This field is required.`;
    }
  }
}
