import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { FeatureService } from '@app/core/services/feature.service';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';

@Component({
  selector: 'app-setup-container',
  templateUrl: './setup-container.component.html',
  styleUrls: ['./setup-container.component.scss'],
  standalone: false,
})
export class SetupContainerComponent implements OnInit {
  links = [];
  featureFlags = FeatureFlags;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private newFeed: NewFeedTransitionService,
    private featureService: FeatureService,
  ) {}

  ngOnInit() {
    const newFeedEnabled = this.newFeed.enabled;
    const isRMAutoResponseEnabled = this.featureService.checkFlag(
      FeatureFlags.rm_auto_response,
    );
    this.links = [
      { text: 'Users', path: '/two-way/setup/users' },
      { text: 'Auto Responses', path: '/two-way/setup/auto-response' },
      ...(isRMAutoResponseEnabled && newFeedEnabled
        ? [
            {
              text: 'Business Hours',
              path: '/two-way/setup/business-hours',
              data: {
                flag: FeatureFlags.rm_auto_response,
              },
            },
          ]
        : []),
      ...(newFeedEnabled
        ? [
            {
              text: 'Notification Ping',
              path: '/two-way/setup/notification-ping',
            },
          ]
        : [{ text: 'SMS Ping', path: '/two-way/setup/sms-ping' }]),
      ...(newFeedEnabled
        ? [
            {
              text: 'Conversations',
              path: '/two-way/setup/conversations',
              data: {},
            },
          ]
        : []),
      { text: 'Summary', path: '/two-way/setup/summary' },
    ];

    if (!this.activatedRoute.snapshot.children[0]) {
      this.router.navigateByUrl('/two-way/setup/users');
    }
  }
}
