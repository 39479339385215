import React from 'react';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import Search from 'baseui/icon/search';
import { Button, SHAPE, SIZE } from 'baseui/button';
import { Select, TYPE } from 'baseui/select';
import { AgentListSearchResult } from '../relay-messenger-agents/RelayMessengerAgents';
import { AgentFullName } from '../relay-messenger-axios-service/relay-messenger-axios-service';

export type SearchInputAgentsProps = {
  setIsSearchInputOpen: (value: boolean) => void;
  isSearchInputOpen: boolean;
  agentList: AgentListSearchResult[];
  setSearchOptions: (searchOptions: AgentFullName) => void;
  isAgent?: boolean;
};

export const SearchInputAgents = ({
  setIsSearchInputOpen,
  isSearchInputOpen,
  agentList,
  setSearchOptions,
  isAgent = false,
}: SearchInputAgentsProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const [value, setValue] = React.useState([]);

  const placeholder = 'Search by agent name';
  const setSearchOption = (selectedValue: AgentListSearchResult[]) => {
    if (selectedValue) {
      const firstSearchOption = selectedValue[0];
      if (firstSearchOption) {
        setSearchOptions({
          firstName: firstSearchOption.firstName,
          lastName: firstSearchOption.lastName,
        });
      }
    } else {
      setSearchOptions(null);
    }
    setValue([]);
  };

  if (isSearchInputOpen) {
    return (
      <>
        <Select
          options={agentList}
          value={value}
          type={TYPE.search}
          placeholder={placeholder}
          onChange={(params) => {
            setValue(params.value as any);
            setSearchOption(params.value as any);
          }}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                backgroundColor: '#FFFFFF',
                outline: `#00000`,
              }),
            },
            ControlContainer: {
              style: ({ $theme }) => ({
                backgroundColor: '#FFFFFF',
                padding: `0px`,
              }),
            },
          }}
          maxDropdownHeight="300px"
        />
        <Button
          onClick={() => setIsSearchInputOpen(false)}
          size={SIZE.compact}
          kind={'tertiary'}
          overrides={{
            BaseButton: {
              props: { 'data-testid': 'search-actions-close-btn' },
            },
          }}
        >
          Close
        </Button>
      </>
    );
  }

  if (!isSearchInputOpen) {
    return (
      <Button
        onClick={() => setIsSearchInputOpen(true)}
        size={SIZE.compact}
        shape={SHAPE.circle}
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: isSearchInputOpen
                ? '#3693BF'
                : customTheme.colors.white,
              color: isSearchInputOpen ? 'white' : '#3693BF',
              outline: 'none !important',
              ':hover': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
              ':active': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
            },
            props: { 'data-testid': 'search-actions-btn' },
          },
        }}
      >
        <Search size={28} />
      </Button>
    );
  }
};
