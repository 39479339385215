import { useCallback, useEffect, useState } from 'react';
import { useClientInfo } from '../../context/ClientInfoContext';
import {
  AgentFullName,
  AgentListBody,
  ControlGroup,
  TwoWayService,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { useUserInfo } from '../../context/UserInfoContext';
import React from 'react';
import { useStyletron } from 'baseui';
import { ListItem } from 'baseui/list';
import { Badge } from '../badge/Badge';
import { COLOR } from 'baseui/badge';
import { ParagraphSmall } from 'baseui/typography';
import { Button } from '@relaynetwork/design-system';
import { SortingToast } from '../filter-sorting/SortingToastMessage';
import {
  AgentFilterSortingPopover,
  AgentStatus,
} from '../filter-sorting/AgentFilterSortingPopover';
import { AgentNameAscendingDescendingMethods } from '../filter-sorting/SortAgentName';
import { useAssignedAgents } from '../../context/AssignedAgentsContext';
import { SearchInputAgents } from '../search/SearchInputAgents';
import { RelayMessengerAgent } from '../agent-modal/AgentModal';

export type AgentListSearchResult = {
  label: string;
  id: string;
  type: 'agent';
  firstName: string;
  lastName: string;
};

export interface RelayMessengerAgentsProps {
  agentConversationsClicked: boolean;
  onAgentSelected: (agent: RelayMessengerAgent) => void;
}

export const RelayMessengerAgents = ({
  agentConversationsClicked,
  onAgentSelected,
}: RelayMessengerAgentsProps) => {
  const [{ clientId }] = useClientInfo();
  const [{ userId }] = useUserInfo();
  const [agents, setAgents] = useState<RelayMessengerAgent[]>(null);
  const [isLoadingAgentList, setIsLoadingAgentList] = useState(false);
  const [isErrorAgentList, setIsErrorAgentList] = useState(false);
  const [css] = useStyletron();
  const [agentSearchOptions, setAgentSearchOptions] =
    useState<AgentFullName>(null);
  const [isAgentSearchInputOpen, setIsAgentSearchInputOpen] = useState(false);
  const [isAgentFilterSortingPopoverOpen, setIsAgentFilterSortingPopoverOpen] =
    useState(false);
  const [filterByOption, setFilterByOption] = useState<
    AgentStatus.Active | AgentStatus.Inactive
  >(null);
  const [filterByGroupId, setFilterByGroupId] = useState<string>(null);
  const [sortingName, setSortingName] = React.useState<
    | AgentNameAscendingDescendingMethods.Ascending
    | AgentNameAscendingDescendingMethods.Descending
  >();
  const [showClearAllButton, setShowClearAllButton] = useState(false);
  const { setAssignedAgents } = useAssignedAgents();
  const [groups, setGroups] = useState<ControlGroup[]>([]);

  const getGroupId = (filterOption: string): string => {
    const groupToFilterOn = groups.find(
      (group) => group.group_name === filterOption,
    );
    return groupToFilterOn.id;
  };

  const buildAgentListBody = () => {
    let body: AgentListBody = {};

    if (agentSearchOptions) {
      body.searchByAgentName = agentSearchOptions;
    }
    if (filterByOption) {
      let filterOption = '';
      switch (filterByOption) {
        case AgentStatus.Active:
          filterOption = 'active';
          break;
        case AgentStatus.Inactive:
          filterOption = 'inactive';
          break;
        default:
          filterOption = filterByOption;
          break;
      }
      if (filterOption === 'active' || filterOption === 'inactive') {
        body.filterByAgentStatus = filterOption;
      }
    }

    if (filterByGroupId) {
      body.filterByGroupId = getGroupId(filterByGroupId);
    }

    if (sortingName) {
      body.sortByName =
        sortingName === AgentNameAscendingDescendingMethods.Ascending
          ? 'ASC'
          : 'DESC';
    }

    return body;
  };

  const fetchAgents = useCallback(async () => {
    setIsLoadingAgentList(true);
    setAgents(null); // reset agents list
    try {
      const agentListBody = buildAgentListBody();
      const agents = await TwoWayService.getAgentsList(clientId, agentListBody);
      // exclude the current user from the list of agents if they are in the list
      const updatedAgents = agents
        ?.map((el) => {
          el.isSelected = false;
          return el;
        })
        .filter((agent) => agent.id !== userId);
      setAgents(updatedAgents);
      setIsErrorAgentList(false);
    } catch (error) {
      setAgents([]);
      setIsErrorAgentList(true);
    } finally {
      setIsLoadingAgentList(false);
    }
  }, [clientId, userId, agentSearchOptions, filterByOption, sortingName, filterByGroupId]);

  const fetchGroups = useCallback(async () => {
    try {
      const clientGroups = await TwoWayService.getGroups(clientId);
      setGroups(clientGroups);
    } catch (error) {}
  }, [clientId]);

  useEffect(() => {
    if (!agentConversationsClicked) {
      if (agents && agents.length) {
        const updatedAgents = agents.map((agent, i) => {
          return {
            ...agent,
            isSelected: false,
          };
        });
        setAgents(updatedAgents);
      }
    }
  }, [agentConversationsClicked]);

  useEffect(() => {
    if (agentSearchOptions || filterByOption || sortingName || filterByGroupId) {
      fetchAgents();
      setShowClearAllButton(true);
    } else {
      fetchAgents();
      setShowClearAllButton(false);
    }
  }, [agentSearchOptions, filterByOption, sortingName, filterByGroupId]);

  useEffect(() => {
    fetchGroups();
  }, []);

  const updateIsSelected = (agent, index) => {
    onAgentSelected(agent);
    if (agents && agents.length) {
      const updatedAgents = agents.map((agent, i) => {
        if (i === index) {
          return {
            ...agent,
            isSelected: true,
          };
        }
        return {
          ...agent,
          isSelected: false,
        };
      });
      setAgents(updatedAgents);
    }
  };

  const mapAgentNames = (
    agents: RelayMessengerAgent[],
  ): AgentListSearchResult[] => {
    const agentNames = [];
    if (agents) {
      agents.forEach((agent) => {
        if (agent?.firstName) {
          agentNames.push({
            label: `${agent.firstName} ${agent.lastName}`,
            id: agent.id,
            tag: 'agent',
            firstName: agent.firstName,
            lastName: agent.lastName,
          });
        }
      });
    }
    return agentNames;
  };

  const resetFilterSorting = () => {
    setAgentSearchOptions(null);
    setFilterByOption(null);
    setFilterByGroupId(null);
    setSortingName(null);
  };

  const removeFilterSorting = (isFilter: boolean, filterBy: string) => {
    if (isFilter && (filterBy === AgentStatus.Active || filterBy === AgentStatus.Inactive)) {
      setFilterByOption(null);
    } else if (isFilter && (filterBy !== AgentStatus.Active && filterBy !== AgentStatus.Inactive)) {
      setFilterByGroupId(null)
    } else {
      setSortingName(null);
    }
  };
  const clearFilterState = () => {
    // This is to unselect filtering (that does not have any options) when the sorting option is selected.
    if (filterByOption || filterByGroupId) {
      setFilterByOption(null);
      setFilterByGroupId(null);
    }
  };

  const clearSortingSelection = () => {
    setSortingName(null);
  };

  return (
    <div
      className={css({
        width: '100%',
        marginTop: '2rem',
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <div
          className={css({
            display: 'flex',
          })}
        >
          {!isAgentSearchInputOpen && (
            <p
              className={css({
                color: '#37474F',
                fontFamily: 'Source Sans Pro',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
              })}
              id="assigned-to-agent"
            >
              AGENTS
            </p>
          )}
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '-10px',
            })}
          >
            <SearchInputAgents
              isSearchInputOpen={isAgentSearchInputOpen}
              setIsSearchInputOpen={setIsAgentSearchInputOpen}
              agentList={mapAgentNames(agents)}
              setSearchOptions={setAgentSearchOptions}
              isAgent={true}
            />
            <AgentFilterSortingPopover
              filterByOption={filterByOption}
              filterByGroupId={filterByGroupId}
              setFilterByOption={setFilterByOption}
              setFilterByGroupId={setFilterByGroupId}
              isAgentFilterSortingPopoverOpen={isAgentFilterSortingPopoverOpen}
              setIsAgentFilterSortingPopoverOpen={
                setIsAgentFilterSortingPopoverOpen
              }
              sortingName={sortingName}
              setSortingName={setSortingName}
              clearFilterState={clearFilterState}
              clearSortingSelection={clearSortingSelection}
              groups={groups}
            ></AgentFilterSortingPopover>
          </div>
        </div>
        <div>
          <hr className={css({ border: '1', margin: '0px' })} />
          {showClearAllButton && (
            <>
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                })}
              >
                <ParagraphSmall
                  className={css({
                    color: 'rgba(55, 71, 79, 0.25) !important',
                    fontFamily: 'Source Sans Pro !important',
                    fontSize: '12px !important',
                    fontStyle: 'normal !important',
                    fontWeight: '600 !important',
                    lineHeight: 'normal !important',
                    marginBottom: '10px',
                    marginTop: '10px',
                  })}
                  data-testid="filter-by-text"
                >
                  FILTER BY
                </ParagraphSmall>
                {showClearAllButton && (
                  <Button
                    variant="empty"
                    onClick={resetFilterSorting}
                    size="small"
                    data-testid="clear-all-button"
                  >
                    Clear all
                  </Button>
                )}
              </div>
              <div
                className={css({
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'start',
                })}
              >
                {agentSearchOptions && (
                  <SortingToast
                    onActionClick={() => setAgentSearchOptions(null)}
                    text={`${agentSearchOptions.firstName} ${agentSearchOptions.lastName}`}
                    dataTestId={`toast-search-option-` + agentSearchOptions}
                  />
                )}
                {sortingName && (
                  <SortingToast
                    onActionClick={() => removeFilterSorting(false, sortingName)}
                    text={sortingName}
                    dataTestId={`toast-sorting-option-` + sortingName}
                  />
                )}
                {filterByOption && (
                  <SortingToast
                    onActionClick={() => removeFilterSorting(true, filterByOption)}
                    text={filterByOption}
                    dataTestId={`toast-filter-option-status` + filterByOption}
                  />
                )}
                {
                  filterByGroupId && (
                    <SortingToast
                      onActionClick={() => removeFilterSorting(true, filterByGroupId)}
                      text={filterByGroupId}
                      dataTestId={`toast-filter-option-by-group-id` + filterByGroupId}
                    />
                  )
                }
              </div>
            </>
          )}
        </div>
      </div>
      {(isLoadingAgentList || (!isErrorAgentList && agents === null)) && (
        <p>Loading agents...</p>
      )}
      {isErrorAgentList && <p>Failed to fetch agents</p>}
      {!isErrorAgentList && agents && agents.length === 0 && (
        <p>No agents found</p>
      )}
      <ul
        className={css({
          paddingLeft: '0',
          paddingRight: '0',
          overflowY: 'auto',
          maxHeight: '50vh',
        })}
        id="agents-list"
      >
        {!isErrorAgentList &&
          agents &&
          agents.length > 0 &&
          agents.map((agent, index) => {
            const status = agent.status || 'inactive';
            return (
              <ListItem
                key={agent.id}
                overrides={{
                  Root: {
                    style: {
                      outline: 'none',
                      backgroundColor: 'none',
                    },
                  },
                  Content: {
                    style: {
                      paddingLeft: '10px',
                      minHeight: '45px',
                      borderBottomColor: 'rgba(0, 0, 0, 0.12)',
                    },
                  },
                }}
              >
                <div
                  onClick={() => {
                    setAssignedAgents([]);
                    updateIsSelected(agent, index);
                  }}
                  className={css({
                    fontWeight: agent && agent?.isSelected ? `700` : `400`,
                  })}
                  id="agent-conversations"
                >
                  <Badge
                    color={
                      status === 'active' ? COLOR.positive : COLOR.negative
                    }
                    position="left"
                    status={status}
                  >
                    {agent.fullName}
                  </Badge>
                </div>
              </ListItem>
            );
          })}
      </ul>
    </div>
  );
};
