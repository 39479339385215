import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CsrJourneysModalService } from '@app/core/services/csr-journeys-modal.service';

@Component({
    selector: 'app-select-journey-button',
    templateUrl: './select-journey-button.component.html',
    styleUrls: ['./select-journey-button.component.scss'],
    standalone: false
})
export class SelectJourneyButtonComponent implements OnInit {

  routeParams: any = null;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private csrJourneysModalService: CsrJourneysModalService) { }

  ngOnInit() {}

  openJourneyList(): void {
    this.csrJourneysModalService.open();
  }
}
