import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { JourneyService } from '@app/core/services/journey.service';
import { Journey } from '@app/core/models/journey';
import { ComponentBaseClass } from '@app/core/models/message';
import { ToolbarService } from '@app/cx-builder/services/toolbar.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import {
  Permissions,
  PermissionService,
} from '@app/core/services/permission.service';
import { AutosaveService } from '@app/cx-builder/services/autosave.service';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    standalone: false
})
export class ToolbarComponent implements OnInit {
  @Input() journeyId: string;
  @Input() journey: Journey;
  @Output() validateJourney: EventEmitter<Journey> =
    new EventEmitter<Journey>();
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  @ViewChild('activeInspector') activeInspector;
  readonly fullWidth = 150;
  readonly collapsedWidth = 48;
  inspectorActive = false;
  selectedComponent: ComponentBaseClass;
  permissions = Permissions;
  featureFlags = FeatureFlags;
  pdfPreviewDropdown = false;
  constructor(
    public toolbarService: ToolbarService,
    private journeyService: JourneyService,
    private autosaveService: AutosaveService,
    public featureService: FeatureService,
    public ps: PermissionService,
  ) {}

  get currentWidth() {
    if (this.toolbarService.collapsed) {
      return this.collapsedWidth;
    } else {
      return this.fullWidth;
    }
  }

  ngOnInit() {
    this.toolbarService.inspectorError.subscribe((errorMessage) => {
      this.messageDialog.showMessage(errorMessage);
    });

    this.toolbarService.openInspector.subscribe(() => {
      this.inspectorActive = true;
    });
  }

  toggleSize() {
    this.toolbarService.collapsed = !this.toolbarService.collapsed;
  }

  toggleInspector() {
    this.inspectorActive = !this.inspectorActive;
  }

  saveIfNeeded() {
    if (this.inspectorActive) {
      this.autosaveService.saveIfNeeded();
    }
  }

  completeJourney() {
    if (this.journey.draft) {
      this.validateJourney.emit(this.journey);
    }
  }

  previewJourney(): void {
    this.journeyService.previewJourney(this.journey);
  }

  pdfPreview(): void {
    this.pdfPreviewDropdown = !this.pdfPreviewDropdown;
  }

  expandedPdfPreview(): void {
    this.closeModal();
    this.journeyService.pdfExpandPreview(this.journey);
  }

  collapsedPdfPreview(): void {
    this.closeModal();
    this.journeyService.pdfPreview(this.journey);
  }

  journeyPreviewable(): boolean {
    return this.journeyService.journeyPreviewable(this.journey);
  }

  closeModal() {
    this.pdfPreviewDropdown = false;
  }
}
