import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { TwoWayMessagingContainer } from '@app/two-way/relay-messenger/components/relay-messenger-container/RelayMessengerContainer';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { ClientService } from '@app/core/services/client.service';
import { Client } from '@app/core/models/client';

export type RelayMessengerFeatureFlag = {
  flagName: string;
  flagValue: boolean;
};

@Component({
    selector: 'app-two-way-messaging',
    templateUrl: './relay-messenger.component.html',
    styleUrls: ['./relay-messenger.component.scss'],
    standalone: false
})
export class RelayMessengerComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  client: Client;
  clientId: string = '';
  paramsClientId: string = '';
  userId: string = '';
  userRoleIds: string[] = [];
  reactWrapperId: string = '';
  relayMessengerR2FeatureFlag: boolean = false;
  featureFlags = FeatureFlags;
  root: Root = null;
  rmAutoResponseFeatureFlag: boolean = false;

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    public featureService: FeatureService,
    private clientService: ClientService,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    const node = document.getElementById(this.reactWrapperId);
    this.root = createRoot(node);
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngOnInit(): void {
    this.paramsClientId = this.route.snapshot.children[0]?.params.client_id;
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    // check for client id in the url and switch to that client if it is different or exists
    if (this.paramsClientId && this.paramsClientId !== this.clientId) {
      this.clientService.getClient(this.paramsClientId).subscribe(
        (client) => {
          this.switchClients(client);
        },
        () => {
          this.client = new Client();
        },
      );
    }
    this.titleService.activate('Relay Messenger');
    this.userId = this.sessionService.currentUser.id;
    this.userRoleIds = this.sessionService.currentUser.role_id;
    this.reactWrapperId = 'relay-messenger-wrapper-id';
    this.rmAutoResponseFeatureFlag = this.featureService.checkFlag(
      this.featureFlags.rm_auto_response,
    );
  }

  reloadSession() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(location.pathname);
    });
  }

  switchClients(client: Client) {
    this.sessionService.setClient(client);
    this.client = client;
    this.reloadSession();
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
    this.root.unmount();
  }

  protected render() {
    const featureFlags: RelayMessengerFeatureFlag[] = [
      {
        flagName: 'rm_auto_response',
        flagValue: this.rmAutoResponseFeatureFlag,
      },
    ];
    if (this.root) {
      this.root.render(
        React.createElement(TwoWayMessagingContainer, {
          clientId: this.clientId,
          userId: this.userId,
          userRoleIds: this.userRoleIds,
          channelSid: this.route.snapshot.queryParamMap.get('chat') ?? null,
          featureFlags,
        }),
      );
    }
  }
}
