import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { SessionService } from '@app/security/session.service';
import { LoadingMaskService } from '@app/core/services/loading-mask.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-safe-redirect',
    templateUrl: './safe-redirect.component.html',
    styleUrls: ['./safe-redirect.component.scss'],
    standalone: false
})
export class SafeRedirectComponent implements OnInit, OnDestroy {

  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              private ps: PermissionService,
              private loadingMask: LoadingMaskService,
              private sessionService: SessionService) {
  }

  ngOnInit() {
    this.loadingMask.loadingOn();

    if (this.ps.hasCurrentUser()) {
      this.navigateAway();
      return;
    }

    if (this.sessionService.hasUserInLocalStorage()) {
      this.sessionService.getCurrentUser().subscribe(
      () => { 
        this.navigateAway();
      },
      (error) => {
        this.router.navigateByUrl('/login');
      });
      return;
    }
    this.router.navigateByUrl('/logout', {replaceUrl: true});
  }

  ngOnDestroy() {
    this.loadingMask.loadingOff();
  }

  navigateAway() {
    const snapshot = this.activeRoute.snapshot;

    if (snapshot.queryParams.hasOwnProperty('redirect')) {
      this.goToRedirect(snapshot);
    } else if (this.ps.checkPermissions(Permissions.ui_journey_list)) {
      this.router.navigateByUrl('/experiences', {replaceUrl: true});
    } else if (this.ps.checkPermissions(Permissions.ui_quick_launch)) {
      this.router.navigateByUrl('/onboarding', {replaceUrl: true});
    } else if (this.ps.checkPermissions(Permissions.ui_journey_launcher)) {
      this.router.navigateByUrl('/launcher', {replaceUrl: true});
    } else if (this.ps.checkPermissions(Permissions.ui_user_mgmt)) {
      this.router.navigateByUrl('/user-mgmt', { replaceUrl: true });
    } 
  }
  
  goToRedirect(snapshot) {
    // two-way takes full path, all other routes do not.
    const redirectUrl = this.isTwoWay(snapshot.queryParams.redirect) ? snapshot.queryParams.redirect : snapshot.queryParams.redirect.split('/')[1];
    this.router.navigateByUrl(redirectUrl, {replaceUrl: true});
  }

  isTwoWay(url): boolean {
    return _.includes(url, 'two-way');
  }
}
