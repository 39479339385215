import { Component, Input, OnInit } from '@angular/core';
import { Client } from '@app/core/models/client';
import { JourneySelectService } from '@app/core/services/journey-select.service';

@Component({
    selector: 'app-agent-onboarding-config',
    templateUrl: './agent-onboarding-config.component.html',
    styleUrls: ['./agent-onboarding-config.component.scss'],
    standalone: false
})
export class AgentOnboardingConfigComponent implements OnInit {
  @Input() client: Client;
  preview = false;
  showConfigGroup: boolean = false;

  constructor(private journeySelectService: JourneySelectService) {
  }

  ngOnInit() {
    this.setEnabledIfJourneySelect();
  }

  private setEnabledIfJourneySelect(): void {
    if (this.client.onboarding.csr.enabled !== 'true' && this.journeySelectService.init) {
      this.client.onboarding.csr.enabled = 'true';
    }
  }

}
