import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RelaySharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerChannelListComponent } from './customer-consent-list/customer-consent-list.component';
import { ValidPhoneNumberPipe } from './local-pipes/valid-phone-number.pipe';
import { FilterChannelsPipe } from './local-pipes/filter-channels.pipe';
import { CustomerRowComponent } from './customer-row/customer-row.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InfiniteScrollModule,
    RelaySharedModule,
    RouterModule,
    MatTooltipModule,
  ],
  declarations: [
    FilterChannelsPipe,
    ValidPhoneNumberPipe,
    CustomerListComponent,
    CustomerDetailComponent,
    CustomerChannelListComponent,
    CustomerRowComponent,
  ],
})
export class CustomerListModule {}
