import React from 'react';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { SetupConversationsHome } from '../setup-conversations-home/SetupConversationsHome';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { BaseProvider, LightTheme } from 'baseui';
import { PLACEMENT, SnackbarProvider } from 'baseui/snackbar';

export type SetupConversationsContainerProps = {
  clientId: string;
  twoWayConfig: TwoWayConfig;
  isRMAutoResponseEnabled?: boolean;
  isFeedEnabled?: boolean;
};

const engine = new Styletron({ prefix: 'setup-conversations-' }); // generate classNames with a prefix of 'setup-conversations';

export const SetupConversationsContainer = ({
  clientId,
  twoWayConfig,
  isRMAutoResponseEnabled,
  isFeedEnabled,
}: SetupConversationsContainerProps) => {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <SnackbarProvider
          placement={PLACEMENT['bottom']}
          overrides={{
            Root: {
              style: {
                outline: `#37474F solid`,
                backgroundColor: '#37474F',
                color: '#FFFFFF',
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '12.57px',
                borderTopRightRadius: '5px',
                borderTopLeftRadius: '5px',
                borderBottomRightRadius: '5px',
                borderBottomLeftRadius: '5px',
              },
            },
            Content: {
              style: {
                height: 'auto',
                justifyContent: 'center',
              },
            },
          }}
        >
          <SetupConversationsHome
            clientId={clientId}
            twoWayConfig={twoWayConfig}
            isRMAutoResponseEnabled={isRMAutoResponseEnabled}
            isFeedEnabled={isFeedEnabled}
          ></SetupConversationsHome>
        </SnackbarProvider>
      </BaseProvider>
    </StyletronProvider>
  );
};
