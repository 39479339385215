// Angular Core Imports
import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'app-product-legal-generic',
    templateUrl: './product-legal-generic.component.html',
    styleUrls: ['./product-legal-generic.component.scss'],
    standalone: false
})
export class ProductLegalGenericComponent implements OnInit {
  @Input() label: string;
  @Input() inputModel: any;

  constructor() { }

  ngOnInit() {
  }

}
