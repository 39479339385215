import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client, ProductGroup, PGVerification } from '@app/core/models/client';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { VerificationField } from '@app/core/models/validation-verification';
import { CustomValidators } from '@app/core/utils/custom-validators';
import * as _ from 'lodash';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
  standalone: false,
})
export class VerificationComponent implements OnInit {
  @Input() client: Client;
  @Input() productGroup: ProductGroup;
  @Output() validationStateChange: EventEmitter<boolean> = new EventEmitter();

  headerGroups = { verification: true };
  showTooltip: boolean = false;
  // TODO: kinda confusing to have 2 forms in one component...
  verificationConfigForm: FormGroup; // VERIFICATION
  verificationFieldsArray: FormArray; // VALIDATION

  ngOnInit() {
    this.initConfigForm();
    this.initVerificationFieldsForm();

    // submit an initial event to broadcast initial validation state
    this.validationStateChange.emit(this.verificationFieldsArray.valid);

    this.verificationFieldsArray.valueChanges.subscribe(() => {
      this.validationStateChange.emit(this.verificationFieldsArray.valid);
    });
  }

  getVerificationFields(): VerificationField[] {
    return this.verificationFieldsArray.getRawValue() as VerificationField[];
  }

  getVerificationConfig(): PGVerification {
    return this.verificationConfigForm.getRawValue() as PGVerification;
  }

  markAllAsTouched() {
    CustomValidators.markAllAsTouched(this.verificationConfigForm);
    CustomValidators.markAllAsTouched(this.verificationFieldsArray);
  }

  get validationFieldValues(): string[] {
    return _.values(this.verificationFieldsArray.controls);
  }

  // verificationConfigForm Group shape matches the PGVerificationModel in models/client
  private initConfigForm(): void {
    this.verificationConfigForm = new FormGroup({
      body: new FormControl<string>(
        this.productGroup.onboarding.verification.body,
      ),
      header: new FormControl<string>(
        this.productGroup.onboarding.verification.header,
      ),
      button_label: new FormControl<string>(
        this.productGroup.onboarding.verification.button_label,
      ),
    });
  }

  // formGroups in the verificationFieldsArray
  private initVerificationFieldsForm(): void {
    this.verificationFieldsArray = new FormArray([]);

    _.each(this.client.verification.fields, (field: VerificationField) => {
      // if the client is feed enabled, we want to use the prompt from the product group or
      // fallback to the client config if there isn't one configured
      // otherwise, we want to use the prompt from the clients
      this.verificationFieldsArray.push(
        new FormGroup({
          field: new FormControl<string>(field.field),
          prompt: new FormControl<string>(
            this.client.feed_enabled
              ? this.productGroup.onboarding.verification.fields.find(
                  (item) => item.field === field.field,
                )?.prompt ?? field.prompt
              : field.prompt,
            Validators.required,
          ),
        }),
      );
    });
  }
}
