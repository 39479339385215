import { Component, OnInit } from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { Client } from '@app/core/models/client';
import { SessionService } from '@app/security/session.service';

@Component({
    selector: 'app-sftp-mgmt',
    templateUrl: './sftp-mgmt.component.html',
    styleUrls: ['./sftp-mgmt.component.scss'],
    standalone: false
})
export class SftpMgmtComponent implements OnInit {

  tabs = [
    {id: 'internal-sftp-mgmt', label: 'Relay Hosted SFTP'},
    {id: 'external-sftp-mgmt', label: 'Client Hosted SFTP'},
    {id: 'key-config', label: 'File Encryption'}
  ];
  currentTabId: string = 'internal-sftp-mgmt';
  client: Client;

  constructor(private titleService: TitleService,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.titleService.activate('SFTP & GPG Key Management');
    this.client = this.sessionService.getCurrentUsersClient();
  }
}
