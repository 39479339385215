import React, { useCallback, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { COLOR } from 'baseui/badge';
import { AgentAvatar } from '../agent-avatar/AgentAvatar';
import { Button, KIND, SIZE } from 'baseui/button';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { useCustomerInfo } from '../../context/CustomerInfoContext';
import {
  GetAgentStatusesOutput,
  TwoWayService,
  getUpdatedDate,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { useClientInfo } from '../../context/ClientInfoContext';
import { Badge } from '../badge/Badge';
import { useUserInfo } from '../../context/UserInfoContext';
import { AgentStatus } from '../filter-sorting/AgentFilterSortingPopover';
import { useAssignedAgents } from '../../context/AssignedAgentsContext';
import { AgentStatusT } from '../relay-messenger-axios-service/relay-messenger-axios-service';

export type RelayMessengerAgent = {
  id: string;
  roleId: string[];
  clientId: string;
  emailAddress: string;
  fullName: string;
  firstName: string;
  lastName: string;
  lastLogin: string | null;
  groupId: string | null;
  assignedConversation?: number;
  imageURL?: string;
  isSelected?: boolean;
  status?: AgentStatusT;
};

export type AgentModalVariant = 'assign' | 'invite' | 're-assign';

export type AgentModalProps = {
  customerCcid: string;
  isInviteModalOpen: boolean;
  setIsInviteCheckModalOpen: (value: boolean) => void;
  setSelectedAgent: (agent: RelayMessengerAgent) => void;
  closeModal: () => void;
  variant?: AgentModalVariant;
};

type TextMessageProps = {
  message: string;
};

const TextMessage = ({ message }: TextMessageProps) => {
  const [css] = useStyletron();
  return (
    <p
      className={css({
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '15.6px',
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft: '12px',
        marginRight: '12px',
      })}
    >
      {message}
    </p>
  );
};

const AgentCountMessage = ({ message }: TextMessageProps) => {
  const [css] = useStyletron();
  return (
    <p
      className={css({
        fontSize: '12px',
        lineHeight: '10.06px',
        fontWeight: '600',
        color: '#37474F80',
      })}
    >
      {message}
    </p>
  );
};

export const AgentModal = ({
  customerCcid,
  isInviteModalOpen,
  setIsInviteCheckModalOpen,
  setSelectedAgent,
  closeModal,
  variant = 'invite',
}: AgentModalProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const {
    customerInfo: {
      firstName,
      lastName,
      dateCreated,
      dateUpdated,
      tagName,
      tagId,
    },
  } = useCustomerInfo();
  const [{ clientId }] = useClientInfo();
  const [{ userId }] = useUserInfo();
  const formattedDateCreated = new Date(dateCreated).toLocaleDateString();
  const updatedDate = dateUpdated ? getUpdatedDate(dateUpdated) : '';
  const [searchText, setSearchText] = useState('');
  const [agents, setAgents] = useState<RelayMessengerAgent[]>([]);
  const [isAgentsLoading, setIsAgentsLoading] = useState(false);
  const [isAgentsError, setIsAgentsError] = useState(false);
  const [agentIds, setAgentIds] = useState<string[]>([]);
  const { assignedAgents } = useAssignedAgents();
  const [agentStatuses, setAgentStatuses] = useState<GetAgentStatusesOutput[]>(
    [],
  );

  const fetchAgents = useCallback(async () => {
    // perform search when invite/assign/reassigned modals are open
    try {
      const agents = await TwoWayService.getAgentsByTag(
        clientId,
        tagId,
        searchText,
      );
      // exclude the current user from the list of agents only if the variant is not re-assign
      let updatedAgents: RelayMessengerAgent[] = agents;
      if (variant !== 're-assign') {
        updatedAgents = agents?.filter((agent) => agent.id !== userId);
      }
      setAgents(updatedAgents);
      const updatedAgentsIds = updatedAgents.map((user) => user.id);
      setAgentIds(updatedAgentsIds);
    } catch (error) {
      if (error.response?.data?.error === 'no agents found') {
        setAgents([]);
      } else {
        setIsAgentsError(true);
      }
    } finally {
      setIsAgentsLoading(false);
    }
  }, [isInviteModalOpen, tagId, searchText]);

  useEffect(() => {
    setIsAgentsLoading(true);
    setIsAgentsError(false);
    // debounce search to reduce the number of requests
    const timeoutId = setTimeout(() => {
      if (isInviteModalOpen && tagId) {
        fetchAgents();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchText, isInviteModalOpen, tagId]);

  const fetchAgentsStatuses = useCallback(async () => {
    try {
      const agentStatuses = await TwoWayService.getAgentStatuses(
        clientId,
        agentIds,
      );
      setAgentStatuses(agentStatuses);
    } catch (error) {
      console.error('Error fetching two-way user statuses', error);
    }
  }, [agentIds]);

  useEffect(() => {
    // get agents statuses
    if (agentIds.length) {
      fetchAgentsStatuses();
    }
  }, [agentIds]);

  const selectAgent = (agent: RelayMessengerAgent) => {
    closeModal();
    setIsInviteCheckModalOpen(true);
    setSelectedAgent(agent);
  };

  return (
    <div>
      <p
        className={css({
          color: '#37474F33',
          fontWeight: '600',
          lineHeight: '15.6px',
          textTransform: 'uppercase',
          fontSize: '14px',
        })}
      >
        Conversation
      </p>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: '22px',
          paddingRight: '22px',
        })}
      >
        <p
          className={css({
            fontSize: '17px',
            lineHeight: '13.8px',
            fontWeight: '700',
          })}
        >
          {firstName} {lastName}
        </p>
        <p
          className={css({
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '15.6px',
          })}
        >
          Account ID (CCID): {customerCcid}
        </p>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '15.6px',
          })}
        >
          <p>Created: {formattedDateCreated}</p>
          <p>Last Modified: {updatedDate}</p>
          <p>Tag: {tagName}</p>
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          padding: '0px 12px',
          flexDirection: 'row',
        })}
      >
        <Input
          startEnhancer={<Search size="18px" />}
          placeholder="Search by agent name or email"
          value={searchText}
          type="text"
          onChange={(e) => setSearchText(e.target['value'])}
        />
      </div>
      {/* Load two-way agents */}
      {isAgentsLoading ? (
        <TextMessage message="Loading..." />
      ) : isAgentsError ? (
        <TextMessage message="There was an error fetching agents. Please refresh to try again!" />
      ) : agents.length ? (
        <div
          className={css({
            display: 'flex',
            padding: '0px 12px',
            flexDirection: 'column',
            marginTop: '20px',
          })}
        >
          <AgentCountMessage
            message={`${agents.length} agent${agents.length !== 1 ? 's' : ''}`}
          />
          <div
            className={css({
              maxHeight: '42vh',
              overflowY: 'auto',
              marginBottom: '20px',
            })}
          >
            {agents.map((agent, index) => {
              const status: AgentStatusT =
                agentStatuses.length > 0 && agentStatuses[index]?.status
                  ? agentStatuses[index].status
                  : 'inactive';
              const disabledButtonAssignedAgent = assignedAgents.find(
                (assignedAgent) =>
                  assignedAgent['attributes']['agent'].id === agent.id,
              );
              return (
                <div
                  key={agent.id}
                  className={css({
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    padding: '10px',
                    height: 'auto',
                    ':hover': {
                      backgroundColor: '#3693BF0D',
                    },
                  })}
                >
                  <div
                    className={css({
                      flexGrow: 0,
                      flexShrink: 0,
                      flexBasis: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    })}
                  >
                    <AgentAvatar
                      name={agent.fullName}
                      variant="small"
                    ></AgentAvatar>
                  </div>
                  <div
                    className={css({
                      display: 'flex',
                      flexGrow: '4',
                      flexDirection: 'column',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                      maxWidth: '300px',
                    })}
                  >
                    <p
                      className={css({
                        fontSize: '17px',
                        fontWeight: '700',
                        color: '#37474F',
                      })}
                    >
                      {agent.fullName}
                    </p>
                    <p
                      className={css({
                        fontSize: '12px',
                        fontWeight: '400',
                        textAlign: 'left',
                        color: '#37474F',
                      })}
                    >
                      {agent.emailAddress}
                    </p>
                    <div
                      className={css({
                        display: 'flex',
                        fontSize: '12px',
                        fontWeight: '400',
                        textAlign: 'left',
                        alignItems: 'center',
                        color: '#37474FB2',
                      })}
                    >
                      <span
                        className={css({
                          marginRight: '25px',
                        })}
                      >
                        Status:
                      </span>
                      <Badge
                        color={
                          status === 'active' ? COLOR.positive : COLOR.negative
                        }
                        position="left"
                        status={status}
                      >
                        {status === 'active'
                          ? AgentStatus.Active
                          : AgentStatus.Inactive}
                      </Badge>
                    </div>
                  </div>
                  <div
                    className={css({
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 0,
                      flexShrink: 0,
                      flexBasis: 'auto',
                    })}
                  >
                    <Button
                      onClick={() => selectAgent(agent)}
                      disabled={disabledButtonAssignedAgent ? true : false}
                      kind={KIND.secondary}
                      size={SIZE.mini}
                      overrides={{
                        BaseButton: {
                          style: () => ({
                            width: 'auto',
                            height: '40px',
                            borderTopLeftRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            borderBottomLeftRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            borderTopRightRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            borderBottomRightRadius:
                              customTheme.borders.agentInfoButtonBorderRadius,
                            background: '#3693BF',
                            color: '#FFFFFF',
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                            boxShadow: '0px 0px 5px 0px #3693BF80',
                            ':hover': {
                              backgroundColor: '#f6f6f6',
                              color: '#5192BB',
                            },
                          }),
                        },
                      }}
                    >
                      {variant === 'assign' || variant === 're-assign'
                        ? 'Assign'
                        : 'Invite'}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className={css({
            display: 'flex',
            padding: '0px 12px',
            flexDirection: 'column',
            marginTop: '20px',
          })}
        >
          <AgentCountMessage message="No Agents found" />
        </div>
      )}
    </div>
  );
};
