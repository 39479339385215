import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { EnvService } from '@app/core/services/env.service';
import { InsightAppIds } from '@app/core/utils/insight-app-ids';
import { SessionService } from '@app/security/session.service';
import * as $ from 'jquery';

window['$'] = $;
window['jQuery'] = $;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  constructor(
    private meta: Meta,
    private envService: EnvService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.addInsightAppMeta();
    this.initPendo();
  }

  /**
   * Initialize Pendo for the entire application.
   *
   * Waits until user information is populated so it can be provided to Pendo.
   */
  initPendo() {
    this.sessionService.loggedIn$.subscribe((loggedIn) => {
      const { currentUser } = this.sessionService;
      if (!loggedIn || currentUser == null) {
        return;
      }

      const env = this.envService.getEnv();
      // gets types from @types/pendo-io-browser, which defines this global
      pendo.initialize({
        visitor: {
          id: `${
            (env !== 'production' ? `${env}:` : '') + currentUser.email_address
          }`,
        },

        account: {
          id: currentUser.clientId,
        },
      });
    });
  }

  addInsightAppMeta() {
    const env = this.envService.getEnv();
    if (!InsightAppIds[env]) {
      return;
    }
    this.meta.addTag({
      name: 'insight-app-sec-validation',
      content: InsightAppIds[env],
    });
  }
}
