import React, { useCallback, useState } from 'react';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import { Modal } from '../modal/Modal';
import { useClientInfo } from '../../context/ClientInfoContext';
import { ActionsModal } from '../actions-modal/ActionsModal';
import {
  AgentStatusT,
  TwoWayService,
} from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { useCustomerInfo } from '../../context/CustomerInfoContext';
import { CustomerActionsPopover } from '../customer-actions/CustomerActionsPopover';
import { useUserInfo } from '../../context/UserInfoContext';
import { useAlertMessage } from '../../hooks/useAlertMessage/use-alert-message';
import {
  ClosedReasons,
  ClosedReasonsService,
} from '../../../setup/components/setup-conversations/components/closed-reasons-axios-service/closed-reasons-axios-service';

export type TwoWayMessagingCustomerInfoProps = {
  conversationsClicked: {
    isMyConversationsClicked: boolean;
    IsMyUnassignedConversationClicked: boolean;
    IsMyClosedConversationsClicked: boolean;
  };
  setIsMyConversationsClicked: (isMyConversationsClicked: boolean) => void;
  setAgentStatus: (agentStatus: AgentStatusT) => void;
  customerCcid: string;
  setIsPingTriggered: (triggerFlag: boolean) => void;
};

export const TwoWayMessagingCustomerInfo = ({
  conversationsClicked: { isMyConversationsClicked },
  setIsMyConversationsClicked,
  setAgentStatus,
  customerCcid,
  setIsPingTriggered,
}: TwoWayMessagingCustomerInfoProps) => {
  const [css] = useStyletron();
  const [{ clientId }] = useClientInfo();
  const {
    customerInfo: { firstName, lastName, productGroupId, channelSid },
    setCustomerInfo,
  } = useCustomerInfo();
  const [{ userId }] = useUserInfo();
  const [isActionsPopoverOpen, setIsActionsPopoverOpen] = useState(false);
  const [isPingModalOpen, setIsPingModalOpen] = useState(false);
  const [isCloseChannelModalOpen, setIsCloseChannelModalOpen] = useState(false);
  const [closedReasons, setClosedReasons] = useState<ClosedReasons[]>([]);
  const showMessage = useAlertMessage();

  const fetchClosedReasons = useCallback(async () => {
    try {
      const data = await ClosedReasonsService.getClient(clientId);
      if (
        data &&
        data.client &&
        data.client.two_way_config &&
        data.client.two_way_config.close_reasons_enabled
      ) {
        const closedReasonsRes = await ClosedReasonsService.getClosedReasons(
          clientId,
        );
        setClosedReasons(closedReasonsRes);
      } else {
        setClosedReasons([]);
      }
      setIsCloseChannelModalOpen(true);
    } catch (error) {
      console.error('Error fetching closed reasons', error);
    }
  }, [clientId, setIsCloseChannelModalOpen, setClosedReasons]);

  const pingCustomer = async () => {
    setIsPingModalOpen(false);
    try {
      await TwoWayService.setAgentStatus(clientId, userId, 'ping');
      await TwoWayService.pingUser(clientId, channelSid);
      showMessage('Ping sent to customer!', 'success');
      setIsPingTriggered(true);
      setAgentStatus('active');
    } catch (error) {
      const errCode = error.response?.data.code;
      if (errCode === 'PING_SUPPRESSION') {
        showMessage('Customer was already pinged recently', 'error');
      } else if (errCode === 'BAD_CONSENT') {
        showMessage(
          'Customer has not given consent to receive notifications',
          'error',
        );
      } else {
        showMessage('There was an error sending the ping', 'error');
      }
    }
  };

  const navigateToExperienceLauncher = () => {
    document.location.href = `/launcher/launchforuser/${customerCcid}?chat=${channelSid}`;
  };

  const closeConversation = async (reasonId, reasonValue, reasonText) => {
    setIsCloseChannelModalOpen(false);
    try {
      await TwoWayService.closeConversation(
        clientId,
        channelSid,
        reasonId,
        reasonValue,
        reasonText,
      );
      await TwoWayService.setAgentStatus(clientId, userId, 'close');
      setAgentStatus('active');
      setIsMyConversationsClicked(true); // refresh conversations list
      setCustomerInfo({}); // hide customer info
    } catch (error) {
      const errMsg = `There was an issue closing the conversation: ${error.response.data}`;
      showMessage(errMsg, 'error');
    }
  };

  return (
    <Block
      overrides={{
        Block: {
          style: () => ({
            marginTop: '20px',
            marginLeft: '20px',
            marginRight: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
            height: '62px',
          }),
        },
      }}
    >
      <div data-testid="customer-information">
        {customerCcid ? (
          <>
            <p
              className={css({
                fontWeight: '700',
                lineHeight: '14px',
                marginBottom: '0',
              })}
              data-testid="customer-name"
            >
              {firstName} {lastName}
            </p>
            <p
              className={css({ marginBottom: '0' })}
              data-testid="customer-ccid"
            >
              Account ID (CCID): {customerCcid}
            </p>
            <p
              className={css({ marginBottom: '0' })}
              data-testid="customer-product-group-id"
            >
              Product Group: {productGroupId}
            </p>
          </>
        ) : (
          <p
            className={css({
              fontWeight: '700',
              lineHeight: '14px',
              marginBottom: '0',
            })}
          >
            Loading...
          </p>
        )}
      </div>
      <div>
        {isMyConversationsClicked && (
          <CustomerActionsPopover
            isActionsPopoverOpen={isActionsPopoverOpen}
            setIsActionsPopoverOpen={setIsActionsPopoverOpen}
            setIsPingModalOpen={setIsPingModalOpen}
            setIsCloseChannelModalOpen={fetchClosedReasons}
            navigateToExperienceLauncher={navigateToExperienceLauncher}
          />
        )}
      </div>
      {isPingModalOpen && (
        <Modal
          closeFn={() => setIsPingModalOpen(false)}
          isOpen={isPingModalOpen}
          autoFocus={false}
          content={
            <ActionsModal
              closeModal={() => setIsPingModalOpen(false)}
              successModal={pingCustomer}
              modalSubTitle="Are you sure you want to send a ping to this customer?"
              cancelBtnText="No, go back"
              successBtnText="Yes"
              dataTestId="confirm-ping-customer-btn"
            />
          }
          modalTitle="Ping Customer?"
          closeable={false}
        />
      )}
      {isCloseChannelModalOpen && (
        <Modal
          closeFn={() => setIsCloseChannelModalOpen(false)}
          isOpen={isCloseChannelModalOpen}
          autoFocus={false}
          content={
            <ActionsModal
              closeModal={() => setIsCloseChannelModalOpen(false)}
              successModal={closeConversation}
              modalSubTitle="Are you sure you want to close this conversation? The conversation would be permanently closed and would no longer be available, unless re-opened by the customer."
              cancelBtnText="Cancel"
              successBtnText="Yes"
              dataTestId="confirm-close-conversation-btn"
              reasons={closedReasons}
            />
          }
          modalTitle="Close Conversation?"
          closeable={false}
        />
      )}
    </Block>
  );
};
