<div class="rn-page">
  <div class="help-text-container">
    <h3>Congratulations!</h3>
    <i>Here are the details on what you created:</i>
  </div>
  <rn-setup-banner-autoresponse
  [clientId]="clientId"
  ></rn-setup-banner-autoresponse>
  <div class="rn-page__section">
    <div class="rn-page--busy" *ngIf="isLoading(); else main_content">
      <app-spinner
        [styleRules]="{ transform: 'translateY(250px)' }"
      ></app-spinner>
    </div>
    <ng-template #main_content>
      <ng-container *ngIf="twoWayConfig">
        <h2 *ngIf="isFeedEnabled">Relay Messenger Settings Summary</h2>
        <h2 *ngIf="!isFeedEnabled">Two Way Settings Summary</h2>
        <!-- Old Auto Responses -->
        <div
          class="two-way-summary-container"
          *ngIf="!isRMAutoResponseEnabled || !isFeedEnabled"
        >
          <h3>Auto Responses</h3>
          <div class="inline-col">
            <h4>Default</h4>
            <generic-phone>
              <app-spinner
                *ngIf="!defaultProductGroup"
                [styleRules]="{ transform: 'translateY(100px)' }"
              ></app-spinner>
              <wire-header
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
              ></wire-header>
              <two-way-preview
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
                [messageText]="twoWayConfig.default_auto_response"
              ></two-way-preview>
            </generic-phone>
          </div>

          <div class="inline-col">
            <h4>After Hours</h4>
            <generic-phone>
              <app-spinner
                *ngIf="!defaultProductGroup"
                [styleRules]="{ transform: 'translateY(100px)' }"
              ></app-spinner>
              <wire-header
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
              ></wire-header>
              <two-way-preview
                *ngIf="defaultProductGroup"
                [productGroup]="defaultProductGroup"
                [messageText]="twoWayConfig.after_hours_auto_response"
              ></two-way-preview>
            </generic-phone>
          </div>
        </div>

        <!-- New Auto Responses -->
        <div *ngIf="isRMAutoResponseEnabled && isErrorAutoResponse">
          <p class="auto-responses-error-msg">
            Oops, there was an error loading Auto Responses preview. Please try
            refreshing
          </p>
        </div>
        <div
          class="two-way-summary-container"
          *ngIf="
            isRMAutoResponseEnabled &&
            isFeedEnabled &&
            !isErrorAutoResponse &&
            autoResponseMessages
          "
        >
          <h3>Auto Responses</h3>
          <ng-container
            *ngFor="let autoResponseMessage of autoResponseMessages"
          >
            <div class="inline-col">
              <h4>{{ autoResponseMessage.title }}</h4>
              <div class="phone-container">
                <phone
                  [screen]="'two-way'"
                  [autoResponseMessage]="autoResponseMessage.message"
                  [productGroup]="defaultProductGroup"
                  [autoResponseMessageId]="autoResponseMessage.id"
                ></phone>
              </div>
            </div>
          </ng-container>
        </div>

        <h3>{{ newFeed.enabled ? 'Notification' : 'SMS' }} Ping</h3>
        <h4>{{ newFeed.enabled ? 'Notification' : 'Message' }}</h4>
        <generic-phone>
          <bubble
            [text]="twoWayConfig.sms.body + '\n' + twoWayConfig.sms.disclaimer"
          ></bubble>
        </generic-phone>
      </ng-container>
    </ng-template>
  </div>
</div>
