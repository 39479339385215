<div class="rn-page">
  <div class="help-text-container">
    <p *ngIf="!currentClient.feed_enabled">
      Since Two Way Messaging is an asynchronous conversation, your agent may
      need to ping the customer they are conversing with to let the customer
      know that there is a message waiting for them. This
      {{ newFeed.enabled ? 'Notification' : 'SMS' }} Ping will include a link
      directly back into the conversation.
    </p>
    <p *ngIf="currentClient.feed_enabled">
      Since Relay Messenger is an asynchronous conversation, your agent may
      need to ping the customer they are conversing with to let the customer
      know that there is a message waiting for them. This notification ping 
      will include a link directly back into the conversation.
    </p>
  </div>
  <rn-setup-banner-autoresponse
  [clientId]="clientId"
  ></rn-setup-banner-autoresponse>

  <div class="rn-page__section">
    <div class="rn-page--busy" *ngIf="isLoading(); else main_content">
      <app-spinner
        [styleRules]="{ transform: 'translateY(250px)' }"
      ></app-spinner>
    </div>

    <ng-template #main_content>
      <form *ngIf="smsPingForm" class="form-group" [formGroup]="smsPingForm">
        <h3>{{ newFeed.enabled ? 'Notification' : 'SMS' }} Ping</h3>

        <div class="input-group input-group-with-preview clearfix">
          <generic-phone>
            <bubble [text]="fullSmsText"></bubble>
          </generic-phone>

          <div class="input-group-with-preview__fields fields-group">
            <rn-sms-builder
              *ngIf="twoWayConfig.sms"
              [isTwoWaySms]="true"
              [smsModel]="twoWayConfig.sms"
              [smsFormGroup]="smsPingForm"
            ></rn-sms-builder>

            <p>{{ blackoutWindowMessage }}</p>
          </div>
        </div>
      </form>
    </ng-template>
  </div>

  <div class="button-container">
    <button
      class="btn btn-primary"
      id="next-btn"
      (click)="saveAndContinue()"
      [disabled]="!isLoading() && !this.smsPingForm.valid"
    >
      <span
        i18n="Label for Next button on new client configuration"
        id="save-and-next-button"
      >
        Save and Next
      </span>
      <span class="icon icon-right">
        <i class="fa fa-arrow-right"></i>
      </span>
    </button>
  </div>
</div>

<message-dialog #smsPingMessage [showCancel]="false"></message-dialog>

<message-dialog
  #dynamicInputsMessage
  [showCancel]="true"
  (affirm)="saveAndContinue(true)"
>
  <p>
    You are about to save a
    {{ newFeed.enabled ? 'notification' : 'message' }} that has dynamic inputs
    without setting up
    {{
      newFeed.enabled ? 'alternate notification text' : 'an alternate message'
    }}. We recommend setting up
    {{
      newFeed.enabled ? 'alternate notification text' : 'an alternate message'
    }}
    in case this information is not provided for a user's account. If you
    attempt to launch the experience as is and the information requested is not
    available for a customer, this
    {{ newFeed.enabled ? 'notification' : 'message' }} will not be sent to that
    particular user.
  </p>
</message-dialog>
