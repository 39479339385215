import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { TwoWaySetupService } from '@app/two-way/services/two-way-setup.service';
import { Client } from '@app/core/models/client';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'rn-setup-banner-autoresponse',
  templateUrl: './setup-banner-autoresponse.component.html',
  styleUrl: './setup-banner-autoresponse.component.scss',
  standalone: false,
})
export class SetupBannerAutoresponseComponent implements OnDestroy, OnInit {
  @Input() clientId: string;
  currentHoursExpires: string | null = null;
  isRMAutoResponseEnabled: boolean;
  currentHoursType: string | null = null;
  isErrorClientStatus: boolean = false;
  isErrorBusinessHours: boolean = false;
  isOutOfOfficeEnabled: boolean = false;
  currentClient: Client;
  feedEnabled: boolean = false;
  constructor(
    private sessionService: SessionService,
    public featureService: FeatureService,
    private twoWaySetupService: TwoWaySetupService,
    private cd: ChangeDetectorRef,
  ) {}
  ngOnInit(): void {
    this.isRMAutoResponseEnabled = this.featureService.checkFlag(
      FeatureFlags.rm_auto_response,
    );
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.feedEnabled = this.currentClient.feed_enabled;
    this.twoWaySetupService.getClientStatus(this.clientId).subscribe(
      (data) => {
        const { type, expires } = data;
        this.currentHoursType = type;
        if (expires) {
          const expiryTime = new Date(expires)
            .toLocaleString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: 'numeric',
              minute: 'numeric',
              hour: 'numeric',
              timeZoneName: 'short',
            })
            .replace(',', ' at ');
          this.currentHoursExpires = expiryTime;
        } else {
          this.currentHoursExpires = null;
        }

        this.cd.detectChanges();
      },
      () => {
        this.isErrorClientStatus = true;
        this.cd.detectChanges();
      },
    );
    this.twoWaySetupService.getBusinessHours(this.clientId).subscribe(
      (data) => {
        const { outOfOffice } = data;
        this.isOutOfOfficeEnabled = outOfOffice.enabled;
      },
      () => {
        this.isErrorBusinessHours = true;
        this.cd.detectChanges();
      },
    );
  }
  ngOnDestroy(): void {
    this.isErrorClientStatus = false;
    this.isErrorBusinessHours = false;
    this.currentHoursExpires = null;
    this.currentHoursType = null;
    this.isOutOfOfficeEnabled = false;
    this.cd.detectChanges();
  }
}
