import { Component, EventEmitter, Output, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploadService, Filetype } from '@app/file-launcher/services/file-upload.service'
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { LoggerService } from '@app/core/services/logger.service';
import { FeatureService } from '@app/core/services/feature.service';


import * as _ from 'lodash';
import { FeatureFlags } from '@app/core/models/feature-flags';

export type Status = 'waitingForFile' | 'uploading' | 'waitingForAction' | 'scheduling' | 'completedRun' | 'completedScheduling';

@Component({
    selector: 'rn-file-launcher',
    templateUrl: './file-launcher.component.html',
    styleUrls: ['./file-launcher.component.scss'],
    standalone: false
})
export class FileLauncherComponent implements OnInit {
  @ViewChild('dialog', { static: true }) dialog: MessageDialogComponent;

  @Input() triggerId: string;
  @Output() statusChanged: EventEmitter<string> = new EventEmitter();

  selectedFiletype: Filetype;

  // model for filetype dropdown
  
  // current step
  status: Status = 'waitingForFile'; // used to determine which UI elements should be visible at any given time.
  
  // For rn-upload-progress
  uploadStatus: object;
  
  // for Validation Errors
  erroredFiletype: string;
  headerErrors: object;
  
  // for Successful Upload/Validation
  jobId: string = '';
  fileName: string;
  count: number;

  // for Scheduling
  scheduleTime: Date;
  jobsListOrMonitorBtnTxt: string; // delete when the file_engine_jobs_list flag and monitor tab go away

  constructor(
    private router: Router,
    private fileUploadService: FileUploadService,
    public featureService: FeatureService) { }

  ngOnInit() {
    this.selectedFiletype = _.isEmpty(this.triggerId) ? 'messaging' : 'journey_messaging';
    this.jobsListOrMonitorBtnTxt = this.featureService.checkFlag(FeatureFlags.file_engine_jobs_list) ? 'Jobs List' : 'Monitor';
  }

  runFile() {
    this.fileUploadService.run(this.jobId).subscribe(
      () => this.setStatus('completedRun'),
      (error) => {
        const errorMsg = _.get(error, 'error.response') || 'There was an error running your file.';
        this.dialog.showMessage(errorMsg);
        LoggerService.log('FileLauncherComponent: scheduleFile', error);
      });
  }

  scheduleFile(scheduleTime) {
    this.fileUploadService.schedule(this.jobId, scheduleTime).subscribe(
      () => {
        this.setStatus('completedScheduling');
        this.scheduleTime = scheduleTime;
      },
      (error) => {
        const errorMsg = _.get(error, 'error.response') || 'There was an error scheduling your file.';
        this.dialog.showMessage(errorMsg);
        LoggerService.log('FileLauncherComponent: scheduleFile', error);
      });
  }

  openScheduleDialog() {
    this.scheduleTime = new Date(Date.now());
    this.setStatus('scheduling');
  }

  closeScheduleDialog() {
    this.scheduleTime = null;
    this.setStatus('waitingForAction');
  }

  startUpload(file) {
    this.setStatus('uploading');
    this.fileName = file.name;

    this.fileUploadService
        .initializeAndValidate(file, this.selectedFiletype, this.triggerId)
        .subscribe((result) => {
          if (result['header_errors']) {
            // if validation returns errors
            this.headerErrors = result['header_errors'];
            this.erroredFiletype = this.selectedFiletype;
            this.resetFileData(); // validation or upload errors send you back to the beginning.  hence the reset.
            this.setStatus('waitingForFile');
          } else { 
            // if validation is successful
            this.jobId = result['job_id'];
            this.count = result['count'];
            this.setStatus('waitingForAction');
          }
        }, (error) => {
          const errorMsg = _.get(error, 'error.response') || 'There was an error uploading your file.';
          this.dialog.showMessage(errorMsg);
          this.setStatus('waitingForFile');
          this.resetFileData();
          LoggerService.log('FileLauncherComponent: startUpload', error);
        });

        this.fileUploadService.progress.subscribe((eventData) => {
          this.uploadStatus = eventData;
        });
  }

  validationMessage(fileName: string, count: number): string {
    // `count` here is currently guaranteed to never be more than 1000 (see 
    // DV-1568), even if there are more than 1000 records in the file; so even
    // though the count being `>= 1000` is logically not the same as '1000+'
    // (it's off by one), we avoid communicating that we do not have insight
    // into the total number of records in the file at this point in time while
    // still maintaining some sort of accuracy
    return `Your experience from file \"${fileName}\" is ready to process. There are ${count >= 1000 ? '1000+' : count} records in the file.`
  }

  reset() {
    this.setStatus('waitingForFile');
    this.headerErrors = [];
    this.erroredFiletype = null;
    this.scheduleTime = null;
    this.resetFileData();
    this.statusChanged.emit('upload');
  }

  resetFileData(): void {
    this.fileName = null;
    this.jobId = null;
    this.count = null;
  }

  goToMonitorOrJobsList() {
    if (this.featureService.checkFlag(FeatureFlags.file_engine_jobs_list)) {
      this.router.navigateByUrl('/file-engine-jobs');
    } else {
      this.router.navigateByUrl('/monitor');
    }
  }

  isCompleted(): boolean {
    return this.status === 'completedRun' || this.status === 'completedScheduling';
  }

  setStatus(status: Status) {
    this.statusChanged.emit(status);
    this.status = status;
  }

  headersIncludeSpaces(headers): boolean {
    return headers.filter((header) => header.includes(' ')).length > 0;
  }
}
