import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss'],
    standalone: false
})
export class StartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
