import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormConfigService } from '@app/client-config/client-form/client-power-ups-config/services/form-config-service';
import {
  PowerUpsLiveFormIdAndTitle,
} from '@app/core/models/power-ups-live-form';
import { SessionService } from '@app/security/session.service';

@Component({
    selector: 'rn-power-ups-preview-list',
    templateUrl: './power-ups-preview-list.component.html',
    styleUrls: ['./power-ups-preview-list.component.scss'],
    standalone: false
})
export class PowerUpsPreviewListComponent implements OnInit {
  @Input() selectedPowerUpId: string;
  @Input() clientId: string;
  @Output() newPowerUpSelected: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() closeMsgDialog: EventEmitter<void> = new EventEmitter();
  selectedPowerUp: PowerUpsLiveFormIdAndTitle;
  searchTerm: string = '';
  powerUps: PowerUpsLiveFormIdAndTitle[];

  constructor(
    private sessionService: SessionService,
    private formConfigService: FormConfigService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    const result = (await this.formConfigService.getFormConfigSet(this.clientId))
      .map((powerUp) => ({
          id: powerUp.external_id,
          title: powerUp.form_name,
        }));
    this.powerUps = result as PowerUpsLiveFormIdAndTitle[];
    // if there is a selectedPowerUpId already, use it
    if (this.selectedPowerUpId && this.selectedPowerUpId.length) {
      this.selectPowerUp(this.selectedPowerUpId);
    } else if (this.powerUps.length > 0 && !this.selectedPowerUpId) {
      // otherwise use the first item that's come back from the results
      this.selectPowerUp(this.powerUps[0].id);
    }
  }

  selectPowerUp(powerUpId: string) {
    if (!this.powerUps) {
      return;
    }
    this.selectedPowerUpId = powerUpId;
    this.selectedPowerUp = this.powerUps.find((powerUp) => {
      return powerUp.id === powerUpId;
    });
  }

  filteredPowerUps() {
    if (this.searchTerm.length < 2) {
      return this.powerUps;
    }

    return this.powerUps.filter((powerUp) => {
      return powerUp.title
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase());
    });
  }

  saveAndClose() {
    this.newPowerUpSelected.emit(this.selectedPowerUpId);
    this.searchTerm = '';
  }

  cancelAndClose() {
    this.closeMsgDialog.emit();
  }

  clearSearchBar() {
    this.searchTerm = '';
  }
}
