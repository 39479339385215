<div *ngIf="showEditDialog" class="modal" style="display: block; z-index: 999;">
  <app-journey-metadata-dialog [(journeyContentPropertiesInput)]="journey.latestContent" [btnConfig]="{cancel:'Cancel',save:'save'}" (onSave)="saveJourneyMetadata($event)" (onCancel)="closeEditDialog()"></app-journey-metadata-dialog>
</div>
<div class="d-flex flex-row-reverse h-100">
  <app-toolbar (validateJourney)="prepForSave()" [journeyId]="journeyId" [journey]="journey"></app-toolbar>
  <div class="builder-area no-text-selection" id="builder-area" (click)="deselectComponent()">
    <ul>
      <ng-container *ngFor="let step of journey?.latestContent?.components;let i = index">
        <li [id]="step.name" class="no-text-selection">
          <div class="trigger" *ngIf="step.type === 'APITrigger'">
            <div class="control">
              <journey-trigger #journeyTrigger [trigger]="step" (deleteCmp)="onDeleteMsg($event)" (editCmp)="onEditTriggerRequest($event)" [selected]="newTriggerComponent === step ? true : false" (click)="selectComponent($event, journeyTrigger)" (dblclick)="onStepDoubleClick($event)"></journey-trigger>
              <journey-arrow [arrowType]="'down-right'"></journey-arrow>
            </div>
          </div>
          <div class="messages" *ngIf="step.type === 'MessageSender'">
            <journey-message #journeyMessage [(isValid)]="isValid" [isNewStep]="isNewStep" [msg]="step" id="top-messages-{{step.name}}" [class]="step.type" [journeyId]="journeyId" [(journey)]="journey" (click)="selectComponent($event, journeyMessage)" (dblclick)="onStepDoubleClick($event)" (responseMessageSelected)="selectComponent(null, $event)" (deleteMsg)="onDeleteMsg($event)"></journey-message>
          </div>
          <div class="delay" *ngIf="step.type === 'WorkflowDelayer'">
            <div class="control">
              <journey-delay #journeyDelay [delay]="step" (click)="selectComponent($event, journeyDelay)" (dblclick)="onStepDoubleClick($event)" (deleteCmp)="onDeleteMsg($event)"></journey-delay>
            </div>
          </div>
          <div class="stop" *ngIf="step.type === 'WorkflowEnder'">
            <div class="control">
              <journey-stop (onNewStep)="onNewStep($event)" [msgEnder]="step"></journey-stop>
            </div>
          </div>
        </li>
        <li *ngIf="(step.type === 'WorkflowDelayer')">
          <journey-arrow [arrowType]="'straight-right'"></journey-arrow>
        </li>
        <li *ngIf="(step.type === 'MessageSender')">
          <journey-arrow [arrowType]="'straight-right-plus'" (onNewStep)="onNewStep($event)" [nextMessage]="getNextComponent(step)"></journey-arrow>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<message-dialog #messageDialogWithReload (affirm)="reload()">
</message-dialog>

<message-dialog #messageDialogNoReload>
</message-dialog>

<message-dialog #messageDialogDeleteMessage [showCancel]="true" (affirm)="deleteComponent($event)">
</message-dialog>

<message-dialog #iwcuWarning [showCancel]="true" (affirm)="cleanIWCU()">
</message-dialog>


