import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Client, ProductGroup } from '@app/core/models/client';
import { CharacterLimits } from '@app/core/utils/characterLimits';
import { RelaySharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'rn-sign-up-form',
  imports: [CommonModule, ReactiveFormsModule, RelaySharedModule],
  templateUrl: './sign-up-form.component.html',
  styleUrl: './sign-up-form.component.scss',
})
export class SignUpFormComponent {
  @Input() client: Client;

  @Input() productGroup: ProductGroup;
  @Input() express_written_consent_ts_cs: string;
  @Input({ required: true }) signUpGroup: UntypedFormGroup;

  getCharacterLimit(form: string) {
    switch (form) {
      case 'signUpSuccessCopy':
        return CharacterLimits.SignUpPageSuccessCopy;
      case 'signUpFailureCopy':
        return CharacterLimits.SignUpPageFailureCopy;
      case 'signUpPageSubmitButtonLabel':
        return CharacterLimits.SignUpPageSubmitButtonLabel;
      case 'signUpPageTermsAndConditions':
        return CharacterLimits.SignUpPageTermsAndConditions;
      case 'signUpPageTermsAndConditionsValidationError':
        return CharacterLimits.SignUpPageTermsAndConditionsValidationError;
    }
  }
}
