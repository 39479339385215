import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { ListOfReasons } from '../list-of-reasons/ListOfReasons';
import { RelayToggle } from '../relay-button/RelayToggle';
import { ClosedReasonsService } from '../closed-reasons-axios-service/closed-reasons-axios-service';
import { ActionsModal } from '@app/two-way/relay-messenger/components/actions-modal/ActionsModal';
import { Modal } from '@app/two-way/relay-messenger/components/modal/Modal';
import { ModalButton } from 'baseui/modal';
import { Button, KIND as ButtonKind, SIZE as ButtonSize } from 'baseui/button';
import { Plus } from 'baseui/icon';
import { environment } from '@env/environment';
import axios from 'axios';
import { useAlertMessage } from '@app/two-way/relay-messenger/hooks/useAlertMessage/use-alert-message';
import { TwoWayService } from '@app/two-way/relay-messenger/components/relay-messenger-axios-service/relay-messenger-axios-service';

interface SetupConversationsHomeProps {
  clientId: string;
  twoWayConfig: TwoWayConfig;
  isRMAutoResponseEnabled?: boolean;
  isFeedEnabled?: boolean;
}

export type ClientStatus = {
  type: 'BUSINESS_HOURS' | 'OUT_OF_OFFICE' | 'AFTER_HOURS';
  expires: string;
};

export const SetupConversationsHome = ({
  clientId,
  twoWayConfig,
  isRMAutoResponseEnabled,
  isFeedEnabled,
}: SetupConversationsHomeProps) => {
  const [css] = useStyletron();
  const { close_reasons_enabled: closeReasonsEnabled } = twoWayConfig;
  const [closedReasons, setClosedReasons] = useState([]);
  const [isClosedConvModalOpen, setIsClosedConvModalOpen] = useState(false);
  const [isReasonTextEmpty, setIsReasonTextEmpty] = useState(false);
  const [isClosedReasonsEnabled, setIsClosedReasonsEnabled] = useState<number>(
    closeReasonsEnabled ? 1 : 0,
  );
  const showMessage = useAlertMessage();

  const [clientStatus, setClientStatus] = useState<ClientStatus | null>(null);
  const [isOutOfOfficeEnabled, setIsOutOfOfficeEnabled] = useState(false);

  const getClientStatus = useCallback(async () => {
    const status = await TwoWayService.getClientStatus(clientId);
    if (status) {
      const { type, expires } = status as ClientStatus;
      const expiryTime = new Date(expires)
        .toLocaleString(undefined, {
          year: 'numeric',
          month: '2-digit',
          day: 'numeric',
          minute: 'numeric',
          hour: 'numeric',
          timeZoneName: 'short',
        })
        .replace(',', ' at ');
      const formattedStatus = {
        type,
        expires: expiryTime,
      } as ClientStatus;
      setClientStatus(formattedStatus);
    }
  }, [clientId]);

  const getBusinessHoursData = useCallback(async () => {
    const businessHours = await TwoWayService.getBusinessHours(clientId);
    const { outOfOffice } = businessHours;
    setIsOutOfOfficeEnabled(outOfOffice.enabled);
  }, [clientId]);

  const fetchClosedReasons = useCallback(async () => {
    setIsClosedConvModalOpen(false);
    try {
      const closedReasonsRes = await ClosedReasonsService.getClosedReasons(
        clientId,
      );
      const sortedConversations = closedReasonsRes.sort(
        (a, b) => a.ordinal - b.ordinal,
      );
      setClosedReasons(sortedConversations);
    } catch (error) {
      console.error('Failed to fetch close reasons:', error);
    }
  }, [clientId, setIsClosedConvModalOpen, setClosedReasons]);

  useEffect(() => {
    getClientStatus();
    getBusinessHoursData();
    if (isClosedReasonsEnabled === 1) {
      fetchClosedReasons();
    }
  }, []);

  const createNewReason = () => {
    const newReason = {
      clientId,
      reason: '',
      allowAdditionalDetails: false,
      enabled: true,
      archived: false,
    };
    setClosedReasons([...closedReasons, newReason]);
  };

  useEffect(() => {
    // Check if any closed reason has an empty reason text
    const hasEmptyReasonText = closedReasons.some(
      (reason) => reason.reason.trim() === '',
    );
    setIsReasonTextEmpty(hasEmptyReasonText);
  }, [closedReasons]);

  const saveMethod = async (closedReasonsArray) => {
    closedReasonsArray = closedReasonsArray.map((reason, ordinal) =>
      Object.assign(reason, { ordinal }),
    );
    const closedReasonsHaveEmptyText = closedReasonsArray.some(
      (reasonObj) => !reasonObj.reason,
    );
    if (
      closedReasonsArray.length ||
      isClosedReasonsEnabled === 1 ||
      isClosedReasonsEnabled === 0
    ) {
      await axios.put(
        `${environment.configURLBase}/client/${clientId}/config/two_way_config`,
        {
          data: {
            ...twoWayConfig,
            close_reasons_enabled: isClosedReasonsEnabled === 1,
          },
        },
      );
    }
    if (closedReasonsHaveEmptyText) {
      setIsReasonTextEmpty(true);
      return;
    }
    const operations = closedReasonsArray.map(async (reasonObj) => {
      const { enabled, allowAdditionalDetails, reason, archived, id, ordinal } =
        reasonObj;
      if (!id) {
        return ClosedReasonsService.createClosedReasons(
          clientId,
          enabled,
          allowAdditionalDetails,
          reason,
          archived,
          ordinal,
        );
      } else if (id) {
        return ClosedReasonsService.updateClosedReasons(
          clientId,
          id,
          enabled,
          allowAdditionalDetails,
          reason,
          archived,
          ordinal,
        );
      }
    });
    const responses = await Promise.all(operations);
    fetchClosedReasons();
    showMessage('Changes have been saved successfully!', 'success');
    return responses;
  };

  const disableSaveButton = () => {
    const hasErrorState = closedReasons.some(
      (reasonObj) => reasonObj.isInErrorState,
    );
    if (isReasonTextEmpty || hasErrorState) {
      return true;
    }
    return false;
  };
  return (
    <div data-testid="setup-conversations-home">
      <div
        className={css({
          backgroundColor: '#f3f4f4',
          paddingTop: '24px',
          paddingBottom: '24px',
          paddingLeft: '48px',
          paddingRight: '48px',
        })}
      >
        <p
          className={css({
            marginBottom: '0px',
            color: '#212529',
          })}
        >
          Relay Messenger can support a high volume of conversations with
          numerous agents responding and resolving issues. To help better
          understand resolutions, messenger admins can configure a list of
          reasons why a conversation was closed.
        </p>
      </div>
      <div>
        {clientStatus &&
          clientStatus.type === 'OUT_OF_OFFICE' &&
          isFeedEnabled &&
          isOutOfOfficeEnabled &&
          isRMAutoResponseEnabled && (
            <div
              className={css({
                width: '100%',
                padding: '12px 24px',
                margin: '0px',
                backgroundColor: ' #ffc0762e',
                border: '1px solid #fcc076',
              })}
            >
              <p
                className={css({
                  textAlign: 'center',
                  marginBottom: '0px',
                })}
              >
                The Out of Office auto response is enabled until{' '}
                {clientStatus.expires}. Your business hours and other auto
                responses will resume then.
              </p>
            </div>
          )}
      </div>
      <div
        className={css({
          marginLeft: '48px',
          marginRight: '48px',
          color: '#5B5A59',
        })}
      >
        <h3
          className={css({
            fontWeight: '700',
          })}
        >
          Close Conversation Configuration
        </h3>
        <p>User required to provide reason for closing conversations</p>
        <RelayToggle
          selected={isClosedReasonsEnabled}
          cb={(_event, index) => {
            setIsClosedReasonsEnabled(index);
            setIsClosedConvModalOpen(true);
          }}
        />
        {isClosedReasonsEnabled === 1 && (
          <Modal
            closeFn={() => setIsClosedConvModalOpen(false)}
            isOpen={isClosedConvModalOpen}
            autoFocus={false}
            content={
              <ActionsModal
                closeModal={() => {
                  setIsClosedConvModalOpen(false);
                  setIsClosedReasonsEnabled(0);
                }}
                successModal={fetchClosedReasons}
                modalSubTitle={`Are you sure you want to allow the agents to choose a reason for closing a conversation? This will make it mandatory for the agents to choose from a list of pre-defined reasons.\nAs a next step, you will get to edit the list of reasons that the agents will choose from.`}
                cancelBtnText="Cancel"
                successBtnText="Yes"
                dataTestId="confirm-close-reasons-conversation-btn"
              />
            }
            modalTitle="Allow agents to choose a reason?"
            closeable={false}
          />
        )}
        <div
          className={css({
            paddingTop: '96px',
            paddingBottom: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            })}
          >
            <div
              className={css({
                fontSize: '14px',
                color: '#37474F80',
                fontWeight: '400',
                lineHeight: '15.6px',
              })}
            >
              NOTE: Reasons will appear to the agents in the same sequence as it
              appears below. Drag and move a reason to modify the sequence.
            </div>
            <ModalButton
              size={ButtonSize.mini}
              kind={ButtonKind.secondary}
              disabled={isClosedReasonsEnabled === 0}
              endEnhancer={
                <div className={css({ display: 'flex', alignItems: 'center' })}>
                  <div
                    className={css({
                      marginRight: '8px',
                      height: '35px',
                      width: '1px',
                      backgroundColor: '#427a9d',
                    })}
                  ></div>
                  <Plus size={24} />
                </div>
              }
              autoFocus={false}
              overrides={{
                BaseButton: {
                  style: {
                    height: '33px',
                    color: 'white',
                    backgroundColor: '#3693bf',
                    outline: '#427a9d 1px solid',
                    fontWeight: '400',
                    textTransform: 'uppercase',
                    ':hover': {
                      backgroundColor: '#f6f6f6',
                      color: '#3693bf',
                    },
                  },
                  props: {
                    'data-testid': 'add-reason-btn',
                  },
                },
              }}
              onClick={createNewReason}
            >
              ADD REASON
            </ModalButton>
          </div>
          <ListOfReasons
            closedReasons={closedReasons}
            setClosedReasons={setClosedReasons}
            isClosedReasonsEnabled={isClosedReasonsEnabled}
          />
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '48px',
        })}
      >
        <ModalButton
          disabled={disableSaveButton()}
          size={ButtonSize.mini}
          kind={ButtonKind.secondary}
          endEnhancer={
            <div className={css({ display: 'flex', alignItems: 'center' })}>
              <div
                className={css({
                  marginRight: '8px',
                  height: '35px',
                  width: '1px',
                  backgroundColor: '#427a9d',
                })}
              ></div>
              <i className="fa fa-floppy-o"></i>
            </div>
          }
          autoFocus={false}
          overrides={{
            BaseButton: {
              style: {
                height: '33px',
                color: 'white',
                backgroundColor: '#3693bf',
                outline: `#427a9d 1px solid`,
                fontWeight: '400',
                textTransform: 'uppercase',
                ':hover': {
                  backgroundColor: '#f6f6f6',
                  color: '#3693bf',
                },
              },
              props: {
                'data-testid': 'save-close-reasons-btn',
              },
            },
          }}
          onClick={() => {
            saveMethod(closedReasons);
          }}
        >
          Save
        </ModalButton>
      </div>
    </div>
  );
};
