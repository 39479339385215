import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AutoResponses,
  TwoWaySetupService,
} from '@app/two-way/services/two-way-setup.service';
import { TitleService } from '@app/core/services/title.service';
import { ProductGroup } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { User } from '@app/core/models/user';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';

export type AutoResponseMessages = {
  id: string;
  message: string;
  title: string;
};
@Component({
  selector: 'app-setup-summary',
  templateUrl: './setup-summary.component.html',
  styleUrls: ['./setup-summary.component.scss'],
  standalone: false,
})
export class SetupSummaryComponent implements OnInit, OnDestroy {
  twoWayConfig: TwoWayConfig;
  twoWayUsers: User[];
  defaultProductGroup: ProductGroup;
  currentClient: Client;
  isRMAutoResponseEnabled: boolean;
  isFeedEnabled: boolean;
  autoResponseMessages: AutoResponseMessages[];
  isErrorAutoResponse: boolean;
  clientId: string;

  constructor(
    private titleService: TitleService,
    private twoWaySetupService: TwoWaySetupService,
    public newFeed: NewFeedTransitionService,
    private sessionService: SessionService,
    public featureService: FeatureService,
  ) {}

  ngOnInit() {
    this.isRMAutoResponseEnabled = this.featureService.checkFlag(
      FeatureFlags.rm_auto_response,
    );
    this.isFeedEnabled = this.newFeed.enabled;
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.clientId = this.currentClient.id;
    this.titleService.activate(
      this.isFeedEnabled ? 'Relay Messenger Setup' : 'Two Way Messaging Setup',
      'Summary',
    );
    this.twoWaySetupService.getTwoWayConfig().subscribe((twoWayConfig) => {
      this.twoWayConfig = twoWayConfig;
    });

    this.twoWaySetupService
      .getDefaultProductGroup()
      .subscribe((defaultProductGroup) => {
        this.defaultProductGroup = defaultProductGroup;
      });

    if (this.isRMAutoResponseEnabled && this.isFeedEnabled) {
      this.twoWaySetupService
        .getAutoResponses(this.currentClient.id)
        .subscribe({
          next: (autoResponses) => {
            this.autoResponseMessages =
              this.convertAutoResponses(autoResponses);
          },
          error: () => {
            this.isErrorAutoResponse = true;
          },
        });
    }
  }

  convertAutoResponses(autoResponses: AutoResponses): AutoResponseMessages[] {
    const autoResponseTitles: { [key: string]: string } = {
      businessHours: 'Welcome',
      afterHours: 'After Hours',
      outOfOffice: 'Out of Office',
    };
    return Object.keys(autoResponses).map((key) => ({
      id: key,
      message: autoResponses[key as keyof AutoResponses].message,
      title: autoResponseTitles[key],
    }));
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  isLoading() {
    return this.twoWayConfig === undefined;
  }
}
