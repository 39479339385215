import { Injectable } from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { Observable, of } from 'rxjs';
import { ClientService } from '@app/core/services/client.service';
import { Client, ProductGroup } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

export interface AutoResponses {
  businessHours: {
    message: string;
  };
  afterHours: {
    message: string;
  };
  outOfOffice: {
    message: string;
  };
}

export interface AutoResponsesPostBody {
  autoResponses: {
    businessHours: {
      message: string;
    };
    afterHours: {
      message: string;
    };
    outOfOffice: {
      message: string;
    };
  };
}

export type Day = {
  enabled: boolean;
  startTime: {
    hours: number;
    minutes: number;
  };
  endTime: {
    hours: number;
    minutes: number;
  };
};

export type Timezone = 'EST5EDT' | 'CST6CDT' | 'MST7MDT' | 'PST8PDT';

export interface BusinessHoursResponse {
  businessHours: {
    timezone: Timezone;
    monday: Day;
    tuesday: Day;
    wednesday: Day;
    thursday: Day;
    friday: Day;
    saturday: Day;
    sunday: Day;
    outOfOffice: {
      enabled: boolean;
      expirationDate: Date;
      expirationTime: {
        hours: number;
        minutes: number;
      };
    };
  };
}
export type ClientStatus = {
  type: 'BUSINESS_HOURS' | 'OUT_OF_OFFICE' | 'AFTER_HOURS';
  expires: string;
}
export interface GetClientStatusResponse {
  autoresponse: ClientStatus;
}

@Injectable()
export class TwoWaySetupService {
  private clientStore$: Observable<Client>;
  private defaultProductGroup$: Observable<ProductGroup>;
  private readonly twoWayUrl = `${environment.twoWayURLBase}`;

  constructor(
    private clientService: ClientService,
    private sessionService: SessionService,
    private http: HttpClient,
  ) {
    this.sessionService.selectedClient.subscribe(() => {
      this.resetData();
    });
  }

  resetData() {
    this.clientStore$ = this.refreshClient();
    this.defaultProductGroup$ = this.setDefaultProductGroup();
  }

  /**
   * used for auto-response previews only
   */
  getDefaultProductGroup(): Observable<ProductGroup> {
    return this.defaultProductGroup$;
  }

  /**
   * used to get defaults for after-hours-auto-response blackoutWindows
   */
  getClient(): Observable<Client> {
    return this.clientStore$;
  }

  getTwoWayConfig(): Observable<TwoWayConfig> {
    return this.clientStore$.pipe(
      switchMap((client) => {
        return of(client['two_way_config']);
      }),
    );
  }

  saveTwoWayConfig(newTwoWayConfig: TwoWayConfig): Observable<TwoWayConfig> {
    return this.clientStore$.pipe(
      switchMap((client) => {
        client['two_way_config'] = newTwoWayConfig; // updates client with new two way config
        return this.updateTwoWayConfig(newTwoWayConfig);
      }),
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  getAutoResponses(clientId: string): Observable<AutoResponses> {
    const url = `${this.twoWayUrl}/client/${clientId}/configs/autoresponses`;
    return this.http
      .get(url)
      .pipe(map((response) => response['autoResponses']));
  }

  saveAutoResponses(
    clientId: string,
    autoResponsesBody: AutoResponsesPostBody,
  ): Observable<string> {
    const url = `${this.twoWayUrl}/client/${clientId}/configs/autoresponses`;
    return this.http
      .post(url, autoResponsesBody)
      .pipe(map((response) => response['message']));
  }

  getBusinessHours(clientId: string) {
    const url = `${this.twoWayUrl}/client/${clientId}/configs/businesshours`;
    return this.http.get<BusinessHoursResponse>(url).pipe(
      map((response) => {
        return response['businessHours'];
      }),
    );
  }

  getClientStatus(clientId: string) {
    const url = `${this.twoWayUrl}/client/${clientId}/status`;
    return this.http.get<GetClientStatusResponse>(url).pipe(
      map((response) => {
        return response['autoresponse'];
      }),
    );
  }

  private refreshClient(): Observable<Client> {
    return this.clientService.getClient(this.clientId, true).pipe(
      switchMap((response: Client) => {
        return of(response);
      }),
    );
  }

  private setDefaultProductGroup(): Observable<ProductGroup> {
    return this.clientStore$.pipe(
      switchMap((client) => {
        return this.clientService.getProductGroup(client.id, 'default', true);
      }),
    );
  }

  private updateTwoWayConfig(
    TwoWayConfig: TwoWayConfig,
  ): Observable<TwoWayConfig> {
    return this.clientService.updateClientPartial(
      this.clientId,
      'two_way_config',
      TwoWayConfig,
    );
  }

  updateBusinessHours(
    clientId: string,
    updatedBusinessHours: BusinessHoursResponse['businessHours'],
  ): Observable<string> {
    const body = { businessHours: updatedBusinessHours };
    body.businessHours.outOfOffice = {
      enabled: updatedBusinessHours.outOfOffice.enabled,
      expirationDate: updatedBusinessHours.outOfOffice.expirationDate,
      expirationTime: {
        hours: updatedBusinessHours.outOfOffice.expirationTime.hours,
        minutes: updatedBusinessHours.outOfOffice.expirationTime.minutes,
      },
    };
    const url = `${this.twoWayUrl}/client/${clientId}/configs/businesshours`;
    return this.http.post(url, body).pipe(
      map((response) => {
        return response['message'];
      }),
    );
  }

  private get clientId(): string {
    return this.sessionService.getCurrentUsersClient().id;
  }
}
