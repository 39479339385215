import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkflowClass } from '../../core/models/message';
import { JourneyService } from '../../core/services/journey.service';

@Component({
    selector: 'journey-stop',
    templateUrl: './journey-stop.component.html',
    styleUrls: ['./journey-stop.component.scss'],
    standalone: false
})

export class JourneyStopComponent implements OnInit {
  @Input() msgEnder: WorkflowClass;
  @Output() onNewStep = new EventEmitter;
  showHexButtons: boolean;

  constructor() { }

  ngOnInit() {
    this.showHexButtons = false;
  }

  addStep(type: string) {
    this.showHexButtons = false;
    
    this.onNewStep.emit({
      type: type,
      currentComponent: this.msgEnder,
      insert: false
    });
  }

}
