import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'rn-keyword-response-component',
    templateUrl: './keyword-response-component.component.html',
    styleUrls: ['./keyword-response-component.component.scss'],
    standalone: false
})
export class KeywordResponseComponentComponent implements OnInit {
  @Input() keywordGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }
}
