import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoadingMaskService } from '@app/core/services/loading-mask.service';
import { MediaService } from '@app/core/services/media.service';
import { MediaAsset } from '@app/core/models/media';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { NavbarStateService } from '@app/core/services/navbar-state.service';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { SearchableField, SearchBarComponent } from '@app/shared/search-bar/search-bar.component';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { SessionService } from '@app/security/session.service';
import { SortableComponent } from '@app/core/sortable-component';
import { TitleService } from '@app/core/services/title.service';
import { VideoUtils } from '@app/core/utils/video-utils';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { PageManager } from '@app/core/utils/page-manager';
import { VideoThumbnailService } from '@app/core/services/video-thumbnail.service';
import { FeatureService } from '@app/core/services/feature.service';

@Component({
    selector: 'app-media-list',
    templateUrl: './media-list.component.html',
    styleUrls: ['./media-list.component.scss'],
    standalone: false
})
export class MediaListComponent extends SortableComponent implements OnInit, OnDestroy {

  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;
  @ViewChild(SearchBarComponent, { static: true }) searchBar: SearchBarComponent;
  mediaAssets: MediaAsset[] = [];
  pageManager: PageManager = new PageManager();
  searchCriteria: SearchCriteria;
  loaded = false;
  showAddAssetDialog = false;
  showAssetDetailsDialog = false;
  showUpdateAssetDialog = false;
  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;
  showNoSearchFieldsMessage = false;
  offset = 0;
  videoUtils = VideoUtils;
  permissions = Permissions;
  searchableFields = [
    new SearchableField('Asset Name', 'shortname'),
    new SearchableField('Asset Type', 'asset_type'),
    new SearchableField('Asset ID', 'asset_id'),
    new SearchableField('Description', 'description'),
    new SearchableField('Created By', 'created_by_name')
  ];
  newMediaAsset: MediaAsset;
  mediaAssetToEdit: MediaAsset;

  constructor(private titleService: TitleService,
              public navbarStateService: NavbarStateService,
              public mediaService: MediaService,
              public sessionService: SessionService,
              public loadingMaskService: LoadingMaskService,
              private videoThumbnailService: VideoThumbnailService,
              private featureService: FeatureService,
              private ps: PermissionService) {
    super();
  }

  ngOnInit() {
    if (this.ps.checkPermissions(Permissions.media_asset_create)) {
      this.titleService.activate('Media & Branding', '', 'Add New Asset', 'fa-plus-circle', () => {
        this.openAddNewMediaAssetDialog();
      });
    } else {
      this.titleService.activate('Media & Branding');
    }

    this.reloadMediaAssets();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  clearNewMediaAsset() {
    if (this.newMediaAsset) {

      if (this.searchCriteria) {
        this.searchBar.clearSearch();
      } else {
        this.mediaAssets.unshift(this.newMediaAsset);

        // Use an artificial page of offset -1 to hold newly created media assets
        let newAssetPage = this.pageManager.pages.get(-1);
        if (!newAssetPage) {
          newAssetPage = [];
        }
        newAssetPage.unshift(this.newMediaAsset);
        this.pageManager.pages.set(-1, newAssetPage);
      }

      this.newMediaAsset = undefined;
    }
  }

  fetchData() {
    this.pageManager = new PageManager();
    this.mediaAssets = [];
    this.loaded = false;
    this.offset = 0;
    this.getMediaAssets();
  }

  private getMediaAssets() {
    const offset = this.offset;

    this.mediaService.getAllMediaAssets(this.limit, this.offset, this.ordering, this.searchCriteria, this.offset !== 0).subscribe(
      assets => {
        this.pageManager.addPage(offset, assets);
        this.mediaAssets = this.pageManager.flattenPages();
        this.videoThumbnailService.setVideoThumbnailUrls(this.mediaAssets);
      },
      error => {
        if (error.status !== 401) {
          this.messageDialog.showMessage('Ooops...there was error loading media assets.');
        }
      },
      () => {
        this.loaded = true;
      }
    );
  }

  openAddNewMediaAssetDialog() {
    this.showAddAssetDialog = true;
  }

  onClearSearch() {
    this.showNoSearchFieldsMessage = false;
    this.searchCriteria = undefined;
    this.reloadMediaAssets();
  }

  onCloseAddMediaAssetDialog() {
    this.showAddAssetDialog = false;
  }

  onCloseMediaAssetDetailsDialog() {
    this.showAssetDetailsDialog = false;
    this.clearNewMediaAsset();
  }

  onCloseUpdateMediaAssetDialog() {
    this.showUpdateAssetDialog = false;
    this.showAssetDetailsDialog = true;
  }

  /**
   * Note: actual 'add new asset to list' code doesn't run until the 
   * 'onCloseMediaAssetDetailsDialog' function runs.  
   */
  onMediaAssetAdded(mediaAsset: MediaAsset) {
    this.newMediaAsset = mediaAsset;
    this.showAddAssetDialog = false;
    this.showAssetDetailsDialog = true;
    this.mediaAssetToEdit = Object.create(mediaAsset);
  }

  onMediaAssetClick(mediaAsset: MediaAsset) {
    if (this.ps.checkPermissions(Permissions.media_asset_edit)) {
      this.mediaAssetToEdit = Object.assign(new MediaAsset(), mediaAsset);
      this.showAssetDetailsDialog = true;
    }
  }

  onMediaAssetDetailsUpdated(mediaAsset: MediaAsset) {
    this.showAssetDetailsDialog = false;
    this.clearNewMediaAsset(); // clearNewMediaAsset also adds NEW assets to the page manager.  
    this.replaceMediaAsset(mediaAsset); // asset must exist before you try to replace it.  hence, gotta run this 2nd
  }

  onMediaAssetUpdated(mediaAsset: MediaAsset) {
    this.replaceMediaAsset(mediaAsset);
    this.showUpdateAssetDialog = false;
    this.showAssetDetailsDialog = true;
  }

  onRequestSearch(searchCriteria: SearchCriteria) {
    this.showNoSearchFieldsMessage = false;
    this.searchCriteria = searchCriteria;
    if (this.searchCriteria.searchFields.length === 0) {
      this.showNoSearchFieldsMessage = true;
      this.pageManager = new PageManager();
      this.mediaAssets = [];
      this.offset = 0;
    } else {
      this.reloadMediaAssets();
    }
  }

  onScrollDown() {
    this.offset += this.limit;
    this.getMediaAssets();
  }

  onUpdateRequest() {
    this.showAssetDetailsDialog = false;
    this.showUpdateAssetDialog = true;
  }

  reloadMediaAssets() {
    this.loaded = false;
    this.pageManager = new PageManager();
    this.mediaAssets = [];
    this.offset = 0;
    this.getMediaAssets();
  }

  replaceMediaAsset(mediaAsset: MediaAsset) {
    this.pageManager.forEachPage(page => {
      const index = page.findIndex(m => m.asset_id === mediaAsset.asset_id);
      if (index !== -1) {
        page[index] = mediaAsset;
      }
    });
    this.mediaAssets = this.pageManager.flattenPages();
  }
}
