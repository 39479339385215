import { MediaLibraryModule } from '../media-library/media-library.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RelaySharedModule } from '../shared/shared.module';
import { JourneyListModule } from '../journey-list/journey-list.module';
import { AgentOnboardingConfigComponent } from './client-form/client-onboarding-and-feature/agent-onboarding-config/agent-onboarding-config.component';
import { AgentJourneySelectComponent } from './client-form/client-onboarding-and-feature/agent-onboarding-config/agent-journey-select/agent-journey-select.component';
import { AgentExpressConsentComponent } from './client-form/client-onboarding-and-feature/agent-onboarding-config/agent-express-consent/agent-express-consent.component';
import { AgentValidationComponent } from './client-form/client-onboarding-and-feature/agent-onboarding-config/agent-validation/agent-validation.component';
import { ClientConfigContainerComponent } from 'app/client-config/client-form/client-config/client-config-container.component';
import { ClientInfoEditComponent } from './client-form/client-info/client-info-edit.component';
import { ClientImagesNewComponent } from './client-form/client-images/client-images-new.component';
import { ClientInputsNewComponent } from './client-form/client-inputs/client-inputs-new.component';
import { ClientInfoNewComponent } from './client-form/client-info/client-info-new.component';
import { ClientOnboardingAndFeatureComponent } from './client-form/client-onboarding-and-feature/client-onboarding-and-feature.component';
import { ClientFeatureConfigComponent } from './client-form/client-onboarding-and-feature/client-feature-config/client-feature-config.component';
import { ImageInputComponent } from './image-input/image-input.component';
import { MessageDialogComponent } from '../shared/message-dialog/message-dialog.component';
import { ProductInfoComponent } from './product-group/components/info/product-info.component';
import { ProductOnboardingComponent } from './product-group/components/onboarding/onboarding.component';
import { OnboardingToggleComponent } from './onboarding-toggle/onboarding-toggle.component';
import { ProductReportComponent } from './report/product-report/product-report.component';
import { ProductContactInfoRowComponent } from './report/product-report-rows/product-contact-info-row/product-contact-info-row.component';
import { ProductOnboardingFieldsRowComponent } from './report/product-report-rows/product-onboarding-fields-row/product-onboarding-fields-row.component';
import { ProductConsumerPasswordRequiredComponent } from './report/product-report-rows/product-consumer-password-required/product-consumer-password-required.component';
import { ProductOnboardingIvrRowComponent } from './report/product-report-rows/product-onboarding-ivr-row/product-onboarding-ivr-row.component';
import { ProductOnboardingFileGenericComponent } from './report/product-report-rows/product-onboarding-file-generic/product-onboarding-file-generic.component';
import { ProductOnboardingFilePasswordComponent } from './report/product-report-rows/product-onboarding-file-password/product-onboarding-file-password.component';
import { ProductLegalGenericComponent } from './report/product-report-rows/product-legal-generic/product-legal-generic.component';
import { ProductGroupsListComponent } from './product-group/product-groups-list/product-groups-list.component';
import { ProductGroupsEditComponent } from './product-group/edit/edit.component';
import { SelectJourneyButtonComponent } from './client-form/client-onboarding-and-feature/agent-onboarding-config/agent-journey-select/select-journey-button/select-journey-button.component';
import { ProductLegalComponent } from './product-group/legal/legal.component';
import { ClientValidationAndVerificationComponent } from './client-form/client-validation-and-verification/client-validation-and-verification.component';
import { ClientMobileMessageVerificationComponent } from './client-form/client-mobile-message-verification/client-mobile-message-verification.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientConfigReportComponent } from './report/client-config-report/client-config-report.component';
import { RemoveJourneyModalComponent } from './client-form/client-onboarding-and-feature/agent-onboarding-config/agent-journey-select/remove-journey-modal/remove-journey-modal.component';
import { ContactInfoInputsComponent } from './product-group/components/info/contact-info-inputs/contact-info-inputs.component';
import { ProductGroupOnboardingComponent } from './product-group/onboarding/onboarding.component';
import { ProductGroupFeaturesComponent } from './product-group/components/features/features.component';
import { FileProcessingConfigComponent } from './client-form/client-onboarding-and-feature/file-processing-config/file-processing-config.component';
import { HelpTextComponent } from '@app/client-config/product-group/components/info/help-text/help-text.component';
import { IvrConfigComponent } from './client-form/client-onboarding-and-feature/ivr-config/ivr-config.component';
import { VerificationComponent } from './product-group/components/verification/verification.component';
import { ValidationFieldsListComponent } from './validation-fields-list/validation-fields-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClientSmsArchiveComponent } from './client-form/client-sms-archive/client-sms-archive.component';
import { SftpMgmtService } from '@app/sftp-mgmt/services/sftp-mgmt.service';
import { FileLauncherModule } from '@app/file-launcher/file-launcher.module';
import { ClientHelpsiteComponent } from '@app/client-config/client-form/client-helpsite/client-helpsite.component';
import { ClientSmsResponseComponent } from './client-form/client-sms-response/client-sms-response.component';
import { ClientSmsCustomKeywordsComponent } from './client-form/client-sms-custom-keywords/client-sms-custom-keywords.component';
import { ClientHelpsiteGeneratorComponent } from './client-form/client-helpsite-generator/client-helpsite-generator.component';
import { ClientHelpsitePreviewComponent } from './client-form/client-helpsite-preview/client-helpsite-preview.component';
import { ClientPowerUpsConfigComponent } from './client-form/client-power-ups-config/client-power-ups-config.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RelaySharedModule,
    RouterModule,
    MediaLibraryModule,
    ButtonsModule.forRoot(),
    TooltipModule,
    InfiniteScrollModule,
    JourneyListModule,
    DragDropModule,
    FileLauncherModule,
  ],
  declarations: [
    AgentOnboardingConfigComponent,
    AgentJourneySelectComponent,
    AgentExpressConsentComponent,
    AgentValidationComponent,
    ClientInfoNewComponent,
    ClientInfoEditComponent,
    ClientImagesNewComponent,
    ClientInputsNewComponent,
    ClientConfigContainerComponent,
    ClientOnboardingAndFeatureComponent,
    ClientFeatureConfigComponent,
    ClientConfigReportComponent,
    ClientListComponent,
    ImageInputComponent,
    ProductInfoComponent,
    ProductOnboardingComponent,
    OnboardingToggleComponent,
    ProductReportComponent,
    ProductContactInfoRowComponent,
    ProductOnboardingFieldsRowComponent,
    ProductConsumerPasswordRequiredComponent,
    ProductOnboardingIvrRowComponent,
    ProductOnboardingFileGenericComponent,
    ProductOnboardingFilePasswordComponent,
    ProductLegalGenericComponent,
    ProductGroupsListComponent,
    ProductGroupsEditComponent,
    SelectJourneyButtonComponent,
    ProductLegalComponent,
    ClientValidationAndVerificationComponent,
    ClientMobileMessageVerificationComponent,
    RemoveJourneyModalComponent,
    ContactInfoInputsComponent,
    ProductGroupOnboardingComponent,
    ProductGroupFeaturesComponent,
    FileProcessingConfigComponent,
    HelpTextComponent,
    IvrConfigComponent,
    VerificationComponent,
    ValidationFieldsListComponent,
    ClientSmsArchiveComponent,
    ClientHelpsiteComponent,
    ClientSmsResponseComponent,
    ClientSmsCustomKeywordsComponent,
    ClientHelpsiteGeneratorComponent,
    ClientHelpsitePreviewComponent,
    ClientPowerUpsConfigComponent,
  ],
  providers: [MessageDialogComponent, SftpMgmtService],
  exports: [ProductInfoComponent],
})
export class ClientConfigModule {}
