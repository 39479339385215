<div class="upload-area" (drop)="fileDropped($event)" (dragover)="dragOver($event)">
    <div class="upload-area__inner-content">
      <ng-content select="upload-instruction">
        <p>Drag your file here</p>
        <p>or</p>
      </ng-content>
      <div>
          <input class="upload-area__hidden-input" id="fileUploadInput" type="file" (change)="fileSelected($event)" [accept]="acceptedFileTypes">
          <label select-button class="btn btn-primary" for="fileUploadInput" i18n="Label for file selection button">
            <ng-content select="[select-btn-text]"></ng-content>
          </label>
      </div>
      
      <div class="upload-area__description">
        <ng-content select="[description-text]"></ng-content>
      </div>
    </div>
</div>
