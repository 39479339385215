import React, { useEffect } from 'react';
import { StyledBodyCell, StyledHeadCell, StyledTable } from 'baseui/table-grid';
import {
  StyledBodyCellOverride,
  StyledHeadCellOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { useStyletron } from 'baseui';
import { AuditEntry } from '@app/api-mgmt/components/push-api/types/audit-entry';
import { AuditEntriesService } from '@app/api-mgmt/components/push-api/audit-entries-service';
import { SortIcon } from '@app/api-mgmt/components/push-api/components/configurations-display/SortIcon';

type AuditEntryDisplay = Omit<
  AuditEntry,
  'created_at' | 'old_value' | 'new_value'
> & {
  created_at: string;
  old_value: {
    value: string;
    display: string;
  };
  new_value: {
    value: string;
    display: string;
  };
};

const toLocalTimeZone = (date: string | Date) => {
  const dateInTimeZone = new Date(date as string)
    .toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZoneName: 'short',
    })
    .replace(',', '');
  return `${dateInTimeZone}`;
};

const AuditEntryRow = ({ auditEntry }: { auditEntry: AuditEntryDisplay }) => {
  return (
    <>
      <StyledBodyCell style={StyledBodyCellOverride}>
        {auditEntry.user}
      </StyledBodyCell>
      <StyledBodyCell style={StyledBodyCellOverride}>
        {auditEntry.created_at}
      </StyledBodyCell>
      <StyledBodyCell style={StyledBodyCellOverride}>
        {auditEntry.field_name}
      </StyledBodyCell>
      <StyledBodyCell style={StyledBodyCellOverride}>
        {auditEntry.old_value.display}
      </StyledBodyCell>
      <StyledBodyCell style={StyledBodyCellOverride}>
        {auditEntry.new_value.display}
      </StyledBodyCell>
    </>
  );
};

/**
 * A table to display a list of Audit Entry.
 */
export const AuditEntriesDisplay = ({
  clientId,
  uid,
}: {
  clientId?: string;
  uid?: string;
}) => {
  const [css] = useStyletron();

  const [auditEntries, setAuditEntries] = React.useState<AuditEntryDisplay[]>(
    [],
  );
  const [limit, setLimit] = React.useState(20);
  const [offset, setOffset] = React.useState(0);
  const [isLoaded, setIsLoaded] = React.useState(true);

  const [createdByAuditEntry, setCreatedByAuditEntry] =
    React.useState<AuditEntry>(null);

  const [sortBy, setSortBy] = React.useState<'date' | 'user' | 'field_name'>(
    'date',
  );
  const [isReverse, setIsReverse] = React.useState(false);

  function onHandleScroll() {
    // entire page height
    const scrollHeight = document.documentElement.scrollHeight;

    // user page height
    const clientHeight = document.documentElement.clientHeight;
    // scroll top position
    const scrollTop = document.documentElement.scrollTop;

    if (scrollTop + clientHeight >= scrollHeight && isLoaded) {
      setOffset(offset + limit);
    }
  }

  const formatValue = (value: string, hidden?: boolean) => {
    let parsedValue = undefined;
    try {
      parsedValue =
        typeof JSON.parse(value) !== 'boolean' ? JSON.parse(value) : value;
    } catch {
      parsedValue = value;
    }

    if (parsedValue === undefined) {
      throw new Error('Value being formatted is undefined.');
    }

    if (Array.isArray(parsedValue)) {
      return parsedValue.map((event) => {
        return <div key={event}> {hidden ? '****' : event} </div>;
      });
    } else if (typeof parsedValue === 'object') {
      return Object.entries(parsedValue).map(([key, value]) => {
        return (
          <div key={key}>
            {key}: {hidden ? '****' : (value as any)}
          </div>
        );
      });
    } else {
      return hidden ? '****' : parsedValue;
    }
  };

  async function updateAuditEntrySet() {
    if (isLoaded) {
      const newAuditEntries = (
        (await AuditEntriesService.queryAuditEntries(
          clientId,
          uid,
          sortBy,
          isReverse,
          limit,
          offset,
        )) ?? []
      ).map((entry) => ({
        ...entry,
        created_at: toLocalTimeZone(entry.created_at),
        old_value: {
          value: entry.old_value,
          display: formatValue(
            entry.old_value,
            ['password', 'username'].includes(entry.field_name),
          ),
        },
        new_value: {
          value: entry.new_value,
          display: formatValue(
            entry.new_value,
            ['password', 'username'].includes(entry.field_name),
          ),
        },
      }));

      if (newAuditEntries.length === 0) {
        setIsLoaded(false);
      } else {
        setIsLoaded(false);
        setAuditEntries(auditEntries.concat(newAuditEntries));
        setIsLoaded(true);
      }
    }
  }

  async function initAuditEntries() {
    const auditEntrySortByDateReverse =
      (await AuditEntriesService.queryAuditEntries(
        clientId,
        uid,
        'date',
        true,
        1,
        0,
      )) ?? [];
    setCreatedByAuditEntry(auditEntrySortByDateReverse[0]);
  }

  useEffect(() => {
    initAuditEntries();
  }, []);

  useEffect(() => {
    updateAuditEntrySet();
    window.addEventListener('scroll', onHandleScroll);
    return () => {
      window.removeEventListener('scroll', onHandleScroll);
    };
  }, [offset, sortBy, isReverse]);

  function changeSortedTable(selectedSortBy: 'date' | 'user' | 'field_name') {
    setAuditEntries([]);
    setOffset(0);
    setIsLoaded(true);
    if (sortBy !== selectedSortBy) {
      setSortBy(selectedSortBy);
      setIsReverse(false);
    } else {
      if (!isReverse) {
        setIsReverse(true);
      } else {
        setSortBy(undefined);
        setIsReverse(false);
      }
    }
  }

  return (
    <>
      <div
        style={{
          color: '#908e8f',
          fontSize: '16px',
          fontWeight: '400',
          padding: '0px 25px 25px 25px',
        }}
      >
        {createdByAuditEntry && (
          <>
            Created by <b>{createdByAuditEntry.user}</b> on{' '}
            <b>{toLocalTimeZone(createdByAuditEntry.created_at)}</b>.
          </>
        )}
        {!createdByAuditEntry && (
          <>This integration does not have an audit trail.</>
        )}
      </div>
      <StyledTable
        role="grid"
        $gridTemplateColumns="20% 15% 15% 25% 25%"
        style={{
          border: 'none',
          paddingRight: '25px',
          paddingLeft: '25px',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <div
          role="row"
          className={css({
            display: 'contents',
          })}
        >
          <StyledHeadCell
            style={StyledHeadCellOverride}
            onClick={() => changeSortedTable('user')}
          >
            <div>
              <span style={{ paddingRight: '5px', cursor: 'pointer' }}>
                User
              </span>
              <SortIcon
                isActive={sortBy === 'user'}
                isReverse={isReverse}
              ></SortIcon>
            </div>
          </StyledHeadCell>
          <StyledHeadCell
            style={StyledHeadCellOverride}
            onClick={() => changeSortedTable('date')}
          >
            <div>
              <span style={{ paddingRight: '5px', cursor: 'pointer' }}>
                Date & Time
              </span>
              <SortIcon
                isActive={sortBy === 'date'}
                isReverse={isReverse}
              ></SortIcon>
            </div>
          </StyledHeadCell>
          <StyledHeadCell
            style={StyledHeadCellOverride}
            onClick={() => changeSortedTable('field_name')}
          >
            <div>
              <span style={{ paddingRight: '5px', cursor: 'pointer' }}>
                Field Name
              </span>
              <SortIcon
                isActive={sortBy === 'field_name'}
                isReverse={isReverse}
              ></SortIcon>
            </div>
          </StyledHeadCell>
          <StyledHeadCell style={StyledHeadCellOverride}>
            Original
          </StyledHeadCell>
          <StyledHeadCell style={StyledHeadCellOverride}>
            Modified
          </StyledHeadCell>
        </div>
        {auditEntries.map((auditEntry, index) => {
          return <AuditEntryRow auditEntry={auditEntry} key={index} />;
        })}
      </StyledTable>
    </>
  );
};
