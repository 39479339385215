import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ClientService } from '@app/core/services/client.service';
import { catchError, take } from 'rxjs/operators';
import { SmsCustomKeyword } from '@app/core/models/sms-custom-keyword';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { DataMappingUtils } from '@app/core/utils/data-mapping-utils';
import { ValidationUtils } from '@app/core/utils/validation-utils';
import { forkJoin, of } from 'rxjs';
import { Client } from '@app/core/models/client';

@Component({
    selector: 'rn-client-sms-custom-keywords',
    templateUrl: './client-sms-custom-keywords.component.html',
    styleUrls: ['./client-sms-custom-keywords.component.scss'],
    standalone: false
})
export class ClientSmsCustomKeywordsComponent implements OnInit, OnChanges {
  @Input() shortCode: string;
  @Input() shortCodeGroup: UntypedFormGroup;
  @Input() client: Client;

  disableCheckboxes: boolean = false;
  customKeywordArray: UntypedFormArray = new UntypedFormArray([]);
  masterList: SmsCustomKeyword[];

  constructor(
    public clientConfigService: ClientService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.handleInputChanges();
  }

  ngOnInit(): void {
    this.shortCodeGroup.addControl(
      'custom_sms_keywords',
      this.customKeywordArray,
    );
  }

  /**
   * Handles casting the control appropriately.
   * @param control
   * @param name
   */
  getFormControl(control: AbstractControl, name: string): UntypedFormControl {
    const found = control.get(name);
    if (found instanceof UntypedFormControl) {
      return found as UntypedFormControl;
    } else {
      return null;
    }
  }

  /**
   * Handles casting the control appropriately.
   * @param control
   * @param name
   */
  getFormGroup(control: AbstractControl, name: string): UntypedFormGroup {
    const found = control.get(name);
    if (found instanceof UntypedFormGroup) {
      return found as UntypedFormGroup;
    } else {
      return null;
    }
  }

  /**
   * Rebuilds the hierarchy for the UI form controls. Note that the checked formcontrol relies on
   * this.disableCheckBoxes to determine whether the input should be enabled or disabled.
   * @param masterList
   * @param activeList
   */
  rebuildControlHierarchy(
    masterList: SmsCustomKeyword[],
    activeList: SmsCustomKeyword[] = [],
  ): void {
    this.customKeywordArray.clear();
    const stringifiedActiveList = activeList.map((keyword) => {
      return JSON.stringify(keyword);
    });

    masterList.map((keyword) => {
      const uiGroup = new UntypedFormGroup({});
      const keywordGroup = new UntypedFormGroup({});
      const stringifiedKeyword = JSON.stringify(keyword);
      const isChecked = stringifiedActiveList.some((activeKeyword) => {
        return stringifiedKeyword === activeKeyword;
      });
      const keys = Object.keys(keyword);

      uiGroup.addControl(
        'checked',
        new UntypedFormControl({ value: isChecked, disabled: this.disableCheckboxes }),
      );
      uiGroup.addControl('custom_sms_keyword', keywordGroup);
      keys.map((key) => {
        keywordGroup.addControl(key, new UntypedFormControl(keyword[key]));
      });

      this.customKeywordArray.push(uiGroup);
    });
  }

  /**
   * If we don't have a good value for the shortCode, don't bother trying to call out.
   * Otherwise, check against the shared shortCode and enable/disable accordingly.
   * @private
   */
  handleInputChanges(): void {
    this.toggleDisabledOnCheckboxes();
    this.getCustomSmsKeywords();
  }

  /**
   * Disables checkboxes if the shortcode is invalid either because it is empty or
   * the shortcode matches the shared code.
   */
  toggleDisabledOnCheckboxes(): boolean {
    this.disableCheckboxes = ValidationUtils.isStringEmptyOrNull(
      this.shortCode,
    );
    return this.disableCheckboxes;
  }

  /**
   * Helper utility to turn the active records into a list for ease of access.
   * @param apiActiveList
   * @private
   */
  convertServiceKeywordsToModelKeywords(apiActiveList): SmsCustomKeyword[] {
    let result = [];

    if (
      apiActiveList.custom_sms_keywords &&
      Object.keys(apiActiveList.custom_sms_keywords).length > 0
    ) {
      result = DataMappingUtils.entriesPolyFill(
        apiActiveList.custom_sms_keywords,
      ).map((value) => {
        return SmsCustomKeyword.deserialize(value[0], value[1]);
      });
    }

    return result;
  }

  /**
   * Once the lookup keys are in place, calls out to the service to pull the sms keywords for the client.
   * Then it builds it into an AbstractControl collection, which then feeds the
   * checkboxes in the UI.
   * @private
   */
  getCustomSmsKeywords(): void {
    if (
      ValidationUtils.isStringEmptyOrNull(this.client.id) ||
      ValidationUtils.isStringEmptyOrNull(this.shortCode)
    ) {
      return;
    }

    forkJoin({
      apiMasterList: this.clientConfigService.getMasterCustomSMSKeywords(),
      apiActiveList: this.clientConfigService
        .getCustomSMSKeywords(this.client.id, this.shortCode)
        .pipe(catchError((error) => of([]))),
    })
      .pipe(take(1))
      .subscribe(
        ({ apiMasterList, apiActiveList }) => {
          const activeList = apiActiveList.custom_sms_keywords
            ? this.convertServiceKeywordsToModelKeywords(apiActiveList)
            : [];
          this.rebuildControlHierarchy(apiMasterList, activeList);
        },
        (error) => {
          this.rebuildControlHierarchy([], []);
        },
      );
  }
}
