<div
  class="help-text-container"
  *ngIf="
    isRMAutoResponseEnabled &&
    isOutOfOfficeEnabled &&
    feedEnabled &&
    currentHoursType === 'OUT_OF_OFFICE' &&
    !isErrorClientStatus &&
    !isErrorBusinessHours
  "
>
  <p>
    The Out of Office auto response is enabled until
    {{ this.currentHoursExpires }}. Your business hours and other auto responses
    will resume then.
  </p>
</div>
