import React from 'react';
import { Button, KIND, SIZE } from 'baseui/button';
import { AgentAvatar } from '../agent-avatar/AgentAvatar';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { AgentStatusT } from '../relay-messenger-axios-service/relay-messenger-axios-service';

export type SingleAgentViewProps = {
  userFullName: string;
  status: AgentStatusT;
  userLeft: boolean;
  openInviteModal: () => void;
  openLeaveModal: () => void;
  openRemoveModal: (agentId) => void;
  setIsAssignModalOpen?: (isOpen: boolean) => void;
  hideAgentActions?: boolean;
  agentConversationsClicked: boolean;
  firstAgentId?: string;
};

// display single agent with invite and leave buttons
export const SingleAgentView = ({
  userFullName,
  status,
  userLeft,
  openInviteModal,
  openLeaveModal,
  openRemoveModal,
  setIsAssignModalOpen,
  hideAgentActions,
  agentConversationsClicked,
  firstAgentId,
}: SingleAgentViewProps) => {
  const [css, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  return (
    <>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '20px',
        })}
      >
        <AgentAvatar
          name={userFullName ? userFullName : 'Loading...'}
          status={status}
        ></AgentAvatar>
      </div>
      {!hideAgentActions ? (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '15px',
            paddingBottom: '10px',
          })}
        >
          <div
            className={css({
              marginRight: 'auto',
            })}
          >
            <div
              className={css({
                display: 'flex',
                gap: '10px',
              })}
            >
              {!agentConversationsClicked && (
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  overrides={{
                    BaseButton: {
                      style: () => ({
                        width: 'auto',
                        height: '40px',
                        borderTopLeftRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        borderBottomLeftRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        borderTopRightRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        borderBottomRightRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        color: '#3693BF',
                        backgroundColor: customTheme.colors.white,
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        boxShadow: 'rgba(54, 147, 191, 0.23) 0px 0px 5px 0px',
                      }),
                    },
                  }}
                  onClick={openInviteModal}
                >
                  Invite
                </Button>
              )}

              {agentConversationsClicked && (
                <>
                  <Button
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    disabled={userLeft ? true : false}
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: 'auto',
                          paddingLeft: '2rem',
                          paddingRight: '2rem',
                          height: '40px',
                          borderTopLeftRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderBottomLeftRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderTopRightRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderBottomRightRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          color: '#3693BF',
                          backgroundColor: customTheme.colors.white,
                          boxShadow: 'rgba(54, 147, 191, 0.23) 0px 0px 5px 0px',
                        }),
                        props: {
                          'data-testid': 're-assign-conversation-button',
                        },
                      },
                    }}
                    onClick={() => setIsAssignModalOpen(true)}
                  >
                    Re-assign
                  </Button>
                  <Button
                    kind={KIND.secondary}
                    size={SIZE.mini}
                    disabled={userLeft ? true : false}
                    overrides={{
                      BaseButton: {
                        style: () => ({
                          width: 'auto',
                          paddingLeft: '2rem',
                          paddingRight: '2rem',
                          height: '40px',
                          borderTopLeftRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderBottomLeftRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderTopRightRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          borderBottomRightRadius:
                            customTheme.borders.agentInfoButtonBorderRadius,
                          color: '#BE0000',
                          backgroundColor: customTheme.colors.white,
                          boxShadow:
                            'rgba(255, 112, 112, 0.23) 0px 0px 5px 0px',
                        }),
                        props: {
                          'data-testid': 'leave-conversation-button',
                        },
                      },
                    }}
                    onClick={() => openRemoveModal(firstAgentId)}
                  >
                    Remove
                  </Button>
                </>
              )}
              {!agentConversationsClicked && (
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  disabled={userLeft ? true : false}
                  overrides={{
                    BaseButton: {
                      style: () => ({
                        width: 'auto',
                        paddingLeft: '2rem',
                        paddingRight: '2rem',
                        height: '40px',
                        borderTopLeftRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        borderBottomLeftRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        borderTopRightRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        borderBottomRightRadius:
                          customTheme.borders.agentInfoButtonBorderRadius,
                        color: '#BE0000',
                        backgroundColor: customTheme.colors.white,
                        boxShadow: 'rgba(255, 112, 112, 0.23) 0px 0px 5px 0px',
                      }),
                      props: {
                        'data-testid': 'leave-conversation-button',
                      },
                    },
                  }}
                  onClick={openLeaveModal}
                >
                  Leave
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
