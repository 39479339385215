import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Clipboard } from '../../core/utils/clipboard';

@Component({
    selector: 'app-user-reset',
    templateUrl: './user-reset.component.html',
    styleUrls: ['./user-reset.component.scss'],
    standalone: false
})
export class UserResetComponent implements OnInit {
  @Input() config: any;
  @Output() closeWindow: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }
  closeModal(name:string) {
      this.closeWindow.emit(name);
  }
}
