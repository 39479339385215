// Angular Core Imports
import { Component, Input, OnInit } from '@angular/core';
import { URLUtils } from '@app/core/utils/url-utils';

// Import Modules
import * as _ from 'lodash';

// Data Models
import { Client, ProductGroup } from '@app/core/models/client';

@Component({
    selector: 'app-product-onboarding-fields-row',
    templateUrl: './product-onboarding-fields-row.component.html',
    styleUrls: ['./product-onboarding-fields-row.component.scss'],
    standalone: false
})
export class ProductOnboardingFieldsRowComponent implements OnInit {
  @Input() product: ProductGroup;
  @Input() client: Client;

  onboardingArray: Array<any> = [];
  anyEnabled: boolean;

  constructor() { }

  ngOnInit() {
    this.onboardingArray = _.toPairs(this.product.onboarding);
    this.anyEnabled = _.findKey(this.product.onboarding, o => {return o.enabled === 'true'}) === undefined ? false : true;
  }

  getGeneratedURL(): string {
    const path = `/c/${this.client.id}/p/${this.product.id}/sign-up`;
    if (this.product.is_vanity_url_enabled) {
      return this.product.vanity_url + path;
    } else {
      return URLUtils.getWireURLDefaultToProd() + path;
    }
  }

  private formatName(string: string) {
    return string.replace(/_/g, ' ');
  }
}
