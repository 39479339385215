import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { TitleService } from '@app/core/services/title.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { RelaySharedModule } from '@app/shared/shared.module';
import { mergeMap } from 'rxjs';
import { ClientConfigModule } from '@app/client-config/client-config.module';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CharacterLimits } from '@app/core/utils/characterLimits';
import { SignUpFormComponent } from '../components/sign-up-form/sign-up-form.component';
import {
  VerificationField,
  VerificationFieldType,
} from '@app/core/models/validation-verification';
import { NonDepositLanguageSpanish } from '@relaynetwork/rn-node-feed-api-types';

const defaultFormSpanishText = {
  SuccessCopy: 'Éxito',
  FailureCopy: 'Falla',
  SubmitButtonLabel: 'Enviar',
  TermsAndConditionsCheckboxText: 'Acepto los términos y condiciones',
  TermsAndConditionsErrorText: 'Por favor, acepte los términos y condiciones.',
};

@Component({
  selector: 'translations',
  templateUrl: './translations.html',
  styleUrls: ['./translations.scss'],
  imports: [
    RelaySharedModule,
    ClientConfigModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SignUpFormComponent,
  ],
})
export class Translations implements OnInit {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;

  protected headerGroups = {
    productGroupInfo: true,
    fdic: true,
    disclaimer: true,
    tnc: true,
    verification: true,
    signup: true,
    password: true,
  };
  private clientService = inject(ClientService);
  private titleService = inject(TitleService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  protected productGroupId: string;
  protected client: Client;
  protected productGroup: ProductGroup;
  protected currentGroupNew = false;
  validationFields: VerificationField[] = [];
  nonDepositLanguageSpanish = NonDepositLanguageSpanish;

  form: FormGroup = null;

  constructor() {}

  ngOnInit() {
    this.getClientAndProductGroup();
  }

  getClientAndProductGroup() {
    this.productGroupId = this.activatedRoute.snapshot.params['productId'];
    this.clientService
      .getClient(this.activatedRoute.snapshot.params['clientId'])
      .pipe(
        mergeMap((client: Client) => {
          this.client = client;
          return this.clientService.getProductGroup(
            this.client.id,
            this.productGroupId,
          );
        }),
      )
      .subscribe(
        (productGroup: ProductGroup) => {
          this.productGroup = productGroup;

          this.form = new FormGroup({
            name: new FormControl(
              this.productGroup?.i18n?.es?.translations?.name ?? '',
              Validators.maxLength(
                CharacterLimits.ProductGroupFeedDisplayNameCharLimit,
              ),
            ),
            description: new FormControl(
              this.productGroup?.i18n?.es?.translations?.description ?? '',
              Validators.maxLength(
                CharacterLimits.ProductGroupDescriptionCharLimit,
              ),
            ),
            fdicNonDepositLanguage: new FormControl(
              this.productGroup?.i18n?.es?.translations?.fdic
                .non_deposit_language ?? '',
              Validators.maxLength(
                CharacterLimits.FdicNonDepositLanguageCharLimit,
              ),
            ),
            disclaimer: new FormControl(
              this.productGroup?.i18n?.es?.translations?.disclaimer.text ?? '',
              Validators.maxLength(CharacterLimits.DisclaimerCharacterLimit),
            ),
            expressConsentTC: new FormControl(
              this.productGroup?.i18n?.es?.translations?.consent
                .express_consent_ts_cs ?? '',
              Validators.maxLength(CharacterLimits.ExpressConsentTsCsCharLimit),
            ),
            expressWrittenConsentTC: new FormControl(
              this.productGroup?.i18n?.es?.translations?.consent
                .express_written_consent_ts_cs ?? '',
              Validators.maxLength(
                CharacterLimits.ExpressWrittenConsentTsCsCharLimit,
              ),
            ),
            inFeedConsentUpgradeTC: new FormControl(
              this.productGroup?.i18n?.es?.translations?.consent
                .in_wire_upgrade ?? '',
              Validators.maxLength(
                CharacterLimits.InFeedConsentUpgradeTsCsCharLimit,
              ),
            ),
            passwordPageTC: new FormControl(
              this.productGroup?.i18n?.es?.translations?.password.tcs ?? '',
              Validators.maxLength(CharacterLimits.PasswordPageTsCsCharLimit),
            ),
            verificationFields: new FormGroup({
              first_name: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.fields.first_name ?? 'Nombre de pila',
              ),
              last_name: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.fields.last_name ?? 'Apellido',
              ),
              ssn_last4: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.fields.ssn_last4 ?? 'Ultimos 4 de SSN',
              ),
              date_of_birth: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.fields.date_of_birth ?? 'Fecha de nacimiento',
              ),
              postal_code: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.fields.postal_code ?? 'Codigo Postal',
              ),
              header: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.header ?? '',
              ),
              body: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.body ?? '',
              ),
              button_label: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .verification.button_label ?? '',
              ),
            }),
            signup: new FormGroup({
              message_text: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.message_text ?? '',
              ),
              success_text: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.success_text ||
                  defaultFormSpanishText.SuccessCopy,
              ),
              failure_text: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.failure_text ||
                  defaultFormSpanishText.FailureCopy,
              ),
              submit_button_label: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.submit_button_label ||
                  defaultFormSpanishText.SubmitButtonLabel,
              ),
              terms_and_conditions_text: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.terms_and_conditions_text ||
                  defaultFormSpanishText.TermsAndConditionsCheckboxText,
              ),
              terms_and_conditions_validation_error: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.terms_and_conditions_validation_error ||
                  defaultFormSpanishText.TermsAndConditionsErrorText,
              ),
              ccid_input_label: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.ccid_input_label ?? '',
              ),
              mobile_input_label: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.onboarding
                  .sign_up_page.mobile_input_label ?? '',
              ),
              fields: new FormGroup({
                first_name: new FormControl<string>(
                  this.productGroup?.i18n?.es?.translations?.onboarding
                    .sign_up_page.fields.first_name ?? 'Nombre de pila',
                ),
                last_name: new FormControl<string>(
                  this.productGroup?.i18n?.es?.translations?.onboarding
                    .sign_up_page.fields.last_name ?? 'Apellido',
                ),
                ssn_last4: new FormControl<string>(
                  this.productGroup?.i18n?.es?.translations?.onboarding
                    .sign_up_page.fields.ssn_last4 ?? 'Ultimos 4 de SSN',
                ),
                date_of_birth: new FormControl<string>(
                  this.productGroup?.i18n?.es?.translations?.onboarding
                    .sign_up_page.fields.date_of_birth ?? 'Fecha de nacimiento',
                ),
                postal_code: new FormControl<string>(
                  this.productGroup?.i18n?.es?.translations?.onboarding
                    .sign_up_page.fields.postal_code ?? 'Codigo Postal',
                ),
              }),
            }),
            password: new FormGroup({
              text: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.password.text ?? '',
              ),
              label: new FormControl<string>(
                this.productGroup?.i18n?.es?.translations?.password.label ||
                  defaultFormSpanishText.SubmitButtonLabel,
              ),
            }),
          });

          this.validationFields = [
            {
              field: VerificationFieldType.first_name,
              prompt: this.form.get('signup.fields.first_name').value,
              labels: {},
            },
            {
              field: VerificationFieldType.last_name,
              prompt: this.form.get('signup.fields.last_name').value,
              labels: {},
            },
            {
              field: VerificationFieldType.ssn_last4,
              prompt: this.form.get('signup.fields.ssn_last4').value,
              labels: {},
            },
            {
              field: VerificationFieldType.date_of_birth,
              prompt: this.form.get('signup.fields.date_of_birth').value,
              labels: {},
            },
            {
              field: VerificationFieldType.postal_code,
              prompt: this.form.get('signup.fields.postal_code').value,
              labels: {},
            },
          ];
          this.form.controls['signup'].valueChanges.subscribe(() => {
            this.validationFields = [
              {
                field: VerificationFieldType.first_name,
                prompt: this.form.get('signup.fields.first_name').value,
                labels: {},
              },
              {
                field: VerificationFieldType.last_name,
                prompt: this.form.get('signup.fields.last_name').value,
                labels: {},
              },
              {
                field: VerificationFieldType.ssn_last4,
                prompt: this.form.get('signup.fields.ssn_last4').value,
                labels: {},
              },
              {
                field: VerificationFieldType.date_of_birth,
                prompt: this.form.get('signup.fields.date_of_birth').value,
                labels: {},
              },
              {
                field: VerificationFieldType.postal_code,
                prompt: this.form.get('signup.fields.postal_code').value,
                labels: {},
              },
            ];
          });
        },
        (error) =>
          this.messageDialog.showMessage('Product group retrieval failed.'),
        () => this.setPrimaryTitle(),
      );
  }

  onSave() {
    this.productGroup.i18n = {
      ...this.productGroup.i18n,
      ...{
        es: {
          translations: {
            name: this.form.value.name ? this.form.value.name : undefined,
            description: this.form.value.description
              ? this.form.value.description
              : undefined,
            fdic: {
              non_deposit_language: this.form.value.fdicNonDepositLanguage
                ? this.form.value.fdicNonDepositLanguage
                : undefined,
            },
            disclaimer: {
              text: this.form.value.disclaimer
                ? this.form.value.disclaimer
                : undefined,
            },
            consent: {
              express_consent_ts_cs: this.form.value.expressConsentTC
                ? this.form.value.expressConsentTC
                : undefined,
              express_written_consent_ts_cs: this.form.value
                .expressWrittenConsentTC
                ? this.form.value.expressWrittenConsentTC
                : undefined,
              in_wire_upgrade: this.form.value.inFeedConsentUpgradeTC
                ? this.form.value.inFeedConsentUpgradeTC
                : undefined,
            },
            password: {
              tcs: this.form.value.passwordPageTC
                ? this.form.value.passwordPageTC
                : undefined,
              text: this.form.get('password.text').value
                ? this.form.get('password.text').value
                : undefined,
              label: this.form.get('password.label').value
                ? this.form.get('password.label').value
                : undefined,
            },
            onboarding: {
              sign_up_page: {
                message_text: this.form.get('signup.message_text').value
                  ? this.form.get('signup.message_text').value
                  : undefined,
                success_text: this.form.get('signup.success_text').value
                  ? this.form.get('signup.success_text').value
                  : undefined,
                failure_text: this.form.get('signup.failure_text').value
                  ? this.form.get('signup.failure_text').value
                  : undefined,
                submit_button_label: this.form.get('signup.submit_button_label')
                  .value
                  ? this.form.get('signup.submit_button_label').value
                  : undefined,
                terms_and_conditions_text: this.form.get(
                  'signup.terms_and_conditions_text',
                ).value
                  ? this.form.get('signup.terms_and_conditions_text').value
                  : undefined,
                terms_and_conditions_validation_error: this.form.get(
                  'signup.terms_and_conditions_validation_error',
                ).value
                  ? this.form.get(
                      'signup.terms_and_conditions_validation_error',
                    ).value
                  : undefined,
                ccid_input_label: this.form.get('signup.ccid_input_label').value
                  ? this.form.get('signup.ccid_input_label').value
                  : undefined,
                mobile_input_label: this.form.get('signup.mobile_input_label')
                  .value
                  ? this.form.get('signup.mobile_input_label').value
                  : undefined,
                fields: {
                  first_name: this.form.get('signup.fields.first_name').value
                    ? this.form.get('signup.fields.first_name').value
                    : undefined,
                  last_name: this.form.get('signup.fields.last_name').value
                    ? this.form.get('signup.fields.last_name').value
                    : undefined,
                  ssn_last4: this.form.get('signup.fields.ssn_last4').value
                    ? this.form.get('signup.fields.ssn_last4').value
                    : undefined,
                  date_of_birth: this.form.get('signup.fields.date_of_birth')
                    .value
                    ? this.form.get('signup.fields.date_of_birth').value
                    : undefined,
                  postal_code: this.form.get('signup.fields.postal_code').value
                    ? this.form.get('signup.fields.postal_code').value
                    : undefined,
                },
              },
              verification: {
                header: this.form.get('verificationFields.header').value
                  ? this.form.get('verificationFields.header').value
                  : undefined,
                body: this.form.get('verificationFields.body').value
                  ? this.form.get('verificationFields.body').value
                  : undefined,
                button_label: this.form.get('verificationFields.button_label')
                  .value
                  ? this.form.get('verificationFields.button_label').value
                  : undefined,
                fields: {
                  first_name: this.form.get('verificationFields.first_name')
                    .value
                    ? this.form.get('verificationFields.first_name').value
                    : undefined,
                  last_name: this.form.get('verificationFields.last_name').value
                    ? this.form.get('verificationFields.last_name').value
                    : undefined,
                  ssn_last4: this.form.get('verificationFields.ssn_last4').value
                    ? this.form.get('verificationFields.ssn_last4').value
                    : undefined,
                  date_of_birth: this.form.get(
                    'verificationFields.date_of_birth',
                  ).value
                    ? this.form.get('verificationFields.date_of_birth').value
                    : undefined,
                  postal_code: this.form.get('verificationFields.postal_code')
                    .value
                    ? this.form.get('verificationFields.postal_code').value
                    : undefined,
                },
              },
            },
          },
        },
      },
    };

    // Save the updated productGroup
    this.clientService
      .updateProductGroup(this.client.id, this.productGroup)
      .subscribe(
        () =>
          this.router.navigateByUrl(`/client/${this.client.id}/product-group`),
        (error) =>
          this.messageDialog.showMessage('Product group update failed.'),
      );
  }

  private setPrimaryTitle() {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Edit Product Group - ' + this.client.company_name
        : 'Edit Product Group',
    );
  }

  passwordHasTextError(): boolean {
    return (
      this.productGroup.password.enabled &&
      (this.productGroup.password.text?.length ?? 0) === 0 &&
      this.form.get('password.text').dirty
    );
  }

  getCharacterLimit(field: string): number {
    switch (field) {
      case 'feedDisplayName':
        return CharacterLimits.ProductGroupFeedDisplayNameCharLimit;
      case 'description':
        return CharacterLimits.ProductGroupDescriptionCharLimit;
      case 'customerContactsLabel':
        return CharacterLimits.ProductGroupCustomerContactsLabelCharLimit;
      case 'fdicNonDepositLanguage':
        return CharacterLimits.FdicNonDepositLanguageCharLimit;
      case 'disclaimer':
        return CharacterLimits.DisclaimerCharacterLimit;
      case 'expressConsentTsCs':
        return CharacterLimits.ExpressConsentTsCsCharLimit;
      case 'expressWrittenConsentTsCs':
        return CharacterLimits.ExpressWrittenConsentTsCsCharLimit;
      case 'inFeedConsentUpgradeTsCs':
        return CharacterLimits.InFeedConsentUpgradeTsCsCharLimit;
      case 'passwordTsCs':
        return CharacterLimits.PasswordPageTsCsCharLimit;
      case 'setPasswordText':
        return CharacterLimits.PasswordRequiredMessageText;
      case 'setPasswordSubmitButtonLabel':
        return CharacterLimits.PasswordRequiredSubmitButtonLabel;
    }
  }

  handleNonDepositLanguageFocusOut(): void {
    const ctl = this.form.get('fdicNonDepositLanguage');
    if (!ctl.value) {
      ctl.setValue(NonDepositLanguageSpanish);
      ctl.updateValueAndValidity();
    }
  }
}
