import React from 'react';
import { Button } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { Block } from 'baseui/block';
import { Check, ChevronRight } from 'baseui/icon';
import { ControlGroup } from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { AgentStatus } from './AgentFilterSortingPopover';

interface SortingActionItemProps {
  groups: ControlGroup[];
  color?: string;
  dataTestId?: string;
  icon?: () => React.ReactNode;
  filterByGroupId: string;
  setFilterByGroupId: (value: string) => void;
  close: () => void;
  clearSortingSelection: () => void;
}

export const FilterGroupNameAction = ({
  groups,
  setFilterByGroupId,
  filterByGroupId,
  close,
  clearSortingSelection,
}: SortingActionItemProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  return (
    <StatefulPopover
      content={() => (
        <Block
          overrides={{
            Block: {
              style: {
                width: '150px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
            },
          }}
        >
          {groups.map((group) => {
            return (
            <Button
            onClick={() => {
              setFilterByGroupId(group.group_name);
              close();
            }}
            value={group.group_name}
            overrides={{
              BaseButton: {
                style: {
                  width: '100%',
                  display: 'flex',
                  color: filterByGroupId && filterByGroupId === group.group_name
                  ? '#3693BF'
                  : 'black',
                  fontWeight:
                  filterByGroupId && filterByGroupId === group.group_name
                      ? 'bolder'
                      : 'inherit',
                  backgroundColor: 'transparent',
                  textAlign: 'left',
                  justifyContent: 'left',
                  fontSize: '12px',
                  padding: '10px',
                  outline: 'none !important',
                  ':hover': {
                    backgroundColor: '#E1EFF5',
                  },
                  ':active': {
                    backgroundColor: 'transparent',
                  },
                },
                props: {
                  'data-testid': `filter-by-group-name`,
                },
              },
            }}
            startEnhancer={() =>
              filterByGroupId && filterByGroupId === group.group_name ? (
                <Check
                  size={'20px'}
                  color="#3693BF"
                  overrides={{
                    Svg: {
                      style: {
                        display: 'flex',
                      },
                    },
                  }}
                />
              ) : (
                ''
              )
            }
          >
            {group.group_name}
          </Button>
            )
          })}

        </Block>
      )}
      placement={PLACEMENT.right}
      autoFocus={false}
      overrides={{
        Inner: {
          style: {
            backgroundColor: customTheme.colors.white,
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
          props: { 'data-testid': 'sorting-name-popover' },
        },
        Body: {
          style: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginTop: '0',
            marginLeft: '25px',
          },
        },
      }}
    >
      <Block
        overrides={{
          Block: {
            style: {
              width: '190px',
            },
          },
        }}
      >
        <Button
          value={'Groups'}
          overrides={{
            BaseButton: {
              style: {
                width: '100%',
                display: 'flex',
                color: filterByGroupId 
                  ? '#3693BF'
                  : 'black',
                fontWeight: filterByGroupId
                  ? 'bolder'
                  : 'inherit',
                backgroundColor: filterByGroupId
                  ? '#E1EFF5'
                  : 'transparent',
                textAlign: 'left',
                fontSize: '12px',
                outline: 'none !important',
                justifyContent: 'left',
                ':hover': {
                  backgroundColor: 'none',
                },
                ':active': {
                  backgroundColor: 'none',
                },
              },
              props: {
                'data-testid': 'groups-name-btn',
              },
            },
          }}
          endEnhancer={() => <ChevronRight />}
          startEnhancer={() =>
            filterByGroupId ? (
              <Check size={'30px'} color="#3693BF" />
            ) : (
              ''
            )
          }
        >
          {'Groups'}
        </Button>
      </Block>
    </StatefulPopover>
  );
};
