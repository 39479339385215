import { Journey } from '../../../core/models/journey';
import { MessageClass } from '../../../core/models/message';
import { ToolbarService } from '../../services/toolbar.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-message-inspector',
    templateUrl: './message-inspector.component.html',
    styleUrls: ['./message-inspector.component.scss'],
    standalone: false
})
export class MessageInspectorComponent implements OnInit {
  
  @Input() journeyId: string;
  @Input() journey: Journey;
  @Input() message: MessageClass;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(public toolbarService: ToolbarService, private router: Router) {
  }

  ngOnInit() {
  }

  editMessage() {
    this.router.navigateByUrl(`cx-builder/message-builder/${this.journeyId}/message/${this.message.name}`);
  }
}
