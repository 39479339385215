import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AwsUtils } from '@app/core/utils/aws-utils';
import { Observable } from 'rxjs';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { GuidUtils } from '@app/core/utils/guid-utils';
import { MediaService } from '@app/core/services/media.service';
import { MediaAsset } from '@app/core/models/media';
import { Direction, Ordering } from '@app/core/utils/ordering';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { SearchCriteria } from '@app/core/utils/search-criteria';
import { SessionService } from '@app/security/session.service';
import { VideoUtils } from '@app/core/utils/video-utils';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { UploadComponent } from '@app/shared/upload/upload.component';
import { FeatureService } from '@app/core/services/feature.service';
import { VideoThumbnailService } from '@app/core/services/video-thumbnail.service';


@Component({
    selector: 'add-media-asset',
    templateUrl: './add-media-asset.component.html',
    styleUrls: ['./add-media-asset.component.scss'],
    standalone: false
})
export class AddMediaAssetComponent implements OnInit {
  @Input() clientId: string;
  @Input() assetTypes: string[];
  @Input() maxFileSize = 0;
  @Input() minWidth;
  @Input() minHeight;
  @Input() canSelect = false;
  @Input() assetToUpdate;
  @Input() createBrand: boolean;
  @Input() editBrand: boolean;
  @Output() onClose = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<MediaAsset>();
  @Output() onSelect = new EventEmitter<MediaAsset>();
  @ViewChild(UploadComponent, {static: true}) uploader: UploadComponent;
  @ViewChild(MessageDialogComponent, {static: true}) messageDialog: MessageDialogComponent;
  @ViewChild('selector-area') mediaScrollArea: any;
  displayName = '';
  assetId = '';
  brandingColor: string;
  altText: string;
  videoUrl: string;
  mediaAssetGroup: UntypedFormGroup;
  imagePreviewOn = false;
  videoPreviewOn = false;
  selectionOn = false;
  loadedFile: File;
  previewImage: any;
  loadedImageValid = false;
  uploadStarted = false;
  uploadComplete = false;
  uploadedMediaAsset: MediaAsset;
  offset = 0;
  selectableMediaAssets: MediaAsset[] = [];
  selectedAsset: MediaAsset;
  selectionPreview = false;
  videoUtils = VideoUtils;
  searchText: string;
  searchCriteria: SearchCriteria;
  ordering = new Ordering('updated_at', Direction.Desc);

  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;

  constructor(private mediaService: MediaService,
              private sessionService: SessionService,
              private changeDetectorRef: ChangeDetectorRef,
              private formBuilder: UntypedFormBuilder,
              private videoThumbnailService: VideoThumbnailService,
              public featureService: FeatureService) {
  }

  ngOnInit() {
    if (this.assetToUpdate) {
      this.mediaAssetGroup = this.formBuilder.group({
        'selected_asset_type': [this.assetToUpdate.asset_type, Validators.required],
        'alternate_text': [this.assetToUpdate.alternate_text, Validators.required],
        'short_name': [this.assetToUpdate.shortname, Validators.required],
        'user_asset_id': [this.assetToUpdate.asset_id],
        'branding_color': [this.assetToUpdate.branding_color, this.editBrand ? Validators.required : []],
        'video_url': [this.assetToUpdate.url, [Validators.required, CustomValidators.whiteListURL]]
      });

      // if editing an existing id, validation isn't needed because
      // the asset_id field is read-only. Also some older media assets
      this.mediaAssetGroup.get('user_asset_id').disable();

      this.assetTypes = [this.assetToUpdate.asset_type];
      this.selectedAssetType = this.assetTypes[0];
      this.assetId = this.assetToUpdate.asset_id;
      this.displayName = this.assetToUpdate.shortname;
      this.altText = this.assetToUpdate.alternate_text;
      this.brandingColor = this.assetToUpdate.branding_color;

    } else if (this.createBrand) {
      this.mediaAssetGroup = this.formBuilder.group({
        'selected_asset_type': ['image', Validators.required],
        'alternate_text': [null, Validators.required],
        'short_name': [null, Validators.required],
        'user_asset_id': [null, CustomValidators.apiCompatibleMediaID],
        'branding_color': ['#000000', Validators.required],
        'video_url': [null, [Validators.required, CustomValidators.whiteListURL]]
      });
    } else {
      this.mediaAssetGroup = this.formBuilder.group({
        'selected_asset_type': ['image', Validators.required],
        'alternate_text': [null, Validators.required],
        'short_name': [null, Validators.required],
        'user_asset_id': [null, CustomValidators.apiCompatibleMediaID],
        'video_url': [null, [Validators.required, CustomValidators.whiteListURL]]
      });
    }

    this.mediaAssetGroup.controls['video_url'].disable();
    this.mediaAssetGroup.get('selected_asset_type').valueChanges.subscribe((assetType: string) => {
      if (assetType === 'image' || assetType === 'banner') {
        this.mediaAssetGroup.get('alternate_text').setValidators([Validators.required]);
        this.mediaAssetGroup.get('alternate_text').enable();
      } else {
        this.mediaAssetGroup.get('alternate_text').setValidators([]);
        this.mediaAssetGroup.get('alternate_text').disable();
      }
    });

    if (this.assetTypes.length === 1) {
      this.selectedAssetType = this.assetTypes[0];
    }

    this.mediaAssetGroup.valueChanges.subscribe(val => {
      this.altText = val['alternate_text'];
      this.displayName = val['short_name'];
      this.assetId = val['user_asset_id'];
      this.videoUrl = val['video_url'];
      this.brandingColor = val['branding_color'];
    });
  }

  get selectedAssetType(): string {
    return this.mediaAssetGroup.get('selected_asset_type').value;
  }

  set selectedAssetType(newType: string) {
    this.mediaAssetGroup.get('selected_asset_type').setValue(newType);
  }

  get isValid() {
    if (this.mediaAssetGroup.valid && this.loadedImageValid
      || this.selectedAssetType === 'video' && this.mediaAssetGroup.valid
      || this.selectedAsset) {
      return true;
    } else {
      return false;
    }
  }

  get currentVideoUrl() {
    if (this.selectedAsset) {
      return this.selectedAsset.url;
    }
    return this.videoUrl || '';
  }

  setColor(event: string | boolean) {
    if (this.createBrand || this.editBrand) {
      this.mediaAssetGroup.get('branding_color').setValue(event);
    }
  }

  clearSearch() {
    this.searchText = undefined;
    this.searchCriteria = undefined;
    this.selectedAsset = undefined;
    this.displayName = undefined;
    this.assetId = undefined;
    this.resetSelectableMediaAssets();
  }

  checkForEnter(event) {
    if (event.keyCode === 13) {
      this.submitSearchRequest();
    }
  }

  confirmSelection() {
    this.onSelect.emit(this.selectedAsset);
  }

  close() {
    this.onClose.emit();
  }

  dragOver(event) {
    event.preventDefault();
  }

  dropFile(event) {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.loadImage(file);
    }
  }

  getSelectableMediaAssets() {
    let mediaAssetObs: Observable<Array<MediaAsset>>;

    mediaAssetObs = this.mediaService.getMediaAssetsByClient(this.clientId, this.selectedAssetType, this.limit,
      this.offset, this.ordering, this.searchCriteria, this.offset !== 0);

    mediaAssetObs.subscribe(
      mediaAssets => {
        this.selectableMediaAssets = this.selectableMediaAssets.concat(mediaAssets);
        this.videoThumbnailService.setVideoThumbnailUrls(this.selectableMediaAssets);
      },
      error => {
        if (error.status !== 401) {
          this.messageDialog.showMessage('Oops...there was an error getting media assets.');
        }
      });
  }

  handleUploadError() {
    this.messageDialog.showMessage('Oops...there was an error uploading the file. Please try re-exporting the file.');
    this.loadedFile = undefined;
    this.uploadStarted = false;
    this.uploadComplete = false;
    this.imagePreviewOn = false;
    if (this.canSelect) {
      this.turnOnSelection();
    }
    this.changeDetectorRef.detectChanges();
  }

  loadImage(file: File) {
    if (file.size > this.maxFileSize) {
      this.messageDialog.showMessage('File is too big!  All images must be less than 1 MB.');
    } else if (!file.type.toString().startsWith('image/')) {
      this.messageDialog.showMessage('That file type is not allowed.  Please select an image file.');
    } else {

      const fileReader = new FileReader();

      fileReader.onload = (event) => {

        const imageElement = <HTMLImageElement>document.getElementById('previewImage');
        this.previewImage = fileReader.result;
        imageElement.addEventListener('load', (e) => {
          if (imageElement.naturalWidth < this.effectiveMinWidth) {
            const error = `Oops...that image has a width of ${imageElement.naturalWidth} pixels, but must be at least ${this.effectiveMinWidth} pixels wide.`;
            this.messageDialog.showMessage(error);
          } else if (imageElement.naturalHeight < this.effectiveMinHeight) {
            const error = `Oops...that image has a height of ${imageElement.naturalHeight} pixels, but must be at least ${this.effectiveMinHeight} pixels high.`;
            this.messageDialog.showMessage(error);
          } else if (this.selectedAssetType === 'brand' && imageElement.naturalWidth !== imageElement.naturalHeight) {
            const error = `Oops...that image is not square and brand icons have to be square`;
            this.messageDialog.showMessage(error);
          } else {
            this.loadedImageValid = true;
          }
        });
        this.loadedFile = file;
        this.imagePreviewOn = true;
        this.selectionOn = false;
      };
      fileReader.readAsDataURL(file);
    }
  }

  nextStepForVideo() {
    this.videoPreviewOn = true;
  }

  onAssetTypeChanged() {
    if (this.searchCriteria) {
      this.searchCriteria.clearFilters();
      this.searchCriteria.addFilter('asset_type', this.selectedAssetType);
    }

    this.resetSelectableMediaAssets();
    this.manageVideoFormControls();
  }

  onVideoUrlFocus() {
    if (this.selectedAsset) {
      this.selectedAsset = undefined;
      this.displayName = undefined;
      this.assetId = undefined;
      this.altText = undefined;
    }
  }

  onVideoUrlBlur(): void {
    this.mediaAssetGroup.controls['video_url'].setValue(VideoUtils.forceHttps(this.mediaAssetGroup.controls['video_url'].value));
    this.mediaAssetGroup.controls['video_url'].updateValueAndValidity();
  }

  onScrollDown() {
    this.offset += this.limit;
    this.getSelectableMediaAssets();
  }

  previewSelection() {
    this.selectionPreview = true;
    this.selectionOn = false;
    if (this.selectedAsset.asset_type === 'video') {
      this.videoPreviewOn = true;
      this.videoUrl = this.selectedAsset.url;
    } else {
      const imageElement = <HTMLImageElement>document.getElementById('previewImage');
      imageElement.src = this.selectedAsset.s3_url;
      this.imagePreviewOn = true;
    }
  }

  resetState() {
    this.loadedFile = undefined;
    this.displayName = undefined;
    this.altText = undefined;
    this.loadedImageValid = false;
    this.imagePreviewOn = false;
    this.selectionOn = false;
    this.uploadStarted = false;
    this.uploadComplete = false;
    this.changeDetectorRef.detectChanges();
  }

  save() {
    if (this.isValid) {
      if (this.selectedAssetType === 'video') {
        this.saveVideo();
      } else {
        this.saveImageOrBrand();
      }
    }
  }

  saveAborted() {
    this.uploadStarted = false;
  }

  saveComplete(uploadObj: Object) {
    this.uploadComplete = true;
    // todo - this is put in to be safe. caused by a backend bug: https://rn-jira.atlassian.net/browse/DV-2884
    const resp = uploadObj['response'];
    const url = resp ? AwsUtils.addHostName(resp['s3_url']) : 'undefined';
    this.uploadedMediaAsset.s3_url = url;
    this.uploadedMediaAsset.updated_at = new Date(); // For cache busting purposes
    this.onSave.emit(this.uploadedMediaAsset);
  }

  selectExistingAsset(selectedAsset: MediaAsset) {
    this.selectedAsset = selectedAsset;
    this.mediaAssetGroup.get('selected_asset_type').setValue(selectedAsset.asset_type);
    this.mediaAssetGroup.get('alternate_text').setValue(selectedAsset.alternate_text);
    this.mediaAssetGroup.get('short_name').setValue(selectedAsset.shortname);
    this.mediaAssetGroup.get('user_asset_id').setValue(selectedAsset.asset_id);
    this.mediaAssetGroup.get('video_url').setValue(selectedAsset.url);
    this.mediaAssetGroup.get('branding_color').setValue(selectedAsset.branding_color);
    CustomValidators.markAllAsUntouched(this.mediaAssetGroup);
  }

  selectLocalFile(event) {
    if (event.target['files'].length > 0) {
      const file = event.target['files'][0];
      this.loadImage(file);
      event.target.value = '';

      // Clear the display name and asset ID of an existing asset that was selected.
      // But don't clear them if the user entered them.
      if (this.selectionOn && this.selectedAsset) {
        this.displayName = undefined;
        this.assetId = undefined;
        this.altText = undefined;
      }
      this.selectedAsset = undefined;
      this.changeDetectorRef.detectChanges();
    }
  }

  submitSearchRequest() {
    if (this.searchText) {
      this.selectedAsset = undefined;
      this.searchCriteria = new SearchCriteria();
      this.searchCriteria.searchPhrase = this.searchText;
      this.searchCriteria.searchFields = ['shortname', 'asset_id', 'description', 'created_by'];
      this.searchCriteria.addFilter('asset_type', this.selectedAssetType);
      this.resetSelectableMediaAssets();
    }
  }

  turnOnSelection() {
    this.selectionOn = true;
    this.loadedFile = undefined;
    this.loadedImageValid = false;
    this.imagePreviewOn = false;
    this.getSelectableMediaAssets();
  }

  private resetSelectableMediaAssets() {
    if (this.canSelect && this.selectionOn) {
      this.selectableMediaAssets = [];
      this.offset = 0;
      this.getSelectableMediaAssets();
    }
  }

  private manageVideoFormControls() {
    if (this.selectedAssetType === 'video') {
      this.mediaAssetGroup.controls['video_url'].enable();
    } else {
      this.mediaAssetGroup.controls['video_url'].disable();
    }
  }

  private get effectiveMinWidth() {
    let effectiveMinWidth = 0;
    if (this.minWidth) {
      effectiveMinWidth = this.minWidth;
    } else if (this.selectedAssetType === 'brand') {
      effectiveMinWidth = 95;
    } else if (this.selectedAssetType === 'banner') {
      effectiveMinWidth = 1200;
    }
    return effectiveMinWidth;
  }

  private get effectiveMinHeight() {
    let effectiveMinHeight = 0;
    if (this.minHeight) {
      effectiveMinHeight = this.minHeight;
    } else {
      if (this.selectedAssetType === 'brand') {
        effectiveMinHeight = 95;
      }
    }
    return effectiveMinHeight;
  }

  private ensureAssetIdDefined() {
    if (!this.assetId) {
      this.assetId = GuidUtils.createRandomGuid();
    }
  }

  private saveImageOrBrand() {
    let url = this.mediaService.getUploadUrl(this.clientId);
    if (this.assetToUpdate) {
      url = this.mediaService.getUpdateUrl(this.clientId, this.assetToUpdate.asset_id);
    }

    this.ensureAssetIdDefined();

    if (this.assetToUpdate) {
      this.uploadedMediaAsset = this.assetToUpdate;
    } else {
      this.uploadedMediaAsset = new MediaAsset();
      this.uploadedMediaAsset.asset_id = this.assetId;
      this.uploadedMediaAsset.asset_type = this.selectedAssetType;
      this.uploadedMediaAsset.created_by = this.sessionService.currentUser.email_address;
    }
    this.uploadedMediaAsset.shortname = this.displayName;
    this.uploadedMediaAsset.updated_by = this.uploadedMediaAsset.created_by;
    this.uploadedMediaAsset.file_size = this.loadedFile.size.toString();
    this.uploadedMediaAsset.alternate_text = this.altText;
    this.uploadedMediaAsset.branding_color = this.brandingColor;

    const json = JSON.stringify(this.uploadedMediaAsset.serialize(this.assetToUpdate));

    const formData = new FormData();
    formData.append('body', json);

    let method = 'POST';
    if (this.assetToUpdate) {
      method = 'PUT';
    }
    this.uploader.startUpload(url, this.loadedFile, formData, method);
    this.uploadStarted = true;
  }

  private saveVideo() {
    this.ensureAssetIdDefined();
    const videoAsset = new MediaAsset();
    videoAsset.asset_id = this.assetId;
    videoAsset.asset_type = this.selectedAssetType;
    videoAsset.shortname = this.displayName;
    videoAsset.created_by = this.sessionService.currentUser.email_address;
    videoAsset.updated_by = videoAsset.created_by;
    videoAsset.url = this.videoUrl;
    this.mediaService.createMediaAsset(videoAsset).subscribe(
      response => {
        videoAsset.updated_at = new Date();
        this.onSave.emit(videoAsset);
      },
      error => {
        this.messageDialog.showMessage('Oops...the media asset could not be created.');
      }
    );
  }
}
