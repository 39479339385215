import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ProductGroup, Client } from '@app/core/models/client';
import { ActionService } from '@app/cx-builder/message-builder/services/action.service';
import { Journey } from '@app/core/models/journey';
import { FormatUtils } from '@app/core/utils/format-utils';
import { URLUtils } from '@app/core/utils/url-utils';
import {
  TinyEditorConfig,
  TinyEditorService,
} from '@app/core/services/tiny-editor.service';
import { FeatureService } from '@app/core/services/feature.service';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import { ControlTagsService } from '@app/core/services/control-tags.service';
import { LoggerService } from '@app/core/services/logger.service';
import { Subscription } from 'rxjs';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';
import { CharacterLimits } from '@app/core/utils/characterLimits';
import { SessionService } from '@app/security/session.service';

@Component({
    selector: 'message-builder-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss'],
    standalone: false
})
export class ActionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() actionInput: any;
  @Input() i: number;

  // this value is the index of this action is possiblly moved up to
  // (equal -1 if this actian is primary or already at the top)
  @Input() nextMoveUpIndex: number;

  // this value is the index of this action is possiblly moved down to
  // (equal numberOfActions if this action is primary or already at the bottom)
  @Input() nextMoveDownIndex: number;

  @Input() numberOfActions: number;
  @Input() productGroup: ProductGroup;
  @Input() journey: Journey;
  @Input() dynamicInputs: string[];
  @Output() delete = new EventEmitter<{ action: any; index: any }>();
  @Output() moveUp = new EventEmitter<{ action: any; index: number }>();
  @Output() moveDown = new EventEmitter<{ action: any; index: number }>();
  currentClient: Client;

  tinyEditorConfig: TinyEditorConfig;
  featureFlags = FeatureFlags;
  twoWayTags: any[];
  mentionConfig = {
    mentionSelect: this.formatter,
  };

  @Input() formReference: UntypedFormGroup;
  formSub$: Subscription;

  protected readonly CharacterLimits = CharacterLimits;

  constructor(
    public controlTagsService: ControlTagsService,
    public twoWayService: TwoWaySharedService,
    public featureService: FeatureService,
    private actionService: ActionService,
    private tinyEditorService: TinyEditorService,
    public newFeed: NewFeedTransitionService,
    private sessionService: SessionService,
  ) {}

  get fdicEnabled(): boolean {
    return this.currentClient?.fdic_enabled;
  }

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    if (this.actionInput.type === 'two_way') {
      this.controlTagsService.getTags().subscribe(
        (tags) => {
          this.twoWayTags = tags;
        },
        (err) => {
          this.twoWayTags = [];
          LoggerService.log('ActionComponent', err);
        },
      );
    }

    this.initTinyEditorConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /**
     * Each action gets a reference to the relevant
     * FormGroup and listens to the value change.
     *
     * Also unsubscribe the previous subscriber if any
     */
    if (this.formSub$) {
      this.formSub$.unsubscribe();
    }
    this.formSub$ = this.formReference.valueChanges.subscribe((res) => {
      this.actionInput = Object.assign(this.actionInput, res);
    });
  }
  ngOnDestroy(): void {
    this.formSub$.unsubscribe();
  }

  deleteAction(): void {
    this.delete.emit({ action: this.actionInput, index: this.i });
  }

  moveActionUp(): void {
    this.moveUp.emit({ action: this.actionInput, index: this.i });
  }

  moveActionDown(): void {
    this.moveDown.emit({ action: this.actionInput, index: this.i });
  }

  get labelHeader(): string {
    let description = this.actionInput.description;
    if (
      this.currentClient?.feed_enabled &&
      this.actionInput.type === 'two_way'
    ) {
      description = 'Relay Messenger';
    } else if (
      !this.currentClient?.feed_enabled &&
      this.actionInput.type === 'two_way'
    ) {
      description = 'Two Way Message';
    }
    return (
      (this.newFeed.enabled
        ? this.actionInput.primary
          ? 'Primary '
          : 'Secondary '
        : '') +
      'Action Element - ' +
      description
    );
  }
  get canMoveUp(): boolean {
    return this.nextMoveUpIndex > -1;
  }

  get canMoveDown(): boolean {
    return this.nextMoveDownIndex < this.numberOfActions;
  }

  // only relevant for 'label = "hyperlink"' actions
  get openInNewTabFrmCtl(): UntypedFormControl {
    return this.formReference.controls['open_in_new_tab'] as UntypedFormControl;
  }
  // only relevant for 'label = "hyperlink"' actions and currentClient.fdic_enabled
  get nonDepositPopUpEnabledCtl(): UntypedFormControl {
    return this.formReference.controls[
      'nonDepositPopUpEnabled'
    ] as UntypedFormControl;
  }

  // only relevant for "form_info_capture" actions
  get openWithinMessageFrmCtl(): UntypedFormControl {
    return this.formReference.controls[
      'openWithinMessage'
    ] as UntypedFormControl;
  }
  // only relevant for "consent_upgrade" actions
  get openConsentTCsFrmCtl(): UntypedFormControl {
    return this.formReference.controls['openConsentTCs'] as UntypedFormControl;
  }

  getTooltipData(errors: Object): string | void {
    return this.actionService.getTooltipData(errors);
  }

  getItems(value): string[] {
    let i = this.dynamicInputs;
    value = this.removeAutoCompleteSpans(value);
    if (value && value.length && value[0] !== '@') {
      i = [];
    }
    return i;
  }

  getPrimarySecondaryCharLimit(actionInput) {
    return actionInput.primary
      ? CharacterLimits.ActionLabelCharLimitPrimary
      : CharacterLimits.ActionLabelCharLimitSecondary;
  }

  bindNickname(action: any) {
    const actionMsg = this.journey.draft.components.find(
      (c) => c['name'] === action['value'],
    );
    actionMsg.nickname = action.label;
  }

  formatUrl(action: AbstractControl) {
    const value = action.value;
    if (value) {
      action.patchValue(URLUtils.formatMessageURL(value));
    }
  }

  formatPhoneNumber(action: AbstractControl) {
    const value = action.value;
    // If the value looks like it could be a phone number (has at least 10 digits) and
    // doesn't look like a dynamic input (contain an @), then attempt to format it as a phone number.
    if (!(value.indexOf('@') === 0) && value.replace(/\D/g, '').length >= 10) {
      action.patchValue(FormatUtils.formatPhoneNumber(value));
    }
  }

  formatter(mentionInfo: { label: string }) {
    return `@{${mentionInfo.label}} `;
  }

  private removeAutoCompleteSpans(text): string {
    return this.actionService.removeAutoCompleteSpans(text);
  }

  private initTinyEditorConfig() {
    this.tinyEditorConfig = new TinyEditorConfig();
    this.tinyEditorConfig.includeMentionsAutoComplete = true;
    this.tinyEditorConfig.includeAccountMenu = true;
    this.tinyEditorConfig.includeInputsMenu = true;
    this.tinyEditorConfig.accountMenuList =
      this.tinyEditorService.accountMenuList;
    this.tinyEditorConfig.inputsMenuList =
      this.tinyEditorService.inputsMenuList;
  }
}
