import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '@app/core/models/customer';
import { TitleService } from '@app/core/services/title.service';
import { CustomerService } from '@app/core/services/customer.service';
import { ScrollConstants } from '@app/core/utils/scroll-constants';
import { take } from 'rxjs/operators';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss'],
    standalone: false
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
  customer: Customer;
  customerDetails: any;
  customerId: string;
  customerMessages: any[];
  customerNotifications: any[];
  messageDetails = [];
  notificationDetails = [];

  error: string;
  isError: boolean = false;

  offset = 0;
  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;

  constructor(private titleService: TitleService,
              private activatedRoute: ActivatedRoute,
              private customerService: CustomerService,
              public newFeed: NewFeedTransitionService) {
  }

  ngOnInit() {
    this.titleService.activate('Customer Details');
    this.customerId = this.activatedRoute.snapshot.params['customerId'];
    this.getCustomerDetails();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  onMessageRowClick(messageId: string): void {
    if (this.messageDetails.indexOf(messageId) > -1) {
      this.messageDetails.splice(this.messageDetails.indexOf(messageId), 1);
    } else {
      this.messageDetails.push(messageId);
    }
  }

  onNotificationRowClick(notificationId: string): void {
    if (this.notificationDetails.indexOf(notificationId) > -1) {
      this.notificationDetails.splice(this.notificationDetails.indexOf(notificationId), 1);
    } else {
      this.notificationDetails.push(notificationId);
    }
  }

  private getCustomerDetails() {
    this.customerDetails = undefined;
    this.error = '';
    this.isError = false;
    this.customerService.getCustomerDetail(this.customerId)
      .pipe(take(1))
      .subscribe(
        (customerInfo: { 'customer': Customer, 'customer_details': any }) => {
          this.customer = customerInfo.customer;
          this.customerDetails = customerInfo.customer_details;
          this.getCustomerMessages();
        },
        (err: any) => {
          if (err instanceof Response) {
            this.error = err['error'];
          } else {
            this.error = err.toString();
          }
          this.isError = true;
        }
      );
  }

  private getCustomerMessages() {
    this.customerService.getCustomerMessaging(this.customer.ccid, this.limit, this.offset)
      .pipe(take(1))
      .subscribe(
      ([customerMessages, customerNotifications]: [any[], any[]]) => {
        this.customerMessages = customerMessages;
        this.customerNotifications = customerNotifications;
      },
      (err) => {
        this.error = err.toString();
        this.isError = true;
      }
    );
  }
}
