import { MessageDelayerClass } from '../../core/models/message';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'delay',
    templateUrl: './delay.component.html',
    styleUrls: ['./delay.component.scss'],
    standalone: false
})
export class DelayComponent implements OnInit {

  @Input() delay: MessageDelayerClass = new MessageDelayerClass();
  
  constructor() { }

  ngOnInit(): void {
  }
}
