import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '@app/core/services/client.service';
import { Client } from '@app/core/models/client';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { URLUtils } from '@app/core/utils/url-utils';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';

export interface IHelpSiteList {
  filename: string,
  filename_no_extension: string,
  last_modified: string | Date,
  url: string
}

@Component({
    selector: 'rn-client-helpsite',
    templateUrl: './client-helpsite.component.html',
    styleUrls: ['./client-helpsite.component.scss'],
    standalone: false
})
export class ClientHelpsiteComponent implements OnInit {
  @ViewChild(MessageDialogComponent, {static: true}) messageDialog: MessageDialogComponent;

  @Input() client: Client;
  clientWireBaseUrl: string = '';
  helpsiteList: IHelpSiteList[];
  showUpload = false;

  validNames = ['index.html', 'privacy.html', 'terms.html']
  fileNameToTypeMapping = {
    'index.html': 'help',
    'privacy.html': 'privacy',
    'terms.html': 'terms',
  }
  showTemplateForm: boolean = false;

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.getHelpsites();
    this.clientWireBaseUrl = `${URLUtils.getWireURLAllEnvironments(environment.environment)}/c/${this.client.id}`;
  }

  handleDrop(file: File): void {
    if (!this.validateHelpSite(file)) {
      this.messageDialog.showMessage(`Filename must be one of the following: ${this.validNames.join(', ')}`);
      return;
    }
    this.uploadFile(file);
  }

  handleSaveFromTemplateClicked(html: string) {
    const file = new File([html], 'index.html', {type: "text/html", lastModified: Date.now()});
    this.uploadFile(file);
    this.hideTemplate();
  }

  private uploadFile(file: File) {
    this.clientService.uploadHelpSites(this.client.id, file, file.name)
      .subscribe(
        () => {
          this.messageDialog.showMessage(`Successfully uploaded ${file.name}`);
          this.getHelpsites()
        },
        (err) => this.messageDialog.showMessage(`There was an error uploading helpsites. ${err?.error?.reason}`)
      );
  }

  showUploadScreen(): void {
    this.showUpload = true;
    this.showTemplateForm = false;
  }

  showTemplate() {
    this.showTemplateForm = true;
    this.showUpload = false;
  }

  hideTemplate() {
    this.showTemplateForm = false;
  }

  downloadFile(filenameNoExtension: string) {
    this.clientService.getHelpSite(this.client.id, filenameNoExtension)
      .pipe(take(1))
      .subscribe((result) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(result.content));
        element.setAttribute('download', filenameNoExtension);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      });
  }

  private getHelpsites(): void {
    this.clientService.getHelpSites(this.client.id).pipe(take(1)).subscribe(
      (helpSiteList: IHelpSiteList[]) => {
        this.helpsiteList = helpSiteList;
        this.helpsiteList.map(helpsite => {
          helpsite.url = this.getPreviewSiteURL(helpsite);
          helpsite.filename_no_extension = helpsite.filename.split('.')[0];
        });
      },
      () => this.messageDialog.showMessage('There was an error getting helpsites.')
    );
  }

  private validateHelpSite(file: File): boolean {
    const validTypes = ['text/html'];
    return !(!validTypes.includes(file.type) || !this.validNames.includes(file.name));
  }

  private getPreviewSiteURL(page: IHelpSiteList): string {
    const mappedType = this.fileNameToTypeMapping[page.filename];
    if (mappedType) {
      return `${this.clientWireBaseUrl}/${mappedType}`;
    } else {
      return '';
    }
  }
}
