<div class="intro-line-1" i18n="product group spanish translations title">
  Spanish Translations
</div>

<ng-content *ngIf="form">
  <form (ngSubmit)="onSave()" [formGroup]="form">
    <client-header
      [headerLabel]="'Product Group Information'"
      [headerGroup]="'productGroupInfo'"
      (click)="headerGroups.productGroupInfo = !headerGroups.productGroupInfo"
    >
    </client-header>
    <div class="collapse-container" *ngIf="headerGroups.productGroupInfo">
      <rn-text-input
        [inputLabel]="'Product group feed display name'"
        [inputControl]="form.controls['name']"
        [placeholderText]="'Product group feed display name'"
        [isWide]="true"
        [maxLength]="getCharacterLimit('feedDisplayName')"
      >
        <rn-input-info-box
          input-info-box
          [svgHeight]="60"
          [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
          [text]="'Enter Spanish display name, only if different from existing product group display name.'"
        ></rn-input-info-box>
      </rn-text-input>

      <div class="input-container" style="display: flex">
        <div class="input">
          <label i18n="Label for product description"
            >Product description</label
          >
          <rn-form-input-state-control
            [formReference]="form.controls['description']"
            [showCharacterCount]="true"
            [totalLength]="getCharacterLimit('description')"
          >
            <ng-container>
              <textarea
                id="product-description"
                placeholder="Add a brief description here"
                id="product-description-input"
                i18n-placeholder="Placeholder text for product description"
                spellcheck="false"
                [formControl]="form.controls['description']"
              ></textarea>
            </ng-container>
          </rn-form-input-state-control>
        </div>
      </div>
    </div>
    <ng-container *ngIf="this?.client?.fdic_enabled">
      <client-header
        [headerLabel]="'FDIC Signage'"
        [headerGroup]="'fdic'"
        (click)="headerGroups.fdic = !headerGroups.fdic"
      >
      </client-header>
      <div class="collapse-container" *ngIf="headerGroups.fdic">
        <div class="input-container" style="display: flex">
          <div class="input">
            <label i18n="Label for non-deposit language"
              >Non-deposit language</label
            >
            <rn-form-input-state-control
              [formReference]="form.controls['fdicNonDepositLanguage']"
              [showCharacterCount]="true"
              [totalLength]="getCharacterLimit('fdicNonDepositLanguage')"
            >
              <ng-container >
                <textarea
                  [placeholder]="nonDepositLanguageSpanish"
                  id="non-deposit-language-input"
                  spellcheck="false"
                  (blur)="handleNonDepositLanguageFocusOut()"
                  [formControl]="form.controls['fdicNonDepositLanguage']"
                ></textarea>
              </ng-container>
            </rn-form-input-state-control>
          </div>
        </div>
      </div>
    </ng-container>

    <client-header
      [headerLabel]="'Disclaimer'"
      [headerGroup]="'disclaimer'"
      (click)="headerGroups.disclaimer = !headerGroups.disclaimer"
    ></client-header>
    <div class="collapse-container" *ngIf="headerGroups.disclaimer">
      <div class="input-container">
        <div class="input">
          <label i18n="Disclaimer that appears at end of every message/feed"
            >Disclaimer</label
          >

          <div style="display: flex">
            <rn-form-input-state-control
              [formReference]="form.controls['disclaimer']"
              [showCharacterCount]="true"
              [totalLength]="getCharacterLimit('disclaimer')"
            >
              <ng-container>
                <app-tiny-editor
                  class="tiny-editor-mentions"
                  [elementId]="'tiny-editor-disclaimer'"
                  [formControl]="form.controls['disclaimer']"
                >
                </app-tiny-editor>
              </ng-container>
            </rn-form-input-state-control>
          </div>
        </div>
      </div>
    </div>

    <client-header
      [headerLabel]="'Terms & Conditions'"
      [headerGroup]="'tnc'"
      (click)="headerGroups.tnc = !headerGroups.tnc"
    ></client-header>
    <div class="collapse-container" *ngIf="headerGroups.tnc">
      <div style="display: flex">
        <div class="input-container" style="flex: 1">
          <div class="input">
            <label>Express Consent Ts&Cs</label>

            <div style="display: flex">
              <rn-form-input-state-control
                [formReference]="form.controls['expressConsentTC']"
                [showCharacterCount]="true"
                [totalLength]="getCharacterLimit('expressConsentTsCs')"
              >
                <ng-container>
                  <app-tiny-editor
                    class="tiny-editor-mentions"
                    [elementId]="'tiny-editor-expressConsentTC'"
                    [formControl]="form.controls['expressConsentTC']"
                  >
                  </app-tiny-editor>
                </ng-container>
              </rn-form-input-state-control>
            </div>
          </div>
        </div>
        <div class="input-container" style="flex: 1">
          <div class="input">
            <label>Express Written Consent Ts&Cs</label>

            <div style="display: flex">
              <rn-form-input-state-control
                [formReference]="form.controls['expressWrittenConsentTC']"
                [showCharacterCount]="true"
                [totalLength]="getCharacterLimit('expressWrittenConsentTsCs')"
              >
                <ng-container>
                  <app-tiny-editor
                    class="tiny-editor-mentions"
                    [elementId]="'tiny-editor-expressWrittenConsentTC'"
                    [formControl]="form.controls['expressWrittenConsentTC']"
                  >
                  </app-tiny-editor>
                </ng-container>
              </rn-form-input-state-control>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex">
        <div class="input-container" style="flex: 1">
          <div class="input">
            <label>In Feed Consent Upgrade Ts&Cs</label>

            <div style="display: flex">
              <rn-form-input-state-control
                [formReference]="form.controls['inFeedConsentUpgradeTC']"
                [showCharacterCount]="true"
                [totalLength]="getCharacterLimit('inFeedConsentUpgradeTsCs')"
              >
                <ng-container>
                  <app-tiny-editor
                    class="tiny-editor-mentions"
                    [elementId]="'tiny-editor-inFeedConsentUpgradeTC'"
                    [formControl]="form.controls['inFeedConsentUpgradeTC']"
                  >
                  </app-tiny-editor>
                </ng-container>
              </rn-form-input-state-control>
            </div>
          </div>
        </div>
        <div
          class="input-container"
          style="flex: 1"
          *ngIf="this?.productGroup?.password?.tc_enabled"
        >
          <div class="input">
            <label>Password Page Ts&Cs</label>

            <div style="display: flex">
              <rn-form-input-state-control
                [formReference]="form.controls['passwordPageTC']"
                [showCharacterCount]="true"
                [totalLength]="getCharacterLimit('passwordTsCs')"
              >
                <ng-container>
                  <app-tiny-editor
                    class="tiny-editor-mentions"
                    [elementId]="'tiny-editor-passwordPageTC'"
                    [formControl]="form.controls['passwordPageTC']"
                  >
                  </app-tiny-editor>
                </ng-container>
              </rn-form-input-state-control>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="productGroup.onboarding.sign_up_page.enabled === 'true'"
    >
      <client-header
        [headerLabel]="'Signup Page'"
        [headerGroup]="'signup'"
        (click)="headerGroups.signup = !headerGroups.signup"
      ></client-header>
      <div
        class="collapse-container"
        *ngIf="headerGroups.signup && client.validation.fields"
      >
        <rn-sign-up-form
          [client]="client"
          [productGroup]="productGroup"
          [express_written_consent_ts_cs]="this.form.get('expressWrittenConsentTC').value"
          [signUpGroup]="form.controls['signup']"
        ></rn-sign-up-form>
        <generic-phone class="generic-phone" [overflowY]="'auto'">
          <sign-up-page-preview-react
            [messageText]="this.form.get('signup.message_text').value || productGroup.onboarding.sign_up_page?.message_text"
            [ccidInputLabel]=" this.form.get('signup.ccid_input_label').value ||
        productGroup.onboarding.sign_up_page?.ccid_input_label
      "
            [mobileInputLabel]="this.form.get('signup.mobile_input_label').value ||
        productGroup.onboarding.sign_up_page?.mobile_input_label
      "
            [validationFields]="validationFields"
            [logoUrl]="productGroup.branding.icon_s3_url"
            [bannerUrl]="productGroup.branding.banner_s3_url"
            [color]="productGroup.branding.color"
            [productGroupName]="this.form.get('name').value || productGroup.name"
            [showProductGroup]="!productGroup.hide_pg_wire_display_name"
            [productGroupDescription]="this.form.get('description').value || productGroup.description"
            [expressWrittenConsentTsCs]="this.form.get('expressWrittenConsentTC').value ||
        productGroup.consent?.express_written_consent_ts_cs
      "
            [submitBtnLabel]="this.form.get('signup.submit_button_label').value ||
        productGroup.onboarding.sign_up_page?.submit_button_label
      "
            [termsAndConditionsText]="this.form.get('signup.terms_and_conditions_text').value ||
        productGroup.onboarding.sign_up_page?.terms_and_conditions_text"
            [termsAndConditionsValidationError]="this.form.get('signup.terms_and_conditions_validation_error').value ||
        productGroup.onboarding.sign_up_page?.terms_and_conditions_validation_error"
            [showBranding]="productGroup.onboarding.sign_up_page.display_branding"
            [hideProductGroupWireDisplayName]="productGroup.hide_pg_wire_display_name"
            [showFdic]="productGroup.fdic.show_signage_on_authentication"
            [fdic]="productGroup.fdic"
          ></sign-up-page-preview-react>
        </generic-phone>
      </div>
    </ng-container>

    <ng-container
      *ngIf="!!productGroup && !!client && client.verification.enabled === 'true'"
    >
      <client-header
        [headerLabel]="'Verification'"
        [headerGroup]="'verification'"
        (click)="headerGroups.verification = !headerGroups.verification"
      >
      </client-header>

      <div
        class="collapse-container"
        *ngIf="headerGroups.verification && client.validation.fields"
      >
        <div class="label-tip">
          <label>Verification Fields</label>
        </div>

        <div formGroupName="verificationFields">
          <div class="validation-fields">
            <span class="validation-field-value">first_name</span>
            <span
              class="validation-field-value"
              [class.has-error]="!form.get('verificationFields.first_name').valid"
            >
              <div class="tooltip-container">
                <input
                  type="text"
                  name="prompt-first_name"
                  formControlName="first_name"
                />
              </div>
            </span>
          </div>

          <div class="validation-fields">
            <span class="validation-field-value">last_name</span>
            <span
              class="validation-field-value"
              [class.has-error]="!form.get('verificationFields.last_name').valid"
            >
              <div class="tooltip-container">
                <input
                  type="text"
                  name="prompt-last_name"
                  formControlName="last_name"
                />
              </div>
            </span>
          </div>

          <div class="validation-fields">
            <span class="validation-field-value">ssn_last4</span>
            <span
              class="validation-field-value"
              [class.has-error]="!form.get('verificationFields.ssn_last4').valid"
            >
              <div class="tooltip-container">
                <input
                  type="text"
                  name="prompt-ssn_last4"
                  formControlName="ssn_last4"
                />
              </div>
            </span>
          </div>

          <div class="validation-fields">
            <span class="validation-field-value">date_of_birth</span>
            <span
              class="validation-field-value"
              [class.has-error]="!form.get('verificationFields.date_of_birth').valid"
            >
              <div class="tooltip-container">
                <input
                  type="text"
                  name="prompt-date_of_birth"
                  formControlName="date_of_birth"
                />
              </div>
            </span>
          </div>

          <div class="validation-fields">
            <span class="validation-field-value">postal_code</span>
            <span
              class="validation-field-value"
              [class.has-error]="!form.get('verificationFields.postal_code').valid"
            >
              <div class="tooltip-container">
                <input
                  type="text"
                  name="prompt-postal_code"
                  formControlName="postal_code"
                />
                <div class="icon-container">
                  <i
                    class="fa fa-question-circle icon-color"
                    aria-hidden="true"
                    (mouseover)="showTooltip = true"
                    (mouseout)="showTooltip = false"
                  ></i>
                </div>
                <div *ngIf="showTooltip" class="tooltip">
                  <p>
                    Verification by postal code will only verify the first 5
                    digits if the customer has a 9 digit postal zip on file.
                  </p>
                </div>
              </div>
            </span>
          </div>

          <rn-text-input
            [inputLabel]="'Header'"
            [inputControl]="this.form.get('verificationFields.header')"
            [placeholderText]="'Account Confirmation'"
            [isWide]="true"
          >
            <rn-input-info-box
              input-info-box
              [svgHeight]="40"
              [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
              [text]="'Header for account verification. Will default to:<br>Account Confirmation'"
            ></rn-input-info-box>
          </rn-text-input>

          <div class="input-container">
            <div class="input">
              <div class="label-tip">
                <label>Verification Message</label>
              </div>

              <app-tiny-editor
                class="tiny-editor-mentions"
                [elementId]="'tiny-editor-express-consent'"
                [formControl]="this.form.get('verificationFields.body')"
              >
              </app-tiny-editor>

              <p class="tip-text">
                <svg width="15" height="80" style="float: left">
                  <polyline
                    points="8,0 8,24, 2,30 8,36 8,80"
                    style="fill: none; stroke: #5b5a59; stroke-width: 1"
                  />
                </svg>
                <span
                  >Text to show user. Will default to:<br />Please enter the
                  information below to confirm your account and access your
                  messages.</span
                >
              </p>
            </div>
          </div>

          <rn-text-input
            [inputLabel]="'Button Label'"
            [inputControl]="this.form.get('verificationFields.button_label')"
            [placeholderText]="'Submit'"
            [isWide]="true"
          >
            <rn-input-info-box
              input-info-box
              [svgHeight]="40"
              [svgPosition]="'8,0 8,24, 2,30 8,36 8,80'"
              [text]="'Button for account verification. Will default to:<br>Submit'"
            ></rn-input-info-box>
          </rn-text-input>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="productGroup.password.enabled">
      <client-header
        [headerLabel]="'Password Page'"
        [headerGroup]="'password'"
        (click)="headerGroups.password = !headerGroups.password"
      ></client-header>
      <div class="collapse-container" style="height:800px;" *ngIf="headerGroups.password">
        
          <div class="messaging-panel-signup arrow-left-center" >
            <div class="card panel-default">
              <div class="card-header">
                <div class="col-sm-12">Password Page Content</div>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <div>
                    <label>Set Password Text</label>
                  </div>
                  <div style="display: flex">
                    <rn-form-input-state-control
                      [formReference]="form.get('password.text')"
                      [isError]="passwordHasTextError()"
                      [showCharacterCount]="this.client.feed_enabled"
                      [totalLength]="getCharacterLimit('setPasswordText')"
                    >
                      <ng-container>
                        <app-tiny-editor
                          class="tiny-editor-mentions"
                          [elementId]="'tiny-editor-password-text'"
                          [formControl]="form.get('password.text')"
                        ></app-tiny-editor>
                      </ng-container>
                    </rn-form-input-state-control>
                  </div>
                </div>

                <div class="form-group">
                  <div>
                    <label>Submit button label</label>
                  </div>
                  <div style="display: flex">
                    <rn-form-input-state-control
                      [formReference]="form.get('password.label')"
                      [showCharacterCount]="this.client.feed_enabled"
                      [totalLength]="
                      getCharacterLimit('setPasswordSubmitButtonLabel')
                    "
                    >
                      <ng-container>
                        <input
                          name="button_label"
                          class="form-control button_label"
                          [formControl]="form.get('password.label')"
                        />
                      </ng-container>
                    </rn-form-input-state-control>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <generic-phone class="generic-phone" [overflowY]="'auto'">
            <set-password-page-preview-react
              [pswMessageText]="this.form.get('password.text').value || productGroup.password.text"
              [pswButtonLabel]="this.form.get('password.label').value || productGroup.password.label"
              [pswTCS]="this.form.get('passwordPageTC').value || productGroup.password.tcs"
              [pswTCMandatory]="productGroup.password.tc_mandatory"
              [logoUrl]="productGroup.branding.icon_s3_url"
              [color]="productGroup.branding.color"
              [productGroupName]="this.form.get('name').value || productGroup.name"
              [showProductGroup]="!productGroup.hide_pg_wire_display_name"
              [productGroupDescription]="this.form.get('description').value || productGroup.description"
              [showFdic]="productGroup.fdic.show_signage_on_authentication"
              [fdic]="productGroup.fdic"
              language="es"
            ></set-password-page-preview-react>
          </generic-phone>
       
      </div>
    </ng-container>

    <div class="button-container clearfix" *ngIf="productGroup">
      <button
        type="submit"
        id="next-btn"
        class="btn btn-primary btn-with-icon"
        [disabled]="form.invalid"
      >
        <span class="btn-label" i18n="Label for save button"> Finish </span>
        <span class="icon icon-right">
          <i class="fa fa-arrow-right"></i>
        </span>
      </button>
    </div>
  </form>
</ng-content>

<message-dialog></message-dialog>
