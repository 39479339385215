import { DelayUtils } from '../../delay-utils';
import { Journey } from '@app/core/models/journey';
import { ToolbarService } from '../../services/toolbar.service';
import { MessageDelayerClass } from '@app/core/models/message';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-delay-inspector',
    templateUrl: './delay-inspector.component.html',
    styleUrls: ['./delay-inspector.component.scss'],
    standalone: false
})

export class DelayInspectorComponent implements OnInit {
  delayUnits: number[];

  @Input() journeyId: string;
  @Input() journey: Journey;
  @Input() delayer: MessageDelayerClass;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(public toolbarService: ToolbarService) {}

  ngOnInit(): void {
    this.setUnitArray();

    // Updates to this delay's interval type might come from the journey-delay component
    this.toolbarService.delayIntervalTypeUpdate.subscribe(delayName => {
      if (this.delayer.name === delayName) {
        this.setUnitArray();
      }
    });
  }

  onIntervalTypeChange() {
    this.toolbarService.delayIntervalTypeUpdate.emit(this.delayer.name);
  }

  setUnitArray() {
    this.delayUnits = DelayUtils.allUnits[this.delayer.interval_type];

    // If the new delay units doesn't have the interval_value, use the last delay unit value.
    if (this.delayUnits.indexOf(this.delayer.interval_value) < 0) {
      this.delayer.interval_value = this.delayUnits[this.delayUnits.length - 1];
    }
  }
}
