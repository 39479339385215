import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, UntypedFormArray } from '@angular/forms';
import { ContactInfo, ProductGroup } from '@app/core/models/client';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { FormatUtils } from '@app/core/utils/format-utils';
import { ToolTipData } from '@app/core/utils/tooltip-utils';
import * as _ from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CharacterLimits } from '@app/core/utils/characterLimits';

@Component({
    selector: 'app-contact-info-inputs',
    templateUrl: './contact-info-inputs.component.html',
    styleUrls: ['./contact-info-inputs.component.scss'],
    standalone: false
})
export class ContactInfoInputsComponent implements OnInit {
  @Input() productGroup: ProductGroup;

  contactInfoForm: UntypedFormArray;

  constructor(public formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.contactInfoForm = new UntypedFormArray([]);
    _.forEach(this.productGroup.contact_us_info, (contactInfo: ContactInfo) => {
      this.contactInfoForm.push(new UntypedFormGroup({
        value: new UntypedFormControl(contactInfo.value, [Validators.required, this.getValidatorByType(contactInfo.type)]),
        display_text: new UntypedFormControl(contactInfo.display_text, [Validators.required, Validators.maxLength(CharacterLimits.ProductGroupCustomerContactsLabelCharLimit)]),
        type: new UntypedFormControl(contactInfo.type, [Validators.required])
      }));
    });
    this.contactInfoForm.valueChanges.subscribe(val => {
      this.productGroup.contact_us_info  = this.contactInfoForm.getRawValue();
    });
  }

  addContactInfo() {
    this.contactInfoForm.push(new UntypedFormGroup({
      value: new UntypedFormControl('', [Validators.required, this.getValidatorByType('telephone')]),
      display_text: new UntypedFormControl('', [Validators.required, Validators.maxLength(CharacterLimits.ProductGroupCustomerContactsLabelCharLimit)]),
      type: new UntypedFormControl('telephone', [Validators.required])
    }));
  }

  format(valueControl: UntypedFormControl, type) {
    if (type === "telephone") {
      if (valueControl.value) {
        let phoneNumber = valueControl.value.trim();
        if (CustomValidators.isPhoneNumberExtMatch(phoneNumber)) {
          valueControl.setValue(FormatUtils.formatPhoneNumber(phoneNumber));
        } else {
          phoneNumber = valueControl.value.replace(/\D/g, '');
          if (CustomValidators.isPhoneNumberMatch(phoneNumber)) {
            valueControl.setValue(FormatUtils.formatPhoneNumber(phoneNumber));
          }
        }
      } else {
        CustomValidators.markAsUntouchedOnEmpty(valueControl);
      }
    }

    valueControl.markAsTouched();
  }

  getValidatorByType(type: string): ValidatorFn {
    switch (type) {
      case 'telephone':
        return CustomValidators.phoneNumberOrExtToBeTrimmed;
      case 'website':
        return CustomValidators.validURLWithQuery;
      case 'email':
        return CustomValidators.emailToBeTrimmed;
      case 'twitter':
        return CustomValidators.twitter;
      default:
        return;
    }
  }

  changeFormControlValidators(rowGroup: UntypedFormGroup): void {
    const typeField: UntypedFormControl = rowGroup.get('type') as UntypedFormControl;
    const typeValidator = this.getValidatorByType(typeField.value);
    const valueFieldValidators = [Validators.required, typeValidator];
    
    const valueField: UntypedFormControl = rowGroup.get('value') as UntypedFormControl;
    valueField.clearValidators();
    valueField.setValidators(valueFieldValidators);
    valueField.updateValueAndValidity();
  }

  getPlaceholderByType(type: string) {
    switch (type) {
      case 'telephone': return 'telephone number';
      case 'website': return 'URL';
      case 'email': return 'email address';
      case 'twitter': return 'twitter username';
    }
  }

  isValid(formControl: UntypedFormControl) {
    return formControl.valid || formControl.untouched;
  }

  getTooltipData(errors: any) {
    return ToolTipData.getTooltipData(errors);
  }

  removeContactInfo(index: number) {
    this.contactInfoForm.removeAt(index);
  }

  dropElement(event: CdkDragDrop<UntypedFormGroup[]>) {	
    moveItemInArray(this.contactInfoForm.controls, event.previousIndex, event.currentIndex);
  }
}
