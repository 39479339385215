import React from 'react';
import { InputTitle, } from '@app/api-mgmt/components/push-api/styles/ConfigurationInputStyles';
import { useStyletron } from 'styletron-react';
import { OtherConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { Redirect } from '@app/api-mgmt/components/push-api/components/configurations-display/Redirect';
import { Button } from 'baseui/button';
import {
  ConfigurationFooter,
} from '@app/api-mgmt/components/push-api/styles/ConfigurationInputStyles';
import {
  ButtonOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import { Textarea } from 'baseui/textarea';

export const LegacyDisplay = ({
  config,
  onCancelClicked
}: {
  config: OtherConfiguration,
  onCancelClicked?: () => void,
}) => {
  const [css] = useStyletron();

  const cancel = () => {
    // callback to close the modal
    onCancelClicked();
  };

  return (
    <div
      className={css({
        margin: 'auto',
      })}
    >
      <Redirect
        link="https://docs.relaynetwork.com/docs/push-event-webhook"
        value="View Relay Integration Documentation"
      />

      <InputTitle>
        <label>Other Integration</label>
      </InputTitle>
      {/* <div style={{
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap'
      }}>
        {JSON.stringify(JSON.parse(config.payload), null, 3)}
      </div> */}
      <Textarea value={JSON.stringify(JSON.parse(config.payload))}/>

      <ConfigurationFooter>
        <div style={{ float: 'right' }}>
          <Button
          type="reset"
          onClick={cancel}
          overrides={{
            BaseButton: ButtonOverride,
          }}
          >
            <span>OK</span>
          </Button>
        </div>
      </ConfigurationFooter>
    </div>
  );
};
