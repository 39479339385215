// Angular Core Imports
import { Component, Input, OnInit } from '@angular/core';

// Data Models
import { ProductGroup } from '../../../../core/models/client';

@Component({
    selector: 'app-product-onboarding-file-password',
    templateUrl: './product-onboarding-file-password.component.html',
    styleUrls: ['./product-onboarding-file-password.component.scss'],
    standalone: false
})
export class ProductOnboardingFilePasswordComponent implements OnInit {
  @Input() product: ProductGroup;

  constructor() { }

  ngOnInit() {
  }

}
