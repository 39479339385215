import { SessionService } from '../session.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TwoWayConversationService } from '@app/core/services/two-way-conversation.service';
import { TimeOutLocalStorageService } from '@app/core/services/timeOutLocalStorage.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: false
})
export class LogoutComponent implements OnInit {
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private twoWayConversationService: TwoWayConversationService,
    private timeOutLocalStorageService: TimeOutLocalStorageService,
  ) {}

  ngOnInit() {
    this.timeOutLocalStorageService.deleteTimeoutValue();
    this.twoWayConversationService
      .shutdownChatClient()
      .then(() => {
        this.sessionService.logout();
        this.router.navigateByUrl('/login');
      })
      .catch(() => {
        this.twoWayConversationService.killChatClient();
        this.sessionService.logout();
        this.router.navigateByUrl('/login');
      });
  }
}
