import axios from 'axios';
import { ControlTag } from '@app/core/services/control-tags.service';
import { environment } from '@env/environment';
import {
  ConversationUpdatedEvent,
  MessageUpdatedEvent,
  ParticipantUpdatedEvent,
  TwilioConversation,
  TwoWayConversationClientEvents,
  TwoWayUnassignedChannel,
  TwoWayClosedChannel,
  UserUpdatedEvent,
  TwilioConversationAttribute,
} from '../../../twilio-conversation.types';
import { differenceInMinutes, format, isYesterday, parseISO } from 'date-fns';
import {
  Client,
  Conversation,
  JSONValue,
  Message,
  Paginator,
  Participant,
  User,
} from '@twilio/conversations';
import * as _ from 'lodash';
import { RelayMessengerAgent } from '../agent-modal/AgentModal';
import { UserInfo } from '../../context/UserInfoContext';
import { TimeOutLocalStorageService } from '@app/core/services/timeOutLocalStorage.service';
import { BusinessHoursResponse } from '@app/two-way/services/two-way-setup.service';

type TokenOutput = {
  jwt: string;
};
export type AgentStatusT = 'active' | 'inactive';

export type GetClientStatusOutput = {
  autoresponse: {
    type: 'BUSINESS_HOURS' | 'AFTER_HOURS' | 'OUT_OF_OFFICE';
    expires: string | null;
  };
};

type GetStatusOutput = {
  status: AgentStatusT;
};

type SetStatusOutput = {
  message: string;
};

export type GetAgentStatusesOutput = {
  userId: string;
  status: AgentStatusT;
};

export type QueryOptionUnassignedChannels = {
  dateCreatedFilterStartDate?: string;
  dateCreatedFilterEndDate?: string;
  lastModifiedFilterStartDate?: string;
  lastModifiedFilterEndDate?: string;
  dateCreatedSort?: 'newest' | 'oldest';
  lastModifiedSort?: 'newest' | 'oldest';
  nameSort?: 'ASC' | 'DESC';
};

export type SortDirection = 'backwards' | 'forward';

export interface MetadataBody {
  id: string;
  clientId: string;
  productGroupId: string;
  createdBy: string;
  channelStatus: string;
  journeyId: string;
  messageId: string;
  customer: {
    id: string;
    firstName: string;
    lastName: string;
  };
  portalId: string;
  lbName: string;
  lbSource: string;
  experienceName?: string;
  messageName?: string;
}

export type DecryptedMessages = {
  cypher: string;
  text?: string;
  version?: string;
};

export type MessageDecryptionResponse = {
  decryptedMessages?: DecryptedMessages[];
  message?: string;
};

export type BulkMessageDecryptionResponse = {
  decryptedChats: {
    decryptedMessages?: DecryptedMessages[];
    message?: string;
  }[];
};

export type ControlGroup = {
  client_id: string;
  created_at: string;
  group_name: string;
  id: string;
  updated_at: string;
};

type sortingConversationFn = (
  a: TwilioConversation,
  b: TwilioConversation,
) => number;

export type ChannelsFilterOptions = {
  tags: string[];
  launched_by: string[];
  customer_ids: string[];
};

export type ClosedChannelsFilterOptions = {
  tags: string[];
  customer_ids: string[];
};

export type AgentFullName = {
  firstName: string;
  lastName: string;
};

export type AgentListBody = {
  filterByAgentStatus?: AgentStatusT;
  filterByGroupId?: string;
  sortByName?: 'ASC' | 'DESC';
  searchByAgentName?: AgentFullName;
};

/**
 * Default sorting function for assigned conversations by last message's timestamp in DESC order
 */
const sortConversationDesc: sortingConversationFn = (a, b) => {
  return (
    b.lastMessage &&
    a.lastMessage &&
    b.lastMessage.dateCreated.getTime() - a.lastMessage.dateCreated.getTime()
  );
};

function buildUrl(
  clientId: string,
  limit: number,
  offset: number,
  queryOption?: QueryOptionUnassignedChannels,
): string {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  if (queryOption) {
    const {
      dateCreatedFilterStartDate,
      dateCreatedFilterEndDate,
      lastModifiedFilterStartDate,
      lastModifiedFilterEndDate,
      dateCreatedSort,
      lastModifiedSort,
      nameSort,
    } = queryOption;

    if (dateCreatedFilterStartDate && dateCreatedFilterStartDate.length !== 0)
      params.append('dateCreatedFilterStartDate', dateCreatedFilterStartDate);
    if (dateCreatedFilterEndDate && dateCreatedFilterEndDate.length !== 0)
      params.append('dateCreatedFilterEndDate', dateCreatedFilterEndDate);
    if (lastModifiedFilterStartDate && lastModifiedFilterStartDate.length !== 0)
      params.append('lastModifiedFilterStartDate', lastModifiedFilterStartDate);
    if (lastModifiedFilterEndDate && lastModifiedFilterEndDate.length !== 0)
      params.append('lastModifiedFilterEndDate', lastModifiedFilterEndDate);
    if (dateCreatedSort && dateCreatedSort.length !== 0)
      params.append('dateCreatedSort', dateCreatedSort);
    if (lastModifiedSort && lastModifiedSort.length !== 0)
      params.append('lastModifiedSort', lastModifiedSort);
    if (nameSort && nameSort.length !== 0) params.append('nameSort', nameSort);
  }
  const url = `/client/${clientId}/channels?${params.toString()}`;
  return url;
}

function buildClosedConversationsUrl(
  clientId: string,
  limit: number,
  offset: number,
  queryOption?: QueryOptionUnassignedChannels,
): string {
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  params.append('offset', offset.toString());

  if (queryOption) {
    const {
      dateCreatedFilterStartDate,
      dateCreatedFilterEndDate,
      lastModifiedFilterStartDate,
      lastModifiedFilterEndDate,
      dateCreatedSort,
      lastModifiedSort,
      nameSort,
    } = queryOption;

    if (dateCreatedFilterStartDate && dateCreatedFilterStartDate.length !== 0)
      params.append('dateCreatedFilterStartDate', dateCreatedFilterStartDate);
    if (dateCreatedFilterEndDate && dateCreatedFilterEndDate.length !== 0)
      params.append('dateCreatedFilterEndDate', dateCreatedFilterEndDate);
    if (lastModifiedFilterStartDate && lastModifiedFilterStartDate.length !== 0)
      params.append('lastModifiedFilterStartDate', lastModifiedFilterStartDate);
    if (lastModifiedFilterEndDate && lastModifiedFilterEndDate.length !== 0)
      params.append('lastModifiedFilterEndDate', lastModifiedFilterEndDate);
    if (dateCreatedSort && dateCreatedSort.length !== 0)
      params.append('dateCreatedSort', dateCreatedSort);
    if (lastModifiedSort && lastModifiedSort.length !== 0)
      params.append('lastModifiedSort', lastModifiedSort);
    if (nameSort && nameSort.length !== 0) params.append('nameSort', nameSort);
  }
  const url = `/client/${clientId}/closed-channels?${params.toString()}`;
  return url;
}

export const client = axios.create({
  baseURL: environment.twoWayURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  responseType: 'json',
});

const timeOutLocalStorageService = new TimeOutLocalStorageService();
client.interceptors.response.use(
  (response) => {
    const relayTimeout = response.headers['x-relay-timeout'];
    if (relayTimeout) {
      timeOutLocalStorageService.setTimeoutValue(parseInt(relayTimeout));
    }
    return response;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  },
);

let idCache: any = {};
let userInformationCache: any = {};

export const TwoWayService = {
  /***  Get Token
   *
   * Get the token for a client.
   * @param clientId the ID of the client to get the token.
   * @returns the jwt token
   */
  getToken: async (clientId) => {
    const url = `/client/${clientId}/agent/token`;
    try {
      const {
        data: { jwt },
      } = await client.post<TokenOutput>(url);
      return jwt;
    } catch (error) {
      console.error('Error getting token', error);
    }
  },

  /***  Get Client Status
   *
   * Get the current office hours status for a client.
   * @param clientId the ID of the client to get the agent status.
   * @returns the client autoresponse type and expiration
   */
  getClientStatus: async (clientId) => {
    const url = `/client/${clientId}/status`;
    try {
      const {
        data: { autoresponse },
      } = await client.get<GetClientStatusOutput>(url);
      return autoresponse;
    } catch (error) {
      console.error('error getting client status', error);
    }
  },
  /***  Get Business hours data
   *
   * Get the current office hours status for a client.
   * @param clientId the ID of the client to get the agent status.
   * @returns the client business hours config
   */
  getBusinessHours: async (clientId: string) => {
    const url = `/client/${clientId}/configs/businesshours`;
    try {
      const {
        data: { businessHours },
      } = await client.get<BusinessHoursResponse>(url);
      return businessHours;
    } catch (error) {
      console.error('error getting business hours data', error);
    }
  },

  /***  Get Agent Status
   *
   * Get the current status for an agent.
   * @param clientId the ID of the client to get the agent status.
   * @param agentId the ID of the agent to get the agent status.
   * @returns the agent status
   */
  getAgentStatus: async (clientId, agentId) => {
    const url = `/client/${clientId}/agent/status/${agentId}`;
    try {
      const {
        data: { status },
      } = await client.get<GetStatusOutput>(url);
      return status;
    } catch (error) {
      return 'inactive';
    }
  },

  /***  Set Agent Status
   *
   * Set the current status for an agent. Agents are considered "active" for 10 minutes since the last update of their status to active.
   * @param clientId the ID of the client to get the agent status.
   * @param agentId the ID of the agent to get the agent status.
   * @param action can be one of the following: message, ping, join, close, leave, assign, re-assign, remove, invite or logout. Message and ping reset an agent's 10-minute timer to display as active. Logout will result in an agent's status displaying as offline.
   * @returns ok for successful operation
   */
  setAgentStatus: async (clientId, agentId, action) => {
    const url = `/client/${clientId}/agent/status/${agentId}`;
    const reqBody = { activity: action };
    try {
      const {
        data: { message },
      } = await client.post<SetStatusOutput>(url, reqBody);
      return message;
    } catch (error) {
      console.error('Error setting agent status', error);
    }
  },

  /***  Get Agent Statuses
   *
   * Get the current status for each agent.
   * @param clientId the ID of the client to get the agent status.
   * @param agentIds the array of agent IDs.
   * @returns the agent statuses
   */
  getAgentStatuses: async (clientId: string, agentIds: string[]) => {
    const url = `/client/${clientId}/agent/statuses`;
    try {
      const { data } = await client.post<GetAgentStatusesOutput[]>(url, {
        userIds: agentIds,
      });
      return data;
    } catch (error) {
      console.error('Error getting agent statuses', error);
    }
  },

  /*** Get Two Way Agents by tagId
   * @param clientId the ID of the client.
   * @param tagId the tagId for current conversation.
   * @param searchPhrase optional search phrase parameter to filter agents by full name or email.
   * @returns agents
   */
  getAgentsByTag: async (
    clientId: string,
    tagId: string,
    searchPhrase?: string,
  ) => {
    // by default it will be sorted by full_name
    const url = `/client/${clientId}/agents?limit=${1000}&offset=${0}`;
    const { data } = await client.post<RelayMessengerAgent[]>(url, {
      tagId,
      searchPhrase,
    });
    return data;
  },

  /*** Get Two Way Agents for Client
   * @param clientId the ID of the client.
   * @param agentListBody the body for the agent list request.
   * @returns agents
   */
  getAgentsList: async (
    clientId: string,
    agentListBody: AgentListBody,
  ): Promise<RelayMessengerAgent[]> => {
    let url = `/client/${clientId}/agents-list`;
    // by default it will be sorted by full_name in ASC order
    const { data } = await client.post<RelayMessengerAgent[]>(
      url,
      agentListBody,
    );
    return data['agents'];
  },

  /*** Get Two Way Groups for Client
   * @param clientId the ID of the client.
   * @returns control groups
   */
  getGroups: async (clientId: string) => {
    const url = `/client/${clientId}/group`;
    try {
      const { data } = await client.get<ControlGroup[]>(url);
      return data['data'];
    } catch (error) {
      console.error('Error getting groups', error);
    }
  },

  /* Agent Tags */
  /**
   * Get the inital batch of tags.
   * @param url the url for the axios call to two-way-api.
   * @param limit the number of tags within the array.
   * @param offset the offset at which the api call was made, used to identify the batch of tags.
   * @returns an array of tags
   */
  getAgentTagBatch: async function (
    url: string,
    limit: number,
    offset: number,
  ): Promise<ControlTag[]> {
    const batchUrl = `${url}?limit=${limit}&offset=${offset}`;
    try {
      const { data } = await client.get<ControlTag[]>(batchUrl);
      return data['data'];
    } catch (error) {
      console.error('Error getting agent tags', error);
    }
  },

  /**
   * Get all the tags.
   * @param url the url for the axios call to two-way-api.
   * @param limit the number of tags within the array.
   * @param offset the offset at which the api call was made, used to identify the batch of tags.
   * @returns the total tags array.
   */
  getAgentTags: async function (
    url: string,
    limit = 70,
    offset = 0,
  ): Promise<ControlTag[]> {
    try {
      let returnArray: ControlTag[];
      const batch = await this.getAgentTagBatch(url, limit, offset);
      returnArray = batch;
      if (batch.length !== limit && batch.length < limit) {
        return returnArray;
      } else if (batch.length === limit) {
        const newOffset = offset + limit;
        const newBatch = await this.getAgentTags(url, limit, newOffset);
        const finalArray = returnArray.concat(newBatch);
        return finalArray;
      } else {
        return returnArray;
      }
    } catch (error) {
      console.error('Error getting agent tags', error);
    }
  },

  /**
   * Invite agent to the conversation
   *
   * @param clientId the clientId
   * @param agentEmail the agent email that should be added to the conversation
   * @param channelSid the twilio id for the current conversation
   * @param initiatedBy the agent id that initiated the invite
   * @returns invited agent
   */
  inviteAgentToConversation: async function (
    clientId: string,
    agentEmail: string,
    channelSid: string,
    initiatedBy: string,
  ) {
    const url = `/client/${clientId}/agent/invite`;
    const { data } = await client.post(url, {
      channel_sid: channelSid,
      email_address: agentEmail.toLowerCase(),
      initiated_by: initiatedBy,
    });
    return data;
  },

  /**
   * Remove agent from the conversation
   *
   * @param clientId the clientId
   * @param agentEmail the agent email that should be added to the conversation
   * @param channelSid the twilio id for the current conversation
   * @returns invited agent
   */
  removeAgentFromConversation: async function (
    clientId: string,
    agentId: string,
    channelSid: string,
  ) {
    const url = `/client/${clientId}/agents/${agentId}/remove`;
    try {
      const { data } = await client.post(url, {
        channel_sid: channelSid,
      });
      return data;
    } catch (error) {
      console.error('Error removing agent from conversation', error);
    }
  },

  /**
   * Assign agent to the conversation, only for admins
   *
   * @param clientId the clientId
   * @param agentId the agent id that should be added to the conversation
   * @param channelSid the twilio id for the current conversation
   * @param initiatedBy the agent id that assigning the another agent
   * @returns assigned agent
   */
  assignAgentToConversation: async function (
    clientId: string,
    agentId: string,
    channelSid: string,
    initiatedBy: string,
  ) {
    const url = `/client/${clientId}/agents/${agentId}/assign`;
    const { data } = await client.post(url, {
      channel_sid: channelSid,
      initiated_by: initiatedBy,
    });
    return data;
  },

  /**
   * Re-Assign agent to a conversation, only for admins
   *
   * @param clientId the clientId
   * @param agentId the agent id that should be added to the conversation
   * @param channelSid the twilio id for the current conversation
   * @param initiatedBy the agent id that assigning the another agent
   * @returns assigned agent
   */
  reAssignAgentToConversation: async function (
    clientId: string,
    agentId: string,
    channelSid: string,
    initiatedBy: string,
  ) {
    const url = `/client/${clientId}/agents/${agentId}/re-assign`;
    const { data } = await client.post(url, {
      channel_sid: channelSid,
      initiated_by: initiatedBy,
    });
    return data;
  },

  /* Channel Metadata */
  /**
   * @description Get two way channel/conversation metadata
   * @param clientId the clientId
   * @param channelSid the conversationSid or channelSid
   * @returns the channel metadata object
   */
  getClientMetaData: async function (
    clientId,
    channelSid,
  ): Promise<MetadataBody> {
    if (channelSid && clientId) {
      const batchUrl = `/client/${clientId}/channel/${channelSid}/metadata`;
      try {
        const { data } = await client.get(batchUrl);
        return data;
      } catch (error) {
        console.error('Error getting channel metadata', error);
      }
    }
  },

  /* Unassigned Channels */
  /**
   * Get all the unassigned conversations.
   * @param clientId the clientId.
   * @param filters the object of filters to send.
   * @param limit the number of unassigned conversations within the array.
   * @param offset the offset at which the api call was made, used to identify the batch of conversations.
   * @param queryOption the query options for filtering and sorting the unassigned conversations.
   * @param abortController the abort controller to cancel the request.
   * @returns the total array of conversations.
   */
  getAllUnassignedChats: async function (
    clientId: string,
    filters: ChannelsFilterOptions,
    limit: number = 100,
    offset: number = 0,
    queryOption?: QueryOptionUnassignedChannels,
    abortController?: AbortController,
  ): Promise<{ count: number; data: TwoWayUnassignedChannel[] }> {
    const url = buildUrl(clientId, limit, offset, queryOption);
    try {
      const { data } = await client.post(
        url,
        {
          tags: filters.tags,
          launched_by: filters.launched_by,
          customer_ids: filters.customer_ids,
        },
        { signal: abortController?.signal },
      );
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // new request attempted, don't log this error
      } else {
        console.error('Error getting unassigned chats', error);
      }
    }
  },

  /** Get Assigned Conversations
   *
   * @param client a Twilio Client
   * @returns the array of assigned conversations for a client
   */
  getAssignedConversations: async (client: Client) => {
    if (client) {
      try {
        return await client.getSubscribedConversations();
      } catch (error) {
        console.error('Error getting assigned conversations', error);
      }
    }
  },

  getAllAssignedChats: async (arrInitializer, conversation, pageSize) => {
    arrInitializer = arrInitializer.concat(conversation.items);
    if (conversation.hasNextPage && arrInitializer.length < pageSize) {
      conversation = await conversation.nextPage();
      return TwoWayService.getAllAssignedChats(
        arrInitializer,
        conversation,
        pageSize,
      );
    } else {
      return arrInitializer;
    }
  },

  /** Get Twilio Conversation
   *
   * @param client a Twilio Client
   * @param conversationSid the conversation sid
   * @returns twilio conversation for a client
   */
  getTwilioConversation: async (client: Client, conversationSid: string) => {
    if (client && conversationSid) {
      try {
        const conversation = await client.getConversationBySid(conversationSid);
        return conversation;
      } catch (error) {
        console.error('Error getting conversation', error);
      }
    }
  },

  /** Get Twilio Conversation Participants
   *
   * @param client a Twilio Client
   * @param conversationSid the conversation sid
   * @returns agents assigned to a conversation
   */
  getTwilioAgents: async (client: Client, conversationSid: string) => {
    try {
      const conversation = await client.peekConversationBySid(conversationSid);
      const agents = await conversation.getParticipants();
      const participants: Participant[] = agents.filter(
        (agent) => agent.attributes['agent'],
      );
      return participants;
    } catch (error) {
      console.error('Error getting conversation participants', error);
    }
  },

  /** Get Twilio User
   *
   * @param client a Twilio Client
   * @param conversationSid the conversation sid
   * @returns user information id and full name
   */
  getUserInfo: async (client: Client, identity: string) => {
    try {
      const user = await client.getUser(identity);
      const { id, first_name, last_name } = user.attributes['agent'];
      const userInfo: UserInfo = {
        userId: id,
        userFullName: `${first_name} ${last_name}`,
      };
      return userInfo;
    } catch (error) {
      console.error('Error getting user info', error);
    }
  },

  /* Closed Channels */
  /**
   * Get all the closed conversations.
   * @param clientId the clientId.
   * @param filters the object of filters to send.
   * @param limit the number of closed conversations within the array.
   * @param offset the offset at which the api call was made, used to identify the batch of conversations.
   * @param queryOption the query options for filtering and sorting the closed conversations.
   * @param abortController the abort controller to cancel the request.
   * @returns the total array of conversations.
   */
  getClosedConversations: async function (
    clientId: string,
    filters: ClosedChannelsFilterOptions,
    limit: number = 100,
    offset: number = 0,
    queryOption?: QueryOptionUnassignedChannels,
    abortController?: AbortController,
  ): Promise<{ count: number; data: TwoWayClosedChannel[] }> {
    const url = buildClosedConversationsUrl(
      clientId,
      limit,
      offset,
      queryOption,
    );
    try {
      const { data } = await client.post(
        url,
        {
          tags: filters.tags,
          customer_ids: filters.customer_ids,
        },
        { signal: abortController?.signal },
      );
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // new request attempted, don't log this error
      } else {
        console.error('Error getting closed channels', error);
      }
    }
  },

  /** Ping User
   *
   * @param clientId the clientId.
   * @param channelSid the channelSid.
   * @returns message if ping was sent or not.
   */
  pingUser: async (clientId, channelSid) => {
    const url = `/client/${clientId}/ping`;
    const { data } = await client.post(url, { channel_sid: channelSid });
    return data;
  },

  /** Close Conversation
   *
   * @param clientId the clientId.
   * @param channelSid the channelSid.
   * @param reasonId the id of the closed reason
   * @param reasonValue the name of the closed reason
   * @param reasonText additional details of the closed reason if applicable
   * @returns ok if leave channel was successful.
   */
  closeConversation: async (
    clientId,
    channelSid,
    reasonId,
    reasonValue,
    reasonText,
  ) => {
    const url = `/client/${clientId}/agent/channel/close`;
    const objToSend = {
      channel_sid: channelSid,
      ...(reasonId && { closed_reason: reasonId }),
      ...(reasonValue && { closed_reason_name: reasonValue }),
      ...(reasonText && { closed_reason_additional_detail: reasonText }),
    };
    const { data } = await client.post(url, objToSend);
    return data;
  },

  /** Join Conversation
   *
   * @param clientId the clientId.
   * @param channelSid the channelSid.
   * @returns channel attrs, status and jwt for two-way conversation when agent successfully joins conversation.
   */
  joinConversation: async (clientId, channelSid) => {
    const url = `/client/${clientId}/agent/channel/join`;
    const { data } = await client.post(url, { channel_sid: channelSid });
    return data;
  },

  /** Get Two way messages
   *
   * @param clientId the clientId.
   * @param channelSid the channelSid.
   */
  getId: async (clientId, channelSid) => {
    if (idCache && idCache[channelSid]) {
      return idCache[channelSid];
    } else {
      const url = `client/${clientId}/channel/${channelSid}`;
      try {
        const { data } = await client.get(url);
        idCache[channelSid] = data?.id;
        return data?.id;
      } catch (error) {
        console.error('Error getting id', error);
      }
    }
  },

  getDisplayName: async (identity: string, client: Client) => {
    if (userInformationCache && userInformationCache[identity]) {
      return userInformationCache[identity];
    } else {
      return client
        .getUser(identity)
        .then((user) => {
          const name =
            _.get(user, 'attributes.customer.first_name') ||
            _.get(user, 'attributes.agent.first_name') ||
            'system';
          userInformationCache[identity] = name;
          return name;
        })
        .catch(() => {
          return 'system';
        });
    }
  },

  getMessages(
    channel: Conversation,
    pageSize: number = 30,
    anchor: number = 0,
    direction: SortDirection = 'backwards',
  ): Promise<Paginator<Message>> {
    return channel.getMessages(pageSize, anchor, direction);
  },

  /** Decrypt two-way Messages
   *
   * @param clientId the clientId.
   * @param channelSid the channelSid.
   * @param messages array of messages.
   * @returns decrypted two-way messages that were encrypted using a cypherText.
   */
  decryptMessage: async (
    messages: any[],
    clientId,
    channelSid,
  ): Promise<MessageDecryptionResponse> => {
    try {
      const arrayMessagesToDecrypt = {
        channelSid: channelSid,
        messages,
      };
      const url = `client/${clientId}/agent/channel/message/decrypt`;
      const response = await client.post(url, arrayMessagesToDecrypt);
      return response?.data;
    } catch (error) {
      console.error('Error decrypting messages', error);
    }
  },

  /** Bulk decrypt two-way Messages
   *
   * @param clientId the clientId.
   * @param channelSid the channelSid.
   * @param chatsToDecrypt array of chats with messages to decrypt.
   * @returns decrypted two-way messages that were encrypted using a cypherText.
   */
  decryptMessages: async (
    chatsToDecrypt: {
      channelSid: string;
      messagesToDecrypt: any[];
      messageBody?: string;
    }[],
    clientId: string,
  ): Promise<BulkMessageDecryptionResponse> => {
    try {
      const url = `client/${clientId}/agent/channel/message/bulk-decrypt`;
      const response = await client.post(url, { chatsToDecrypt });
      return response?.data;
    } catch (error) {
      console.error('Error decrypting messages', error);
    }
  },
  /** Leave conversations
   *
   * @param clientId the clientId.
   * @param channelSid the channelSid.
   * @returns a string based if the agent was removed or not.
   */
  leaveConversation: async (clientId: string, channelSid: string) => {
    const url = `client/${clientId}/agent/channel/leave`;
    if (clientId && channelSid) {
      const { data } = await client.post(url, { channel_sid: channelSid });
      return data;
    }
  },
  sendMessageToEncrypt: async (clientId: string, messageToEncrypt: any) => {
    if (clientId && messageToEncrypt) {
      const url = `client/${clientId}/agent/channel/message`;
      try {
        const { data } = await client.post(url, messageToEncrypt);
        return data;
      } catch (error) {
        console.error('Error sending message to encrypt', error);
      }
    }
  },
  sendChannelMessage: async (channel: Conversation, message: string) => {
    const channelAttributes =
      channel?.attributes as TwilioConversationAttribute & JSONValue;
    const latestConversationId = channelAttributes?.['conversation_id'];
    const conversationStatus = channelAttributes?.['conversation_status'];
    const isInBlackoutWindow = channelAttributes?.['is_in_blackout_window'];
    channel.sendMessage(message, {
      tag_id: _.get(channel, 'attributes.tags'),
      v3: { encrypted: true },
      is_in_blackout_window: isInBlackoutWindow,
      conversation_status: conversationStatus,
      ...(latestConversationId && {
        conversation_id: latestConversationId,
      }),
    });
  },

  setAllMessagesConsumed: async (channel: Conversation) => {
    try {
      await channel.setAllMessagesRead();
      await channel.updateLastReadMessageIndex(channel.lastMessage.index);
    } catch (error) {
      console.error('Error consume messages', error);
    }
  },

  loadAgentConversation: async (agentId: string, clientId: string) => {
    if (agentId) {
      const batchUrl = `/client/${clientId}/agents/${agentId}/conversations`;
      try {
        const { data } = await client.get(batchUrl);
        return data;
      } catch (error) {
        console.error('Error getting agent conversations', error);
      }
    }
  },

  /*************
   * listeners *
   *************/

  listenToClientMessages: (client: Client, callback) => {
    client.on(
      TwoWayConversationClientEvents.connectionStateChanged,
      (event: any) => {
        callback({
          event_type: TwoWayConversationClientEvents.connectionStateChanged,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.conversationAdded,
      (event: TwilioConversation) => {
        callback({
          event_type: TwoWayConversationClientEvents.conversationAdded,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.conversationJoined,
      (event: TwilioConversation) => {
        callback({
          event_type: TwoWayConversationClientEvents.conversationJoined,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.conversationLeft,
      (event: TwilioConversation) => {
        callback({
          event_type: TwoWayConversationClientEvents.conversationLeft,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.conversationRemoved,
      (event: TwilioConversation) => {
        callback({
          event_type: TwoWayConversationClientEvents.conversationRemoved,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.conversationUpdated,
      (event: ConversationUpdatedEvent) => {
        callback({
          event_type: TwoWayConversationClientEvents.conversationUpdated,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.participantJoined,
      (event: Participant) => {
        callback({
          event_type: TwoWayConversationClientEvents.participantJoined,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.participantLeft,
      (event: Participant) => {
        callback({
          event_type: TwoWayConversationClientEvents.participantLeft,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.participantUpdated,
      (event: ParticipantUpdatedEvent) => {
        callback({
          event_type: TwoWayConversationClientEvents.participantUpdated,
          event,
        });
      },
    );

    client.on(TwoWayConversationClientEvents.messageAdded, (event: Message) => {
      callback({
        event_type: TwoWayConversationClientEvents.messageAdded,
        event,
      });
    });

    client.on(
      TwoWayConversationClientEvents.messageRemoved,
      (event: Message) => {
        callback({
          event_type: TwoWayConversationClientEvents.messageRemoved,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.messageUpdated,
      (event: MessageUpdatedEvent) => {
        callback({
          event_type: TwoWayConversationClientEvents.messageUpdated,
          event,
        });
      },
    );

    client.on(TwoWayConversationClientEvents.pushNotification, (event: any) => {
      callback({
        event_type: TwoWayConversationClientEvents.pushNotification,
        event,
      });
    });

    client.on(TwoWayConversationClientEvents.tokenAboutToExpire, () => {
      // do re-auth here
      callback({
        event_type: TwoWayConversationClientEvents.tokenAboutToExpire,
      });
    });

    client.on(TwoWayConversationClientEvents.tokenExpired, () => {
      // do re-auth here
      callback({
        event_type: TwoWayConversationClientEvents.tokenExpired,
      });
    });

    client.on(
      TwoWayConversationClientEvents.typingEnded,
      (event: Participant) => {
        callback({
          event_type: TwoWayConversationClientEvents.typingEnded,
          channel_sid: event.conversation.sid,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.typingStarted,
      (event: Participant) => {
        callback({
          event_type: TwoWayConversationClientEvents.typingStarted,
          channel_sid: event.conversation.sid,
          event,
        });
      },
    );

    client.on(TwoWayConversationClientEvents.userSubscribed, (event: User) => {
      callback({
        event_type: TwoWayConversationClientEvents.userSubscribed,
        event,
      });
    });

    client.on(
      TwoWayConversationClientEvents.userUnsubscribed,
      (event: User) => {
        callback({
          event_type: TwoWayConversationClientEvents.userUnsubscribed,
          event,
        });
      },
    );

    client.on(
      TwoWayConversationClientEvents.userUpdated,
      (event: UserUpdatedEvent) => {
        callback({
          event_type: TwoWayConversationClientEvents.userUpdated,
          event,
        });
      },
    );
  },
};

/**
 * Get Tag Name By Id
 * @param tagArray the total array of tags.
 * @param id the tag id to find.
 * @returns the tag name.
 */
export const getTagNameById = (tagArray, id: string): string => {
  const tag = tagArray.find((tag) => tag.id === id);
  return tag ? tag['tag_name'] : 'Missing Tag Data';
};

/**
 * Get the updated time for different scenarios.
 * @param dateUpdated The last time a chat was updated (dateUpdated)
 * @returns  the updated time for different scenarios:
 * 1) IF the date of last activity is same as CURRENT DAY, then we will return the time of last activity, ex: 11:20 am
 * 2) IF the date of last activity is the day before the current date, then it will reflect ‘Yesterday’.
 * 3) All other scenarios will just return the date (mm/dd/yyyy), ex: 12/12/2023
 */
export const getUpdatedDate = (dateUpdated): string => {
  const dateToParse = new Date(dateUpdated).toISOString();
  const formattedUpdatedTime = format(
    parseISO(dateToParse),
    "MM/dd/yyyy hh:mm aaaaa'm'",
  );
  const yesterday = isYesterday(parseISO(dateToParse));
  const interval = differenceInMinutes(new Date(dateUpdated), new Date());
  const formattedTimeArray = formattedUpdatedTime.split(' ');
  // checking if interval is between 0 or max 2 minutes to return time formatted HH:mm am/pm
  if (interval === 0 || interval >= -2) {
    const time = formattedTimeArray[1];
    const timePeriods = formattedTimeArray[2];
    const finalTime = `${time} ${timePeriods}`;
    return finalTime;
  } else if (yesterday) {
    return `Yesterday`;
  } else {
    return formattedTimeArray[0];
  }
};
