import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { ShortcodeService } from '@app/core/services/shortcode.service';
import { SessionService } from '@app/security/session.service';
import { take } from 'rxjs/operators';
import { ShortcodeSmsResponseTypes } from '@app/core/models/sms-response-types';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { ClientService } from '@app/core/services/client.service';

@Component({
    selector: 'rn-shortcode-mgmt-component',
    templateUrl: './shortcode-mgmt.component.html',
    styleUrls: ['./shortcode-mgmt.component.scss'],
    standalone: false
})
export class ShortcodeMgmtComponent implements OnInit {
  shortCodeManagementGroup: UntypedFormGroup;
  shortcodeSearchControl: UntypedFormControl;
  shortcodeResponsesArray: UntypedFormArray;
  helpGroup: UntypedFormGroup;
  stopGroup: UntypedFormGroup;
  shortcodeConfig: ShortcodeSmsResponseTypes;
  clientId: string;

  @ViewChild('saveConfirmationDialog', {static: true}) saveConfirmationDialog: MessageDialogComponent;

  constructor(private titleService: TitleService,
              private shortcodeService: ShortcodeService,
              private sessionService: SessionService,
              private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.titleService.activate('Short Code Management');

    this.clientId = this.sessionService.getCurrentUsersClient().id;

    this.shortcodeSearchControl = new UntypedFormControl('',
      [
        Validators.required,
        CustomValidators.digitsOnly,
        Validators.minLength(4),
        Validators.maxLength(11),
        CustomValidators.longCodeLeadingOneDigit
      ]);
    this.shortcodeSearchControl.enable();
    this.shortcodeResponsesArray = new UntypedFormArray([]);

    this.helpGroup = new UntypedFormGroup({
      Keyword: new UntypedFormControl('help'),
      Message: new UntypedFormControl('', Validators.required)
    });
    this.stopGroup = new UntypedFormGroup({
      Keyword: new UntypedFormControl('stop'),
      Message: new UntypedFormControl('', Validators.required)
    });

    this.shortCodeManagementGroup = new UntypedFormGroup(
      {
        ShortCodeSearch: this.shortcodeSearchControl,
        ShortCodeResponsesArray: this.shortcodeResponsesArray
      }
    );
  }

  onSearchClick() {
    if (this.isSearchButtonDisabled()) {
      return;
    }

    this.shortcodeSearchControl.disable();
    this.shortcodeService.getShortCodeResponses(this.shortcodeSearchControl.value)
      .pipe(take(1))
      .subscribe((result) => {
          if (result.sms_keywords) {
            this.shortcodeConfig = ShortcodeSmsResponseTypes.deserialize(result.sms_keywords);
          } else {
            this.shortcodeConfig = new ShortcodeSmsResponseTypes();
          }

          this.rebuildResponsesArray(this.shortcodeConfig);
        },
        (error) => {
          if (error.status === 404) {
            this.shortcodeConfig = new ShortcodeSmsResponseTypes();
            this.rebuildResponsesArray(this.shortcodeConfig);
          } else {
            this.shortcodeConfig = undefined;
            this.shortcodeSearchControl.enable();
            this.shortcodeResponsesArray.clear();
          }
        });
  }

  rebuildResponsesArray(data: ShortcodeSmsResponseTypes) {
    this.shortcodeResponsesArray.clear();

    this.helpGroup.get('Message').patchValue(data.help.en.response_template);
    this.stopGroup.get('Message').patchValue(data.stop.en.response_template);

    this.shortcodeResponsesArray.push(this.helpGroup);
    this.shortcodeResponsesArray.push(this.stopGroup);
    this.shortcodeResponsesArray.updateValueAndValidity();
  }

  onSaveClick() {
    this.shortcodeConfig.help.en.response_template = this.helpGroup.get('Message').value;
    this.shortcodeConfig.stop.en.response_template = this.stopGroup.get('Message').value;

    this.shortcodeService.updateShortCodeResponses(this.shortcodeSearchControl.value, this.shortcodeConfig)
      .pipe(take(1))
      .subscribe(() => {
          this.saveConfirmationDialog.showMessage(`Short code updated for ${this.shortcodeSearchControl.value}`);
          this.onResetClick();
        },
        () => {
          this.saveConfirmationDialog.showMessage('An error occurred.');
        });
  }

  onResetClick() {
    this.shortcodeResponsesArray.clear();
    this.shortCodeManagementGroup.reset();
    this.shortcodeSearchControl.enable();
  }

  isSearchButtonDisabled() {
    return !this.shortcodeSearchControl.valid || this.shortcodeSearchControl.disabled;
  }

  isShortCodeResponseDisplayVisible() {
    return this.shortcodeResponsesArray.controls.length > 0;
  }

  isSaveButtonVisible() {
    return this.shortcodeResponsesArray.controls.length > 0;
  }

  isSaveButtonDisabled() {
    return !this.shortcodeResponsesArray.valid;
  }
}
