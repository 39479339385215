import { environment } from '@env/environment';
import axios from 'axios';
import { SearchResult } from '../relay-messenger-list/RelayMessenger.list';

export const client = axios.create({
  baseURL: environment.searchURLBase,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  responseType: 'json',
});

export const SearchAPIService = {
  /** Get List of Customers
   *
   *  @param clientId the clientId.
   *  @param searchPhrase the phrase to use for searching by full name
   *  @param controller to abort request if new search is made while request is pending
   */
  getListOfCustomerNames: async (
    clientId: string,
    searchPhrase: string,
    controller: AbortController,
  ): Promise<SearchResult[]> => {
    const url = `/client/${clientId}/customers?offset=${0}&limit=${30}`;
    return client
      .post(
        url,
        { search_phrase: searchPhrase, search_fields: ['full_name']},
        { signal: controller.signal },
      )
      .then((response) => {
        return response.data['customers'].map((c) => {
          try {
            return { label: c.full_name, id: c.id, type: 'customer' };
          } catch {}
        });
      });
  },
};
