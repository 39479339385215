import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Client } from '@app/core/models/client';
import { VerificationField } from '@app/core/models/validation-verification';

@Component({
    selector: 'app-client-mobile-message-verification',
    templateUrl: './client-mobile-message-verification.component.html',
    styleUrls: ['./client-mobile-message-verification.component.scss'],
    standalone: false
})
export class ClientMobileMessageVerificationComponent implements OnInit {
  @Input() client: Client;
  @Input() allFields: VerificationField[];

  // allFields is source of truth
  availableFields: VerificationField[];
  selectedFields:  VerificationField[] = [];
  heldField:       VerificationField;
  max_attempts: number[] = [...Array(99)];
  showCustomerTable: boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.client) {
      this.toggleVerificationEnabled(this.client.verification.customer_type);
      this.availableFields = _.cloneDeep(this.allFields);

      if (this.client.verification.fields && this.client.verification.fields.length > 0) {
        this.selectedFields = _.cloneDeep(this.client.verification.fields);
        this.availableFields = _.differenceWith(this.availableFields, this.selectedFields, function(arrVal, othVal) {
          if (arrVal.field === othVal.field) {
            return othVal;
          }
        });
      }
    }
    if (this.availableFields[0]) {
      this.holdField(this.availableFields[0].field);
    }
  }

  holdField(input: any): void {
    this.heldField = _.find(this.availableFields, field => {
      return field.field === input;
    });
  }

  setMaxAttempts(e: any): void {
    this.client.verification.max_attempts = parseInt(e.target.value);
  }

  initSelected(input: VerificationField): any {
    let value = input.field === this.heldField.field ? true : null;
    return value;
  }

  removeField(input: VerificationField): void {
    _.pull(this.selectedFields, input);
    this.availableFields = _.cloneDeep(this.allFields);
    this.availableFields = _.differenceWith(this.availableFields, this.selectedFields, function(arrVal, othVal) {
      if (arrVal.field === othVal.field) {
        return othVal;
      }
    });

    if (this.availableFields.length === 1) {
      this.holdField(this.availableFields[0].field);
    }

    this.client.verification.fields = this.selectedFields;
  }

  selectField(input: VerificationField): void {
    this.availableFields = _.differenceWith(this.availableFields, [input], function(arrVal, othVal) {
      if (arrVal.field === othVal.field) {
        return othVal;
      }
    });
    this.selectedFields = _.unionWith(this.selectedFields, [input], function(arrVal, othVal) {
      if (arrVal.field === othVal.field) {
        return othVal;
      }
    });

    if (this.availableFields[0]) {
      this.holdField(this.availableFields[0].field);
    }

    this.client.verification.fields = this.selectedFields;
  }

  toggleVerificationEnabled(ctype: string): void {
    if (ctype === '4') {
      this.client.verification.enabled = 'false';
    } else {
      this.client.verification.enabled = 'true';
    }
  }
}
