import { TitleService } from '@app/core/services/title.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Status } from '@app/file-launcher/components/file-launcher/file-launcher.component';

@Component({
    selector: 'rn-file-launcher-page',
    templateUrl: './file-launcher-page.component.html',
    styleUrls: ['./file-launcher-page.component.scss'],
    standalone: false
})
export class FileLauncherPageComponent implements OnInit, OnDestroy {
  progressTabs: object[] = [{id: 'upload', text: 'Upload'}, {id: 'run', text: 'Run'}];
  currentTab: 'upload' | 'run' = 'upload';

  constructor(private titleService: TitleService) { }

  ngOnInit(): void {
    this.titleService.activate('File Engine Jobs', 'Upload File');
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
  }

  updateProgressTabs(newStatus: Status) {
    if (newStatus === 'waitingForFile') {
      this.currentTab = 'upload';
    }

    if (newStatus === 'waitingForAction') {
      this.currentTab = 'run';
    }
  }
}
