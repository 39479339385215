import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client, SMTPCredentials } from '@app/core/models/client';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NewFeedTransitionService } from '@app/core/services/new-feed-transition.service';

@Component({
    selector: 'app-client-sms-archive',
    templateUrl: './client-sms-archive.component.html',
    styleUrls: ['./client-sms-archive.component.scss'],
    standalone: false
})
export class ClientSmsArchiveComponent implements OnInit {
  @Input() client: Client;
  @Input() smtpCredentials: SMTPCredentials;

  @Output() smtpCredentialsChange = new EventEmitter<SMTPCredentials>();

  smsArchive: string;
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public newFeed: NewFeedTransitionService,
  ) {}

  ngOnInit() {
    this.buildForm();
    if (Object.keys(this.smtpCredentials).length === 0) {
      this.smsArchive = undefined;
    } else {
      this.smsArchive = 'global-relay';
    }
  }

  onChange(): void {
    if (!this.smsArchive) {
      this.smtpCredentialsChange.emit({});
    } else {
      const credentials = this.cleanCredentials(this.form.getRawValue());
      this.smtpCredentialsChange.emit(credentials);
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      host: new UntypedFormControl(this.smtpCredentials.host, []),
      user: new UntypedFormControl(this.smtpCredentials.user, []),
      pass: new UntypedFormControl('', []),
      port: new UntypedFormControl(this.smtpCredentials.port, []),
      secure: new UntypedFormControl(!!this.smtpCredentials.secure, []),
    });

    this.form.valueChanges.subscribe((res) =>
      this.smtpCredentialsChange.emit(this.cleanCredentials(res)),
    );
  }

  private cleanCredentials(credentials: SMTPCredentials): SMTPCredentials {
    for (const c in credentials) {
      if (
        credentials[c] === null ||
        credentials[c] === '' ||
        credentials[c] === undefined
      ) {
        delete credentials[c];
      }
      if (c === 'port') {
        // @ts-ignore - complains about a string but port is a number
        credentials[c] = parseInt(credentials[c]);
      }
    }
    return credentials;
  }
}
