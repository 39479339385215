import React, { useRef, useState } from 'react';
import { Textarea } from 'baseui/textarea';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';

export type TwoWayChatInputProps = {
  onNewMessage: (messageVal: string) => void;
  onTyping?: () => void;
};

export const TwoWayChatInput = ({
  onNewMessage,
  onTyping,
}: TwoWayChatInputProps) => {
  const [css] = useStyletron();
  const [inputValue, setInputValue] = useState('');
  const maxLength = 2000;
  const buttonRef = useRef(null);
  const onKeyPress = (e) => {
    if (inputValue.length < maxLength) {
    if (e.keyCode === 13 || e.charCode === 13) {
      e.preventDefault();
      onNewMessage(inputValue);
      buttonRef.current.value = '';
      setInputValue('');
    } else if (inputValue.length) {
      onTyping();
    }
  }
  };
  return (
    <div
      className={css({
        display: 'flex',
        position: 'absolute',
        bottom: '0',
        flexDirection: 'row',
        padding: '10px',
        backgroundColor: '#37474F',
        width: '100%',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
      })}
    >
      <Textarea
        onChange={(e: any) => setInputValue(e.target.value)}
        value={inputValue}
        ref={buttonRef}
        onKeyPress={onKeyPress}
        maxLength={maxLength}
      />
      <Button
        overrides={{
          BaseButton: {
            style: () => ({
              backgroundColor: '#37474F',
            }),
          },
        }}
        onClick={() => {
          onNewMessage(inputValue);
          setInputValue('');
        }}
        disabled={!inputValue.trim() || inputValue.length === maxLength}
      >
        Send
      </Button>
    </div>
  );
};
