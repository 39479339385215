"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  DURATION: true,
  PLACEMENT: true,
  SnackbarElement: true,
  SnackbarProvider: true,
  useSnackbar: true,
  StyledRoot: true,
  StyledContent: true,
  StyledStartEnhancerContainer: true,
  StyledSpinner: true,
  StyledMessage: true,
  StyledWrapActionButtonContainer: true,
  StyledActionButtonContainer: true,
  StyledPlacementContainer: true
};
Object.defineProperty(exports, "DURATION", {
  enumerable: true,
  get: function () {
    return _constants.DURATION;
  }
});
Object.defineProperty(exports, "PLACEMENT", {
  enumerable: true,
  get: function () {
    return _constants.PLACEMENT;
  }
});
Object.defineProperty(exports, "SnackbarElement", {
  enumerable: true,
  get: function () {
    return _snackbarElement.default;
  }
});
Object.defineProperty(exports, "SnackbarProvider", {
  enumerable: true,
  get: function () {
    return _snackbarContext.default;
  }
});
Object.defineProperty(exports, "StyledActionButtonContainer", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledActionButtonContainer;
  }
});
Object.defineProperty(exports, "StyledContent", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledContent;
  }
});
Object.defineProperty(exports, "StyledMessage", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledMessage;
  }
});
Object.defineProperty(exports, "StyledPlacementContainer", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledPlacementContainer;
  }
});
Object.defineProperty(exports, "StyledRoot", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledRoot;
  }
});
Object.defineProperty(exports, "StyledSpinner", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledSpinner;
  }
});
Object.defineProperty(exports, "StyledStartEnhancerContainer", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledStartEnhancerContainer;
  }
});
Object.defineProperty(exports, "StyledWrapActionButtonContainer", {
  enumerable: true,
  get: function () {
    return _styledComponents.StyledWrapActionButtonContainer;
  }
});
Object.defineProperty(exports, "useSnackbar", {
  enumerable: true,
  get: function () {
    return _snackbarContext.useSnackbar;
  }
});
var _constants = require("./constants");
var _snackbarElement = _interopRequireDefault(require("./snackbar-element"));
var _snackbarContext = _interopRequireWildcard(require("./snackbar-context"));
var _styledComponents = require("./styled-components");
var _types = require("./types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function (e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != typeof e && "function" != typeof e) return {
    default: e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) {
    var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
    i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
  }
  return n.default = e, t && t.set(e, n), n;
}
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}