import { MediaAsset } from '@app/core/models/media';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { LoadingMaskService } from '@app/core/services/loading-mask.service';
import { MediaService } from '@app/core/services/media.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { flatMap } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'edit-media-asset-details',
    templateUrl: './edit-media-asset-details.component.html',
    styleUrls: ['./edit-media-asset-details.component.scss'],
    standalone: false
})
export class EditMediaAssetDetailsComponent implements OnInit {

  @Input() mediaAsset: MediaAsset;
  @Input() editBrand: boolean;
  @Output() onClose = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<MediaAsset>();
  @Output() onUpdateRequest = new EventEmitter<void>();
  @ViewChild(MessageDialogComponent, { static: true }) messageDialogComponent: MessageDialogComponent;
  imageWidth: number;
  imageHeight: number;
  videoUrlEdittable = false;

  formGroup: UntypedFormGroup;
  saving = false;

  constructor(private mediaService: MediaService,
              private loadingMaskService: LoadingMaskService,
              private formBuilder: UntypedFormBuilder,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      'short_name': [this.mediaAsset.shortname, Validators.required],
      'description': [this.mediaAsset.description, []],
      'video_url': [this.mediaAsset.url, [Validators.required, CustomValidators.whiteListURL]],
      'alternate_text': [this.mediaAsset.alternate_text, []],
      'tags': [this.mediaAsset.tags || [], []],
      'branding_color': [this.mediaAsset.branding_color, this.editBrand ? Validators.required : []],
    });
    this.formGroup.controls['video_url'].disable();
    if (this.mediaAsset.asset_type === 'image') {
      this.formGroup.addControl('alternate_text', new UntypedFormControl(null, Validators.required));
    }

    this.formGroup.valueChanges.subscribe(val => {
      this.mediaAsset.shortname = this.formGroup.get('short_name').value;
      this.mediaAsset.description = this.formGroup.get('description').value;
      this.mediaAsset.url = this.formGroup.get('video_url').value;
      this.mediaAsset.alternate_text = this.formGroup.get('alternate_text').value;
      this.mediaAsset.tags = this.formGroup.get('tags').value;
      this.mediaAsset.branding_color = this.formGroup.get('branding_color').value;
    });
  }

  previewImageLoaded(event: Event) {
    const imageElement = <HTMLImageElement>event.target;
    this.imageWidth = imageElement.naturalWidth;
    this.imageHeight = imageElement.naturalWidth;
  }

  setColor(event: string | boolean) {
    if (this.editBrand) {
      this.formGroup.get('branding_color').setValue(event);
    }
  }

  saveChanges() {
    if (!this.saving && this.formGroup.valid) {
      this.saving = true;

      if (this.mediaAsset.asset_type === 'video') {
        delete this.mediaAsset.alternate_text; //shouldn't pass any alt text for video assets
        this.changeDetectorRef.detectChanges();
      }
      
      this.mediaAsset = MediaAsset.deserialize(_.omitBy(this.mediaAsset, _.isNil));

      this.mediaService.updateMediaAssetMetaData(this.mediaAsset).pipe(
        flatMap(response => {
          // Get the asset we just saved for system generated fields
          return this.mediaService.getMediaAsset(this.mediaAsset.asset_id);
        })
      ).subscribe(
        updatedMediaAsset => {
          this.mediaAsset = updatedMediaAsset;
          this.onSave.emit(this.mediaAsset);
        },
        error => {
          if (error.status !== 401) {
            this.messageDialogComponent.showMessage('Oops...could not save changes to media asset.');
          }
        },
        () => {
          this.saving = false;
        }
      );
    }
  }

  editVideoUrl() {
    this.videoUrlEdittable = true;
    this.formGroup.controls['video_url'].enable();
    this.changeDetectorRef.detectChanges();
  }

  close() {
    this.onClose.emit();
  }

  requestUpdate() {
    this.onUpdateRequest.emit();
  }

  getMediaAssetUrl(): string {
    if (this.mediaAsset.asset_type === 'video') {
      return this.mediaAsset.url;
    } else {
      return this.mediaAsset.s3_url;
    } 
  }
}
