<div class="messaging-panel arrow-left-center">
  <div class="card panel-default">
    <div class="card-header">
      <div class="col-sm-12">
        Sign up page {{ this.client.feed_enabled ? 'content' : 'composer' }}
      </div>
    </div>
    <div class="card-body">
      <div class="form-group">
        <div
          [ngClass]="{
            'has-error':
              !signUpGroup.controls['message_text'].valid &&
              signUpGroup.controls['message_text'].dirty
          }"
        >
          <label>Message Text</label>
          <rn-form-input-state-control
            [formReference]="signUpGroup.controls['message_text']"
            [totalLength]="getCharacterLimit('signUpMessageText')"
          >
            <ng-container>
              <app-tiny-editor
                class="tiny-editor-mentions"
                [elementId]="'tiny-editor-message-text'"
                [formControl]="signUpGroup.controls['message_text']"
              >
              </app-tiny-editor>
            </ng-container>
          </rn-form-input-state-control>
        </div>
      </div>

      <div class="form-group">
        <span class="validation-field-header"> Validation field </span>
        <span class="validation-field-header"> Field label </span>
        <div class="validation-fields">
          <span class="validation-field-value">{{
            client.validation.look_up_by_secondary_account_id
              ? 'secondary_id'
              : 'ccid'
          }}</span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.controls['ccid_input_label'].valid
            }"
          >
            <input
              type="text"
              [formControl]="signUpGroup.controls['ccid_input_label']"
            />
          </span>
        </div>
        <div class="validation-fields">
          <span class="validation-field-value">mobile_number</span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.controls['mobile_input_label'].valid
            }"
          >
            <input
              type="text"
              [formControl]="signUpGroup.controls['mobile_input_label']"
            />
          </span>
        </div>
        <div class="validation-fields">
          <span class="validation-field-value">
            first_name
          </span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.get('fields.first_name').valid
            }"
          >
            <input
              type="text"
              name="prompt-first_name"
              [formControl]="signUpGroup.get('fields.first_name')"
            />
          </span>
        </div>
        <div class="validation-fields">
          <span class="validation-field-value">
            last_name
          </span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.get('fields.last_name').valid
            }"
          >
            <input
              type="text"
              name="prompt-last_name"
              [formControl]="signUpGroup.get('fields.last_name')"
            />
          </span>
        </div>

        <div class="validation-fields">
          <span class="validation-field-value">
            ssn_last4
          </span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.get('fields.ssn_last4').valid
            }"
          >
            <input
              type="text"
              name="prompt-ssn_last4"
              [formControl]="signUpGroup.get('fields.ssn_last4')"
            />
          </span>
        </div>

        <div class="validation-fields">
          <span class="validation-field-value">
            date_of_birth
          </span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.get('fields.date_of_birth').valid
            }"
          >
            <input
              type="text"
              name="prompt-date_of_birth"
              [formControl]="signUpGroup.get('fields.date_of_birth')"
            />
          </span>
        </div>

        <div class="validation-fields">
          <span class="validation-field-value">
            postal_code
          </span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.get('fields.postal_code').valid
            }"
          >
            <input
              type="text"
              name="prompt-postal_code"
              [formControl]="signUpGroup.get('fields.postal_code')"
            />
          </span>
        </div>

        <!-- <div
          class="validation-fields"
          *ngFor="let field of client.validation.fields; let i = index"
        >
          <span class="validation-field-value">
            {{ field.field }}
          </span>
          <span
            class="validation-field-value"
            [ngClass]="{
              'has-error': !signUpGroup.controls[field.field].valid
            }"
          >
            <input
              type="text"
              name="prompt-{{ i }}"
              [formControl]="signUpGroup.controls[field.field]"
            />
          </span>
        </div> -->
      </div>

      <div class="form-group">
        <div
          [ngClass]="{
            'has-error': !express_written_consent_ts_cs
          }"
        >
          <span
            class="error-msg"
            *ngIf="!express_written_consent_ts_cs"
            tooltip="This field is required"
            placement="bottom"
          >
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          </span>
          <label
            >Terms and Conditions - Pulled from Express Written Consent</label
          >
          <p
            *ngIf="express_written_consent_ts_cs"
            class="message-text"
            [innerHtml]="express_written_consent_ts_cs | safeHtml"
          ></p>
          <p
            *ngIf="!express_written_consent_ts_cs"
            class="message-text has-error"
          >
            Express written consent must be set
          </p>
        </div>
      </div>

      <div class="form-group">
        <div
          [ngClass]="{
            'has-error':
              !signUpGroup.controls['success_text'].valid &&
              signUpGroup.controls['success_text'].touched
          }"
        >
          <label>Success Copy</label>
          <rn-form-input-state-control
            [formReference]="signUpGroup.controls['success_text']"
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="getCharacterLimit('signUpSuccessCopy')"
          >
            <ng-container>
              <textarea
                name="success_text"
                class="form-control"
                rows="3"
                [formControl]="signUpGroup.controls['success_text']"
              ></textarea>
            </ng-container>
          </rn-form-input-state-control>
        </div>
      </div>

      <div class="form-group">
        <div
          [ngClass]="{
            'has-error':
              !signUpGroup.controls['failure_text'].valid &&
              signUpGroup.controls['failure_text'].touched
          }"
        >
          <label>Failure Copy</label>
          <rn-form-input-state-control
            [formReference]="signUpGroup.controls['failure_text']"
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="getCharacterLimit('signUpFailureCopy')"
          >
            <ng-container>
              <textarea
                name="failure_text"
                class="form-control"
                rows="3"
                [formControl]="signUpGroup.controls['failure_text']"
              ></textarea>
            </ng-container>
          </rn-form-input-state-control>
        </div>
      </div>

      <div class="form-group">
        <div
          [ngClass]="{
            'has-error':
              !signUpGroup.controls['submit_button_label'].valid &&
              signUpGroup.controls['submit_button_label'].touched
          }"
        >
          <label>Submit button label</label>
          <rn-form-input-state-control
            [formReference]="signUpGroup.controls['submit_button_label']"
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="getCharacterLimit('signUpPageSubmitButtonLabel')"
          >
            <ng-container>
              <input
                name="button_label"
                class="form-control"
                [formControl]="signUpGroup.controls['submit_button_label']"
              />
            </ng-container>
          </rn-form-input-state-control>
        </div>
      </div>

      <div class="form-group">
        <div
          [ngClass]="{
            'has-error':
              !signUpGroup.controls['terms_and_conditions_text'].valid &&
              signUpGroup.controls['terms_and_conditions_text'].touched
          }"
        >
          <label>Terms and Conditions Checkbox Text</label>
          <rn-form-input-state-control
            [formReference]="signUpGroup.controls['terms_and_conditions_text']"
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="getCharacterLimit('signUpPageTermsAndConditions')"
          >
            <ng-container>
              <input
                name="button_label"
                class="form-control"
                [formControl]="
                  signUpGroup.controls['terms_and_conditions_text']
                "
              />
            </ng-container>
          </rn-form-input-state-control>
        </div>
      </div>

      <div class="form-group">
        <div
          [ngClass]="{
            'has-error':
              !signUpGroup.controls['terms_and_conditions_validation_error']
                .valid &&
              signUpGroup.controls['terms_and_conditions_validation_error']
                .touched
          }"
        >
          <label>Terms and Conditions Error Text</label>
          <rn-form-input-state-control
            [formReference]="
              signUpGroup.controls['terms_and_conditions_validation_error']
            "
            [showCharacterCount]="this.client.feed_enabled"
            [totalLength]="
              getCharacterLimit('signUpPageTermsAndConditionsValidationError')
            "
          >
            <ng-container>
              <input
                name="button_label"
                class="form-control"
                [formControl]="
                  signUpGroup.controls['terms_and_conditions_validation_error']
                "
              />
            </ng-container>
          </rn-form-input-state-control>
        </div>
      </div>
    </div>
  </div>
</div>
