import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LoggerService } from '@app/core/services/logger.service';
import { SessionService } from '@app/security/session.service';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  TwilioConversation,
  TwoWayConversationClientEvents,
  ConversationUpdatedEvent,
  ParticipantUpdatedEvent,
} from '@app/two-way/twilio-conversation.types';
import { ControlTag } from '@app/core/services/control-tags.service';
import { SortableComponent } from '@app/core/sortable-component';
import { sortBy } from '@app/core/utils/sorting-utils';
import { Direction, Ordering } from '@app/core/utils/ordering';

import * as _ from 'lodash';
import { TwoWayConversationService } from '@app/core/services/two-way-conversation.service';
import { Message } from '@twilio/conversations';

@Component({
    selector: 'assigned',
    templateUrl: './assigned.component.html',
    styleUrls: ['./assigned.component.scss'],
    standalone: false
})
export class AssignedComponent
  extends SortableComponent
  implements OnInit, OnDestroy
{
  @Input() tagFilter: BehaviorSubject<any>;
  @Input() launchFilter: BehaviorSubject<any>;
  @Input() tags: ControlTag[];
  @Input() $selectedChannel: BehaviorSubject<string>;
  @Output() selectedChannelEmitter = new EventEmitter<string>();

  error: string;
  loading = true;
  headings: string[] = ['name', 'tag', 'unread', 'created', 'last updated'];

  selectedChannel: string;

  assignedChannels: TwilioConversation[] = [];

  filters: { tags: string[]; launched_by: string[] } = {
    tags: [],
    launched_by: [],
  };

  subs$: Subscription[];

  constructor(
    private twoWayConversationService: TwoWayConversationService,
    private sessionService: SessionService,
  ) {
    super();
  }

  ngOnInit() {
    this.twoWayConversationService
      .getAssignedChats()
      .pipe(
        switchMap((res) => this.removeInvalidConversations(res.items)),
        map((channels) => this.filterConversations(channels)),
        take(1),
      )
      .subscribe(
        (channels) => {
          this.setAssignedConversations(channels);
        },
        (e) => this.handleError(e),
      );

    const subA$ = this.twoWayConversationService.clientEmitter.subscribe(
      (event) => this.channelConversationEventRouter(event),
    );
    const subB$ = this.$selectedChannel.subscribe(
      (channel_sid) => (this.selectedChannel = channel_sid),
    );
    const subC$ = this.tagFilter.subscribe((res) => {
      this.filters.tags = _.map(res, 'tag_id');
      this.getAssigned();
    });
    const subD$ = this.launchFilter.subscribe((res) => {
      this.filters.launched_by = _.map(res, 'lb_name');
      this.getAssigned();
    });

    this.subs$ = [subA$, subB$, subC$, subD$];
  }

  ngOnDestroy() {
    _.forEach(this.subs$, (sub: Subscription) => sub.unsubscribe());
  }

  // required for abstract class SortableComponent
  fetchData() {
    this.applySort();
  }

  applySort() {
    // apply default sort if undefined
    if (this.ordering === undefined) {
      this.ordering = new Ordering('unread', Direction.Desc);
    }

    if (this.ordering.orderBy === 'name') {
      this.assignedChannels = sortBy(
        this.assignedChannels,
        this.ordering.direction,
        (channel) =>
          `${channel.attributes.customer.first_name} ${channel.attributes.customer.last_name}`,
      );
    } else if (this.ordering.orderBy === 'tag') {
      this.assignedChannels = sortBy(
        this.assignedChannels,
        this.ordering.direction,
        (channel) => this.getTagNameById(channel.attributes.tags),
      );
    } else if (this.ordering.orderBy === 'unread') {
      this.assignedChannels = sortBy(
        this.assignedChannels,
        this.ordering.direction,
        (channel) => this.getUnreadCount(channel),
      );
    } else if (this.ordering.orderBy === 'created') {
      this.assignedChannels = sortBy(
        this.assignedChannels,
        this.ordering.direction,
        'dateCreated',
      );
    } else if (this.ordering.orderBy === 'last updated') {
      this.assignedChannels = sortBy(
        this.assignedChannels,
        this.ordering.direction,
        'dateUpdated',
      );
    }
  }

  selectChannel(channel_sid: string): void {
    if (channel_sid === this.selectedChannel) {
      return;
    }
    const sub$ = this.twoWayConversationService
      .selectChannel(channel_sid)
      .subscribe(
        () => {
          this.selectedChannel = channel_sid;
          this.selectedChannelEmitter.emit(this.selectedChannel);
        },
        (err) => this.handleError(err),
      );

    this.subs$.push(sub$);
  }

  getTagNameById(id: string): string {
    const tag = this.tags.find((t) => t.tag_id === id);
    return tag ? tag['tag_name'] : 'Missing Tag Data';
  }

  retry(): void {
    this.getAssigned();
  }

  private tagIsInvalid(id: string): boolean {
    return !this.tags.some((t) => t.tag_id === id);
  }

  private removeInvalidConversations(
    channels: TwilioConversation[],
  ): Observable<TwilioConversation[]> {
    const invalidChannels = channels.filter((channel) =>
      this.tagIsInvalid(channel.attributes.tags),
    );
    /*
     * If invalid channels are found, leave them, and then make
     * another call for assigned chats. This 2nd call won't have
     * the chats we just left.
     */
    if (invalidChannels.length) {
      invalidChannels.forEach((channel) => {
        this.twoWayConversationService
          .leaveChannel(
            this.sessionService.selectedClient.getValue(),
            channel.sid,
          )
          .toPromise()
          .catch((err) => LoggerService.log(err));
      });
      return this.twoWayConversationService
        .getAssignedChats()
        .pipe(map((res) => res['items']));
    }

    return of(channels); // if all channels are valid, pass along the first set of results
  }

  private setAssignedConversations(channels: TwilioConversation[]): void {
    this.assignedChannels = channels;
    this.loading = false;
    this.applySort(); // must sort before unread counter ui element is updated!
    _.forEach(this.assignedChannels, (channel) =>
      this.updateUnreadCounterConversation(channel),
    );
  }

  private getAssigned(): void {
    this.twoWayConversationService
      .getAssignedChats()
      .pipe(
        map((res) => this.filterConversations(res.items)),
        take(1),
      )
      .subscribe(
        (channels) => this.setAssignedConversations(channels),
        (err) => this.handleError(err),
      );
  }

  /**
   * Filters the displayed list of assigned channels based on the filters
   * selected in the tag-select and launched-select components
   */
  private filterConversations(
    channels: TwilioConversation[],
  ): TwilioConversation[] {
    return channels.filter((channel) => {
      if (
        this.noFiltersSelected() ||
        this.matchesTagFilter(channel) ||
        this.matchesLaunchedByFilter(channel)
      ) {
        return true;
      }
      return false;
    });
  }

  private matchesTagFilter(channel: TwilioConversation): boolean {
    const tags = this.filters.tags;
    return tags.length > 0 && tags.indexOf(channel.attributes.tags) !== -1;
  }

  private matchesLaunchedByFilter(channel: TwilioConversation): boolean {
    const launched_by = this.filters.launched_by;
    if (_.get(channel, 'attributes.launched_by.lb_name')) {
      return launched_by.indexOf(channel.attributes.launched_by.lb_name) !== -1;
    }
    return false;
  }

  private noFiltersSelected(): boolean {
    return (
      this.filters.launched_by.length === 0 && this.filters.tags.length === 0
    );
  }

  private handleError(err: string): void {
    this.error =
      'There was an error fetching your conversations. Please refresh to try again.';
    this.loading = false;
    LoggerService.log('MessagingComponent', err);
  }

  private channelConversationEventRouter(event: any): void {
    switch (event.event_type) {
      case TwoWayConversationClientEvents.messageAdded:
        this.messageConversationAddedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.conversationJoined:
        this.channelJoinedHandler();
        return;
      case TwoWayConversationClientEvents.conversationUpdated:
        this.conversationUpdatedHandler(event.event);
        return;
      case TwoWayConversationClientEvents.conversationLeft:
        this.channelRemovedHandler();
        return;
      case TwoWayConversationClientEvents.conversationRemoved:
        this.channelRemovedHandler();
        return;
      case TwoWayConversationClientEvents.participantUpdated:
        this.participantUpdatedHandler(event.event);
        return;
    }
  }

  private messageConversationAddedHandler(event: Message): void {
    this.getAssigned();
    this.applySort(); // update sort after count gets updated
    this.updateUnreadCounterConversation(
      event.conversation as TwilioConversation,
    );
  }

  private channelRemovedHandler(): void {
    this.getAssigned();
  }

  private channelJoinedHandler(): void {
    this.getAssigned();
  }

  private conversationUpdatedHandler(event: ConversationUpdatedEvent): void {
    if (event.updateReasons.indexOf('lastMessage') > -1) {
      this.addBlinker(event.conversation.sid);
      this.updateUnreadCounterConversation(event.conversation);
    }
  }

  private participantUpdatedHandler(event: ParticipantUpdatedEvent): void {
    if (event.updateReasons.indexOf('lastReadMessageIndex') > -1) {
      this.resetUnread(event.participant.conversation.sid);
    }
  }

  private addBlinker(channel_sid: string): void {
    // debounce this
    if (this.selectedChannel !== channel_sid) {
      const blinkTimeout = 2000;
      const blinkClassName = 'blink';
      const element: HTMLElement = document.getElementById(
        channel_sid,
      ) as HTMLElement;
      element.className += ' ' + blinkClassName;
      setTimeout(() => element.classList.remove(blinkClassName), blinkTimeout);
    }
  }

  private getUnreadCount(channel: TwilioConversation): number {
    return this.getUnreadInConversationCount(channel);
  }

  private getUnreadInConversationCount(channel: TwilioConversation): number {
    return channel.lastMessage.index - channel.lastReadMessageIndex;
  }

  private updateUnreadCounterConversation(channel: TwilioConversation): void {
    if (this.selectedChannel !== channel.sid) {
      const unread = this.getUnreadInConversationCount(channel);
      const el: HTMLElement = document.getElementById(
        `unread-${channel.sid}`,
      ) as HTMLElement;
      if (el) {
        el.innerText = unread.toString();
      }
    }
  }

  private updateUnreadCounter(channel: any): void {
    if (this.selectedChannel !== channel.sid) {
      const unread = this.getUnreadCount(channel);
      const el: HTMLElement = document.getElementById(
        `unread-${channel.sid}`,
      ) as HTMLElement;
      if (el) {
        el.innerText = unread.toString();
      }
    }
  }

  private resetUnread(channel_sid: any): void {
    this.twoWayConversationService.setAllMessagesConsumed();
    const el: HTMLElement = document.getElementById(
      `unread-${channel_sid}`,
    ) as HTMLElement;
    el.innerText = '0';
  }
}
