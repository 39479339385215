import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { FeatureService } from '@app/core/services/feature.service';
import { TitleService } from '@app/core/services/title.service';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { SessionService } from '@app/security/session.service';
import {
  BusinessHoursResponse,
  Timezone,
  TwoWaySetupService,
} from '@app/two-way/services/two-way-setup.service';

type BusinessHours = {
  [key: string]: {
    enabled: boolean;
    startTime: Date;
    endTime: Date;
  };
};
type OutOfOffice = {
  enabled: boolean;
  expirationDate: Date;
  expirationTime: {
    hours: number;
    minutes: number;
  };
};
type BusinessDays = Omit<
  BusinessHoursResponse['businessHours'],
  'timezone' | 'outOfOffice'
>;

@Component({
  selector: 'app-setup-business-hours',
  templateUrl: './setup-business-hours.component.html',
  styleUrl: './setup-business-hours.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  providers: [provideNativeDateAdapter()],
})
export class SetupBusinessHoursComponent implements OnInit, OnDestroy {
  clientId: string = '';
  isRMAutoResponseEnabled: boolean;
  isErrorBusinessHours: boolean = false;
  isFormInvalid: boolean = false;
  businessHoursGroup: UntypedFormGroup;
  isOutOfOfficeEnabled: boolean = false;
  days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  businessHoursData: BusinessDays;
  businessHours: BusinessHours;
  timezone: Timezone;
  outOfOffice: OutOfOffice;
  isErrorClientStatus: boolean = false;
  currentHoursType: string | null;
  currentHoursExpires: string | null;
  minDate: Date = new Date();
  maxDate: Date;

  constructor(
    private titleService: TitleService,
    private sessionService: SessionService,
    private router: Router,
    public featureService: FeatureService,
    private twoWaySetupService: TwoWaySetupService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.calculateMaxDate();
    this.isRMAutoResponseEnabled = this.featureService.checkFlag(
      FeatureFlags.rm_auto_response,
    );
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.titleService.activate('Relay Messenger Setup', 'Business Hours');
    this.loadData();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  loadData() {
    this.twoWaySetupService.getClientStatus(this.clientId).subscribe(
      (data) => {
        const { type, expires } = data;
        this.currentHoursType = type;
        if (expires) {
          const expiryTime = new Date(expires)
            .toLocaleString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: 'numeric',
              minute: 'numeric',
              hour: 'numeric',
              timeZoneName: 'short',
            })
            .replace(',', ' at ');
          this.currentHoursExpires = expiryTime;
        } else {
          this.currentHoursExpires = null;
        }

        this.cd.detectChanges();
      },
      () => {
        this.isErrorClientStatus = true;
        this.cd.detectChanges();
      },
    );
    // GET business hours data from twoWaySetupService
    this.twoWaySetupService.getBusinessHours(this.clientId).subscribe(
      (data) => {
        const { timezone, outOfOffice, ...businessHours } = data;
        this.businessHoursData = businessHours;
        this.outOfOffice = outOfOffice;
        this.timezone = data.timezone;
        this.isOutOfOfficeEnabled = outOfOffice.enabled;
        if (this.businessHoursData) {
          // format business hours time to display in Date format for mat-timepicker
          this.businessHours = this.setUpBusinessHoursData(
            this.businessHoursData,
          );
          this.initBusinessHoursForm(this.businessHours, this.outOfOffice);
          this.cd.detectChanges();
        }
      },
      () => {
        this.isErrorBusinessHours = true;
        this.cd.detectChanges();
      },
    );
  }

  isInErrorState() {
    const outOfOfficeGroup = this.businessHoursGroup.get(
      'outOfOffice',
    ) as FormGroup;
    const expirationDate = outOfOfficeGroup.get('expirationDate')?.value;
    if (!expirationDate) {
      return true;
    }
    return false;
  }

  isTimeSelectedInThePast() {
    const outOfOfficeGroup = this.businessHoursGroup.get(
      'outOfOffice',
    ) as FormGroup;
    const expirationDate = outOfOfficeGroup.get('expirationDate')?.value;
    const expirationTime = outOfOfficeGroup.get('expirationTime')?.value;
    const now = new Date().getTime();
    const selectedTime = new Date(expirationTime).getTime();
    const selectedDate = new Date(expirationDate).getTime();
    if (selectedDate < now && selectedTime < now) {
      return true;
    }
    return false;
  }

  initBusinessHoursForm(
    businessHours: BusinessHours,
    outOfOffice: OutOfOffice,
  ) {
    this.businessHoursGroup = this.fb.group({});
    this.days.forEach((day) => {
      const isEnabled = businessHours[day].enabled;
      const dayGroup = this.fb.group(
        {
          enabled: [isEnabled],
          startTime: [
            { value: businessHours[day].startTime, disabled: !isEnabled },
            [Validators.required, CustomValidators.dateValidator],
          ],
          endTime: [
            { value: businessHours[day].endTime, disabled: !isEnabled },
            [Validators.required, CustomValidators.dateValidator],
          ],
          originalStartTime: [businessHours[day].startTime],
          originalEndTime: [businessHours[day].endTime],
        },
        {
          validators: isEnabled
            ? [CustomValidators.startBeforeEndValidator()]
            : [],
        },
      );
      this.businessHoursGroup.addControl(day, dayGroup);
    });
    this.businessHoursGroup.addControl(
      'outOfOffice',
      this.fb.group({
        enabled: [outOfOffice.enabled],
        expirationDate: [
          new Date(outOfOffice.expirationDate),
          [Validators.required, CustomValidators.dateValidator],
        ],
        expirationTime: [
          this.formatTimeToDate(
            outOfOffice.expirationTime.hours,
            outOfOffice.expirationTime.minutes,
          ),
          [Validators.required],
        ],
      }),
    );
  }

  setUpBusinessHoursData(businessDaysRes: BusinessDays): BusinessHours {
    const mappedData: BusinessHours = {};
    Object.keys(businessDaysRes).forEach((day) => {
      const dayData = businessDaysRes[day as keyof BusinessDays];
      mappedData[day] = {
        enabled: dayData.enabled,
        startTime: this.formatTimeToDate(
          dayData.startTime.hours,
          dayData.startTime.minutes,
        ),
        endTime: this.formatTimeToDate(
          dayData.endTime.hours,
          dayData.endTime.minutes,
        ),
      };
    });
    return mappedData;
  }

  calculateMaxDate(): void {
    const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
    this.maxDate = new Date(this.minDate.getTime() + twoWeeksInMilliseconds); // Add 14 days to minDate
  }

  formatTimeToDate(hour: number, minute: number): Date {
    const now = new Date();
    const date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      minute,
    );
    // always show fixed hours/minutes in local timezone
    return date;
  }

  formatDateToTime(date: Date): { hours: number; minutes: number } {
    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };
  }

  formatBusinessHoursForSave(): BusinessHoursResponse['businessHours'] {
    const mappedData = {} as BusinessHoursResponse['businessHours'];
    this.days.forEach((day) => {
      const dayGroup = this.businessHoursGroup.get(day) as FormGroup;
      if (!dayGroup) {
        console.warn(`No form group found for ${day}`);
        return;
      }
      // Ensure all values are retrieved, including disabled controls
      const enabled = dayGroup.get('enabled')?.value || false;
      const startTime = dayGroup.get('startTime')?.value;
      const endTime = dayGroup.get('endTime')?.value;
      mappedData[day as keyof BusinessDays] = {
        enabled,
        startTime: this.formatDateToTime(startTime),
        endTime: this.formatDateToTime(endTime),
      };
    });
    mappedData['timezone'] = this.timezone;
    const outOfOfficeGroup = this.businessHoursGroup.get(
      'outOfOffice',
    ) as FormGroup;
    const expirationDate = outOfOfficeGroup.get('expirationDate')?.value;
    const expirationTime = outOfOfficeGroup.get('expirationTime')?.value;
    mappedData['outOfOffice'] = {
      enabled: this.isOutOfOfficeEnabled,
      expirationDate,
      expirationTime: this.formatDateToTime(expirationTime),
    };
    return mappedData;
  }

  toggleDay(day: string) {
    const dayGroup = this.businessHoursGroup.get(day) as FormGroup;
    const isEnabled = !dayGroup.get('enabled')?.value;

    dayGroup.get('enabled')?.setValue(isEnabled);
    if (isEnabled) {
      dayGroup.get('startTime')?.enable();
      dayGroup.get('endTime')?.enable();
      dayGroup.setValidators([CustomValidators.startBeforeEndValidator()]);
    } else {
      dayGroup.get('startTime')?.disable();
      dayGroup.get('endTime')?.disable();
      dayGroup.clearValidators();
    }
  }

  toggleOutOfOffice() {
    this.isOutOfOfficeEnabled = !this.isOutOfOfficeEnabled;
  }

  saveAndContinue() {
    const updatedBusinessHours = this.formatBusinessHoursForSave();
    this.twoWaySetupService
      .updateBusinessHours(this.clientId, updatedBusinessHours)
      .subscribe((response) => {
        return response;
      });
    this.router.navigateByUrl(`/two-way/setup/notification-ping`);
  }

  isLoading(): boolean {
    return (
      (!this.businessHoursGroup ||
        Object.keys(this.businessHoursGroup.controls).length === 0) &&
      !this.isErrorBusinessHours
    );
  }
}
