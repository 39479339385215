import React, { Children, isValidElement, useState } from 'react';
import { styled, useStyletron } from 'baseui';
import { CustomTheme } from '../baseweb-custom-theme/customTheme';
import { Search } from 'baseui/icon';
import { Button, SHAPE, SIZE } from 'baseui/button';
import {
  OnChangeParams,
  Select,
  StyledDropdown,
  TYPE,
  Value,
} from 'baseui/select';
import { ChannelsFilterOptions } from '../relay-messenger-axios-service/relay-messenger-axios-service';
import { SearchResult } from '../relay-messenger-list/RelayMessenger.list';
import { TagsAndCustomers } from '../relay-messenger-conversations/RelayMessengerConversations';

export type SearchProps = {
  setIsSearchInputOpen: (value: boolean) => void;
  isSearchInputOpen: boolean;
  tagsAndCustomersList: TagsAndCustomers;
  isAgent?: boolean;
  setChannelsFilterOptions: React.Dispatch<
    React.SetStateAction<ChannelsFilterOptions>
  >;
  searchOptions: SearchResult[];
  setSearchOptions: React.Dispatch<React.SetStateAction<SearchResult[]>>;
  channelsFilterOptions: ChannelsFilterOptions;
  setInputValue?: (value: string) => void;
  setOffset?: React.Dispatch<React.SetStateAction<number>>;
};

const isReactElement = (child: any): child is React.ReactElement => {
  return isValidElement(child);
};

export const SearchInput = ({
  setIsSearchInputOpen,
  isSearchInputOpen,
  tagsAndCustomersList,
  searchOptions,
  setSearchOptions,
  setChannelsFilterOptions,
  channelsFilterOptions,
  setInputValue,
  setOffset,
}: SearchProps) => {
  const [, theme] = useStyletron();
  const customTheme = theme as CustomTheme;
  const [value, setValue] = useState<Value>([]);
  const placeholder = 'Search by tags or customers';
  const setSearchOption = (selectedValue: Value) => {
    setOffset(0);
    if (selectedValue.length > 0) {
      const formattedSelectedValue = selectedValue.map((el) => ({
        id: String(el.id),
        label: el.label as string,
        type: el.type,
      }));
      // check that formattedSelectedValue was not already added to the list
      const selectedValueNotAdded = formattedSelectedValue.some(
        (value) =>
          (value.type === 'tag' &&
            !channelsFilterOptions.tags.includes(value.id)) ||
          (value.type === 'customer' &&
            !channelsFilterOptions.customer_ids.includes(value.id)),
      );
      if (selectedValueNotAdded) {
        const customersList = formattedSelectedValue.filter(
          (el) => el.type === 'customer',
        );
        const tagsList = formattedSelectedValue.filter(
          (el) => el.type === 'tag',
        );
        // should not set option id if it is already added
        setChannelsFilterOptions({
          tags: channelsFilterOptions.tags.concat(tagsList.map((el) => el.id)),
          launched_by: [],
          customer_ids: channelsFilterOptions.customer_ids.concat(
            customersList.map((el) => el.id),
          ),
        });
        setSearchOptions([...searchOptions, ...formattedSelectedValue]);
      }
    } else {
      setChannelsFilterOptions({
        tags: [],
        launched_by: [],
        customer_ids: [],
      });
      setSearchOptions([]);
    }
    setValue([]);
  };

  const OptGroupOption = styled('div', () => ({
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingLeft: '10px',
    paddingRight: '8px',
    paddingTop: '6px',
    paddingBottom: '11px',
    color: '#000',
    fontFamily:
      'UberMoveText, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: '14px',
    lineHeight: '16px',
  }));

  const CustomDropdown = (props) => {
    const childrenArray = Children.toArray(props.children);
    return (
      <StyledDropdown {...props}>
        {childrenArray.map((child) =>
          isReactElement(child) &&
          (child?.props?.children === 'Tags' ||
            child?.props?.children === 'Customers') ? (
            <OptGroupOption key={child.key}>
              {child?.props?.children}
            </OptGroupOption>
          ) : (
            child
          ),
        )}
      </StyledDropdown>
    );
  };

  if (isSearchInputOpen) {
    return (
      <>
        <Select
          options={tagsAndCustomersList}
          value={value}
          type={TYPE.search}
          placeholder={placeholder}
          onChange={(params: OnChangeParams) => {
            setValue(params.value);
            setSearchOption(params.value);
          }}
          onInputChange={(event) => {
            setInputValue(event.currentTarget.value);
          }}
          onClose={() => {
            setInputValue('');
          }}
          overrides={{
            Root: {
              style: {
                width: `100%`,
                backgroundColor: '#FFFFFF',
                outline: `#00000`,
              },
            },
            ControlContainer: {
              style: {
                backgroundColor: '#FFFFFF',
                padding: `0px`,
              },
            },
            Dropdown: {
              component: CustomDropdown,
            },
            DropdownListItem: {
              style: {
                paddingLeft: '21px',
              },
            },
          }}
          maxDropdownHeight="300px"
        />
        <Button
          onClick={() => setIsSearchInputOpen(false)}
          size={SIZE.compact}
          kind={'tertiary'}
          overrides={{
            BaseButton: {
              props: { 'data-testid': 'search-actions-close-btn' },
            },
          }}
        >
          Close
        </Button>
      </>
    );
  }

  if (!isSearchInputOpen) {
    return (
      <Button
        onClick={() => setIsSearchInputOpen(true)}
        size={SIZE.compact}
        shape={SHAPE.circle}
        overrides={{
          BaseButton: {
            style: {
              backgroundColor: isSearchInputOpen
                ? '#3693BF'
                : customTheme.colors.white,
              color: isSearchInputOpen ? 'white' : '#3693BF',
              outline: 'none !important',
              ':hover': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
              ':active': {
                backgroundColor: '#3693BF',
                color: 'white',
              },
            },
            props: { 'data-testid': 'search-actions-btn' },
          },
        }}
      >
        <Search size={28} />
      </Button>
    );
  }
};
