import React, { useEffect, useState } from 'react';
import { useStyletron } from 'styletron-react';
import { useForm } from 'react-hook-form';
import {
  validateName,
  validatePassword,
  validateUri,
  validateUsername,
  validateHeadersArray,
} from '@app/api-mgmt/components/push-api/functions/validate';
import { BasicAuthConfiguration } from '@app/api-mgmt/components/push-api/types/push-api-models';
import { useClientInfo } from '@app/api-mgmt/components/push-api/context/ClientInfoContext';
import { PushAPIService } from '@app/api-mgmt/components/push-api/push-api-service';
import { RnStepper } from '@app/react-baseweb/baseweb-custom-components/RnStepper';
import { BasicAuthSettings } from './BasicAuthSettings';
import { CustomHeadersEditor } from '../form-components/CustomHeadersEditor';
import { EventsEditor } from '../form-components/EventsEditor';

export type BasicAuthFormData = {
  name: string;
  uid: string;
  active: boolean;
  uri: string;
  headers: {};
  sensitiveHeaders?: {};
  sensitiveHeadersMasked?: {};
  method: 'GET' | 'PUT' | 'POST';
  eventlist: string[];
  queue_name: string;
  username: string;
  password: string;
  batching: boolean;
};

export type BasicAuthReactFormData = BasicAuthFormData & {
  eventlist: string;
};

export const BasicAuthEditor = ({
  onSaveClicked,
  onCancelClicked,
  onWasEdited,
  integration,
}: {
  onSaveClicked?: (newIntegration: BasicAuthConfiguration) => void;
  onCancelClicked?: () => void;
  onWasEdited?: () => void;
  integration?: BasicAuthConfiguration;
}) => {
  const [css] = useStyletron();
  const [{ clientId }] = useClientInfo();

  const defaultFormData = {
    uid: integration?.uid ?? '',
    name: integration?.name ?? '',
    active: integration?.active ?? true,
    uri: integration?.uri ?? '',
    eventlist: integration?.eventlist ?? [],
    queue_name: integration?.queue_name ?? '',
    username: integration?.username ?? '',
    password: integration?.password ?? '',
    method: integration?.method ?? 'POST',
    newapi: integration?.newapi !== undefined ? integration.newapi : true,
    batching: integration?.batching ?? false,
  };

  const [authSettings, setAuthSettings] = useState({
    name: defaultFormData.name,
    uri: defaultFormData.uri,
    username: defaultFormData.username,
    password: defaultFormData.password,
    method: defaultFormData.method,
    batching: defaultFormData.batching,
  });

  const defaultAllHeaders = [
    ...Object.entries(integration?.headers || {}).map(([key, value]) => ({
      key,
      value,
      source: 'headers',
    })),
    ...Object.entries(integration?.sensitiveHeaders || {}).map(
      ([key, value]) => ({ key, value, source: 'sensitiveHeaders' }),
    ),
    ...Object.entries(integration?.sensitiveHeadersMasked || {}).map(
      ([key, value]) => ({ key, value, source: 'sensitiveHeadersMasked' }),
    ),
  ];

  const [allHeaders, setAllHeaders] = useState(defaultAllHeaders || []);

  const updateAuthSettings = (updatedSettings) => {
    setAuthSettings((prevSettings) => ({
      ...prevSettings,
      ...updatedSettings,
    }));
  };

  const [integrationNames, setIntegrationNames] = useState([]);

  const { reset, getValues } = useForm<BasicAuthReactFormData>({
    defaultValues: defaultFormData,
  });

  const [eventlist, setEventlist] = useState(getValues('eventlist'));

  const [newapi, setNewapi] = useState(defaultFormData.newapi);
  
  async function updateIntegrationNames() {
    setIntegrationNames(await PushAPIService.getIntegrationNames(clientId));
  }

  useEffect(() => {
    updateIntegrationNames();
  }, []);

  const checkIfActive = () => {
    if (integration && integration.active === true) {
      return true;
    }
  };

  // used to determine whether the user should be prompted with a confirmation modal upon exiting without having saved changes
  const isFormEdited = () => {
    const areHeadersEqual =
      allHeaders.length === defaultAllHeaders.length &&
      allHeaders.every((header, index) => {
        return (
          header.key === defaultAllHeaders[index].key &&
          header.value === defaultAllHeaders[index].value
        );
      });
  
    return (
      authSettings.name !== defaultFormData.name ||
      authSettings.uri !== defaultFormData.uri ||
      authSettings.username !== defaultFormData.username ||
      authSettings.password !== defaultFormData.password ||
      authSettings.method !== defaultFormData.method ||
      authSettings.batching !== defaultFormData.batching ||
      !areHeadersEqual
    );
  };

  useEffect(() => {
    if (isFormEdited()) {
      onWasEdited();
    }
  }, [authSettings, allHeaders, onWasEdited]);

  const isSettingsValid =
    validateName(authSettings.name, integration?.name, integrationNames) ===
      '' &&
    validateUri(authSettings.uri) === '' &&
    validateUsername(authSettings.username) === '' &&
    validatePassword(authSettings.password) === '';

  const isHeadersValid =
    validateHeadersArray(
      allHeaders.map((header) => ({ ...header, value: String(header.value) })),
    ) === '';

  const isValid = isSettingsValid && isHeadersValid;

  /**
   * Handles when data is submitted to the form (e.g. click Save or press
   * Enter).
   */
  const onSubmit = (activate: boolean) => {
    if (isValid) {
      // Separate headers and sensitiveHeaders;
      const headers = allHeaders
        .filter((header) => header.source === 'headers')
        .reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {});

      const sensitiveHeaders = allHeaders
        .filter((header) => header.source === 'sensitiveHeaders')
        .reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {});

      onSaveClicked({
        ...defaultFormData,
        type: 'basic-auth',
        name: authSettings.name,
        uri: authSettings.uri,
        username: authSettings.username,
        password: authSettings.password,
        eventlist,
        headers: headers,
        sensitiveHeaders: sensitiveHeaders,
        method: authSettings.method,
        batching: authSettings.batching,
        newapi,
      });
    }
  };

  return (
    <div
      className={css({
        marginTop: '10px',
      })}
    >
      <RnStepper
        steps={[
          {
            label: 'Settings',
            component: (
              <BasicAuthSettings
                authSettings={authSettings}
                updateAuthSettings={updateAuthSettings}
                integrationNames={integrationNames}
                integration={integration}
              />
            ),
            stepComplete: isSettingsValid,
          },
          {
            label: 'Custom Headers',
            component: (
              <CustomHeadersEditor
                allHeaders={allHeaders}
                setAllHeaders={setAllHeaders}
              />
            ),
            stepComplete: isHeadersValid,
          },
          {
            label: 'Events',
            component: (
              <EventsEditor
                eventlist={eventlist}
                setEventlist={setEventlist}
                integration={integration}
                onSubmit={onSubmit}
              />
            ),
            stepComplete: isValid,
          },
        ]}
        onCompletePrimary={() => onSubmit(true)}
        completePrimaryText={checkIfActive() ? 'SAVE' : 'ACTIVATE'}
        onCompleteSecondary={() => onSubmit(false)}
        completeSecondaryText={
          checkIfActive() ? 'Save & Deactivate' : 'Save & Exit'
        }
      />
    </div>
  );
};
