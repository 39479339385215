import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'journey-launched',
    templateUrl: './journey-launched.component.html',
    styleUrls: ['./journey-launched.component.scss'],
    standalone: false
})
export class JourneyLaunchedComponent implements OnInit {
  @Input() leftButtonLabel: string;
  @Input() rightButtonLabel: string;
  @Input() scheduleTime: Date;
  @Input() launchResult: any;
  @Output() leftButtonClick = new EventEmitter();
  @Output() rightButtonClick = new EventEmitter();

  errors: number = 0;
  recipients: number = 0;
  fileJobSuccess: boolean;

  constructor() {}

  ngOnInit() {
    if (this.launchResult && this.launchResult.recipients) {
      _.each(this.launchResult.recipients, (rec) => {
        ++this.recipients;
        if (rec.error || 'error' in rec.message) {
          ++this.errors;
        }
      });
    } else if (this.launchResult && this.launchResult.message) {
      this.fileJobSuccess =
        this.launchResult.message === 'Accepted' ||
        this.launchResult.message.status === 'sent';
    }
  }
}
