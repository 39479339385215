
import { WorkflowClass } from '@app/core/models/message';
import { Journey } from '@app/core/models/journey';
import { SelectableJourneyComponent } from '@app/cx-builder/selectable-journey-component';
import { BoxMenuComponent } from '@app/cx-builder/box-menu/box-menu.component';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef,ViewChild } from '@angular/core';

@Component({
    selector: 'journey-trigger',
    templateUrl: './journey-trigger.component.html',
    styleUrls: ['./journey-trigger.component.scss'],
    standalone: false
})
export class JourneyTriggerComponent implements OnInit, SelectableJourneyComponent {
  @Input() trigger: WorkflowClass;
  @Input() selected: boolean;
  @Input() journey: Journey;
  @Output() deleteCmp = new EventEmitter;
  @Output() editCmp = new EventEmitter;
  @ViewChild('triggerBox', { read: ElementRef, static: true }) triggerElement: ElementRef;
  @ViewChild('boxMenu', { static: true }) boxMenu: BoxMenuComponent;
  
  boxMenuItems = [
    {
      labelText: 'Edit trigger',
      onClickHandler: () => { this.editComponent(); }
    },
    {
      labelText: 'Delete trigger',
      onClickHandler: () => { this.deleteComponent(); }
    }
  ];
  
  constructor() {}

  ngOnInit() {}

  deleteComponent() {
    this.deleteCmp.emit(this.trigger);
  }

  editComponent() {
    this.editCmp.emit();
    this.boxMenu.onCloseMenu();
  }

  getJourneyComponentElementRef(): ElementRef {
    return this.triggerElement;
  }

  getJourneyComponent(): WorkflowClass {
    return this.trigger;
  }

  displayableTrigger(): string {
    return this.trigger.client_trigger_id || this.trigger.name;
  }

  deselectChildren() {
    // Delays don't have children, so nothing to do.
    return;
  }
}
