import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-remove-journey-modal',
    templateUrl: './remove-journey-modal.component.html',
    styleUrls: ['./remove-journey-modal.component.scss'],
    standalone: false
})
export class RemoveJourneyModalComponent implements OnInit {
  @Output() cancelRemove: EventEmitter<null> = new EventEmitter();
  @Output() affirmRemove: EventEmitter<null> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  cancel(): void {
    this.cancelRemove.emit();
  }

  remove(): void {
    this.affirmRemove.emit();
  }

}
