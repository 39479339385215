import React from 'react';
import { StyledHeadCell, StyledTable } from 'baseui/table-grid';
import {
  StyledHeadCellOverride,
} from '@app/react-baseweb/baseweb-custom-theme/customTheme';
import {
  BodyCell,
  RowContainer,
} from '@app/react-baseweb/styled-containers/styledContainers';
import { PLACEMENT, StatefulTooltip } from 'baseui/tooltip';
import { DisplayAction } from '@app/client-config/client-form/client-power-ups-config/components/configurations-display/Actions';
import { useStyletron } from 'baseui';
import { FormConfig } from '@app/client-config/client-form/client-power-ups-config/types/form-config';

const ConfigurationRow = ({
  configuration,
  editConfigurationClicked,
  deleteConfigurationClicked,
}: {
  configuration: FormConfig;
  editConfigurationClicked: (newConfiguration: FormConfig) => void;
  deleteConfigurationClicked: (newConfiguration: FormConfig) => void;
}) => {
  const [css] = useStyletron();

  const toLocalTimeZone = (value?: string) => {
    if (value) {
      const parsedDate = Date.parse(value);
      const date = new Date(parsedDate);
      return date
        .toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZoneName: 'short',
        })
        .replace(',', '')
        .replace('24:', '00:');
    }
    return undefined;
  };

  return (
    <>
      <div
        role="row"
        onClick={() => editConfigurationClicked(configuration)}
        className={css({ display: 'contents', cursor: 'pointer' })}
      >
        <RowContainer>
          <BodyCell>{configuration.form_name}</BodyCell>
          <BodyCell>{configuration.form_id}</BodyCell>
          <BodyCell>{configuration.uid}</BodyCell>
          <BodyCell>{configuration.external_id}</BodyCell>
          <BodyCell>
            <span>
              {toLocalTimeZone(configuration.updated_at ?? configuration.created_at)}
              <br/>
              {`By: ${configuration.updated_by ?? configuration.created_by}`}
            </span>
          </BodyCell>
          <BodyCell>
            <StatefulTooltip
              accessibilityType={'tooltip'}
              content="edit"
              placement={PLACEMENT.bottom}
            >
              <DisplayAction
                onClick={(e) => {
                  e.stopPropagation();
                  editConfigurationClicked(configuration);
                }}
              >
                <i className="fa fa-pencil" style={{ fontSize: '18px' }}></i>
              </DisplayAction>
            </StatefulTooltip>
            <StatefulTooltip
              accessibilityType={'tooltip'}
              content="delete"
              placement={PLACEMENT.bottom}
            >
              <DisplayAction
                onClick={(e) => {
                  e.stopPropagation();
                  deleteConfigurationClicked(configuration);
                }}
              >
                <i className="fa fa-trash" style={{ fontSize: '18px' }}></i>
              </DisplayAction>
            </StatefulTooltip>
          </BodyCell>
        </RowContainer>
      </div>
    </>
  );
};

/**
 * A table to display a list of configurations.
 */
export const ConfigurationsDisplay = ({
  configurations,
  editConfigurationClicked,
  deleteConfigurationClicked,
}: {
  configurations: FormConfig[];
  editConfigurationClicked: (selectedConfiguration: FormConfig) => void;
  deleteConfigurationClicked: (selectedConfiguration: FormConfig) => void;
}) => {
  const [css] = useStyletron();
  return (
    <>
      {configurations.length > 0 && (
        <>
          <StyledTable
            role="grid"
            $gridTemplateColumns="auto auto auto auto auto auto"
            style={{
              border: 'none',
              marginTop: '30px',
              marginRight: 'auto',
              marginLeft: 'auto',
            }}
          >
            <div
              role="row"
              className={css({
                display: 'contents',
              })}
            >
              <StyledHeadCell style={StyledHeadCellOverride}>
                Name
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Form ID
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Configuration ID
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Power-Ups ID
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Modified
              </StyledHeadCell>
              <StyledHeadCell style={StyledHeadCellOverride}>
                Actions
              </StyledHeadCell>
            </div>

            {configurations.map((configuration) => {
              return (
                <ConfigurationRow
                  key={configuration.uid}
                  configuration={configuration}
                  editConfigurationClicked={editConfigurationClicked}
                  deleteConfigurationClicked={deleteConfigurationClicked}
                />
              );
            })}
          </StyledTable>
        </>
      )}
    </>
  );
};
