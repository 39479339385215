import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { Role, User } from '@app/core/models/user';
import { Client } from '@app/core/models/client';
import { UserService } from '@app/core/services/user.service';
import { ClientService } from '@app/core/services/client.service';
import { SessionService } from '@app/security/session.service';
import { Ordering } from '@app/core/utils/ordering';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import {
  Permissions,
  PermissionService,
} from '@app/core/services/permission.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ControlTagsService,
  ControlGroup,
} from '@app/core/services/control-tags.service';
import { TwoWaySharedService } from '@app/core/services/two-way-shared.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { FeatureService } from '@app/core/services/feature.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss'],
    standalone: false
})
export class UserEditComponent implements OnInit, OnChanges {
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog;
  @ViewChild('resetMfaSucess', { static: true })
  resetMfaSucess: MessageDialogComponent;
  @ViewChild('resetMfaFail', { static: true })
  resetMfaFail: MessageDialogComponent;

  @Input() userID: string;
  @Output() close: EventEmitter<string> = new EventEmitter();
  @Input() isNew: boolean;
  loading: boolean;
  showUserInfo: boolean = true;
  showRoleInfo: boolean = true;
  showClientInfo: boolean = true;
  showReset: boolean = false;
  adminRoleOptions: Role[] = [];
  twoWayRoleOptions: Role[] = [];
  selectedSecondaryClient: Client;
  userInfoValidGroup: UntypedFormGroup;
  user: User = new User();
  availableClients: Client[] = [];
  availableSecondaryClients: Client[] = [];
  allClients: Client[] = [];
  showAll: boolean;
  permissions = Permissions;
  twoWayPermRegex = /two_way/;
  controlGroups: ControlGroup[] = [];
  currentClient: Client;
  featureFlags = FeatureFlags;

  constructor(
    private userService: UserService,
    private clientService: ClientService,
    private formBuilder: UntypedFormBuilder,
    private sessionService: SessionService,
    public ps: PermissionService,
    private controlTagsService: ControlTagsService,
    private featureService: FeatureService,
    public twoWayService: TwoWaySharedService,
  ) {}

  ngOnInit() {
    this.getRoles();
    this.getClients();
    this.currentClient = this.sessionService.getCurrentUsersClient();
    if (!this.isNew) {
      this.loading = true;
    }
  }

  ngOnChanges(changes) {
    if (changes.userID && !changes.userID.firstChange) {
      this.resetUser();
    }
  }

  initForm(user) {
    this.userInfoValidGroup = this.formBuilder.group({
      first_name: [user.first_name, Validators.required],
      last_name: [user.last_name, Validators.required],
      group_id: [user.group_id],
      primary_client: [user.client_id, Validators.required],
      secondary_clients: new UntypedFormArray([]),
      email: [
        user.email_address,
        [Validators.required, CustomValidators.emailToBeTrimmed],
      ],
      // roles get updated by other unnecessarily convoluted means... They come from a key 'role_id'
      //  which is actually an array value w/ both admin & two way `['admin_role', 'two_way_role']`
      admin_role: [null, Validators.required],
      two_way_role: [null],
    });
    // better to disable here than in template, according to Angular
    this.userInfoValidGroup.get('primary_client').disable();

    _.each(user.secondary_clients, (secondaryClient) => {
      this.selectedSecondaryClientsCtl.push(
        new UntypedFormControl(secondaryClient),
      );
    });

    this.userInfoValidGroup.controls['primary_client'].valueChanges.subscribe(
      () => {
        this.handlePrimaryClientChange();
      },
    );
  }
  get hideControlGroup(): boolean {
    return this.featureService.checkFlag(this.featureFlags.hide_control_group);
  }
  get selectedSecondaryClients(): string[] {
    return this.selectedSecondaryClientsCtl.value;
  }

  get selectedSecondaryClientsCtl(): UntypedFormArray {
    return this.userInfoValidGroup.controls[
      'secondary_clients'
    ] as UntypedFormArray;
  }

  resetPasswordConfig() {
    return {
      title: 'Send password link',
      icon: 'icon-secure',
      msg: `An auth link has been generated for the user ${this.user.email_address}. The user will receive an email with the link to set their password.`,
      btns: [{ name: 'Close', icon: 'fa-times' }],
    };
  }

  getClients() {
    const ordering = new Ordering('data.company_name');

    this.clientService.getAllClients(ordering).subscribe(
      (clients) => {
        this.allClients = clients;
        this.resetUser();
      },
      (error) => {
        this.loading = false;
      },
    );
  }

  resetUser() {
    if (!this.isNew) {
      this.userService.getUserById(this.userID).subscribe((user) => {
        this.initForm(user);
        this.user = user;

        const twoWayPermRegex = this.twoWayPermRegex; // b/c the `this` context changes in lodash callbacks

        this.selectedAdminRole.setValue(
          _.reject(user.role_id, (role) => {
            return twoWayPermRegex.test(role);
          })[0],
        );

        this.selectedTwoWayRole.setValue(
          _.filter(user.role_id, (role) => {
            return twoWayPermRegex.test(role);
          })[0],
        );

        if (
          this.selectedSecondaryClients.length &&
          this.selectedSecondaryClients[0] === '*'
        ) {
          this.availableClients = [];
        } else {
          this.updateAvailableClients();
        }

        this.userInfoValidGroup.get('group_id').setValue(user.group_id); // set group_id to user's value in form
        this.userInfoValidGroup.get('primary_client').setValue(user.client_id); // set initial value for primary client id in form
        this.refreshControlGroups();

        this.loading = false;
      });
    } else {
      this.user.client_id = this.sessionService.currentUser.client.id;
      this.initForm(this.user);
      this.userInfoValidGroup
        .get('primary_client')
        .setValue(this.user.client_id); // set initial value for primary client id in form
      this.updateAvailableClients();
      this.refreshControlGroups();
      this.loading = false;
    }
  }

  handlePrimaryClientChange() {
    if (
      this.selectedSecondaryClient &&
      this.selectedSecondaryClient.id ===
        this.userInfoValidGroup.controls['primary_client'].value
    ) {
      this.selectedSecondaryClient = null;
    }
    this.updateAvailableClients();
    this.refreshControlGroups();
  }

  // filters this.allClients to get options for the Primary Client dropdown
  private newAvailableClients(): Client[] {
    return _.reject(this.allClients, (client) => {
      return this.isInSelectedSecondaryClientArray(client);
    });
  }

  // filters this.allClients to get options for the Secondary Client dropdown
  private newAvailableSecondaryClients(): Client[] {
    return _.reject(this.allClients, (client) => {
      return (
        this.isInSelectedSecondaryClientArray(client) ||
        this.isPrimaryClient(client)
      );
    });
  }

  private isInSelectedSecondaryClientArray(client): boolean {
    return _.includes(this.selectedSecondaryClientsCtl.value, client.id);
  }

  private isPrimaryClient(client): boolean {
    return (
      this.userInfoValidGroup.controls['primary_client'].value === client.id
    );
  }

  private updateAvailableClients(): void {
    this.availableClients = this.newAvailableClients();
    if (
      this.selectedSecondaryClients.length &&
      this.selectedSecondaryClients[0] === '*'
    ) {
      this.availableSecondaryClients = [];
    } else {
      this.availableSecondaryClients = this.newAvailableSecondaryClients();
    }
  }

  canRemove(clientId): boolean {
    const client = this.allClients.find((c) => c.id === clientId);
    return !!client;
  }

  getCompanyNameById(clientId) {
    const client = this.allClients.find((c) => c.id === clientId);
    if (client) {
      return client.company_name;
    } else {
      if (clientId === '*') {
        return 'You have all the clients';
      }
      return clientId;
    }
  }

  adminRoleChange() {
    if (this.selectedAdminRole.value === 'relay-super-admin') {
      this.userInfoValidGroup.controls['secondary_clients'] =
        new UntypedFormArray([new UntypedFormControl('*')]);
    } else {
      if (
        this.selectedSecondaryClients.length &&
        this.selectedSecondaryClients[0] === '*'
      ) {
        // TODO: change to this after ng8 upgrade:
        // this.selectedSecondaryClientsCtl.clear();
        this.userInfoValidGroup.controls['secondary_clients'] =
          new UntypedFormArray([]);
      }
    }
    this.updateAvailableClients();
    this.roleChange();
  }

  roleChange() {
    const newRoles = _.compact([
      this.selectedAdminRole.value,
      this.selectedTwoWayRole.value,
    ]);
    this.user.role_id = newRoles; // manually set model to new value
  }

  get selectedAdminRole(): AbstractControl {
    return this.userInfoValidGroup.get('admin_role') as AbstractControl;
  }

  get selectedTwoWayRole(): AbstractControl {
    return this.userInfoValidGroup.get('two_way_role') as AbstractControl;
  }

  get selectedControlGroup(): AbstractControl {
    return this.userInfoValidGroup.get('group_id') as AbstractControl;
  }

  get selectedControlGroupId(): string {
    return this.selectedControlGroup.value;
  }

  addClient() {
    if (this.selectedSecondaryClient) {
      this.selectedSecondaryClientsCtl.insert(
        0,
        new UntypedFormControl(this.selectedSecondaryClient.id),
      );
      this.updateAvailableClients();
      this.selectedSecondaryClient = null;
    }
  }

  removeClient(clientId: string, index: number) {
    const clientToRemoveIndex =
      this.selectedSecondaryClientsCtl.value.indexOf(clientId);
    this.selectedSecondaryClientsCtl.removeAt(clientToRemoveIndex);
    this.updateAvailableClients();
  }

  /**
   * Extract and store user data from the form in the DOM
   *
   * @returns the user data
   */
  getUpdatedUserModel(): User {
    const formData = this.userInfoValidGroup.getRawValue();
    let updatedUserData = {};
    if (this.isNew) {
      updatedUserData = _.assign(this.user, {
        firstName: formData.first_name,
        lastName: formData.last_name,
        emailAddress: formData.email,
        email_address: formData.email,
        clientId: formData.primary_client,
        secondaryClients: formData.secondary_clients,
        roleId: [formData.admin_role, formData.two_way_role].filter(Boolean),
      });
      // only add group_id key if a two way role is present
      if (formData.two_way_role) {
        this.user.groupId = formData.group_id;
      }
    } else {
      updatedUserData = _.assign(this.user, {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email_address: formData.email,
        client_id: formData.primary_client,
        secondary_clients: formData.secondary_clients,
        role_id: [formData.admin_role, formData.two_way_role].filter(Boolean),
      });
      // only add group_id key if a two way role is present
      if (formData.two_way_role) {
        // Should absolutely refactor to make better use of reactive forms
        this.user.group_id = formData.group_id;
      }
    }

    return updatedUserData as User;
  }

  onSave() {
    this.user = this.getUpdatedUserModel();

    for (const control in this.userInfoValidGroup.controls) {
      if (
        !this.userInfoValidGroup.controls.hasOwnProperty(control) &&
        !this.userInfoValidGroup.controls[control].value
      ) {
        continue;
      }
      this.userInfoValidGroup.controls[control].markAsTouched();
    }

    if (this.userInfoValidGroup.valid) {
      if (this.isNew) {
        this.createUser();
      } else {
        this.updateUser();
      }
    }
  }

  createUser() {
    const userToSend = Object.assign({}, this.user);
    delete userToSend.selected;
    this.userService.createUser(userToSend).subscribe({
      next: (resp) => {
        resp.sendUserEmail ? (this.showReset = true) : this.handleClose();
      },
      error: (error) => {
        if (error?.error?.reason ?? error?.error?.message) {
          this.messageDialog.showMessage(
            error?.error?.reason ?? error?.error?.message,
          );
        }
      },
    });
  }

  updateUser() {
    if (this.availableClients.length === 0) {
      this.selectedSecondaryClientsCtl.setValue(['*']);
    }
    this.userService.updateUser(this.user).subscribe(
      (d) => {
        this.handleClose();
      },
      (error: HttpErrorResponse) => {
        let reason;
        if (error && error.error && error.error.reason) {
          reason = error.error.reason;
        }
        this.messageDialog.showMessage(reason);
      },
    );
  }

  getRoles(): void {
    this.userService.getRoles().subscribe((roles) => {
      this.adminRoleOptions = _.reject(roles, (role) => {
        return this.twoWayPermRegex.test(role.id);
      });

      this.twoWayRoleOptions = _.filter(roles, (role) => {
        return this.twoWayPermRegex.test(role.id);
      });
    });
  }

  handleClose(): void {
    this.showReset = false;
    this.close.emit(this.userID);
  }

  resetPassword() {
    this.sessionService
      .resetPasswordAdmin(this.user.email_address)
      .subscribe((f) => {
        this.showReset = true;
      });
  }

  resetMFASecret() {
    this.sessionService.resetMFASecret(this.user.email_address).subscribe(
      (resp) => {
        this.resetMfaSucess.showMessage();
      },
      (error) => {
        this.resetMfaFail.showMessage();
      },
    );
  }

  get isMFAResetAllowed(): boolean {
    return (
      ((this.user.role_id.includes('relay-super-admin') ||
        this.user.role_id.includes('relay_admin')) &&
        this.sessionService.currentUser.role_id.includes(
          'relay-super-admin',
        )) ||
      this.sessionService.currentUser.role_id.includes('relay_admin')
    );
  }

  get isLoading(): boolean {
    return this.loading || !this.userInfoValidGroup;
  }

  refreshControlGroups(): void {
    if (this.twoWayService.showTwoWay()) {
      const clientId =
        this.userInfoValidGroup.get('primary_client').value ||
        this.sessionService.getCurrentUsersClient()['id'];
      this.controlGroups = [];

      this.controlTagsService.getGroups(clientId).subscribe((newGroups) => {
        this.controlGroups = this.controlGroups.concat(newGroups);
      });
    }
  }

  /**
   * Updates the user's control group every time a value is selected.
   *
   * @param selectedValue when the group selection is changed
   */
  controlGroupChanged(selectedValue: string): void {
    // set control group to null if none are selected
    if (!selectedValue) {
      selectedValue = null;
    }

    this.selectedControlGroup.setValue(selectedValue);
  }
}
