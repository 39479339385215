import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { JobProcessing } from '@app/core/models/client';
import * as _ from 'lodash';
@Component({
    selector: 'app-file-processing-config',
    templateUrl: './file-processing-config.component.html',
    styleUrls: ['./file-processing-config.component.scss'],
    standalone: false
})
export class FileProcessingConfigComponent implements OnInit {
  @Input() jobProcessing: JobProcessing;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  jobProcessingFormGroup: UntypedFormGroup;

  ngOnInit() {
    this.initForm();
  }

  checkIfValid(e: any) {
    this.isValid.emit(this.jobProcessingFormGroup.valid);
  }

  getFormValue(): JobProcessing {
    const jobProcessingData = _.assign(this.jobProcessing, this.jobProcessingFormGroup.getRawValue())
    return jobProcessingData as JobProcessing;
  }

  private initForm(): void {
    this.jobProcessingFormGroup = new UntypedFormGroup({
      'client_contact_email': new UntypedFormControl(this.jobProcessing.client_contact_email, [CustomValidators.emailToBeTrimmed]),
      'cs_manager_email': new UntypedFormControl(this.jobProcessing.cs_manager_email, [CustomValidators.emailToBeTrimmed])
    });
  }

}
