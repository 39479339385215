import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '@app/core/services/title.service';

@Component({
    selector: 'app-user-list-page',
    templateUrl: './user-list-page.component.html',
    standalone: false
})
export class UserListPageComponent implements OnInit, OnDestroy {

  constructor(private router: Router,
              private titleService: TitleService,
              public activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.titleService.activate('User Management', null, 'add user', 'fa fa-plus-circle', () => {
      this.router.navigateByUrl('/user-mgmt/new');
    });
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  editUser(id: string) {
    this.router.navigateByUrl(`user-mgmt/edit/${id}`);
  }

  get isNew() {
    return this.activeRoute.snapshot.url[1].path === 'new';
  }
}


