import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TitleService } from '@app/core/services/title.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { ControlTagsService, ControlGroup, ControlTag } from '@app/core/services/control-tags.service';
import { ControlGroupsFormBuilder } from '@app/two-way/control-groups/utils/control-groups-form-builder'
import { SessionService } from '@app/security/session.service';
import { Client } from '@app/core/models/client';
import * as _ from 'lodash';

@Component({
    selector: 'rn-control-tags',
    templateUrl: './control-tags.component.html',
    styleUrls: ['./control-tags.component.scss'],
    standalone: false
})

export class ControlTagsComponent implements OnInit, OnDestroy {
  @ViewChild('errorMsg', { static: true }) errorMessage: MessageDialogComponent;
  private savedGroups: ControlGroup[] = []; // groups for the current client.  Saved in memory for performance. 
  selectedGroupTempId: string; // id of selected group
  controlGroupsForm: UntypedFormGroup;
  allTagNames: string[]; // passed to ControlTagDetail instances to build the autocomplete in template
  allTags: ControlTag[];
  showNewTagLoading: boolean = false;
  currentClient: Client;

  constructor(
    private titleService: TitleService,
    private controlTagsService: ControlTagsService,
    private sessionService: SessionService,
  ) {}

  ngOnInit() {
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.titleService.activate(this.currentClient.feed_enabled ? 'Relay Messenger Setup' : 'Two Way Messaging Setup', 'Control Groups');

    // Fetches groups and initializes form
    // Sets selectedGroupTempId if there is at least 1 group returned.
    // Note: Groups will not have tags on initial load
    this.controlTagsService.getGroups().subscribe((newGroups: ControlGroup[]) => {
      this.savedGroups = newGroups;
      this.initForm();
      if (this.clientHasGroups()) {
        this.switchGroups(this.groupsCtl[0]); // also requests tags for the selected group 
      }
    }, (error) => {
      this.errorMessage.showMessage(`We're sorry, there was an error fetching groups for your client.`);
    });

    // get 'allTags' to populate the autocomplete
    this.getAllTags();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
    this.controlTagsService.reset(); // since updates to groups/tags aren't being broadcast to the controlTagsService, we need to clear it to get fresh server calls 
  }

  private initForm(): void {
    this.controlGroupsForm = new UntypedFormGroup({
      groups: ControlGroupsFormBuilder.buildGroupsFormArray(this.savedGroups)
    });
  }

  getAllTags() {
    this.controlTagsService.fetchTags().subscribe((newTags: ControlTag[]) => {
      this.allTagNames = _.map(newTags, tag => tag['tag_name']);
      this.allTags = newTags;
    }, (error) => {
      this.errorMessage.showMessage(`We're sorry, there was an error fetching tags for your client.`);
    });
  }

  removeTag(tagsArr, index) {
    tagsArr.removeAt(index);
  }

  /**
   * Validation & Tests
   */

  clientHasGroups(): boolean {
    return this.groupsCtl.length > 0;
  }

  isLoading(): boolean {
    return _.isEmpty(this.controlGroupsForm);
  }

  isSelectedGroup(groupCtl: UntypedFormGroup): boolean {
    return groupCtl.controls.temp_id.value === this.selectedGroupTempId;
  }

  showTagLoading(groupCtl: UntypedFormGroup): boolean {
    return this.isSavedGroup(groupCtl) && !this.groupTagsFetched(groupCtl);
  }

  groupIsInvalid(groupCtl: UntypedFormGroup): boolean {
    return groupCtl.invalid || !this.groupNameIsValid(groupCtl) || !this.groupTagsFetched(groupCtl) || !this.groupHasTags(groupCtl);
  }

  groupHasUnsavedChanges(groupCtl: UntypedFormGroup): boolean {
    /*
      we HAVE to use '.value' instead of '.getRawValue' here, because `.getRawValue` will include 
      disabled formControls, like the autoresponse field. 
      We want a FormGroup with a disabled autoresponse field to match a savedGroup with NO autoresponse 
      field.  If we use `getRawValue`, that comparison will break.
     */
    const currentVersion: ControlGroup = new ControlGroup(groupCtl.value);
    const savedVersion: ControlGroup = this.getMatchingSavedGroup(groupCtl);

    // always return false if there are still tags loading for this group
    if (!this.groupTagsFetched(groupCtl)) {
      return false;
    }

    // if this group is new and has never been saved...
    if (!this.isSavedGroup(groupCtl)) {
      return true;
    }

    // if there is a saved version, and the saved version doesn't match...
    if (savedVersion && !savedVersion.matches(currentVersion)) {
      return true;
    }

    return false;
  }

  groupNameIsValid(groupCtl: UntypedFormGroup): boolean {
    return (this.isSavedGroup(groupCtl) && this.groupNameIsUnchanged(groupCtl)) || this.groupNameIsUnique(groupCtl);
  }

  groupNameIsEmpty(groupCtl: UntypedFormGroup): boolean {
    return _.isEmpty(groupCtl.get('group_name').value);
  }

  private groupNameIsUnique(changedGroup: UntypedFormGroup): boolean {
    // filter out group w/ same id as the changedGroup (because the names are expected to match)
    const savedGroupsSansCurrentGroupId = _.reject(this.groupsCtl, (compareGroup) => {
      return changedGroup.get('temp_id').value && compareGroup.get('temp_id').value === changedGroup.get('temp_id').value;
    });

    // Gets array of group names
    const groupNames = _.map(savedGroupsSansCurrentGroupId, (compareGroup) => {
      return compareGroup.get('group_name').value;
    });

    return !_.includes(groupNames, changedGroup.get('group_name').value);
  }

  private groupNameIsUnchanged(groupCtl: UntypedFormGroup): boolean {
    return this.getMatchingSavedGroup(groupCtl)['group_name'] === groupCtl.get('group_name').value;
  }

  // group_id gets assigned server side, so if it has one, it's an existing group
  private isSavedGroup(groupCtl: UntypedFormGroup): boolean {
    return !_.isEmpty(groupCtl.get('group_id').value);
  }

  groupTagsFetched(groupCtl: UntypedFormGroup): boolean {
    return groupCtl.get('tags') !== null;
  }

  private groupHasTags(groupCtl: UntypedFormGroup): boolean {
    return groupCtl.get('tags').value.length > 0;
  }

  // return true if passed group and passed control are a match
  private isMatchingSavedGroup(savedGroup, control: UntypedFormGroup): boolean {
    return savedGroup['group_id'] === control.get('group_id').value;
  }

  /**
   * Events
   */

  // Needed so it doesn't submit the form when you hit "enter" in the autocomplete
  preventSubmitOnEnter(event): void {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  // for creating new groups
  addEmptyGroup(): void {
    const newGroup = ControlGroupsFormBuilder.buildGroupForm();
    this.groupsCtl.push(newGroup);
    this.selectedGroupTempId = newGroup.get('temp_id').value;
  }

  // Do not call unless tags have already been loaded
  addNewTagEvent(newTagName, group: UntypedFormGroup): void {
    // if there aren't any tags yet, create an empty array
    if (!group.controls['tags']) {
      group.addControl('tags', ControlGroupsFormBuilder.buildTagsFormArray([]))
    }
    const savedTag: ControlTag = this.getMatchingSavedTag(newTagName);

    // if it's an existing tag, show loading indicator, then fetch tag data
    // to see if there is an autoresponse attached already.
    if (savedTag) {
      this.showNewTagLoading = true;
      this.controlTagsService.getTag(savedTag.tag_id).subscribe((updatedTag) => {
        this.showNewTagLoading = false;
        this.addTagToGroup(updatedTag, group);
      });
    } else {
      this.addTagToGroup({tag_name: newTagName}, group);
    }
  }

  private addTagToGroup(newTag, group): void {
    const tagsArray = group.controls['tags'] as UntypedFormArray; // need to cast from abstractControl to FormArray for .push to work
    tagsArray.insert(0, ControlGroupsFormBuilder.buildTagForm(newTag));
  }

  // change selected group
  switchGroups(group): void {
    this.selectedGroupTempId = group.controls.temp_id.value;
    if (this.isSavedGroup(group) && !this.groupTagsFetched(group)) {
      this.getTagsForGroup(group);
    }
  }

  saveGroup(event, groupCtl: UntypedFormGroup): void {
    event.preventDefault(); // prevent page from reloading on form submission

    if (this.groupIsInvalid(groupCtl)) {
      groupCtl.markAllAsTouched();
      return;
    }

    this.controlTagsService.saveGroup(groupCtl.value).subscribe((updatedGroup) => {
      // Saved Group Updates (not reactive forms)
      if (this.savedGroups.length < this.groupsCtl.length) {
        // if the updated group is brand new, append it to the savedGroups list
        this.savedGroups.push(updatedGroup);
      } else {
        // else if the updatedGroup already exists, find old record and replace with the new version
        const matchedIndex = this.getMatchingSavedGroupIndex(updatedGroup);
        this.savedGroups[matchedIndex] = updatedGroup;
      }
      this.updateSavedGroups(); // update the list of saved groups with an api call
      // TODO: at first glance, it looks like updateSavedGroups and the if statement above are sort of overwriting each other, 
      // but if you comment out the if/else, it doesn't quite work as expected.  We could likely clean this up.  just need 
      // to spend more time looking into it. 

      // Form Group Updates
      const matchingFormGroup = this.getMatchingFormGroup(updatedGroup) || this.getMatchingFormGroupByName(updatedGroup);
      matchingFormGroup.patchValue(updatedGroup); // update form group

      // Other updates
      this.selectedGroupTempId = updatedGroup.temp_id; // update selected group id
      this.getAllTags(); // fetch a fresh list of tags for autocomplete, in case any new tags were added with the last group save
    }, (error) => {
      this.errorMessage.showMessage(`We're sorry, there was an error saving your group.`)
    });
  }

  // not sure if this'll work, because the newGroups won't have tag data?
  private updateSavedGroups() {
    this.controlTagsService.getGroups().subscribe((newGroups: ControlGroup[]) => {
      this.savedGroups = newGroups;
    }, (error) => {
      this.errorMessage.showMessage(`We're sorry, there was an error fetching groups for this client.`);
    });
  }

  private getTagsForGroup(groupCtl: UntypedFormGroup): void {
    const groupIdForRequest = groupCtl.get('group_id').value;
    this.controlTagsService.getTagsByGroup(groupIdForRequest)
    .subscribe((tags: ControlTag[]) => {
      // If user has switched groups since requesting tags, don't update the selected group
      if (this.selectedGroupCtl.get('group_id').value === groupIdForRequest) {
        this.selectedGroupCtl.addControl('tags', ControlGroupsFormBuilder.buildTagsFormArray(tags));
      }

      this.getMatchingSavedGroup(groupCtl).addTags(tags);
    });
  }

  /**
   * Getters
   */

  tagsListForGroup(groupCtl: UntypedFormGroup): UntypedFormControl[] {
    const tagsCtl = groupCtl.get('tags') as UntypedFormGroup; // was interpereting as AbstractControl
    return tagsCtl ? tagsCtl.getRawValue() : [];
  }

  get groupsCtl(): UntypedFormGroup[] {
    const form = this.controlGroupsForm.get('groups') as UntypedFormArray;
    return form.controls as UntypedFormGroup[];
  }

  get selectedGroupCtl(): UntypedFormGroup {
    return _.find(this.groupsCtl, (groupCtl) => {
      return groupCtl.get('temp_id').value === this.selectedGroupTempId;
    });
  }

  private getMatchingSavedTag(tagName): ControlTag {
    return _.find(this.allTags, (tag) => tag.tag_name === tagName);
  }

  // go through groups and find one that matches passed groupCtl
  private getMatchingSavedGroup(groupCtl: UntypedFormGroup): ControlGroup {
    return _.find(this.savedGroups, (savedGroup) => this.isMatchingSavedGroup(savedGroup, groupCtl));
  }

  // This is confusing and I'm sorry... this param is not named groupCtl because it's not 
  // a FormGroup (IE an AbstractControl).  But the Object type is actually 'ControlGroup',
  // which is a deceptively similar name...
  private getMatchingSavedGroupIndex(group: ControlGroup): number {
    return _.findIndex(this.savedGroups, (savedGroup) => {
      return savedGroup['group_id'] === group['group_id'];
    });
  }

  private getMatchingFormGroup(group: ControlGroup): UntypedFormGroup {
    return _.find(this.groupsCtl, (formGroup) => {
      return formGroup.get('group_id').value === group['group_id'];
    });
  }

  private getMatchingFormGroupByName(group: ControlGroup): UntypedFormGroup {
    return _.find(this.groupsCtl, (formGroup) => {
      return formGroup.get('group_name').value === group['group_name'];
    });
  }
}
