import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { ShowOnInvalidAndDirtyErrorStateMatcher } from '@app/core/utils/material-error-state-matchers';
import * as _ from 'lodash';


@Component({
    selector: 'rn-new-control-tag-form',
    templateUrl: './new-control-tag-form.component.html',
    styleUrls: ['./new-control-tag-form.component.scss'],
    standalone: false
})
export class NewControlTagFormComponent implements OnInit {
  @Output() addTag: EventEmitter<void> = new EventEmitter<void>();
  @Input() allTagNames: string[];
  @Input() formGroupRecievingTag: UntypedFormGroup;
  newTagName: UntypedFormControl;
  errorStateMatcher = new ShowOnInvalidAndDirtyErrorStateMatcher();

  constructor() { }

  ngOnInit() {
    this.newTagName = new UntypedFormControl('', CustomValidators.itemsAreUniqueByKeyExternal('tag_name', (this.formGroupRecievingTag.get('tags') as UntypedFormArray)));
  }

  // matchingOptions search is case-insensitive
  filteredAutocompleteOptions(): string[] {
    const searchTermRegex = new RegExp(`^${this.newTagName.value}`, 'i');
    return _.filter(this.autocompleteOptionsForGroup(), (option) => {
      return searchTermRegex.test(option);
    }).sort();
  }

  autocompleteOptionSelected(option: string) {
    this.newTagName.setValue(option);
  }

  /*
    Issue: I don't want users to be able to submit an empty field, but if I add a 
    `Validators.required` validator to the newTagName control, then, if I click 
    into the field, and click out again, without hitting the "add" button, then 
    I'll see error messaging.  This doesn't make sense.

    Since this little fake-form-in-another-form isn't technically a <form> - it's 
    just a standalone control - It can't technically have a submit action.  So I 
    can't say "only validate this control on submit".  It's a little funky. 

    Disabling the add button (the "submit" button for this fake form) when the control 
    is empty is the simplest way I can see to get around this. 
   */
  disableAddButton(): boolean {
    return this.newTagName.value === '' || !this.newTagName.valid;
  }

  addTagHandler() {
    if (this.newTagName.valid) {
      // Validation rules: 
        // Does this tag name already exist in this group?  
        // Is the field empty? 
  
      // after submission, mark control as untouched
  
      this.addTag.emit(this.newTagName.value);
      this.newTagName.setValue('');
      this.newTagName.markAsPristine(); // to clear any validation errors resulting from the field being empty again
    }
  }

  // filters out autocomplete options that are already in the group's tag list
  private autocompleteOptionsForGroup(): string[] {
    // if there are no tags in the group, show all possible tags in autocomplete
    if (!this.formGroupRecievingTag.get('tags')) { return this.allTagNames; } 

    // if there are tags in the group, compare allTagNames
    const tagNamesInGroup = _.map((this.formGroupRecievingTag.get('tags') as UntypedFormArray).getRawValue(), 'tag_name');
    const tagNamesNotInGroup = _.reject(this.allTagNames, tag =>  _.includes(tagNamesInGroup, tag));
    return tagNamesNotInGroup;
  }
}
