"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  Avatar: true,
  StyledAvatar: true,
  StyledInitials: true,
  StyledRoot: true
};
Object.defineProperty(exports, "Avatar", {
  enumerable: true,
  get: function () {
    return _avatar.default;
  }
});
Object.defineProperty(exports, "StyledAvatar", {
  enumerable: true,
  get: function () {
    return _styledComponents.Avatar;
  }
});
Object.defineProperty(exports, "StyledInitials", {
  enumerable: true,
  get: function () {
    return _styledComponents.Initials;
  }
});
Object.defineProperty(exports, "StyledRoot", {
  enumerable: true,
  get: function () {
    return _styledComponents.Root;
  }
});
var _avatar = _interopRequireDefault(require("./avatar"));
var _styledComponents = require("./styled-components");
var _types = require("./types");
Object.keys(_types).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _types[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _types[key];
    }
  });
});
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}