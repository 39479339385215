import { NavbarStateService } from './core/services/navbar-state.service';
import { ServiceLocator } from './core/utils/service-locator';
import { SessionService } from './security/session.service';
import { MessageDialogComponent } from './shared/message-dialog/message-dialog.component';
import { LoadingMaskComponent } from './shared/loading-mask/loading-mask.component';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';

window['$'] = $;
window['jQuery'] = $;

@Component({
    selector: 'app-root',
    templateUrl: './app-wrapper.component.html',
    standalone: false
})
export class AppWrapperComponent implements OnInit {

  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;
  @ViewChild(LoadingMaskComponent, { static: true }) loadingMaskComponent: LoadingMaskComponent;
  previewMode = false;
  isLoggedIn = false;

  constructor(public navbarStateService: NavbarStateService,
              public sessionService: SessionService,
              private router: Router,
              private analyticsService: AnalyticsService,
              private injector: Injector) {

    this.sessionService.sessionTimeoutEvent.subscribe(sessionTimeout => {
      this.onSessionTimeout();
    });

    ServiceLocator.injector = injector;
  }

  ngOnInit() {
    this.loadingMaskComponent.loadingMaskService.loadingErrorEvent.subscribe(x => this.messageDialog.showMessage(x));

    this.router.events.subscribe(event => {
      if (this.router.url.startsWith('/preview') || this.router.url.startsWith('/pdf-preview')) {
        this.previewMode = true;
      }
      if (this.router.url.startsWith('/privacy') || this.router.url.startsWith('/terms')) {
        this.sessionService.getCurrentUser().subscribe((result) => {
          this.loadingMaskComponent.loadingMaskService.loadingOff();
        });
      }
    });

    this.analyticsService.init();

    this.sessionService.loggedIn$.subscribe((loggedIn: boolean) => {
      this.isLoggedIn = loggedIn;
      // this.ref.detectChanges();
    });
  }

  getCssClasses() {
    let classes = '';
    if (this.sessionService.loggedIn && !this.previewMode) {
      if (this.navbarStateService.fullSize) {
        classes = 'content-box margin-for-header margin-for-side-navbar-full';
      } else {
        classes = 'content-box margin-for-header margin-for-side-navbar-small';
      }
    }
    return classes;
  }

  onSessionTimeout() {
    this.messageDialog.showMessage('Your session has expired.  You will need to log in again.');
  }
}
