import { AfterContentInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'rn-channel-list-item',
    templateUrl: './channel-list-item.component.html',
    styleUrls: ['./channel-list-item.component.scss'],
    standalone: false
})
export class ChannelListItemComponent {
  @Input() fname: string;
  @Input() lname: string;
  @Input() tagName: string;
  @Input() created: string;
  @Input() updated: string; // optional
  @Input() isHighlighted: string;
  
  constructor() { }
}
