<div class="list-popup dialog-content">
  <!-- UI for new experience transport -->
  <ng-container>
    <div class="import-container px-3 text-left">
      <i
        class="pl-2 pb-2 fa-regular fa-close"
        id="closeImportIcon"
        (click)="backToExperienceBuilder()"
      ></i>
      <!-- intial view -->
      <ng-container *ngIf="!showExperiencePackageDetails()">
        <h3 class="import-header">Upload an experience .zip file</h3>
        <div>Upload an exported experience .zip file.</div>
        <div>This experience must currently not exist in this environment.</div>
        <div>
          <rn-upload-drop-area
            [acceptedFileTypes]="'application/zip'"
            (fileCaptured)="handleDropImport($event)"
          >
            <span select-btn-text>SELECT FILE</span>
            <p description-text><b>Supported File Formats:</b> .zip</p>
          </rn-upload-drop-area>
        </div>
      </ng-container>

      <!-- Success validation view -->
      <ng-container *ngIf="showExperiencePackageDetails()">
        <ng-container *ngIf="isValid">
          <div class="d-flex align-items-end">
            <div class="pr-4">
              <i
                class="fa-regular fa-circle-check fa-5x"
                id="importSuccessIcon"
              ></i>
            </div>
            <h3>Experience is ready for import.</h3>
          </div>
          <div>
            The following file has passed validation and is ready to import into
            this environment. This cannot be undone. Please confirm this is
            correct file.
          </div>
          <div class="py-2">
            <strong>File name:</strong> {{ experienceFile.name }}
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-6 pr-1">
              <input
                class="d-none"
                id="reUploadFileAfterSuccess"
                type="file"
                (change)="fileReUploaded($event)"
                [accept]="'application/zip'"
              />
              <label
                select-button
                class="btn btn-lg btn-outline-secondary w-100"
                for="reUploadFileAfterSuccess"
                i18n="Label for file selection button"
              >
                Upload new file
              </label>
            </div>
            <div class="col-6 pl-1">
              <button
                class="btn btn-lg btn-primary d-block w-100"
                (click)="submitExperienceImport()"
              >
                Import Experience
              </button>
            </div>
          </div>
        </ng-container>

        <!-- Error validation view -->
        <ng-container *ngIf="!isValid">
          <div class="d-flex align-items-center">
            <div class="pr-4">
              <i
                class="fa-regular fa-square-exclamation fa-5x"
                id="importErrorIcon"
              ></i>
            </div>
            <h3>This experience has an error and cannot be imported</h3>
          </div>
          <div>
            The experience has failed to import for the following reason(s)
          </div>
          <div class="pb-2">
            <strong>File name:</strong> {{ experienceFile.name }}
          </div>
          <ul *ngFor="let item of validationErrorMessages">
            <li>
              <strong>{{ item }}</strong>
            </li>
          </ul>
          <div class="pt-2">
            <input
              class="d-none"
              id="reUploadFile"
              type="file"
              (change)="fileReUploaded($event)"
              [accept]="'application/zip'"
            />
            <label
              select-button
              class="btn btn-lg btn-primary d-block"
              for="reUploadFile"
              i18n="Label for file selection button"
            >
              Upload new file
            </label>
          </div>
        </ng-container>
      </ng-container>

      <div class="loader-container" [class.show-loader]="isLoading">
        <div class="loader">
          <div class="spinner">
            <div class="container">
              <div class="hex0"></div>
              <div class="hex120"></div>
              <div class="hex240"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<message-dialog></message-dialog>
