import { environment } from '@env/environment';
import { AgentUnassignResponse, Role, User } from '../models/user';
import { Injectable } from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { SecureHttp } from '@app/security/secure-http';
import { Observable } from 'rxjs';
import { SearchCriteria } from '../utils/search-criteria';
import { Direction, Ordering } from '../utils/ordering';
import { map } from 'rxjs/operators';
import { Customer } from '@app/core/models/customer';

@Injectable()
export class UserService {
  constructor(
    private secureHttp: SecureHttp,
    private sessionService: SessionService,
  ) {}

  createUser(
    user: User,
  ): Observable<{ emailAddress: string; id: string; sendUserEmail: boolean }> {
    const userToSend = Object.assign({}, user);
    const url = `${environment.portalURLBase}/client/${user.client_id}/users`;
    delete userToSend.client_id;
    return this.secureHttp.post(url, userToSend);
  }

  updateUser(user: User, keepPassword?: boolean): Observable<any> {
    delete user.ld;
    const userToSend = Object.assign({}, user);
    const url = `${environment.portalURLBase}/client/${user.client_id}/users/${user.id}`;
    return this.secureHttp.put(url, User.serialize(userToSend, keepPassword));
  }

  deletePortalUser(id: string): Observable<any> {
    const clientId = this.sessionService.currentUser.client.id;
    const url = `${environment.portalURLBase}/client/${clientId}/users/${id}`;
    return this.secureHttp.delete(url);
  }

  unassignPortalAgent(id: string): Observable<AgentUnassignResponse> {
    const clientId = this.sessionService.currentUser.client.id;
    const twoWayUrl = `${environment.twoWayURLBase}/client/${clientId}/agent/deactivate`;
    const twoWayReqBody = {
      agentId: id,
    };
    return this.secureHttp.post(twoWayUrl, twoWayReqBody);
  }

  getUserById(userId: string) {
    const url = `${environment.portalURLBase}/client/${this.sessionService.currentUser.client.id}/users/${userId}`;
    return this.secureHttp
      .get(url)
      .pipe(map((response) => User.deserialize(response['portal_user'])));
  }

  getUsers(
    limit: number,
    offset: number,
    searchCriteria?: SearchCriteria,
    ordering?: Ordering,
    supressMask?: boolean,
  ): Observable<Array<User>> {
    const clientId = this.sessionService.currentUser.client.id;
    if (!searchCriteria) {
      searchCriteria = new SearchCriteria();
    }

    let url = `${environment.searchURLBase}/client/${clientId}/users?limit=${limit}&offset=${offset}`;
    if (ordering) {
      let orderBy = ordering.orderBy;
      if (ordering.orderBy === 'data.first_name') {
        orderBy = 'full_name';
      } else if (ordering.orderBy === 'data.email_address') {
        orderBy = 'email_address';
      } else if (ordering.orderBy === 'data.last_login') {
        orderBy = 'last_login';
      } else if (ordering.orderBy === 'data.role_id') {
        orderBy = 'role_id';
      }
      const searchOrdering = new Ordering(orderBy, ordering.direction);
      url = `${url}&${searchOrdering.getQueryStringUnderscorish()}`;
    }
    const searchRequest = searchCriteria.getSearchRequest();
    const result = this.secureHttp.post(url, searchRequest, null, supressMask);

    return result.pipe(
      map((response) => {
        return response['portal_users'].map(User.deserialize);
      }),
    );
  }

  getRoles(): Observable<Role[]> {
    const url = `${environment.cdmURLBase}/roles?limit=100`;
    return this.secureHttp.get(url).pipe(
      map((response) => {
        return response['roles'].map(Role.deserialize);
      }),
    );
  }

  getCustomerDetailsByCcid(
    clientId: string,
    ccid: string,
  ): Observable<Customer> {
    const url = `${environment.cdmURLBase}/client/${clientId}/customer/${ccid}`;
    return this.secureHttp.get(url, {}, true);
  }

  getCustomerDetails(
    clientId: string,
    customerId: string,
  ): Observable<Customer> {
    const url = `${environment.cdmURLBase}/client/${clientId}/customer/id/${customerId}`;
    return this.secureHttp.get(url, {}, true);
  }
}
