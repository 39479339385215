import { Component, Input} from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { ActionService } from "../../../services/action.service";

@Component({
    selector: "action-text-input",
    styleUrls: ["./action-text-input.component.scss"],
    templateUrl: "./action-text-input.component.html",
    standalone: false
})
export class ActionTextInputComponent {
  
  @Input() i: number;
  @Input() fieldLabel: string;
  @Input() name: string;
  @Input() actionMention;
  @Input() actionMentionConfig;
  @Input() control: UntypedFormControl;
  
  constructor(private actionService: ActionService) {}
    getTooltipData(errors: Object): string | void {
        return this.actionService.getTooltipData(errors);
    }
}
