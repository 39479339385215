import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { SftpKey } from '@app/sftp-mgmt/models/sftp-key';
import { Client } from '@app/core/models/client';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { SftpUser } from '@app/sftp-mgmt/models/sftp-user';

@Component({
    selector: 'rn-sftp-key-list',
    templateUrl: './sftp-key-list.component.html',
    styleUrls: ['./sftp-key-list.component.scss'],
    standalone: false
})
export class SftpKeyListComponent implements OnChanges {
  @Input() client: Client;
  @Input() sftpUser: SftpUser;
  @Input() resultsDialog: MessageDialogComponent;
  @Input() sftpKeys: SftpKey[];
  @Input() hasKeyLoadingError: boolean;
  @Input() hasUserLoadingError: boolean;
  @Output() onDeleteConfirmed = new EventEmitter<number>();
  @Output() onSaveClicked = new EventEmitter<string>();

  showAddKeyComponent: boolean = true;
  keyIdToDelete: number;
  showSFTPUserDetails: boolean = false;

  @ViewChild('confirmationDialog', {static: true}) confirmationDialog: MessageDialogComponent;

  constructor() {
  }

  ngOnChanges(): void {
    this.showAddKeyComponent = !this.hasKeys();
  }

  isLoadingKeys(): boolean {
    return !this.hasKeyLoadingError && !this.sftpKeys;
  }

  hasKeys(): boolean {
    return this.sftpKeys?.length > 0;
  }

  isLoadingUser(): boolean {
    return !this.hasUserLoadingError && !this.sftpUser;
  }

  hasUser(): boolean {
    return !!this.sftpUser;
  }

  promptForDeleteKey(keyId: string): void {
    this.keyIdToDelete = Number(keyId);
    this.confirmationDialog.showMessage('Are you sure you want to delete this key?')
  }

  handleOnSaveClicked(key: string) {
    this.onSaveClicked.emit(key);
  }

  toggleAddKeyComponent() {
    this.showAddKeyComponent = !this.showAddKeyComponent;
  }
}
