import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isValidPhoneNumber',
    standalone: false
})
export class ValidPhoneNumberPipe implements PipeTransform {
  transform(value: any, args?: any): boolean {
    return value?.length === 10 && Number.isInteger(+value);
  }
}
