import React from 'react';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SessionService } from '@app/security/session.service';
import { createRoot, Root } from 'react-dom/client';
import { TitleService } from '@app/core/services/title.service';
import { SetupConversationsContainer } from './components/setup-conversations-container/SetupConversationsContainer';
import { Client } from '@app/core/models/client';
import { TwoWayConfig } from '@app/core/models/two-way-config';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';

@Component({
  selector: 'app-setup-conversations',
  templateUrl: './setup-conversations.component.html',
  styleUrls: ['./setup-conversations.component.scss'],
  standalone: false,
})
export class SetupConversationsComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  currentClient: Client;
  clientId: string = '';
  userId: string = '';
  reactWrapperId: string;
  twoWayConfig: TwoWayConfig;
  root: Root = null;
  isRMAutoResponseEnabled: boolean;
  isFeedEnabled: boolean;

  constructor(
    private sessionService: SessionService,
    public featureService: FeatureService,
    private titleService: TitleService, // private clientService: ClientService,
  ) {}

  ngOnInit(): void {
    this.isRMAutoResponseEnabled = this.featureService.checkFlag(FeatureFlags.rm_auto_response);
    this.isFeedEnabled = this.featureService.checkFlag(FeatureFlags.new_feed);
    this.currentClient = this.sessionService.getCurrentUsersClient();
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.twoWayConfig = this.currentClient.two_way_config;
    this.titleService.activate('Relay Messenger Setup', 'Setup Conversations');
    this.reactWrapperId = 'setup-conversations-wrapper-id';
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
    this.root.unmount();
  }

  ngAfterViewInit(): void {
    const node = document.getElementById(this.reactWrapperId);
    this.root = createRoot(node);
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  protected render() {
    if (this.root) {
      this.root.render(
        React.createElement(SetupConversationsContainer, {
          clientId: this.clientId,
          twoWayConfig: this.twoWayConfig,
          isRMAutoResponseEnabled: this.isRMAutoResponseEnabled,
          isFeedEnabled: this.isFeedEnabled,
        }),
      );
    }
  }
}
