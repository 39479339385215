import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { JobService } from '@app/core/services/job.service';
import { JourneyService } from '@app/core/services/journey.service';
import { InputParamUtil } from '@app/core/models/input-param-util';
import { Journey } from '@app/core/models/journey';
import { Client } from '@app/core/models/client';
import { ComponentBaseClass } from '@app/core/models/message';
import { CustomerLookupLaunchService } from '@app/core/services/customer-lookup-launch.service';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { ChatContextService } from './chat-context.service';

@Component({
    selector: 'app-test-launcher',
    templateUrl: './test-launcher.component.html',
    styleUrls: ['./test-launcher.component.scss'],
    standalone: false
})
export class TestLauncherComponent implements OnInit, OnDestroy {

  @ViewChild(MessageDialogComponent, { static: true }) messageDialog;
  @Input() journeyId: string;
  @Input() validation: any;
  @Input() ccid: string; // can also be secondary client id
  @Output() validationFailed = new EventEmitter<any>();

  client: Client;
  selectJourney = false;
  journey: Journey;
  journeyLaunched = false;
  triggerComponent: ComponentBaseClass;
  triggerInputParams: string[]; // potential values: 'CCID' | 'account_firstname' | 'account_lastname' | 'account_product_group' | 'account_secondary_account_id' | 'ext_*' | 'input_*';
  enteredInputParams = {}; // object generated by mapping triggerInputParams as keys, and pulling corresponding values from the customer object
  enteredInputGroup: UntypedFormGroup; // form group that mirrors enteredInputParams
  allowCustomerIdEntry = false;
  launchResult: any;
  launchSuccessButtonLabel = 'Launch Again';
  launchSuccessButtonClick = () => { 
    this.chatContextService.unsetChannelSid();
    this.launchAnother();
  };
  clientMessageTag: string;
  defaultClientMessageTag: string;
  isCsrOnboarding: boolean = false; // used to determine if secondary_account_id should be used instead of ccid, and if the ccid field should be disabled

  constructor(private titleService: TitleService,
              private journeyService: JourneyService,
              private jobService: JobService,
              public customerLookupLaunchService: CustomerLookupLaunchService,
              private sessionService: SessionService,
              private route: ActivatedRoute,
              private location: Location,
              private router: Router,
              private chatContextService: ChatContextService) {
  }

  ngOnInit(): void {
    this.client = this.sessionService.currentUser.client;

    if (this.journeyId) {
      this.getJourney(this.journeyId);

      this.route.url.subscribe((urlSegments: UrlSegment[]) => {
        this.setIsCsrOnboarding(urlSegments);
        this.setCCID(); // isCsrOnboarding determines if we should use secondary_acct_id instead of ccid, hence the need to reset
      });
    } else {
      this.titleService.activate('Experience Launcher');
      this.route.params
        .subscribe((params: Params) => {
          const ccid = params['ccid'];
          const journeyId = params['journeyId'];

          if (journeyId && !ccid) {
            this.getJourney(journeyId);
          } else if (ccid && !journeyId) {
            this.selectJourney = true;
          } else if (ccid && journeyId) {
            this.selectJourney = false;
            this.ccid = ccid; // use customer ccid in CSR onboarding
            this.getJourney(journeyId);
          }

          this.init();
        });

      this.route.url.subscribe((urlSegments: UrlSegment[]) => {
        this.determineStateOfCustomerId(urlSegments);
        this.setCCID(); // the value of `allowCustomerIDEntry` var that's set in `determindStateOfCustomerId` could change the ccid that's used.  hence we have to set it again.
      });
    }

    const channelSidParam = this.route.snapshot.queryParamMap.get('chat');
    if (channelSidParam != null) {
      this.chatContextService.setChannelSid(channelSidParam)
    }
  }

  ngOnDestroy(): void {
    if (!this.validation) {
      this.titleService.deactivate();
    }
  }

  cancelSelectedTrigger(): void {
    this.triggerComponent = undefined;
    this.triggerInputParams = undefined;
    this.journeyLaunched = false;
    this.enteredInputParams = undefined;
    this.enteredInputGroup = undefined;
  }

  getJourney(journeyId: string): void {
    this.journeyService.getJourneyById(journeyId).subscribe(
      journeyRecord => {
        this.journey = journeyRecord;
        this.journeyId = journeyRecord.id;
        this.journeyLaunched = false;
        this.titleService.activate('Experience Launcher', this.journey.live.name);
        this.setCCID(); // whether or not the journey requires validation can change ccid used
      },
      error => {
        const errorMessage = error.error.response;
        this.messageDialog.showMessage('Oops... there was an error loading the experience: ' + errorMessage);
      }
    );
  }

  getClientMessageTag(trigger: any): void {
    this.defaultClientMessageTag = undefined;
    const targetUUID = trigger.to;
    _.forEach(this.journey.live.components, (c) => {
      if (c.name === targetUUID) {
        this.clientMessageTag = _.get(c, 'client_message_tag');
        this.defaultClientMessageTag = _.get(c, 'client_message_tag');
      }
    });
  }

  goBack(): void {
    if (this.validation) {
      this.validationFailed.emit({error: 'cancel', validation: this.validation, ccid: this.ccid});
    } else {
      this.location.back();
    }
  }

  // Disabled inputs are account_ and ext_ inputs and CCID (but only if it was provided in the route, or as an input).
  // TODO: 2nd 2 conditions are redundant.  We should be able to set allowCustomerIDEntry properly in the 
  // 'isCsrOnboarding === true' case instead of it being a separate thing. This is a staging fix so I don't want to do too much.
  isInputDisabled(inputParam: string): boolean {
    return inputParam.startsWith('account_') || inputParam.startsWith('ext_') ||
          (inputParam === 'CCID' && this.ccid && !this.allowCustomerIdEntry) || 
          (inputParam === 'CCID' && this.isCsrOnboarding);
  }

  // form is invalid if it's both not-valid and not-disabled.  Disabled forms are, in this case, considered valid
  enteredInputGroupIsInvalid(): boolean {
    return !this.enteredInputGroup.valid && !this.enteredInputGroup.disabled;
  }

  isLauncherButtonDisabled(): boolean {
    return !this.enteredInputGroup || this.enteredInputGroupIsInvalid();
  }

  launchJourney(): void {
    const controls = this.enteredInputGroup.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        controls[control].markAsTouched();
      }
    }

    if (!this.enteredInputGroupIsInvalid() && this.triggerComponent) {
      if (this.validation) {
        this.validation.client_message_tag = this.clientMessageTag;
        this.validation.trigger_id = this.triggerComponent.name;
        this.validation.journey_id = this.journeyId;

        const ccid = this.enteredInputParams['CCID'];

        this.jobService.signUpCustomer(ccid, this.validation, this.enteredInputParams).subscribe(
          (response) => {
            this.launchResult = response;
            this.journeyLaunched = true;
          },
          (error: HttpErrorResponse) => {
            let reason;
            if (error && error.error && error.error.reason) {
              reason = error.error.reason;
            }
            if (reason.indexOf('The following fields did not match:') > -1) {
              this.validationFailed.emit({error: reason.split(': ')[1], validation: this.validation});
              return;
            }
            this.messageDialog.showMessage(reason);
          });
        } else {
          // if launching journey from journey builder, then CCID is entered;
          // other paths to launching journey can use CCID or secondary ID,
          // and triggerJourneyStep requires CCID, so use it here
          if (this.customerLookupLaunchService.customer) {
            this.enteredInputParams['CCID'] = this.enteredInputGroup.get('CCID').value;
          }
          this.jobService.triggerJourneyStep(this.journeyId, this.triggerComponent.name, this.enteredInputParams, this.clientMessageTag).subscribe(
            (response) => {
              this.launchResult = response;
              this.journeyLaunched = true;
              const channelSid = this.chatContextService.getChannelSid();
              if (channelSid != null) {
                this.launchSuccessButtonLabel = 'Return to Chat';
                this.launchSuccessButtonClick = () => {
                  this.chatContextService.unsetChannelSid();
                  this.router.navigateByUrl(`two-way/relay-messenger?chat=${channelSid}`)
                };
              }
            },
            (error: HttpErrorResponse) => {
              let reason = error?.error?.reason ?? error?.error?.error ?? "Unknown Error";
              try {
                if (typeof reason === "object") {
                  if (reason.type === "missing-fields") {
                    reason = `missing fields ${reason.data.join(' ')}`
                  }
                }
              }
              finally {
                this.messageDialog.showMessage(`Oops... there was an error launching the trigger: ${reason}`);
              }
            });
      }
    }
  }

  launchAnother(): void {
    // Delete the entered input values
    for (const property in this.enteredInputParams) {
      if (this.enteredInputParams.hasOwnProperty(property) && property.startsWith('input_')) {
        this.enteredInputParams[property] = '';
        this.enteredInputGroup.get(property).setValue('');
      }
    }

    // Mark the controls as untouched
    const controls = this.enteredInputGroup.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        controls[control].markAsUntouched();
      }
    }

    this.clientMessageTag = this.defaultClientMessageTag;
    this.journeyLaunched = false;
  }

  selectTrigger(trigger: ComponentBaseClass): void {
    this.getClientMessageTag(trigger);

    this.triggerComponent = trigger;
    this.triggerInputParams = InputParamUtil.findAllInputParams(this.journey.live, this.triggerComponent.name);

    if (this.ccid == null || this.customerLookupLaunchService.customer == null) {
      this.triggerInputParams = this.triggerInputParams.filter(p => !p.startsWith('account_') && !p.startsWith('ext_'));
    }

    this.init()
  }

  private init() {
    this.enteredInputParams = this.buildModel(this.triggerInputParams);
    this.enteredInputGroup = this.buildForm(this.enteredInputParams);
    this.enteredInputGroup.valueChanges.subscribe((res) => {
      // assigning instead of replacing to maintain the ccid (which isn't in the form).
      // it's needed by `jobService.triggerJourneyStep`
      this.enteredInputParams = _.assign(this.enteredInputParams, res);
    });
  }

  private setCCID(): void {
    this.enteredInputParams['CCID'] = this.getCCID();

    // if form fields exist at this point, set those to the new ccid value.
    if (this.enteredInputGroup && this.enteredInputGroup.controls['CCID']) {
      this.enteredInputGroup.get('CCID').setValue(this.enteredInputParams['CCID']);
    }
  }

  /**
   * The ccid comes from different sources depending on your entrypoint
   *   - if you entered via the Journey Builder 'test' action, the ccid will be blank (user must enter it)
   *   - if you entered via the Customer List 'airplane' button, the ccid will be present in the route
   *   - if you enter via the `onboarding` route... 
   *     - ...and you select a journey that does not require validation, and submit successfully... 
   *         - the ccid will be the stored customer's ccid
   *     - ...you select a journey that requires validation
   *         - ...and the client is set to use secondary acct id
   *             - the ccid will be the stored customer's secondary acct id
   *         - the ccid will be the stored customer's ccid
   *     - ...and you select a journey that does not require validation, and validation fails... 
   *         - then the ccid is passed in as an input? (TODO: verify this path is viable)
   */
  private getCCID() {
    if (this.isCsrOnboarding && this.client.validation.look_up_by_secondary_account_id && this.journey && !this.journey.live.bypass_csr_validation) { // onboarding, use sec acct id, journey requires validation
      return this.customerLookupLaunchService.customer.secondary_account_id;
    } else if (this.allowCustomerIdEntry) { // Journey Builder Test Launcher page - ccid is always blank until user fills it in
      return '';
    } else if (this.ccid) { // use this.ccid if it exists. can come in as an input from onboarding, or as part of the route when coming in from the customer list
      return  this.ccid;
    } else {
      return _.get(this.customerLookupLaunchService, 'customer.ccid') ;
    }
  }

  /**
   * Generate an object using triggerInputParams's values as keys, and picking corresponding values from the customer object in customerLookupLaunchService.
   * @param triggerInputParams potential values: 'CCID' | 'account_firstname' | 'account_lastname' | 'account_product_group' | 'account_secondary_account_id' | 'ext_*' | 'input_*'
   */
  private buildModel(triggerInputParams: string[]): object {
    return _.reduce(triggerInputParams, (result, inputParam) => {
      if (inputParam === 'CCID') {
        result['CCID'] = this.getCCID(); // note that CCID can't be accurately set until route params are checked, so this will end up being overwritten
        return result;
      }

      // if there's no customer to get data from, then just set this input w/ a blank value and return
      if (!this.customerLookupLaunchService.customer) {
        result[inputParam] = '';
        return result;
      }

      // if the inputParam is 'account_product_group' but there's no product group stored, request it and set up a subscriber
      if (inputParam === 'account_product_group' && !this.customerLookupLaunchService.productGroupName) {
        this.getProductGroupName(); // set subscription to get a pgName when one is available
        return result;
      }

      // if input param is an ext field and the customer has ext field data
      if (inputParam.startsWith('ext_') && this.customerLookupLaunchService.customer.ext) {
        result[inputParam] = this.customerLookupLaunchService.customer.ext[inputParam.substr(4, inputParam.length - 4)];
        return result;
      }

      // all other cases
      switch (inputParam) {
        case 'account_firstname':
          result['account_firstname'] = this.customerLookupLaunchService.customer.first_name;
          break;
        case 'account_lastname':
          result['account_lastname'] = this.customerLookupLaunchService.customer.last_name;
          break;
        case 'account_product_group':
          result['account_product_group'] = this.customerLookupLaunchService.productGroupName;
          break;
        case 'account_secondary_account_id':
          result['account_secondary_account_id'] = this.customerLookupLaunchService.customer.secondary_account_id;
          break;
        default: // includes 'input_' cases
          result[inputParam] = '';
      }

      return result;
    }, {});
  }

  private buildForm(enteredInputParams): UntypedFormGroup {
    return _.reduce(enteredInputParams, (formGroup, inputParamVal, inputParamName) => {
      const validator = this.isInputDisabled(inputParamName) ? null : Validators.required;
      const formControl = new UntypedFormControl(inputParamVal, validator);
      if (!validator) { // non-required fields are disabled.
        formControl.disable();
      }
      formGroup.addControl(inputParamName, formControl);
      return formGroup;
    }, new UntypedFormGroup({}));
  }

  /**
   * callback for when there is no productGroupName available during initialization
   * Subscribes to an event for productGroupNameUpdates and adds a key to the form and model
   */
  private getProductGroupName() {
    this.customerLookupLaunchService.productGroupNameUpdates.subscribe(productGroupName => {
      const validator = this.isInputDisabled( 'account_product_group') ? null : Validators.required;
      this.enteredInputParams[ 'account_product_group'] = productGroupName;
      this.enteredInputGroup.addControl( 'account_product_group', new UntypedFormControl(productGroupName, validator));
    });
  }

  private determineStateOfCustomerId(urlSegments: UrlSegment[]): void {
    if (urlSegments.length === 3 && urlSegments[1].path === 'test') {
      this.allowCustomerIdEntry = true;
    } else {
      this.allowCustomerIdEntry = false;
    }
  }

  private setIsCsrOnboarding(urlSegments: UrlSegment[]) {
    if (urlSegments.length === 1 && urlSegments[0].path === 'onboarding') {
      this.isCsrOnboarding = true;
    }
  }
}
