import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule, AbstractControl, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { IvrOnboardingType } from '@app/core/models/client';
import * as _ from 'lodash';

@Component({
    selector: 'app-ivr-config',
    templateUrl: './ivr-config.component.html',
    styleUrls: ['./ivr-config.component.scss'],
    standalone: false
})

export class IvrConfigComponent implements OnInit {
  @Input() ivrData: IvrOnboardingType;
  @Input() productGroupOptions: { name: string, id: string }[];
  @Output() ivrDataUpdated: EventEmitter<IvrOnboardingType> = new EventEmitter();
  @Output() ivrFormValid: EventEmitter<boolean> = new EventEmitter();
  ivrForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.initIvrFormGroup();
    this.setUpFormChangeEvent();
    this.ivrFormValid.emit(this.getIvrFormValidStatus());
  }

  initIvrFormGroup() {
    this.ivrForm = this.fb.group({
      allow_onboarding_minors: [this.ivrData.allow_onboarding_minors],
      customer_minor_cutoff_age_years: [this.ivrData.customer_minor_cutoff_age_years, [Validators.pattern('^[0-9]*$')]],
      data_request_exclusions: this.createDataRequestExclusionsArray(this.ivrData.data_request_exclusions),
      consent_exclusions: this.createConsentExclusionsArray(this.ivrData.consent_exclusions),
      phone_number_mapping: this.createPhoneNumberMapsArray(this.ivrData.phone_number_mapping)
    });
  }

  /*
    This is necessary to report the status of this internal reactive form to
    the parent template-driven form model.
  */
  setUpFormChangeEvent(): void {
    this.ivrForm.valueChanges.subscribe((formData) => {
      formData.data_request_exclusions = this.reformatDataRequestExclusions(formData.data_request_exclusions);
      formData.consent_exclusions = this.reformatConsentExclusions(formData.consent_exclusions);
      formData.phone_number_mapping = this.reformatPhoneNumberMaps(formData.phone_number_mapping);
      formData.enabled = this.ivrData.enabled;
      
      this.ivrDataUpdated.emit(IvrOnboardingType.deserialize(formData));
      this.ivrFormValid.emit(this.getIvrFormValidStatus());
    });
  }

  getIvrFormValidStatus(): boolean {
    return this.ivrForm.status === 'VALID';
  }

  /*
      Minor Cutoff Age and Allow Onboarding Minors
  */

  get minorCutoffAge(): AbstractControl {
    return this.ivrForm.get('customer_minor_cutoff_age_years');
  }

  get allowOnboardingMinors(): AbstractControl {
    return this.ivrForm.get('allow_onboarding_minors');
  }

  allowOnboardingMinorsChanged(newValue): void {
    this.ivrForm.get('allow_onboarding_minors').setValue(newValue);
  }

  /*
      Data Request Exclusions

      in client object: "data_request_exclusions": [  "a",  "b"  ]
      in form: [{ value: 'a' }, { value: 'b' }]
  */

  private createDataRequestExclusionsArray(dataRequestExclusions: string[]): UntypedFormArray {
    return this.fb.array(
      _.map(dataRequestExclusions, (dataRequestExclusion) => {
        return this.createDataRequestExclusionGroup(dataRequestExclusion);
      })
    );
  }

  private createDataRequestExclusionGroup(dataRequestExclusion: string = ''): UntypedFormGroup {
    return this.fb.group({
      value: [dataRequestExclusion, [Validators.required]]
    });
  }

  get dataRequestExclusions(): UntypedFormArray {
    return (this.ivrForm.get('data_request_exclusions') as UntypedFormArray);
  }

  deleteDataRequestExclusion(i: number): void {
    this.dataRequestExclusions.removeAt(i);
  }

  addDataRequestExclusion(): void {
    this.dataRequestExclusions.push(this.createDataRequestExclusionGroup());
  }

  private reformatDataRequestExclusions(rawDataRequestExclusions): string[] {
    return _.compact(_.map(rawDataRequestExclusions, (dataRequestExclusion) => {
      return dataRequestExclusion.value;
    }));
  }

  /*
      Consent Exclusions

      in client object:
        "consent_exclusions": [
          {  "misc_field2": "excluded_value"  },
          {  "misc_field4": "other_excluded_value"  }
        ]

      in form:
      "consent_exclusions": [
        {  key: "misc_field2", value: "excluded_value"  },
        {  key: "misc_field4", value: "other_excluded_value"  }
      ]

      Only accepts 1 key/val pair per object in array
  */

  private createConsentExclusionsArray(consentExclusions): UntypedFormArray {
    return this.fb.array(
      _.map(consentExclusions, (consentExclusion) => {
        return this.createConsentExclusionGroup(consentExclusion);
      })
    );
  }

  private createConsentExclusionGroup(consentExclusion = { '': '' }): UntypedFormGroup {
    const key = _.keys(consentExclusion)[0];
    return this.fb.group({
      key: [key, [Validators.required]],
      value: [consentExclusion[key], [Validators.required]]
    });
  }

  get consentExclusions(): UntypedFormArray {
    return (this.ivrForm.get('consent_exclusions') as UntypedFormArray);
  }

  deleteConsentExclusion(i: number): void {
    this.consentExclusions.removeAt(i);
  }

  addConsentExclusion(): void {
    this.consentExclusions.push(this.createConsentExclusionGroup());
  }

  private reformatConsentExclusions(rawConsentExclusions: { key: string, value: string}[]): any[] {
    return _.compact(_.map(rawConsentExclusions, (rawConsentExclusion) => {
      if (_.isEmpty(rawConsentExclusion.key)) { return; }

      const newObject = {};
      newObject[rawConsentExclusion.key] = rawConsentExclusion.value;
      return newObject;
    }));
  }

  /*
      Phone Number Mapping

      in client object:
        "phone_number_mapping": {
          "10005559999": {
            "product_group_id": "some-product-group"
          }
        }

      in form:
      "phone_number_mapping": [
        { phone_number: "10005559999", product_group: "some-product-group"}
      ]
  */

  private createPhoneNumberMapsArray(phoneNumberMaps): UntypedFormArray {
    return this.fb.array(
      _.map(phoneNumberMaps, (phoneNumberMap, phoneNumber) => {
        return this.createPhoneNumberMapGroup(phoneNumberMap, phoneNumber);
      })
    );
  }

  private createPhoneNumberMapGroup(phoneNumberMap = { product_group_id: '', brand: '' }, phoneNumber = ''): UntypedFormGroup {
    return this.fb.group({
      phone_number: [phoneNumber, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      product_group_id: [phoneNumberMap.product_group_id, [Validators.required]],
      brand: phoneNumberMap.brand
    });
  }

  get phoneNumberMaps(): UntypedFormArray {
    return (this.ivrForm.get('phone_number_mapping') as UntypedFormArray);
  }

  deletePhoneNumberMap(i: number): void {
    this.phoneNumberMaps.removeAt(i);
  }

  addPhoneNumberMap(): void {
    this.phoneNumberMaps.push(this.createPhoneNumberMapGroup());
  }

  private reformatPhoneNumberMaps(rawPhoneNumberMaps: { phone_number: string, product_group_id: string }): any[] {
    const newMap = _.map(rawPhoneNumberMaps, (rawPhoneNumberMap) => {
      if (_.isEmpty(rawPhoneNumberMap.phone_number)) { return; }

      if (_.isEmpty(rawPhoneNumberMap.brand)) {
        return [rawPhoneNumberMap.phone_number, { product_group_id: rawPhoneNumberMap.product_group_id }];
      } else {
        return [rawPhoneNumberMap.phone_number, { product_group_id: rawPhoneNumberMap.product_group_id, brand: rawPhoneNumberMap.brand }];
      }
    });

    return _.fromPairs(_.compact(newMap));
  }
}
