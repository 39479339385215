import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from 'app/core/services/client.service';
import { TitleService } from 'app/core/services/title.service';
import { Client } from 'app/core/models/client';
import { ScrollConstants } from 'app/core/utils/scroll-constants';
import { SortableComponent } from 'app/core/sortable-component';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';
import { Permissions, PermissionService } from 'app/core/services/permission.service';
import { PageManager } from 'app/core/utils/page-manager';
import { SessionService } from 'app/security/session.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-client-list',
    templateUrl: './client-list.component.html',
    styleUrls: ['./client-list.component.scss'],
    standalone: false
})
export class ClientListComponent extends SortableComponent implements OnInit {
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;

  clients: Client[] = [];
  currentClient: Client;
  pageManager: PageManager = new PageManager();
  loaded = false;
  offset = 0;

  readonly limit = 20;
  readonly throttle = ScrollConstants.throttle;
  readonly scrollDistance = ScrollConstants.scrollDistance;

  constructor(private clientService: ClientService,
              private titleService: TitleService,
              private sessionService: SessionService,
              private router: Router,
              private ps: PermissionService) {
    super();
  }

  ngOnInit() {
    if (this.ps.checkPermissions(Permissions.client_create)) {
      this.titleService.activate('All Clients', null, 'add new client', 'fa fa-plus-circle', () => {
        this.router.navigateByUrl('/client/new');
      });
    } else {
      this.titleService.activate('All Clients');
    }
    this.getClients();
    this.getCurrentClient();
  }

  onScrollDown(): void {
    this.offset += this.limit;
    this.getClients();
  }

  fetchData(): void {
    this.pageManager = new PageManager();
    this.clients = [];
    this.loaded = false;
    this.offset = 0;
    this.getClients();
  }

  private getClients() {
    const offset = this.offset;
    this.clientService.getClients(this.limit, this.offset, this.ordering, this.offset !== 0).subscribe(
      clients => {
        this.pageManager.addPage(offset, clients);
        this.clients = this.pageManager.flattenPages();
      },
      error => {
        this.messageDialog.showMessage('Oops...there was an error getting the list of clients.');
      },
      () => {
        this.loaded = true;
      });
  }

  private getCurrentClient(): void {
    this.clientService.getClient(this.sessionService.getCurrentUsersClient().id).subscribe(
      (client: Client) => this.currentClient = client,
      () => {}
    );
  }
}
