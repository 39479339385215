import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import { SessionService } from '@app/security/session.service';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { TwoWayDashboardContainer } from './dashboard-container/DashboardContainer';
import { LookerService } from '@app/core/services/looker.service';

export type RelayMessengerFeatureFlag = {
  flagName: string;
  flagValue: boolean;
};

@Component({
    selector: 'app-two-way-dashboard',
    templateUrl: './two-way-dashboard.component.html',
    styleUrls: ['./two-way-dashboard.component.scss'],
    standalone: false
})
export class TwoWayDashboardComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  clientId: string = '';
  reactWrapperId: string = '';
  featureFlags = FeatureFlags;
  lookerRelayMessengerUrl: string;
  root: Root = null;

  constructor(
    private sessionService: SessionService,
    private titleService: TitleService,
    public featureService: FeatureService,
    private lookerService: LookerService,
  ) {}

  fetchRelayMessengerUrl() {
    this.lookerService.getDashboardUrl(this.lookerService.relayMessengerId).subscribe(
      url => {
        this.lookerRelayMessengerUrl = url;
        this.render();
      }
    );
  }

  ngAfterViewInit(): void {
    const node = document.getElementById(this.reactWrapperId);
    this.root = createRoot(node);
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngOnInit(): void {
    this.titleService.activate('Relay Messenger Dashboard');
    this.clientId = this.sessionService.getCurrentUsersClient().id;
    this.reactWrapperId = 'twoway-dashboard-wrapper-id';
    this.fetchRelayMessengerUrl();
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
    this.root.unmount();
  }

  protected getRootDomNode(): HTMLElement {
    return document.getElementById(this.reactWrapperId);
  }

  protected render() {
    if (this.root && this.lookerRelayMessengerUrl) {
      this.root.render(
        React.createElement(TwoWayDashboardContainer, {
          clientId: this.clientId,
          dashboardUrl: this.lookerRelayMessengerUrl,
        }),
      );
    }
  }
}
