import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'message-copy',
    templateUrl: './message-copy.component.html',
    styleUrls: ['./message-copy.component.scss'],
    standalone: false
})
export class MessageCopyComponent implements OnInit {

  includeSmsOnlyMessages: boolean = true;
  
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.params['targetType'] === 'message-response') {
      this.includeSmsOnlyMessages = false;
    }
  }
}
