import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { map, Observable, startWith } from 'rxjs';
import { JourneyContent } from '@app/core/models/journey';
import { SessionService } from '@app/security/session.service';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { Client } from '@app/core/models/client';
import { CustomValidators } from '@app/core/utils/custom-validators';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { FeatureService } from '@app/core/services/feature.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-journey-metadata-dialog',
  templateUrl: './journey-metadata-dialog.component.html',
  styleUrls: ['./journey-metadata-dialog.component.scss'],
  standalone: false,
})
export class JourneyMetadataDialogComponent implements OnInit {
  @Input() btnConfig: Object = { cancel: 'Cancel', save: 'get Started' };
  @Input() journeyContentPropertiesInput: JourneyContent;
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<JourneyContent>();
  @Output() onCampaignChanged = new EventEmitter<void>();
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog;

  campaigns: Observable<string[]>;
  apiCampaigns: string[] = [];

  journeyContentProperties: JourneyContent = new JourneyContent();
  journeyGroup: FormGroup;
  client: Client;
  clientId: string;
  featureFlags = FeatureFlags;

  constructor(
    public sessionService: SessionService,
    protected featureService: FeatureService,
    private clientService: ClientService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    if (this.journeyContentPropertiesInput) {
      this.journeyContentProperties = this.journeyContentPropertiesInput;
    }

    this.client = this.sessionService.currentUser.client;
    this.clientId = this.sessionService.currentUser.client.id;

    this.initForm();
    this.getCampaigns();
  }

  initForm(): void {
    // Structure Mirrors JourneyContent class
    this.journeyGroup = this.fb.group({
      name: [
        this.journeyContentProperties.name,
        [Validators.required, Validators.pattern(/\S+/)],
      ],
      product_group: [
        this.journeyContentProperties.product_group,
        Validators.required,
      ],
      bypass_csr_validation: [
        this.journeyContentProperties.bypass_csr_validation,
      ],
      campaign: [this.journeyContentProperties.campaign],
      description: [this.journeyContentProperties.description],
    });

    if (this.featureService.checkFlag(FeatureFlags.spanish_translation)) {
      this.journeyGroup.addControl(
        'language',
        new FormControl(this.journeyContentProperties.language ?? 'en'),
      );
    }
  }

  private _filter(value: string | null): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.apiCampaigns.filter(
        (option) => option?.toLowerCase()?.includes(filterValue) ?? true,
      );
    } else {
      return this.apiCampaigns;
    }
  }

  getCampaigns() {
    this.clientService.getCampaignsByClient(this.clientId).subscribe(
      (campaigns) => {
        this.apiCampaigns = campaigns;
        this.campaigns = this.journeyGroup.get('campaign').valueChanges.pipe(
          startWith(this.journeyContentProperties.campaign),
          map((value) => this._filter(value)),
        );
      },
      (error: HttpErrorResponse) => {
        let reason;
        if (error && error.error && error.error.reason) {
          reason = error.error.reason;
        }
        let message = 'Oops...could not get campaigns.';
        if (reason) {
          message = `Oops...could not get campaigns: ${reason}`;
        }
        this.messageDialog.showMessage(message);
      },
    );
  }

  getTooltipData(errors: Object) {
    if (errors['maxlength']) {
      return `this input has a maximun length of ${errors['maxlength'].requiredLength}`;
    }
    if (errors['required']) {
      return `this input is required`;
    }
    if (typeof errors === 'string') {
      return errors;
    }
  }

  handleChange(val: boolean) {
    this.journeyGroup.controls['bypass_csr_validation'].setValue(!val);
  }

  cancel() {
    this.onCancel.emit();
  }

  updateModel() {
    let formData = this.journeyGroup.getRawValue();
    formData = _.omitBy(formData, _.isNil); // remove keys where value is null, because the api doesn't like it
    this.journeyContentProperties = _.assign(
      this.journeyContentProperties,
      formData,
    );
  }

  save() {
    this.updateModel();
    if (this.journeyGroup.valid) {
      this.onSave.emit(this.journeyContentProperties);
    } else {
      CustomValidators.markAllAsTouched(this.journeyGroup); // replace with this.journeyGroup.markAllAsUntouched when we get to ng8
    }
  }
}
