import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConsentEnum } from '@app/core/models/consent-type';
import { Consent } from '@app/core/models/customer';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { FormatUtils } from '@app/core/utils/format-utils';
import { Permissions, PermissionService } from '@app/core/services/permission.service';

type ConsentParts = Pick<Consent, 'channel_addr' | 'consent_type'>;

/**
 * This mapping handles which options to show on the dropdown. New channels will pass undefined,
 * and so be defaulted to Written|Express.
 * Taken from https://github.com/relaynetwork/rn-v3/blob/374732db1dc4491a0be8b58fc5975b8b66023eb1/server/shared/messaging-data/src/rn/services/data/consent_data/api.clj#L15
 */
const consentChangeMap = {
  [ConsentEnum.WRITTEN]: [ConsentEnum.WRITTEN, ConsentEnum.STOP],
  [ConsentEnum.EXPRESS]: [ConsentEnum.EXPRESS, ConsentEnum.WRITTEN, ConsentEnum.STOP],
  [ConsentEnum.STOP]: [ConsentEnum.STOP, ConsentEnum.WRITTEN],
  undefined: [ConsentEnum.WRITTEN, ConsentEnum.EXPRESS],
} as {
  [consent: string]: string[];
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-customer-consent-list',
    templateUrl: './customer-consent-list.component.html',
    styleUrls: ['./customer-consent-list.component.scss'],
    standalone: false
})
export class CustomerChannelListComponent {
  @Input() customerConsents: Consent[] = [];
  @Output() phoneNumberAdded = new EventEmitter<ConsentParts>();
  @Output() phoneNumberDeleted = new EventEmitter<string>();
  @Output() consentUpdated = new EventEmitter<Consent>();
  @ViewChild('confirmChannelDeleteDialog', {static: true}) confirmChannelDeleteDialog: MessageDialogComponent;

  // We store for display purposes the formatted number on channelBeingAdded.channel_addr; for validation
  // and actual committing to the DB we need to strip out that formatting.
  public channelBeingAdded: ConsentParts = null;
  public consentBeingEdited: Consent = null;
  public channelBeingDeleted: string = null;
  public invalidConsentChangeErr: string = null;
  public invalidAddPhoneErr: string = null;
  permissions = Permissions;
  private escapeString = /["'()\-\s+]/g;

  constructor(
    private permissionService: PermissionService) {
  }

  /**
   * Provided the channel passes validation, triggers the event to actually
   * add the phone number to the system. Calls the cleanup method afterwards.
   */
  public onAddChannel(): void {
    const {channel_addr, consent_type} = this.channelBeingAdded ?? {};
    if (this.validatePhoneAdd(channel_addr, consent_type as ConsentEnum)) {
      // Undoes the formatting the cleanup function does so we save the plain text
      const noVisualDressingNumber = channel_addr.replace(this.escapeString, '');

      this.phoneNumberAdded.emit({
        channel_addr: noVisualDressingNumber,
        consent_type: consent_type as ConsentEnum,
      });
      this.onCancelAddPhone();
    }
  }

  /**
   * Triggers the event to delete a channel.
   */
  public onRemoveChannel() {
    if (this.channelBeingDeleted) {
      this.phoneNumberDeleted.emit(this.channelBeingDeleted.slice(1));
      this.channelBeingDeleted = null;
    }
  }

  public getConsentTypes(consent: Consent): string[] {
    return consentChangeMap[consent.consent_type];
  }

  /**
   * Checks to see if we're editing the effectively same consent.
   * Since editing doesn't allow you to change the phone number,
   * we use that as the PKEY.
   * @param sourceConsent
   * @param targetConsent
   */
  public isMatchingConsent(sourceConsent: Consent, targetConsent: Consent): boolean {
    return sourceConsent.channel_addr === targetConsent?.channel_addr;
  }

  /**
   * Validates the consent changes, and if allowed triggers the consent being updated and performs
   * cleanup work.
   * @param oldConsent
   */
  public onConsentChange(oldConsent: ConsentEnum) {
    const {consent_type} = this.consentBeingEdited ?? {};

    if (
      this.consentBeingEdited &&
      this.validateConsentChange(oldConsent, consent_type as ConsentEnum)
    ) {
      this.consentUpdated.emit({
        ...this.consentBeingEdited,
        source_channel: 'csr',
        consent_type: consent_type,
      });
      this.onCancelEditConsent();
    }
  }

  /**
   * Defaults the channelBeingAdded variable to a new channel,
   * defaulting the type to EXPRESS.
   */
  public onStartAddPhoneNumber() {
    this.channelBeingAdded = {
      channel_addr: '',
      consent_type: ConsentEnum.EXPRESS,
    };
  }

  /**
   * Defaults the consentBeingEdited to a clone of the original object
   * (so that we don't update it by reference by mistake).
   * @param consent
   */
  public onStartEditConsent(consent: Consent) {
    this.invalidConsentChangeErr = null;
    this.consentBeingEdited = {
      ...consent,
    };
  }

  /**
   * Clears out the associated variables for editing.
   */
  public onCancelEditConsent() {
    this.invalidConsentChangeErr = null;
    this.consentBeingEdited = null;
  }

  /**
   * Clears out the associated variables for adding.
   */
  public onCancelAddPhone() {
    this.invalidAddPhoneErr = null;
    this.channelBeingAdded = null;
  }

  /**
   * Asks the user to confirm whether they want to delete the associated channel.
   * @param channelAddr
   */
  public onConfirmChannelDelete(channelAddr: string) {
    this.channelBeingDeleted = channelAddr;
    const formattedNumber = FormatUtils.formatPhoneNumber(channelAddr);
    this.confirmChannelDeleteDialog.showMessage(`Are you sure you want to delete the channel ${formattedNumber}?`);
  }

  /**
   * Checks the following:
   * 1. The phone number is unique.
   * 2. A new phone number can't be added with stop as the type.
   * 3. The number is 10 digits.
   *
   * @param channelAddress - Already formatted as (111) 111-1111
   * @param consentType
   * @private
   */
  private validatePhoneAdd(channelAddress: string, consentType: ConsentEnum): boolean {
    this.invalidAddPhoneErr = null;
    // Undoes the formatting the cleanup function does for validation
    const noVisualDressingNumber = channelAddress.replace(this.escapeString, '');

    if (!consentType || consentType === ConsentEnum.STOP) {
      this.invalidAddPhoneErr =
        'A phone number cannot be added with a stop consent';
      return false;
    }

    if (
      !noVisualDressingNumber ||
      noVisualDressingNumber.length !== 10 ||
      !Number.isInteger(+noVisualDressingNumber)
    ) {
      this.invalidAddPhoneErr = 'A phone number must be 10 digits';
      return false;
    }

    // important that we check for matches AFTER checking for length/validity
    const fullAddr = `1${noVisualDressingNumber}`;
    if (this.customerConsents.find(c => c.channel_addr === fullAddr)) {
      // revert to channelAddress so we can show the nice version of the number
      this.invalidAddPhoneErr = `1${channelAddress} is already a channel for the customer`;
      return false;
    }

    return true;
  }

  /**
   * Checks the following:
   * 1. The new consent must be a valid choice based on the old choice.
   * @param oldConsent
   * @param newConsent
   * @private
   */
  private validateConsentChange(
    oldConsent: ConsentEnum,
    newConsent: ConsentEnum
  ) {
    this.invalidConsentChangeErr = null;
    if (!consentChangeMap[oldConsent].includes(newConsent)) {
      this.invalidConsentChangeErr = `Consent ${oldConsent} can't be changed to ${newConsent}`;
      return false;
    }
    if (oldConsent === newConsent) {
      this.invalidConsentChangeErr = `No changes found.`;
      return false;
    }

    return true;
  }

  /**
   * The dropdowns complain about .value not existing, this just allows us to silence it.
   * @param event
   */
  getSelectedValue(event: Event) {
    // The value exists, ignore TS complaining.
    // @ts-ignore
    return event?.currentTarget.value;
  }

  /**
   * Cleanup function to ensure we only get numeric values in the phone entry field,
   * and that it's only 10 digits.
   */
  forceNumeric(channelAddr: string) {
    this.channelBeingAdded.channel_addr = FormatUtils.formatPhoneNumber(channelAddr);
  }

  /**
   * True if we have any consents.
   */
  hasConsents(): boolean {
    return this.customerConsents.length > 0;
  }

  /**
   * True if we have consents or we're in the middle of adding one.
   */
  showGridHeaders(): boolean {
    return this.hasConsents() || !!this.channelBeingAdded;
  }

  /**
   * True if there are no consents and we're not in the middle of adding one.
   * NOTE: Doesn't check for if the user is actually allowed to, because if they're not permissioned we still want the
   * text to appear.
   */
  showNoConsentMessage(): boolean {
    return !this.hasConsents() && !this.channelBeingAdded;
  }

  /**
   * True if we're looking at the last consent in the list and we're not in the middle of adding a consent and
   * the user is permissioned to add a consent.
   * @param consent
   */
  canAddConsentGrid(consent: Consent): boolean {
    return this.customerConsents.indexOf(consent) === (this.customerConsents.length - 1)
      && !this.channelBeingAdded
      && this.canAddConsent;
  }

  get canEditConsent(): boolean {
    return this.permissionService.checkPermissions(this.permissions.consent_edit);
  }

  get canDeleteConsent(): boolean {
    return this.permissionService.checkPermissions(this.permissions.consent_delete);
  }

  get canAddConsent(): boolean {
    return this.permissionService.checkPermissions(this.permissions.consent_add);
  }
}
