import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { SessionService } from '@app/security/session.service';
import { Permissions, PermissionService } from '@app/core/services/permission.service';
import { LoggerService } from '@app/core/services/logger.service';
import { SftpMgmtService } from '@app/sftp-mgmt/services/sftp-mgmt.service';
import { ClientService } from '@app/core/services/client.service';

import { Client } from '@app/core/models/client';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { SftpUser } from '@app/sftp-mgmt/models/sftp-user';
import { SftpKeyListComponent } from '@app/sftp-mgmt/components/sftp-key-list/sftp-key-list.component';
import { SftpKey } from '@app/sftp-mgmt/models/sftp-key';

@Component({
    selector: 'rn-internal-sftp-mgmt',
    templateUrl: './internal-sftp-mgmt.component.html',
    styleUrls: ['../sftp-mgmt/sftp-mgmt.component.scss'],
    standalone: false
})
export class InternalSftpMgmtComponent implements OnInit {
  permissions = Permissions;
  @Input() client: Client;
  sftpUser: SftpUser;
  sftpKeys: SftpKey[];
  hasUserLoadingError: boolean = false;
  hasKeyLoadingError: boolean = false;
  keyIdToDelete: number; // set when the delete icon is clicked for a key
  sftpAutoFileProcessing: boolean;

  @ViewChild('resultsDialog', {static: true}) resultsDialog: MessageDialogComponent;
  @ViewChild('keyListComponent', {static: true}) keyListComponent: SftpKeyListComponent;

  constructor(
    private sessionService: SessionService,
    public permissionService: PermissionService,
    private sftpMgmtService: SftpMgmtService,
    private clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.refreshUser(this.client.id);
    this.refreshKeys(this.client.id);
    this.refreshClient(this.client.id);
  }

  deleteKey(clientId: string, keyId: number): void {
    this.sftpMgmtService.deleteKey(clientId, keyId).subscribe(() => {
        this.resultsDialog.showMessage('Your key was deleted.')
      }, (error) => {
        LoggerService.log('SftpMgmtComponent', `error deleting SFTP key for ${clientId}.  Key id: ${keyId}.  error: ${JSON.stringify(error)}`)
        this.resultsDialog.showMessage(`There was an error deleting your key: "${error.message}`);
      },
      () => {
        this.refreshKeys(clientId);
      });
  }

  refreshUser(clientId: string) {
    this.hasUserLoadingError = false; // reset any errors
    this.sftpUser = undefined; // set to undefined to trigger loading indicators

    // find the first user in the list which has a username & client id matching the current cllient's client id.
    // Note: clients are only supposed to have 1 user per organization.  This user has the client id as a username.
    // The `users` table in postgres doesn't allow multiple users to have the same username, so we can safely assume
    // that the user w/ the client id as the username is the correct one.
    this.sftpMgmtService.getUser(clientId).subscribe((users: SftpUser[]) => {
      this.sftpUser = users.filter((user) => user.username === clientId && user.client_id === clientId)[0];
    }, (error) => {
      LoggerService.log('SftpMgmtComponent', `error getting users for ${clientId}.  error: ${JSON.stringify(error)}`);
      this.hasUserLoadingError = true;
    });
  }

  updateSftpAutoFileProcessing(event: boolean): void {
    this.clientService.updateAutoFileProcessing(this.sessionService.getCurrentUsersClient().id, event)
      .subscribe((result) => {
        LoggerService.log('Sftp auto file processing', `completed: ${JSON.stringify(result)}`);
      }, (error) => {
        LoggerService.log('Sftp auto file processing', `error: ${JSON.stringify(error)}`);
      });
  }

  refreshClient(clientId: string): void {
    this.sftpAutoFileProcessing = true;

    this.clientService.getClient(clientId, true)
      .subscribe(client => {
        this.sftpAutoFileProcessing = client.automated_file_processing;
      }, (error) => {
        LoggerService.log('Sftp auto file processing', `error initializing sftp auto file processing value: ${JSON.stringify(error)}`)
      });
  }

  handleSaveKey(sshKey: string) {
    const clientId = this.client.id;
    this.sftpMgmtService.createKey(clientId, sshKey).subscribe(() => {
      this.resultsDialog.showMessage('Your key was uploaded successfully.');
    }, (error) => {
      LoggerService.log('SftpMgmtComponent', `error uploading SFTP key for ${clientId}.  Key contents: ${sshKey}.  Error: ${JSON.stringify(error)}`)
      this.resultsDialog.showMessage(`There was an error uploading your key: "${error.message}`)
    }).add(() => {
      this.refreshKeys(clientId);
    });
  }

  refreshKeys(clientId: string): void {
    this.hasKeyLoadingError = false; // reset any errors
    this.sftpKeys = undefined; // set to undefined to trigger loading indicators

    this.sftpMgmtService.getKeys(clientId).subscribe(async (keys) => {
      this.sftpKeys = keys;
    }, () => {
      LoggerService.log('SftpMgmtComponent', `error getting sftp keys for ${clientId}`)
      this.hasKeyLoadingError = true;
    })
  }
}
