import { MediaLibraryModule } from '../media-library/media-library.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AutosaveService } from './services/autosave.service';
import { CxBuilderComponent } from './cx-builder.component';
import { DelayInspectorComponent } from './inspector/delay-inspector/delay-inspector.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { JourneyArrowComponent } from './journey-arrow/journey-arrow.component';
import { JourneyBuilderComponent } from './journey-builder/journey-builder.component';
import { JourneyCopyComponent } from './journey-copy/journey-copy.component';
import { JourneyDelayComponent } from './journey-delay/journey-delay.component';
import { JourneyInspectorComponent } from './inspector/journey-inspector/journey-inspector.component';
import { JourneyListModule } from '../journey-list/journey-list.module';
import { JourneyMessageComponent } from './journey-message/journey-message.component';
import { JourneyMetadataDialogComponent } from './journey-metadata-dialog/journey-metadata-dialog.component';
import { JourneyMetadataPageComponent } from './journey-metadata-page/journey-metadata-page.component';
import { JourneyStopComponent } from './journey-stop/journey-stop.component';
import { JourneyTriggerComponent } from './journey-trigger/journey-trigger.component';
import { MessageBuilderComponent } from './message-builder/message-builder.component';
import { MessageInspectorComponent } from './inspector/message-inspector/message-inspector.component';
import { MessageCopyComponent } from './message-copy/message-copy.component';
import { RelaySharedModule } from '../shared/shared.module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ToolbarService } from './services/toolbar.service';
import { TriggerInspectorComponent } from './inspector/trigger-inspector/trigger-inspector.component';
import { JourneyMetadataProductSelectComponent } from './journey-metadata-product-select/journey-metadata-product-select.component';
import { DynamicInputsService } from './services/dynamic-inputs.service';
import { BoxMenuComponent } from './box-menu/box-menu.component';
import { ActionComponent } from './message-builder/components/action/action.component';
import { ActionTextInputComponent } from './message-builder/components/action/action-text-input/action-text-input.component';
import { ActionService } from '@app/cx-builder/message-builder/services/action.service';
import { IWCUHelperService } from '@app/cx-builder/services/builder-helper.service';
import { PowerUpsActionComponent } from '@app/cx-builder/power-ups-action/power-ups-action.component';
import { PowerUpsPreviewListComponent } from '@app/cx-builder/power-ups-preview-list/power-ups-preview-list.component';
import { PowerUpsService } from '@app/core/services/power-ups.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExperienceLibraryModule } from '@app/experience-library/experience-library.module';
import { ExperienceImportComponent } from '@app/cx-builder/experience-import/experience-import.component';
import { FileLauncherModule } from '@app/file-launcher/file-launcher.module';
import { FormConfigService } from '@app/client-config/client-form/client-power-ups-config/services/form-config-service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RelaySharedModule,
    InfiniteScrollModule,
    JourneyListModule,
    MediaLibraryModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    ExperienceLibraryModule,
    FileLauncherModule,
  ],
  declarations: [
    CxBuilderComponent,
    BoxMenuComponent,
    DelayInspectorComponent,
    JourneyArrowComponent,
    JourneyBuilderComponent,
    JourneyCopyComponent,
    JourneyDelayComponent,
    JourneyInspectorComponent,
    JourneyMessageComponent,
    JourneyMetadataDialogComponent,
    JourneyMetadataPageComponent,
    JourneyStopComponent,
    JourneyTriggerComponent,
    MessageBuilderComponent,
    MessageInspectorComponent,
    ToolbarComponent,
    TriggerInspectorComponent,
    MessageCopyComponent,
    JourneyMetadataProductSelectComponent,
    ActionComponent,
    ActionTextInputComponent,
    PowerUpsActionComponent,
    PowerUpsPreviewListComponent,
    ExperienceImportComponent,
  ],
  exports: [
    CxBuilderComponent,
    JourneyBuilderComponent,
    MessageBuilderComponent,
    JourneyMetadataDialogComponent,
    JourneyMetadataPageComponent,
    JourneyArrowComponent,
  ],
  providers: [
    AutosaveService,
    DynamicInputsService,
    ToolbarService,
    ActionService,
    IWCUHelperService,
    PowerUpsService,
    FormConfigService,
  ],
})
export class CxBuilderModule {}
