import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { TitleService } from '@app/core/services/title.service';
import React from 'react';
import { PowerUpsConfigReactContainer } from './power-ups-config-react-container/PowerUpsConfigReactContainer';
import { ActivatedRoute, Params } from '@angular/router';
import { createRoot, Root } from 'react-dom/client';

@Component({
    selector: 'rn-client-power-ups-config',
    templateUrl: './client-power-ups-config.component.html',
    styleUrls: ['./client-power-ups-config.component.scss'],
    standalone: false
})
export class ClientPowerUpsConfigComponent 
  implements OnInit, OnDestroy, OnChanges, AfterViewInit 
{
  clientId: string;
  reactWrapperId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: TitleService,
  ) {}
  
  root: Root = null;
  ngOnInit(): void {
    this.titleService.activate('Power-Ups');
    this.getClient();
    this.reactWrapperId = 'power-ups-config-wrapper-id';
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    this.titleService.deactivate();
    this.root.unmount();
  }

  ngOnChanges() {
    this.render();
  }

  protected render() {
    if (this.root) {
      this.root.render(
        React.createElement(PowerUpsConfigReactContainer, {
          clientId: this.clientId,
        }),
      );
    }
  }

  ngAfterViewInit(): void {
    const node = document.getElementById(this.reactWrapperId);
    this.root = createRoot(node);
    this.render();
  }
  
  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientId = params['clientId'];
    });
  }
}
